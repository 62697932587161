import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
// import { HashLink } from 'react-router-hash-link';

import './style/style.css';
import './style/responsive.style.css';

import AccessStore from '../../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
        isAdmin: state.User.isAdmin,
    };
}
class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }

        this.accessStore = new AccessStore( this.props.dispatch);
        this.accessStore.callback(() => {
            window.location.href = './';
            window.location.reload();
        })
    }

    componentDidMount() {
        if( window.location.href.split("view-docs").length > 1 ) {
            this.props.action( 1 );
        }

        else if( window.location.href.split("qui-sommes-nous").length > 1 ) {
            this.props.action( 2 );
        }

        else if( window.location.href.split("aof").length > 1 ) {
            this.props.action( 3 );
        }

        else if( window.location.href.split("texte_de_lois").length > 1 ) {
            this.props.action( 4 );
        }
    }

    _isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

    _logout() {
        if( window.confirm('Voulez-vous vraiment vous déconnecter?') ) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }
  
    render() {
        return (
            <div className="menu2_container">
                <ul className="menu2" id="menu2">
                    <li className="menu2_item accueil">
                        <span className="menu2_item_title" onClick={() => {
                            this.props.action( 0 );
                        }}>
                            <Link className="link_menu2" variant="contained" to="/" >
                                RAPPORT D'ÉTUDE
                                {/* ACCUEIL */}
                            </Link>
                        </span>
                        <div className="menu2ItemUnderLine" style={{
                            width: this.props.current === 0 ? "100%" : "0px",
                            opacity: this.props.current === 0 ? 1 : 0,
                        }}></div>
                    </li>

                    <li className="menu2_item about-us">
                        <span className="menu2_item_title" onClick={() => {
                            this.props.action( 1 );
                        }}>
                            <Link className="link_menu2" variant="contained" to="/biblotheque">
                                BIBLIOTHÈQUE
                            </Link>
                        </span>
                        <div className="menu2ItemUnderLine" style={{
                            width: this.props.current === 1 ? "100%" : "0px",
                            opacity: this.props.current === 1 ? 1 : 0,
                        }}></div>
                        {/* <ul className="menu2_item_subMenu2">
                        {
                            this.props.categories.map((category, index) => (
                                <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" key={"menu2-biblio-" + index} onClick={() => {
                                    this.props.action( 1 );
                                }}>
                                    {category.name}
                                </li>
                            ))
                        }
                        </ul> */}
                    </li>

                    <li className="menu2_item une">
                        <span className="menu2_item_title" onClick={() => {
                            this.props.action( 2 );
                        }}>
                            <Link className="link_menu2" variant="contained" to="/qui-sommes-nous">
                                QUI SOMMES NOUS
                            </Link>
                        </span>
                        <div className="menu2ItemUnderLine" style={{
                            width: this.props.current === 2 ? "100%" : "0px",
                            opacity: this.props.current === 2 ? 1 : 0,
                        }}></div>
                    </li>

                    <li className="menu2_item une">
                        <span className="menu2_item_title" onClick={() => {
                            this.props.action( 3 );
                        }}>
                            <Link className="link_menu2" variant="contained" to="/aof">
                                Metiers
                            </Link>
                        </span>
                        <div className="menu2ItemUnderLine" style={{
                            width: this.props.current === 3 ? "100%" : "0px",
                            opacity: this.props.current === 3 ? 1 : 0,
                        }}></div>
                    </li>

                    <li className="menu2_item une">
                        <span className="menu2_item_title" onClick={() => {
                            this.props.action( 4 );
                        }}>
                            <Link className="link_menu2" variant="contained" to="/texte_de_lois">
                                Textes de lois
                            </Link>
                        </span>
                        <div className="menu2ItemUnderLine" style={{
                            width: this.props.current === 4 ? "100%" : "0px",
                            opacity: this.props.current === 4 ? 1 : 0,
                        }}></div>
                    </li>

                    {
                        // this._isObjEmpty( this.props.user ) &&
                        // <li className="menu2_item une">
                        //     <span className="menu2_item_title" onClick={() => {
                        //         this.props.action( 2 );
                        //     }}>
                        //         <Link className="link_menu2" variant="contained" to="/login"
                        //             style={{
                        //                 color: this.props.current === 2 ? "#dc3545" : "#ffffff",
                        //             }}
                        //         >
                        //             SE CONNECTER
                        //         </Link>
                        //     </span>
                        //     <div className="menu2ItemUnderLine" style={{
                        //         width: this.props.current === 2 ? "100%" : "0px",
                        //         opacity: this.props.current === 2 ? 1 : 0,
                        //     }}></div>
                        // </li>
                    }
                    
                    <li className="menu2_item project">
                        <span className="menu2_item_title" onClick={() => {
                            this.props.action( 5 );
                        }}>
                            PLUS
                        </span>
                        <div className="menu2ItemUnderLine" style={{
                            width: this.props.current === 5 ? "100%" : "0px",
                            opacity: this.props.current === 5 ? 1 : 0,
                        }}></div>
                        <ul className="menu2_item_subMenu2">
                            <Link
                                className=""
                                to="./search-doc"
                            >
                                <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" onClick={() => {
                                    this.props.action( 5 );
                                }}>
                                    Recherche approfondie
                                </li>
                            </Link>

                            {/* <Link
                                className=""
                                to="./annuaire"
                            >
                                <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" onClick={() => {
                                    this.props.action( 3 );
                                }}>
                                    Annuaire
                                </li>
                            </Link> */}

                            {
                                this._isObjEmpty(this.props.user ) ?
                                    <div>
                                        <Link
                                            className=""
                                            to="./registration"
                                        >
                                            <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" onClick={() => {
                                                this.props.action( 5 );
                                            }}>
                                                Inscription
                                            </li>
                                        </Link>
                                        <Link
                                            className=""
                                            to="./login"
                                        >
                                            <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" onClick={() => {
                                                this.props.action( 5 );
                                            }}>
                                                Se connecter
                                            </li>
                                        </Link>
                                    </div>
                                :
                                    <div>
                                        <Link
                                            className=""
                                            to="./history"
                                        >
                                            <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" onClick={() => {
                                                this.props.action( 5 );
                                            }}>
                                                Historique des activités
                                            </li>
                                        </Link>
                                        
                                        {
                                            this.props.isAdmin &&
                                                <Link
                                                    className=""
                                                    to="./dashboard"
                                                >
                                                    <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" onClick={() => {
                                                        this.props.action( 5 );
                                                    }}>
                                                        Pannel de contrôle
                                                    </li>
                                                </Link>
                                        }

                                        <Link
                                            className=""
                                            to="./"
                                        >
                                            <li className="menu2_item_subMenu2_text menu2_item_subMenu2_text_" onClick={() => {
                                                this._logout();
                                            }}>
                                                Se deconnecter
                                            </li>
                                        </Link>
                                    </div>
                            }
                        </ul>
                    </li>

                </ul>
            </div>

        )
    }
}


Menu = connect(mapStateToProps, null)(Menu);
export default Menu;