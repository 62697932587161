import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
// import words from 'an-array-of-french-words';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import ProjectCard from '../components/projectCard/projectCard.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home_view.style.css';
import './responsive.style/home_view.responsive.style.css';
import './styles/all_categories.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/

/**
 * Importation des helpers
 */
 import tools from '../helpers/tools.helper';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class BibliothequeDocsView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            categories: [],
            currentCategories: [],
            currentCategoriesIndex: [],

            filterResult: false,

            searchResult: [],

            years: [],
            currentYears: [],

            currentMonths: [],

            filterContent: {},
            searchResultsFilter: [],
            currentResultPage: 1,

            printBasket: false,
            basket: [],
        }

        this.accessStore = new AccessStore( this.props.dispatch);

        this.optionsSelected = {};
        this.tools = new tools();

        this.query = null;
    }

    componentDidMount() {
        this.query = new URLSearchParams(this.props.location.search);
        let init_path = decodeURIComponent(this.query.get("searchPath"));
        // console.log( init_path );

        let categories = init_path.replace(/=/g, "");
        categories = categories.replace(/&/g, "");

        let CategoriesSelectedList = [];

        this._get_all_categories(( cat )=>{
            // console.log( cat );
            if( categories.length === 0 ) {
                CategoriesSelectedList = cat;
                // console.log( cat );
            }

            else {
                cat.map((category, index) => {
                    if( categories.includes(category.pk) ) {
                        CategoriesSelectedList.push( category );
                    }
                });
            }

            this.setState({
                categories: CategoriesSelectedList,
                isLoading: true,
            }, () => {
                this._search_doc(null, init_path, true );
            });
        });

        // console.log( CategoriesSelectedList );
    }

    _search_doc(e, searchPath, getMore=false, currentResultPage=1) {
        if( !getMore ) {
            this.setState({
                filterContent: {},
                searchResultsFilter: [],
                currentResultPage: 1,
            });
        }

        let startTime = new Date();

        this._startSearchRequest( searchPath, currentResultPage, ( response ) => {

            // console.log( response.data );
            if( response.data.count > 0 ) {
                let searchResult = [];

                if( !getMore ) {
                    searchResult = response.data.results;
                }else {
                    searchResult = this.state.searchResult;
                    searchResult = searchResult.concat( response.data.results );
                }
                
                // console.log( searchResult );

                let flt_data = [];
                
                let data_year = {
                    name: "Années",
                    content: [],
                };

                let data_categories = {
                    name: "Catégories",
                    content: [{
                        name: "Tout",
                        number_result: 0,
                        content: [],
                    }],
                };

                searchResult.map((result, index) => {
                    if( data_year.content.length === 0 ) {
                        data_year.content.push(
                            {
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            },
                            {
                                name: "Tout",
                                number_result: 1,
                                content: [index],
                            }
                        );
                    }else {
                        let isContain = false;
                        for( var i=0; i<data_year.content.length; i++) {
                            if( data_year.content[i].name === result.year ) {
                                data_year.content[i].number_result =  data_year.content[i].number_result + 1;
                                data_year.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            data_year.content.push({
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            });
                        }
                    }


                    if( data_categories.content.length === 0 ) {
                        this.state.categories.map((category) => {
                            if( category.pk === result.category ) {
                                data_categories.content.push(
                                    {
                                        name: category.name,
                                        pk: category.pk,
                                        number_result: 1,
                                        content: [index],
                                    }
                                );
                                data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                                data_categories.content[0].content.push(index);
                                return true;
                            }
                        });
                    }else {
                        let isContain = false;
                        for( var i=0; i<data_categories.content.length; i++) {
                            if( data_categories.content[i].pk === result.category ) {
                                data_categories.content[i].number_result =  data_categories.content[i].number_result + 1;
                                data_categories.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            this.state.categories.map((category) => {
                                if( category.pk === result.category ) {
                                    data_categories.content.push(
                                        {
                                            name: category.name,
                                            pk: category.pk,
                                            number_result: 1,
                                            content: [index],
                                        }
                                    );
                                    return true;
                                }
                            });
                        }

                        data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                        data_categories.content[0].content.push(index);
                    }
                });

                flt_data.push( data_year );
                flt_data.push( data_categories );

                // console.log( flt_data );

                this.setState({
                    resultSortedByDate: this.tools._sortByKey(searchResult, 'year'),
                    noResultFound: false,
                    allResultsGet: (response.data.next !== null && response.data.next !== undefined ) ? false : true,
                });
                
                let endTime = new Date();

                this.setState({
                    search: true,
                    searchResult: searchResult,
                    searchResultsFilter: flt_data,
                    searchTime: ( endTime - startTime ) / 1000,
                },() => {
                    this._getAllDocsKeywords(0,this.state.searchResult, (newList) => {
                        this.setState({
                            searchResult: newList,
                        }, () => {
                            // console.log( response.data );
                            if( response.data.next !== null && response.data.next !== undefined ) {
                                this._search_doc(e, searchPath, true, currentResultPage + 1);
                            }else {
                                this.setState({
                                    isLoading: false,
                                });
                            }
                        });
                    });
                });
            }

            else {
                let endTime = new Date();

                this.setState({
                    search: true,
                    isLoading: false,
                    noResultFound: true,
                    searchTime: ( endTime - startTime ) / 1000,
                },() => {
                    window.scrollTo(0, 0);
                });
            }
        });
    }

    _getAllDocsKeywords( index=0, docList, callback=()=>{} ) {
        let doc = docList[index];
        this._getDocKey(doc.id, (keywords) => {
            let docs = docList;
            docs[index]["keywords"] = keywords;
            // console.log( index );
            if( index < docList.length - 1 ) {
                this._getAllDocsKeywords( index + 1, docs, callback );
            }else {
                callback(docs);
            }
        });
    }

    _getDocKey(id, callback=()=>{}) {
        this._getKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocKey(id, callback);
            })
        });
    }

    _startSearchRequest( searchPath, page, callback=()=>{} ) {
        this._search(searchPath, page, this.props.user.access, ( response ) => {
            if( response.status === 200 ) {
                callback( response );
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._startSearchRequest( searchPath, page, callback );
            });
        });
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    /**
     * On met a jour la session utilisateur
     */
     _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='headerFake'></div>


                <div className=''>
                    {
                        this.state.categories.map((category, index) => (
                            <div className='doc-associated-container'>
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2">
                                    <div className="OnProjectsView_body_head_text title">
                                        <span className="">{ category.name }</span>
                                    </div>
                                </div>
                                <div className="OnProjectsView_body_description OnProjectsView_body_actuality">
                                    <div className="OnProjectsView_body_actuality_ justify-center">
                                        {
                                            this.state.searchResult.map((doc, index) => (
                                                category.pk === doc.category &&
                                                <div className='card_list_content OnProjectsView_body_actuality_card'>
                                                    <ProjectCard
                                                        project={doc}
                                                        addToPanier={() => {
                                                            this._updateBasket(doc);
                                                        }}
                                                        link={"/view-doc-info:?doc=" + encodeURIComponent(doc.id) + "&cover=" + encodeURIComponent(doc.img)}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }
}


BibliothequeDocsView = connect(mapStateToProps, null)(BibliothequeDocsView);
export default BibliothequeDocsView;