import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import words from 'an-array-of-french-words';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import CardComponent from '../components/card.component/card.component';
import Menu from '../components/menu/menu.component';
import ImageSlider1 from '../components/imageSlider1/imageSlider1.component';
import ProjectCard from '../components/projectCard/projectCard.component';
import PointerComponent from '../components/pointer.component/pointer.component';
// import IframeDownloadComponent from '../components/iframeDownload.component/iframeDownload.component';


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/on_keyword_view.style.css';
import './responsive.style/on_keyword_view.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/


/**
 * Importation des helpers
 */
import tools from '../helpers/tools.helper';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class OnKeywordView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            searchResult: [],
            filterContent: {},
            searchResultsFilter: [],
            currentResultPage: 1,
            allResultsGet: true,

            resultSortedByDate: [],
            noResultFound: false,

            keydocs: [],
            numberOfKeyword: 0,

            search: true,

            keyword: "",
            isReady: true,
            keyList1: ["Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité", "Informatique", "Sécurité"],
            keyList2: ["Réseaux informatique", "Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité"],
            docList: [
                {
                    name: "Loi n° 2019-40 du 07 novembre 2019",
                    shortDescription: "portant révision de la loi numéro 90-32 du 11 décembre 1990 portant Constitution de la République du Bénin.",
                    cover: './upload/images/bg2.jpg',
                    keywords: [
                        "informatique",
                        "Réseaux sécurisés",
                        "Sécurité informatique",
                    ],
                    date: "07/11/2019",
                },
                {
                    name: "Loi n° 2019-40 du 07 novembre 2020",
                    shortDescription: "portant révision de la loi numéro 90-32 du 11 décembre 1990 portant Constitution de la République du Bénin.",
                    cover: './upload/images/bg3.jpg',
                    keywords: [
                        "informatique",
                        "Réseaux sécurisés",
                        "Sécurité informatique",
                    ],
                    date: "07/11/2019",
                },
                {
                    name: "Loi n° 2019-40 du 07 novembre 2021",
                    shortDescription: "portant révision de la loi numéro 90-32 du 11 décembre 1990 portant Constitution de la République du Bénin.",
                    cover: './upload/images/bg4.jpeg',
                    keywords: [
                        "informatique",
                        "Réseaux sécurisés",
                        "Sécurité informatique",
                    ],
                    date: "07/11/2019",
                },
                {
                    name: "Loi n° 2019-40 du 07 novembre 2022",
                    shortDescription: "portant révision de la loi numéro 90-32 du 11 décembre 1990 portant Constitution de la République du Bénin.",
                    cover: './upload/images/bg5.jpg',
                    keywords: [
                        "informatique",
                        "Réseaux sécurisés",
                        "Sécurité informatique",
                    ],
                    date: "07/11/2019",
                }
            ],

            usersList: {},
        }

        this.accessStore = new AccessStore(this.props.dispatch);
        this.tools = new tools();
        this.query = null;
    }

    componentDidMount() {
        window.onload = () => {window.scrollTo(0, 0);}
        this._start();
    }

    _start( reload=false ) {
        this.query = new URLSearchParams(this.props.location.search);

        this._get_users((accounts) => {
            let usersList = {};
            accounts.map((account, index) => {
                usersList[account.id] = account;
                // console.log(usersList);
                if(index === accounts.length - 1) {
                    this.setState({
                        usersList: usersList,
                    });
                }
            });
        });

        this.setState({
            keyword: decodeURIComponent(this.query.get("keyword")),
        }, () => {
            this._get_all_categories(( categories )=>{
                this.setState({
                    categories : categories,
                }, () => {
                    this._get_attribute("dates", (dates) => {
                        let years = [];
                        dates.map((date, index) => {
                            if( !years.includes(date.year) ) {
                                years.push( date.year );
                            }
                        });
    
                        this.setState({
                            years: years,
                        });

                        this._startSearchKeydocs(this.state.keyword, 1, [], (tab)=>{
                            let number = 0;
                            tab.map((elt, index) => {
                                // console.log( elt );
                                number = number + Number(elt.score);
                            });
                            this.setState({
                                keydocs: tab,
                                numberOfKeyword: number,
                            });
                            this._search_doc(this.state.keyword, false);
                        });
                    });
                });
            });
            if( reload ) {
                window.location.reload();
            }
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _startSearchKeydocs(search, page, tab=[], callback = () => { }) {
        this._searchKeydocs(search, page, (response) => {
            // console.log( response.data );
            if (response.status === 200) {
                tab = tab.concat(response.data.results);

                if(response.data.next !== null && response.data.next !== "null") {
                    this._startSearchKeydocs(search, page+1, tab, callback);
                }
                else {
                    callback(tab);
                }
            }
        }, (error) => {
            this._manageError(error, () => {
                this._startSearchKeydocs(search, page, tab, callback);
            });
        });
    }

    _search_doc(value, getMore=false) {
        if( !getMore ) {
            this.setState({
                filterContent: {},
                searchResultsFilter: [],
                currentResultPage: 1,
            });
        }
        
        this.setState({
            isLoading: true,
            currentSearch: value,
        });

        let startTime = new Date();

        let searchPath = "search=" + value;

        this._startSearchRequest( searchPath, this.state.currentResultPage, ( response ) => {

            // console.log( response.data );
            if( response.data.count > 0 ) {
                let searchResult = [];

                if( !getMore ) {
                    searchResult = response.data.results;
                }else {
                    searchResult = this.state.searchResult;
                    searchResult = searchResult.concat( response.data.results );
                }
                
                // console.log( searchResult );

                let flt_data = [];
                
                let data_year = {
                    name: "Années",
                    content: [],
                };

                let data_categories = {
                    name: "Catégories",
                    content: [{
                        name: "Tout",
                        number_result: 0,
                        content: [],
                    }],
                };

                searchResult.map((result, index) => {
                    if( data_year.content.length === 0 ) {
                        data_year.content.push(
                            {
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            },
                            {
                                name: "Tout",
                                number_result: 1,
                                content: [index],
                            }
                        );
                    }else {
                        let isContain = false;
                        for( var i=0; i<data_year.content.length; i++) {
                            if( data_year.content[i].name === result.year ) {
                                data_year.content[i].number_result =  data_year.content[i].number_result + 1;
                                data_year.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            data_year.content.push({
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            });
                        }
                    }


                    /**les categories */

                    if( data_categories.content.length === 0 ) {
                        this.state.categories.map((category) => {
                            if( category.pk === result.category ) {
                                data_categories.content.push(
                                    {
                                        name: category.name,
                                        pk: category.pk,
                                        number_result: 1,
                                        content: [index],
                                    }
                                );
                                data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                                data_categories.content[0].content.push(index);
                                return true;
                            }
                        });
                    }else {
                        let isContain = false;
                        for( var i=0; i<data_categories.content.length; i++) {
                            if( data_categories.content[i].pk === result.category ) {
                                data_categories.content[i].number_result =  data_categories.content[i].number_result + 1;
                                data_categories.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            this.state.categories.map((category) => {
                                if( category.pk === result.category ) {
                                    data_categories.content.push(
                                        {
                                            name: category.name,
                                            pk: category.pk,
                                            number_result: 1,
                                            content: [index],
                                        }
                                    );
                                    return true;
                                }
                            });
                        }

                        data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                        data_categories.content[0].content.push(index);
                    }
                });

                flt_data.push( data_year );
                flt_data.push( data_categories );

                // console.log( flt_data );

                this.setState({
                    resultSortedByDate: this.tools._sortByKey(searchResult, 'year'),
                    noResultFound: false,
                    allResultsGet: (response.data.next !== null && response.data.next !== undefined ) ? false : true,
                });
                
                let endTime = new Date();

                this.setState({
                    search: true,
                    isLoading: false,
                    searchResult: searchResult,
                    searchResultsFilter: flt_data,
                    searchTime: ( endTime - startTime ) / 1000,
                }, () => {
                    this._getAllDocsKeywords(0,this.state.searchResult, (newList) => {
                        this.setState({
                            searchResult: newList,
                        });
                    });
                });
            }

            else {
                let endTime = new Date();

                this.setState({
                    search: true,
                    isLoading: false,
                    noResultFound: true,
                    searchTime: ( endTime - startTime ) / 1000,
                });
            }
        });
    }

    _startSearchRequest(searchPath, page, callback = () => { }) {
        this._search(searchPath, page, this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._startSearchRequest(searchPath, page, callback);
            });
        });
    }


    _get_attribute(attribute, callback = () => { }) {
        this._getAttribute(attribute, this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_attribute(attribute, callback);
            })
        });
    }

    _getAllDocsKeywords( index=0, docList, callback=()=>{} ) {
        let doc = docList[index];
        this._getDocKey(doc.id, (keywords) => {
            let docs = docList;
            docs[index]["keywords"] = keywords;
            // console.log( index );
            if( index < docList.length - 1 ) {
                this._getAllDocsKeywords( index + 1, docs, callback );
            }else {
                callback(docs);
            }
        });
    }

    _getDocKey(id, callback=()=>{}) {
        this._getKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocKey(id, callback);
            })
        });
    }

    _get_all_categories(callback = () => { }) {
        this._getAllCategories(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    

    _manageError(error, callback = () => { }) {
        if (error.response) {
            if (error.response.status === 401) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if (error.response.data.code === "token_not_valid") {
                    this._refreshUserToken(this.props.user.refresh, (response) => {
                        if (response.status === 200) {
                            this._set_new_user(response);
                            callback();
                        }
                    }, (error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    /**
     * On met a jour la session utilisateur
     */
    _set_new_user(response) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user);
    }
    _openDoc(doc) {
        window.open("/view-doc:?doc=" + encodeURIComponent(doc.url) + "&pk=" + encodeURIComponent(doc.id) + "&output=embed", '_blank', 'location=yes, height=570, width=620, scrollbars=yes,status=yes');
    }

    _logout() {
        if (window.confirm('Voulez-vous vraiment vous déconnecter?')) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                    {/* <figure className='background-fig absolute'>
                        <img src={"./upload/images/bg7.png"} style={{objectFit: "contain"}} alt={this.state.keyword} />
                    </figure> */}

                    <div className='headerFake'></div>

                    <div className='mask3'>
                        <div className="viewContent_">
                            <div className="OnCompetenceView_top">
                                <br/>
                                <br/>
                                <div className="OnProjectsView_top_text">
                                    <span className="OnProjectsView_top_text_ title color-blue"
                                        style={{
                                            textShadow: "2px 2px 5px #00000055"
                                        }}
                                    >
                                        #MOT-CLÉ
                                    </span>
                                </div>
                                <div className="OnProjectsView_top_subtext">
                                    <span className="OnProjectsView_top_subtext_ title color-blue"
                                        style={{
                                            textShadow: "2px 2px 5px #00000055"
                                        }}
                                    >
                                        {this.state.keyword}
                                    </span>
                                </div>
                                {/* <div className="OnCompetenceView_top_subtext">
                                    <span className="OnCompetenceView_top_title title">
                                        #MOT-CLÉ
                                    </span>
                                </div> */}
                                {/* <div className="OnCompetenceView_top_title">
                                    <span>
                                        
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className='viewContent'>
                        <div className="viewContent_"></div>
                        <div className='doc-associated-container'>
                            <div className="OnProjectsView_body_head OnProjectsView_body_head2">
                                {/* <div className="OnProjectsView_body_head_text title">
                                    <span className="">Présentation</span>
                                </div> */}
                                <div className="">
                                    <PointerComponent text={"Statistiques"} />
                                </div>
                            </div>
                            <div className="OnProjectsView_body_description OnProjectsView_body_actuality">
                                <ul className='ul-style2'>
                                    <li>
                                        Nombre de document(s) avec ce mot-clé: <strong className='color-blue'>{this.state.searchResult.length}</strong>
                                    </li>
                                    <li>
                                        Nombre d'apparition du mot-clé: <strong className='color-blue'>{this.state.numberOfKeyword}</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='doc-associated-container'>
                            <div className="OnProjectsView_body_head OnProjectsView_body_head2">
                                <div className="">
                                    <div className="">
                                        <PointerComponent text={"Documents en rapport"} />
                                    </div>
                                </div>
                            </div>
                            <div className="OnProjectsView_body_description OnProjectsView_body_actuality">
                                <div className="OnProjectsView_body_actuality_ justify-center">
                                    {
                                        this.state.searchResult.map((doc, index) => (
                                            <div className='card_list_content OnProjectsView_body_actuality_card'>
                                                <ProjectCard
                                                    addToPanier={() => {
                                                        this._updateBasket(doc);
                                                    }}
                                                    action2={() => {this._start(true)}}
                                                    project={doc}
                                                    owner={this.state.usersList[doc.owner] ? this.state.usersList[doc.owner] : ""}
                                                    link={"/view-doc-info:?doc=" + encodeURIComponent(doc.id) + "&cover=" + encodeURIComponent(doc.img) + "&owner=" + encodeURIComponent(this.state.usersList[doc.owner] ? this.state.usersList[doc.owner].username : "")}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <div className='doc-explorer-section-viewMore_'>
                            {
                                !this.state.allResultsGet ?
                                    <div className='doc-explorer-section-viewMore flex-row align-center justify-center'>
                                        <button className='flex-row align-center justify-center' 
                                            onClick={(e) => {
                                                this.setState({
                                                    currentResultPage: this.state.currentResultPage + 1,
                                                }, () => {
                                                    this._search_doc(this.state.keyword, true);
                                                });
                                            }}
                                        >
                                            <span>
                                                Afficher plus
                                            </span>
                                        </button>
                                    </div>
                                :
                                    null
                            }
                        </div>
                </div>
            </>
        )
    }
}


OnKeywordView = connect(mapStateToProps, null)(OnKeywordView);
export default OnKeywordView;
