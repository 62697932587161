import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import words from 'an-array-of-french-words';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/


/**----------------------------------------------------------------------------------------*/
/**Importation des helpers de la page*/
import Verifier from '../helpers/verifier.helper';


/**
 * 
 * Importation des scomposants
 */
import InputStyle1 from '../components/input-style1.component/input-style1.component';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}


class DashboardAddUsers extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            password_verification_results: {
                number: false,
                string: false,
                special_character: false,
                minimum_character: false,
                like_personal_information: true,
                isOk: false,
                isConfirmed: false,
            }
        }

        this._submit_registration_form = this._submit_registration_form.bind(this);
        this.accessStore = new AccessStore(this.props.dispatch);
        this.Verifier = new Verifier();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _submit_registration_form(e) {
        e.preventDefault();
        if( 
            this.state.password_verification_results.like_personal_information === false &&
            this.state.password_verification_results.minimum_character === true &&
            this.state.password_verification_results.number === true &&
            this.state.password_verification_results.special_character === true && 
            this.state.password_verification_results.string === true &&
            this.state.password_verification_results.isConfirmed === true
        ) {
            if( document.forms["registrationForm"]["password"].value === document.forms["registrationForm"]["confirmPassword"].value ){
                this.setState({
                    isLoading: true,
                });
                let data = {
                    username: document.forms["registrationForm"]["username"].value,
                    first_name: document.forms["registrationForm"]["first_name"].value,
                    last_name: document.forms["registrationForm"]["last_name"].value,
                    email: document.forms["registrationForm"]["email"].value,
                    password: document.forms["registrationForm"]["password"].value,
                };
                // console.log( data );
                this._registration(data, ( response ) => {
                    if( response.status === 201 ) {
                        alert("Compte créé avec succès");
                    }
                    this.setState({
                        isLoading: false,
                    });
                    return true;
                },(error) => {
                    console.log( error.response );
                    if( error.response && error.response.data && error.response.data.password ) {
                        alert("Un soucis à été détecté avec le mot de passe que vous avez entrer.!\nVotre mot de passe doit contenir au minimun 8 caractère et un caractère spéciale");
                    }
                    if( error.response && error.response.data && error.response.data.username ) {
                        alert(error.response.data.username[0]);
                    }
                    if( error.response && error.response.status === 401 ) {
                        alert( error.response.data.detail );
                    }
                    if( e.toString() === "Error: Network Error" ) {
                        alert( "Problème de connexion :(" );
                    }
                    this.setState({
                        isLoading: false,
                    });
                    return true;
                });
            }
            else {
                alert("Veuillez confirmer correctement le mot de passe svp!");
            }
        } else {
            alert("Certaines informations n'ont pas été renseignées correctement.");
        }
    }


    render() {
        return (
            <>
                {
                    super.render()
                }
                
                <div className='dashboardUser'>
                    <div className='text-align-center login_screen_subTitle'>
                        <span className='letter-spacing-1 font-size-1_5 bold text-shadow-ff'>
                            Ajouter un utilisateur
                        </span>
                    </div>
                    <form
                        action="#"
                        target="_self"
                        method="post"
                        name="registrationForm"
                        style={{ background: "transparent" }}
                        onSubmit={(e) => {
                            this._submit_registration_form(e);
                        }}
                    >
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Prénom"
                                name="first_name"
                                type="text"
                                required={false}
                                color="#0A3764ee"
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: "#dc3545",
                                }}
                            />
                        </div>
                        <br />
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Nom"
                                name="last_name"
                                type="text"
                                required={false}
                                color="#0A3764ee"
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: "#dc3545",
                                }}
                            />
                        </div>
                        <br />
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Nom d'utilisateur"
                                type="text"
                                name="username"
                                required={true}
                                color="#0A3764ee"
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: "#dc3545",
                                }}
                            />
                        </div>
                        <br />
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Email"
                                type="email"
                                name="email"
                                required={false}
                                color="#0A3764ee"
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: "#dc3545",
                                }}
                            />
                        </div>
                        <br />
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Mot de passe"
                                type="password"
                                name="password"
                                required={true}
                                color="#0A3764ee"
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: "#dc3545",
                                }}
                                onChange={(value) => {
                                    // console.log( value );
                                    let password_verification_results = this.state.password_verification_results;
                                    let result = this.Verifier._password(value, 8);

                                    password_verification_results.minimum_character = result.minimum_character;
                                    password_verification_results.special_character = result.special_character;
                                    password_verification_results.string = result.string;
                                    password_verification_results.number = result.number;

                                    
                                    if( 
                                        ( document.forms["registrationForm"]["username"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["username"].value.toLowerCase()).length > 1 )||
                                        ( document.forms["registrationForm"]["first_name"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["first_name"].value.toLowerCase()).length > 1 )||
                                        ( document.forms["registrationForm"]["last_name"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["last_name"].value.toLowerCase()).length > 1 )||
                                        ( document.forms["registrationForm"]["email"].value.length > 0 && value.toLowerCase().split(document.forms["registrationForm"]["email"].value.toLowerCase()).length > 1 )
                                    ) {
                                        password_verification_results.like_personal_information = true;
                                    }else {
                                        password_verification_results.like_personal_information = false;
                                    }

                                    if( document.forms["registrationForm"]["confirmPassword"].value.length > 0 &&  document.forms["registrationForm"]["confirmPassword"].value === value ) {
                                        password_verification_results.isConfirmed = true;
                                    }else {
                                        password_verification_results.isConfirmed = false;
                                    }

                                    // console.log( password_verification_results );

                                    this.setState({
                                        password_verification_results: password_verification_results,
                                    });
                                }}
                            />
                        </div>
                        <div className='login_screen_input_container'>
                            <div className='password_requirement'>
                                <span
                                    style={{
                                        color: this.state.password_verification_results.like_personal_information ? "#b57575" : "#5dc269",
                                    }}
                                >
                                    - Votre mot de passe ne peut pas trop ressembler à vos autres informations personnelles.
                                </span>
                                    {
                                        !this.state.password_verification_results.like_personal_information ?
                                            <svg style={{color: "#5dc269",}} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                            </svg>
                                        :
                                            null
                                    }
                            </div>

                            <div className='password_requirement'>
                                <span
                                    style={{
                                        color: !this.state.password_verification_results.minimum_character ? "#b57575" : "#5dc269",
                                    }}
                                >
                                    - Votre mot de passe doit contenir au minimum 8 caractères.
                                </span>
                                    {
                                        this.state.password_verification_results.minimum_character ?
                                            <svg style={{color: "#5dc269",}} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                            </svg>
                                        :
                                            null
                                    }
                            </div>

                            <div className='password_requirement'>
                                <span
                                    style={{
                                        color: !this.state.password_verification_results.number ? "#b57575" : "#5dc269",
                                    }}
                                >
                                    - Votre mot de passe doit contenir au moins un chiffre.
                                </span>
                                    {
                                        this.state.password_verification_results.number ?
                                            <svg style={{color: "#5dc269",}} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                            </svg>
                                        :
                                            null
                                    }
                            </div>

                            <div className='password_requirement'>
                                <span
                                    style={{
                                        color: !this.state.password_verification_results.string ? "#b57575" : "#5dc269",
                                    }}
                                >
                                    - Votre mot de passe doit contenir au moins une lettre.
                                </span>
                                    {
                                        this.state.password_verification_results.string ?
                                            <svg style={{color: "#5dc269",}} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                            </svg>
                                        :
                                            null
                                    }
                            </div>

                            <div className='password_requirement'>
                                <span
                                    style={{
                                        color: !this.state.password_verification_results.special_character ? "#b57575" : "#5dc269",
                                    }}
                                >
                                    - Votre mot de passe doit contenir au moins un des caractères suivant : « @ », « . », « + », « - », « $ » et « _ ».
                                </span>
                                    {
                                        this.state.password_verification_results.special_character ?
                                            <svg style={{color: "#5dc269",}} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                            </svg>
                                        :
                                            null
                                    }
                            </div>
                        </div>
                        <br />
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Confirmer le mot de passe"
                                type="password"
                                name="confirmPassword"
                                required={true}
                                color="#0A3764ee"
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: "#dc3545",
                                }}

                                onChange={(value) => {
                                    let password_verification_results = this.state.password_verification_results;
                                    if( document.forms["registrationForm"]["password"].value.length > 0 &&  document.forms["registrationForm"]["password"].value === value ) {
                                        password_verification_results.isConfirmed = true;

                                        this.setState({
                                            password_verification_results: password_verification_results,
                                        });
                                    }

                                    else {
                                        password_verification_results.isConfirmed = false;

                                        this.setState({
                                            password_verification_results: password_verification_results,
                                        });
                                    }
                                }}
                            />
                        </div>

                        <div className='password_requirement'>
                            <span
                                style={{
                                    color: !this.state.password_verification_results.isConfirmed ? "#b57575" : "#5dc269",
                                }}
                            >
                                Confirmation du mot de passe validée
                            </span>
                                {
                                    this.state.password_verification_results.isConfirmed ?
                                        <svg style={{color: "#5dc269",}} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                        </svg>
                                    :
                                        null
                                }
                        </div>

                        <div className='flex-row justify-center login_screen_submit_container'>
                            <input className='button-style-6' type='submit' value="Ajouter" />
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

DashboardAddUsers = connect(mapStateToProps, null)(DashboardAddUsers);

export default DashboardAddUsers;
