import React from 'react';
import { connect } from 'react-redux';
// import words from 'an-array-of-french-words';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/



/**Importation des helpers */
import tools from '../helpers/tools.helper';




/**
 * 
 * Importation des scomposants
 */
import InputStyle1 from '../components/input-style1.component/input-style1.component';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}


class DashboardKeywords extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            allKeyWords: [],
            _allKeyWords: [],
            currentKeywordsList: [],
            searchKeywordResults: [],
            currentDeleted: 0,
        }

        this._submit = this._submit.bind(this);
        this.accessStore = new AccessStore(this.props.dispatch);

        this.tools = new tools();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            isLoading: true,
        });

        this._start_getKeyword_for_allDocs(0, [], (response, ls) => {
            this._startSearchKeydocs("", 1, [], (res)=>{
                this.setState({
                    isLoading: false,
                });
                let myList = response;
                for(let i=0; i<res.length; i++) {
                    if( myList[res[i]['key_word']] ) {
                        myList[res[i]['key_word']]['score'] = myList[res[i]['key_word']]['score'] + res[i]['score']
                    }
                    if( i === res.length -1 ) {
                        let array = Object.keys(myList).map((key) => myList[key]);
                        array = this.tools._sortByKeyDesc(array, "score");
                        this.setState({
                            allKeyWords: myList,
                            _allKeyWords: array,
                        });
                    }
                }
            });
        });
    }

    _start_getKeyword_for_allDocs(page, tab=[], callback = () => { }) {
        this._getKeyword_for_allDocs((response) => {
            // console.log( response );
            if( response.results ) {
                tab = tab.concat(response.results );
            }else {
                tab = tab.concat(response)
            }
            if( response.next !== null && response.next !== undefined ) {
                this._start_getKeyword_for_allDocs(page+1, tab, callback);
            }
            else {
                let list = {};
                for(let i=0; i<tab.length; i++) {
                    if(!list[tab[i].id]) {
                        list[tab[i].id] = {
                            score: 0,
                            word: tab[i],
                        };
                    }
                }
                callback( list, tab );
            }
        },page);
    }

    _getKeyword_for_allDocs(callback=()=>{}, page) {
        this._getKeywordForAllDocs(this.props.user.access, (response) => {
            // console.log( response.data );
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getKeyword_for_allDocs(callback);
            })
        }, page);
    }

    _startSearchKeydocs(search, page, tab=[], callback = () => { }) {
        this._searchKeydocs(search, page, (response) => {
            // console.log( response.data );
            if (response.status === 200) {
                tab = tab.concat(response.data.results);
                // tab = this.tools._sortByKey(tab, "score");
                // console.log( tab );

                if(response.data.next !== null && response.data.next !== "null") {
                    this._startSearchKeydocs(search, page+1, tab, callback);
                }
                else {
                    callback(tab);
                }
            }
        }, (error) => {
            this._manageError(error, () => {
                this._startSearchKeydocs(search, page, tab, callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else if( error.response.status === 400 ) {
                if( error.response.data.name ) {
                    alert(error.response.data.name[0]);
                }
                this.setState({
                    isLoading: false,
                });
                // console.log( error.response.data );
            }

            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à interagir avec cette section");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _submit(e) {
        e.preventDefault(); 
    }

    _ifAllSearchIsChecked() {
        let allIsChecked = true;
        this.state.searchKeywordResults.map((keyword, index) => {
            if(!this.state.currentKeywordsList.includes(keyword.word.id)) {
                allIsChecked = false;
                return false;
            }
        });
        return allIsChecked;
    }

    _startDeleteAllKeywords(callback=()=>{}) {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isProgress: true,
                    progressionTile: 'Suppressions en court...',
                });
                this._delete_all_keyword(this.state.currentKeywordsList, 0, ()=>{
                    this.setState({
                        isProgress: false,
                        progressionTile: 'Suppressions terminée',
                        requestProgression: 0,
                    });
                    let currentKeywordsList = this.state.currentKeywordsList;
                    let _allKeyWords = this.state._allKeyWords;
                    let _allKeyWords_ = [];

                    let searchKeywordResults = this.state.searchKeywordResults;
                    let searchKeywordResults_ = [];

                    searchKeywordResults.map((keyword, index) => {
                        if(!currentKeywordsList.includes(keyword.word.id)) {
                            searchKeywordResults_.push(keyword);
                        }
                        if( index === searchKeywordResults.length-1 ) {
                            this.setState({
                                searchKeywordResults: searchKeywordResults_,
                            });
                        }
                    });

                    _allKeyWords.map((keyword, index) => {
                        if(!currentKeywordsList.includes(keyword.word.id)) {
                            _allKeyWords_.push(keyword);
                        }
                        if( index === _allKeyWords.length-1 ) {
                            this.setState({
                                currentKeywordsList: [],
                                _allKeyWords: _allKeyWords_,
                            });
        
                            callback();
                            this._openOrCloseAuth();
                        }
                    });
                })
            }
        }, () => {
            this._openOrCloseAuth();
        });
    }

    _startDeleteOneKeywords(id, callback=()=>{}) {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isLoading: true,
                });
                this._delete_on_keyword(id, ()=>{
                    alert("Mot-clé supprimé avec succès");
                    this.setState({
                        isLoading: false,
                    });
                    callback();
                    this._openOrCloseAuth();
                });
            }
        }, () => {
            this._openOrCloseAuth();
        });
    }

    _delete_on_keyword(id, callback=()=>{}) {
        this._deleteOnKeyword(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._delete_on_keyword(id, callback);
            });
        });
    }

    _delete_all_keyword(list, index, callback=()=>{}) {
        if( index < list.length ) {
            this._delete_on_keyword(list[index], ()=>{
                this.setState({
                    requestProgression: Math.round((100 * index+1) / list.length),
                }, () => {
                    this._delete_all_keyword(list, index+1, callback);
                });
            })
        }else {
            callback();
        }
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                
                <div className='dashboardUser'>
                    <div className='text-align-center login_screen_subTitle'>
                        <span className='letter-spacing-2 font-size-1_5 bold text-shadow-ff'>
                            Liste des mots-clés du système : {this.state._allKeyWords.length}
                        </span>
                    </div>
                    <form
                        action="#"
                        target="_self"
                        method="post"
                        name="addGroupForm"
                        style={{ background: "transparent" }}
                        onSubmit={(e) => {
                            this._submit(e);
                        }}
                    >
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Rechercher un mot-clé"
                                name="keyword"
                                type="text"
                                required={false}
                                color="#dc3545"
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: "#dc3545",
                                }}
                                onChange={(value)=>{
                                    let tab = [];
                                    this.state._allKeyWords.map((keyword, index) => {
                                        if( keyword.word.word.toLowerCase() === value.toLowerCase() ||
                                            keyword.word.word.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(keyword);
                                        }
                                    });

                                    this.setState({
                                        searchKeywordResults: tab,
                                    });
                                }}
                            />
                        </div>
                        <br/>

                        <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                            <label htmlFor='selectAllKeywords'>
                                Sélectionner tout les mots-clés:
                            </label>
                            <input id="selectAllKeywords" name="selectAllKeywords" checked={
                                this.state.searchKeywordResults.length > 0 ?
                                    this._ifAllSearchIsChecked()
                                :
                                    this.state._allKeyWords.length===this.state.currentKeywordsList.length
                            } style={{marginLeft: "10px", marginRight: "10px"}} type="checkbox"
                                onChange={(e)=>{
                                    if( this.state.searchKeywordResults.length > 0 ) {
                                        let currentKeywordsList = this.state.currentKeywordsList;
                                        if( e.target.checked === true ) {
                                            this.state.searchKeywordResults.map((keyword, index)=>{
                                                if( !currentKeywordsList.includes(keyword.word.id) ) {
                                                    currentKeywordsList.push(keyword.word.id);
                                                }
                                                e.target.checked = true;
                                            })
                                        }
                                        else {
                                            this.state.searchKeywordResults.map((keyword, index)=>{
                                                if( currentKeywordsList.includes(keyword.word.id) ) {
                                                    currentKeywordsList.splice( currentKeywordsList.indexOf(keyword.word.id), 1);
                                                }
                                            })
                                        }
                                        this.setState({
                                            currentKeywordsList: currentKeywordsList,
                                        });
                                    } else {
                                        let currentKeywordsList = this.state.currentKeywordsList;
                                        if( e.target.checked === true ) {
                                            this.state._allKeyWords.map((keyword, index)=>{
                                                if( !currentKeywordsList.includes(keyword.word.id) ) {
                                                    currentKeywordsList.push(keyword.word.id);
                                                }
                                                e.target.checked = true;
                                            })
                                        }
                                        else {
                                            currentKeywordsList = [];
                                        }
                                        this.setState({
                                            currentKeywordsList: currentKeywordsList,
                                        });
                                    }
                                    return true;
                                }}
                            />
                            {this.state.searchKeywordResults.length > 0 ? this.state.searchKeywordResults.length : this.state._allKeyWords.length}
                        </div>
                        <br/>

                        <div className='flex-row justify-center'>
                            <button className='button-style-6'
                                disabled={!this.state.currentKeywordsList.length>0}
                                style={{
                                    color: this.state.currentKeywordsList.length>0 ? "red" : "#00000044",
                                    borderColor: this.state.currentKeywordsList.length>0 ? "red" : "#00000044",
                                    fontSize: "0.8em",
                                }}
                                onClick={() => {
                                    this._startDeleteAllKeywords(()=>{
                                        alert("Mots-clés supprimés avec succès");
                                    })
                                }}
                            >
                                <span>
                                    Supprimer les mots-clés Sélectionnés
                                </span>
                            </button>
                        </div>

                        <br/>
                        <div className=''>
                            {
                                this.state[this.state.searchKeywordResults.length > 0 ? "searchKeywordResults" : "_allKeyWords"].map((keyword, index) => (
                                    <div className='flex-row justify-between align-center permissionGroup' key={keyword.word.word}>
                                        <div className="flex-row align-center">
                                            <input id={keyword.word.word} name={keyword.word.word} checked={this.state.currentKeywordsList.includes(keyword.word.id)} style={{marginRight: "5px"}} type="checkbox"
                                                onChange={(e)=>{
                                                    let currentKeywordsList = this.state.currentKeywordsList;
                                                    if( e.target.checked === true ) {
                                                        if( !currentKeywordsList.includes(keyword.word.id) ) {
                                                            currentKeywordsList.push(keyword.word.id);
                                                        }
                                                        e.target.checked = true;
                                                    }
                                                    else {
                                                        currentKeywordsList.splice( currentKeywordsList.indexOf(keyword.word.id), 1);
                                                        e.target.checked = false;
                                                    }

                                                    this.setState({
                                                        currentKeywordsList: currentKeywordsList,
                                                    });
                                                    return true;
                                                }}
                                            />
                                            <span className='font-size-1_1'>
                                                <label htmlFor={keyword.word.word}>
                                                    {keyword.word.word}
                                                </label>
                                            </span>
                                        </div>
                                    
                                        <div
                                            className="registration_form_input keyword_options_input_ flex-row flex-wrap"
                                        >
                                            <div className='permission-option keyword-option-score'
                                                style={{
                                                    backgroundColor: this.state.currentKeywordsList.includes(keyword.word.id) ? "#0A376433" : "transparent"
                                                }}
                                            >
                                                <div className='color-blue'>
                                                    <label htmlFor={keyword.word.word}>
                                                        {keyword.score}
                                                    </label>
                                                </div>
                                            </div>
                                            <button className='permission-option'
                                                style={{
                                                    backgroundColor: this.state.currentKeywordsList.includes(keyword.word.id) ? "#0A376433" : "transparent"
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this._startDeleteOneKeywords(keyword.word.id, ()=>{
                                                        let searchKeywordResults = this.state.searchKeywordResults;
                                                        let _allKeyWords = this.state._allKeyWords;
                                                        
                                                        if(searchKeywordResults.length > 0) {
                                                            searchKeywordResults.splice( index, 1);
                                                            _allKeyWords.map((keyword_, index)=>{
                                                                if(keyword_.word.id === keyword.word.id) {
                                                                    _allKeyWords.splice( index, 1);
                                                                    return true;
                                                                }
                                                            })
                                                        }else {
                                                            _allKeyWords.splice( index, 1);
                                                        }

                                                        this.setState({
                                                            searchKeywordResults: searchKeywordResults,
                                                            _allKeyWords: _allKeyWords,
                                                        });
                                                    })
                                                }}
                                            >
                                                <div className='color-red'>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

DashboardKeywords = connect(mapStateToProps, null)(DashboardKeywords);

export default DashboardKeywords;
