import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
// import words from 'an-array-of-french-words';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/





/**
 * 
 * Importation des screens
 */
 import LoginScreen from './login.screen';
 import UserHistory from './user_history.screen';
 import UserProfile from './userProfil.screen';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}


class UserManager extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            currentMenuItem: 0,
            printMenu: false,
            printDashboardMenu: true,
            printDashboard: false,
        }

        // this.words = words;

        this.menuList = [
            {
                icon:   <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" className="bi bi-clock-history" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                            <path fillRule="evenodd" d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                            <path fillRule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>,
                name: "Historique des activités",
                link: "/gestion-compte",
                action: (index)=>{
                    this._changeCurrentMenuIndex(index);
                    return true;
                },
            },
            {
                icon:   <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" className="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                            <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                            <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                        </svg>,
                name: "Mon profil",
                link: "/gestion-compte/profile",
                action: (index)=>{
                    this._changeCurrentMenuIndex(index);
                    return true;
                },
            },
        ]

        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.accessStore.callback((session) => {
            // console.log( session );
            if( session && session.access ) {
                this._get_if_IsAdmin(session.access, (stat) => {
                    this.accessStore.setRedux('SET_IS_ADMIN', stat.isadmin);
                    this.setState({
                        printDashboard: true,
                    });

                    // if( stat.isadmin === true ) {
                    //     this.setState({
                    //         printDashboard: true,
                    //     });
                    // }

                    // else {
                    //     window.location.href = '/';
                    // }
                });
            }
            // console.log( session );
        });
        this.accessStore.init( 'sigb_kando_user', "INIT_USER");
    }

    _changeCurrentMenuIndex( newIndex ) {
        this.setState({
            currentMenuItem: newIndex,
        });
    }

    _logout() {
        if( window.confirm('Voulez-vous vraiment vous déconnecter?') ) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }

    _get_if_IsAdmin(access, callback=()=>{}) {
        this._getIfCurrentUserIsAdmin(access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._get_if_IsAdmin( this.props.user.access, callback );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response, callback );
                            // callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    /**
     * On met a jour la session utilisateur
    */
    _set_new_user( response, callback=()=>{} ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user, () => {
            callback();
        });
    }

    _printOrHideDashboardMenu() {
        let menu = document.getElementById("aside-menu");

        if( this.state.printDashboardMenu ) {
            menu.style.marginLeft = "0px";
        }

        else {
            menu.style.marginLeft = "-400px";
        }

        this.setState({
            printDashboardMenu: !this.state.printDashboardMenu,
        });
    }

    render() {
        return (
            <>
                {
                    this._isObjEmpty( this.props.user ) ?
                        <div>
                            <LoginScreen/>
                        </div>
                    : this.state.printDashboard &&
                    <main className='dashboard'>
                        <aside className='dashboard-menu' id="aside-menu">
                            <div className='dashboard-menu-content bg-color-blue no-view-scroll'>
                                <div className='dashboard-menu-content-head'>
                                    <div className='dashboard-menu-content-head-'>
                                        <div className='dashboard-menu-content-head-circle'></div>
                                        <div className='dashboard-menu-content-head-circle'></div>
                                        <div className='dashboard-menu-content-head-circle'></div>
                                    </div>
                                    <div className='menu-cross-2'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => {
                                                this._printOrHideDashboardMenu();
                                            }}
                                        >
                                            <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                                            <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                                        </svg>
                                    </div>
                                    <Link
                                        className="dashboard-menu-content-head-sub"
                                        to="/"
                                        style={{
                                            color: "#0A3764",
                                        }}
                                    >   
                                        <div className='dashboard-menu-content-head-sub'>
                                            <div className='dashboard-menu-content-head-sub-text family-lato-bold'>
                                                <span className='family-cmuntt'>
                                                    SyGED-DPAF
                                                    {/* SIGB */}
                                                    {/* ZeeZaa */}
                                                </span>
                                            </div>
                                            <div>
                                                <img className='dashboard-menu-content-head-sub-logo' src={this.gLogo} alt="NùYoēn" />
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='dashboard-menu-content-body'>
                                    {
                                        this.menuList.map((menu, menuIndex) => (
                                            <Link
                                                className="dashboard-menu-content-item"
                                                key={this._generateID(36)}
                                                to={menu.link}
                                                onClick={() => {
                                                    menu.action( menuIndex );
                                                }}
                                                style={{
                                                    backgroundColor: this.state.currentMenuItem === menuIndex ? "#122f4c" : "transparent",
                                                }}
                                            >
                                                <div className='dashboard-menu-content-item_'>
                                                    <span className='dashboard-menu-content-item-icon'
                                                        style={{
                                                            color: this.state.currentMenuItem === menuIndex ? "#ffffff" : "#ffffff",
                                                        }}
                                                    >
                                                        { menu.icon }
                                                    </span>
                                                    <span className='dashboard-menu-content-item-text'>
                                                        {menu.name}
                                                    </span>
                                                </div>
                                            </Link>
                                        ))
                                    }
                                </div>

                                {/* <div className='dashboard-menu-content-fot'>
                                    <div className='dashboard-menu-content-fot-content'>
                                        Réaliser par Kandosia
                                    </div>
                                </div> */}
                            </div>
                        </aside>
                        <div className="dashboard-contain">
                            <div className='dashboard-contain-head'>
                                <div className='dashboard-contain-head-content flex-row justify-between'>
                                    <div>
                                        <div className='dashboard-menu-viewer'
                                            onClick={() => {
                                                this._printOrHideDashboardMenu();
                                            }}
                                        >
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='dashboard-contain-head-content-right flex-row justify-end align-center'>
                                        <div className='flex-row align-center border-left-1-primary color-primary dashboard-contain-head-content-right-profile'>
                                            <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                                                <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                                            </svg>
                                            <span className='font-size-0_8 bold letter-spacing-0'>
                                                { this.props.user.username ? this.props.user.username : "" }
                                            </span>
                                        </div>
                                        <div className='flex-row align-center color-primary dashboard-contain-head-content-right-profile'
                                            onClick={() => {
                                                this.setState({
                                                    printMenu: true,
                                                });
                                            }}
                                        >
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-menu-button-wide-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M14 7H2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zM2 6a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H2z"/>
                                                <path fillRule="evenodd" d="M15 11H1v-1h14v1zM2 12.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM1.5 0A1.5 1.5 0 0 0 0 1.5v2A1.5 1.5 0 0 0 1.5 5h13A1.5 1.5 0 0 0 16 3.5v-2A1.5 1.5 0 0 0 14.5 0h-13zm1 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm9.927.427l.396.396a.25.25 0 0 0 .354 0l.396-.396A.25.25 0 0 0 13.396 2h-.792a.25.25 0 0 0-.177.427z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <main className='menu'
                                    style={{
                                        transform: this.state.printMenu ? "translateX(0%)" : "translateX(100%)",
                                    }}
                                >
                                    <div className='menu-cross flex-row justify-end'>
                                        <button className='flex-row justify-center align-center'
                                            onClick={() => {
                                                this.setState({
                                                    printMenu: false,
                                                });
                                            }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                                <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <ul>
                                        <li>
                                            <Link
                                                className=""
                                                key={this._generateID(36)}
                                                to="/"
                                                style={{
                                                    color: "#326b78",
                                                }}
                                            >
                                                {/* <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-power" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z"/>
                                                    <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z"/>
                                                </svg> */}
                                                <span>
                                                    Accueil
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            onClick={() => {
                                                this._logout();
                                            }}
                                        >
                                            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-power" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z"/>
                                                <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z"/>
                                            </svg>
                                            <span>
                                                Se déconnecter
                                            </span>
                                        </li>
                                    </ul>
                                </main>
                            </div>
                            <Switch>
                                <Route path="/gestion-compte/profile" component={UserProfile}/>
                                <Route path="/gestion-compte" component={UserHistory}/>
                            </Switch>
                        </div>
                    </main>
                }
            </>
        )
    }
}

UserManager = connect(mapStateToProps, null)(UserManager);

export default UserManager;