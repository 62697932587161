import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import DocViewer, {DocViewerRenderers} from 'react-doc-viewer';
import SearchInputComponent from '../components/searchInput.component/searchInput.component';




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/upload_pdf.style.css';
import './responsive.style/upload_pdf.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class UploadPdf extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            attribute_target: {},
            selectedList: [],
            selectedPreviewLink: [],
            categories: [],
            currentCategory: {},
            
            systemKeywords: [],
            systemKeywords_selected: [],
            key_word: [],
            
            addKeywords: false,
            docPk: null,

            groupsList: [],
            currentGroupsList: [],
            groupSearchResult: [],

            accountsList: [],
            validAccountsList: [],
            unValidAccountsList: [],
            currentAccountsList: [],
            currentAccountListView: 0,
            searchResult: [],

            authLevelsList: [],

            validationDate: "",

            submitTime: null,
        }

        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
        this._onChange = this._onChange.bind( this );
    }

    componentDidMount() {
        window.onload = () => {window.scrollTo(0, 0);}
        this.setState({
            isLoading: true,
        });

        this._get_all_categories(( categories )=>{
            this.setState({
                categories : categories,
                currentCategory: categories.length > 0 ? categories[0] : {},
            });

            this._get_all_groups((groups) => {
                this.setState({
                    groupsList: groups,
                });

                this._selectAllGroups( groups );

                this._getAllLevel((response)=>{
                    this.setState({
                        authLevelsList: response.results,
                        isLoading: false,
                    });
                })
            });
        });

        this._get_users((accounts) => {
            this.setState({
                accountsList: accounts,
            }, ()=> {
                this._startGetUsersGroups(0, this.state.accountsList, (userList) => {
                    this.setState({
                        accountsList: userList,
                    }, () => {
                        this._startGetUsersPermissions(0, this.state.accountsList, (userList_) => {
                            this.setState({
                                accountsList: userList_,
                            }, () => {
                                let validAccountsList = [];
                                let unValidAccountsList = [];
                                this.state.accountsList.map((account, accountIndex) => {
                                    if( account.permissions.length > 0 ) {
                                        validAccountsList.push( account );
                                    }

                                    else {
                                        unValidAccountsList.push( account );
                                    }
                                });
                                this.setState({
                                    validAccountsList: validAccountsList,
                                    unValidAccountsList: unValidAccountsList,
                                });
                                this._get_all_groups((groups) => {
                                    this.setState({
                                        groupsList: groups,
                                        isLoading: false,
                                    }, () => {
                                        let accountsList = this.state.accountsList;

                                        for(let i=0; i<accountsList.length; i++) {
                                            let not_member_of = [];

                                            for(let j=0; j<this.state.groupsList.length; j++) {

                                                let inGroup = false;

                                                for(let k=0; k<accountsList[i].groups.length; k++) {
                                                    if( this.state.groupsList[j].id === accountsList[i].groups[k].id ) {
                                                        inGroup = true;
                                                        // return true;
                                                    }
                                                }

                                                if( inGroup === false ) {
                                                    let isContent = false;
                                                    
                                                    for(let l=0; l<not_member_of.length; l++) {
                                                        if(not_member_of[l].id === this.state.groupsList[j].id) {
                                                            isContent = true;
                                                        }
                                                    }
                                                    if( isContent === false ) {
                                                        not_member_of = not_member_of.concat( this.state.groupsList[j] );
                                                    }
                                                }
                                            }

                                            // console.log( accountsList[i].username, not_member_of );
                                            accountsList[i]["not_member_of"] = not_member_of;
                                            this.setState({
                                                accountsList: accountsList,
                                            });
                                        }
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _startGetUsersGroups( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_groups(user.id, (groups) => {
                let users = userList;
                users[index]["groups"] = groups.groups;
                if( index < userList.length - 1 ) {
                    this._startGetUsersGroups( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _startGetUsersPermissions( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_permissions(user.id, (permissions) => {
                let users = userList;
                users[index]["permissions"] = permissions.permissions;
                if( index < userList.length - 1 ) {
                    this._startGetUsersPermissions( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _get_user_groups(id, callback=()=>{}) {
        this._getUserGroups(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_groups(callback);
            })
        });
    }

    _get_user_permissions(id, callback=()=>{}) {
        this._getUserPermissions(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_permissions(callback);
            })
        });
    }

    _selectAllGroups(list) {
        let tab = [];

        list.map((group, index) => {
            tab.push(group.id);
        });

        this.setState({
            currentGroupsList: tab,
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log('response ...+++', response.data)
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            })
        });
    }

    _onChange = async (e) => {
        const files = Array.from( e );
        
        let selectedList = e;

        let tab = [];

        Promise.all( files.map( file  => {
            return (
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve( ev.target.result );
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL( file );
                })
            )
        })).then( elt => {

            tab = [elt];

            this.setState({
                selectedList: selectedList,
                selectedPreviewLink: tab,
            });
        });
    }

    _get_search_options(optionsList, index=0, callback=()=>{}) {
        this._getAttribute(optionsList[ index ], this.props.user.access, (response) => {
            if( response.status === 200 ) {
                this.setState({
                    [optionsList[index]] : response.data,
                });
                if( index < optionsList.length - 1 ) {
                    this._get_search_options( optionsList, index + 1, callback);
                }
                else {
                    callback();
                }
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_search_options(optionsList, index, callback);
            })
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                */

                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à ajouter votre document pour un groupe");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    /**
     * On met a jour la session utilisateur
    */
    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }


    _submitDoc(e) {
        e.preventDefault();

        let errorMessage = "Veuillez renseigner les informations suivantes:\n\n";
        let numberMessage = 1;
        let error = false;

        this.state.currentCategory.fields.attributes.map((attribute, index) => {
            // console.log( attribute );
            if( attribute.required === true || attribute.required === "true" ) {
                if( attribute.value.length <= 0 || attribute.value === '' ) {
                    error = true;
                    errorMessage = errorMessage + numberMessage + ": " + attribute.name + "\n";
                    numberMessage ++;
                }
            }
        });
        
        if( error === true ) {
            alert( errorMessage );
            return false;
        }else {
            this.setState({
                authAction: (password) => {
                    this.setState({
                        isProgress: true,
                        progressionTile: 'Envoi du document en court...',
                    });
        
                    let formData = new FormData();
        
                    // console.log( document.forms["submitDocForm"]["month"].value );
        
                    formData.append("img", (document.forms["submitDocForm"]["img"].value !== '' && document.forms["submitDocForm"]["img"].value !== null && document.forms["submitDocForm"]["img"].value !== undefined) ? document.forms["submitDocForm"]["img"].files[0] : null);
                    formData.append("file", this.state.selectedList[0]);
                    formData.append("title", document.forms["submitDocForm"]["title"].value);
                    formData.append("summary", document.forms["submitDocForm"]["summary"].value);
                    formData.append("year", Number(document.forms["submitDocForm"]["year"].value));
                    formData.append("month", Number(document.forms["submitDocForm"]["month"].value));
        
                    // if( Number(document.forms["submitDocForm"]["type"].value) === 0 ) {
                    //     formData.append("authlev", Number(document.forms["submitDocForm"]["type"].value));
                    // }
                    formData.append("validated_at", document.forms["submitDocForm"]["validated_at"].value);
                    formData.append("authlev", Number(document.forms["submitDocForm"]["type"].value));
                    formData.append("category", this.state.currentCategory.pk);
        
                    let data = {};
                    this.state.currentCategory.fields.attributes.map((attribute, index) => {
                        if( attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio" ) {
                            data[attribute.name] = attribute.value;
                        }
                        else {
                            data[attribute.name] = [attribute.value];
                        }
                    });

                    if(this.state.currentCategory.fields.objects) {
                        this.state.currentCategory.fields.objects.map((attribute, index) => {
                            if( attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio" ) {
                                data[attribute.name] = attribute.value;
                            }
                            else {
                                data[attribute.name] = [attribute.value];
                            }
                        });
                    }
        
                    let categoryData = JSON.stringify(data);
        
                    // console.log( categoryData );
        
                    formData.append("data", categoryData);
        
        
                    this._startPostDocument(formData, (response) => {
                        // console.log( response.data );
                        clearInterval( this.submitTime );
                        this._openOrCloseAuth();
        
                        let editors = {
                            "editors": this.state.currentAccountsList,
                            "owners": [response.data.owner],
                        };
                        this._setDocEditors(response.data.id, editors, this.props.user.access, (response) => {
                            return true;
                        });
                        this.setState({
                            docPk: response.data.id,
                            progressionTile: "Extraction terminée. Récupération de mots-clés en court!",
                            requestProgression: 0,
                        });
        
                        this._getDocKey(response.data.id, (keywords) => {
                            let kw = [];
                            keywords.word.map((word) => {
                                kw.push(word);
                            });
                            this.setState({
                                systemKeywords: keywords.word,
                                systemKeywords_selected: kw,
                                addKeywords: true,

                                isProgress: false,
                                progressionTile: "",
                                requestProgression: 0,
                            });
                            this._addDocInGroups(response.data.id);
                        }, (event) => {
                            this.setState({
                                requestProgression: Math.round((100 * event.loaded) / event.total),
                            });
                        });
                    }, (event) => {
                        this.setState({
                            requestProgression: Math.round((100 * event.loaded) / event.total),
                            progressionTile: Math.round((100 * event.loaded) / event.total) === 100 ? "Envoi du document terminé. Extraction de mots-clés en court. Veuillez patienter!" : "Envoi du document",
                        });

                        if( Math.round((100 * event.loaded) / event.total) === 100 ) {
                            this.submitTime = setInterval(() => {
                                this._refreshUserToken(this.props.user.refresh, ( response ) => {
                                    if( response.status === 200 ) {
                                        console.log("refresh_token");
                                        this._set_new_user( response );
                                    }
                                },( error ) => {
                                    // if (error.response) {
                                    //     if (error.response.status === 401) {
                                    //         this.accessStore.setRedux('LOG_OUT', {});
                                    //     }
                                    // }
                                    if (error.toString() === "Error: Network Error") {
                                        alert("Aucun accès à internet :(");
                                    }
                                });
                            }, 120000);
                        }
                    });
                }
            }, () => {
                this._openOrCloseAuth();
            });

        //     let formData = new FormData();

        //     formData.append("img", (document.forms["submitDocForm"]["img"].value !== '' && document.forms["submitDocForm"]["img"].value !== null && document.forms["submitDocForm"]["img"].value !== undefined) ? document.forms["submitDocForm"]["img"].files[0] : null);
        //     formData.append("file", this.state.selectedList[0]);
        //     formData.append("title", document.forms["submitDocForm"]["title"].value);
        //     formData.append("summary", document.forms["submitDocForm"]["summary"].value);
        //     formData.append("year", Number(document.forms["submitDocForm"]["year"].value));
        //     formData.append("month", Number(document.forms["submitDocForm"]["month"].value));

        //     // if( Number(document.forms["submitDocForm"]["type"].value) === 0 ) {
        //     //     formData.append("authlev", Number(document.forms["submitDocForm"]["type"].value));
        //     // }
        //     formData.append("validated_at", document.forms["submitDocForm"]["validated_at"].value)
        //     // console.log('validated_at',  document.forms["submitDocForm"]["validated_at"].value)
        //     formData.append("authlev", Number(document.forms["submitDocForm"]["type"].value))
        //     formData.append("category", this.state.currentCategory.pk)

        //     let data = {};
        //     this.state.currentCategory.fields.attributes.map((attribute, index) => {
        //         if( attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio" ) {
        //             data[attribute.name] = attribute.value;
        //         }
        //         else {
        //             data[attribute.name] = [attribute.value];
        //         }
        //     });

        //     let categoryData = JSON.stringify(data);

        //     // console.log( categoryData );

        //     formData.append("data", categoryData);


        //    this._startPostDocument(formData, (response) => {
        //         // console.log( response.data );
        //         this._openOrCloseAuth();

        //         this.setState({
        //             docPk: response.data.id,
        //             progressionTile: "Extraction terminée. Récupération des mots-clés en court!",
        //             requestProgression: 0,
        //         });

        //         this._getDocKey(response.data.id, (keywords) => {
        //             let kw = [];
        //             keywords.word.map((word) => {
        //                 kw.push(word);
        //             });
        //             this.setState({
        //                 systemKeywords: keywords.word,
        //                 systemKeywords_selected: kw,
        //                 addKeywords: true,

        //                 isProgress: false,
        //                 progressionTile: "",
        //                 requestProgression: 0,
        //             });
        //             this._addDocInGroups(response.data.id);
        //         }, (event) => {
        //             this.setState({
        //                 requestProgression: Math.round((100 * event.loaded) / event.total),
        //             });
        //         });
        //     }, (event) => {
        //         this.setState({
        //             requestProgression: Math.round((100 * event.loaded) / event.total),
        //             progressionTile: Math.round((100 * event.loaded) / event.total) === 100 ? "Envoi du document terminé. Extraction des mots-clés en court. Veuillez patienter !" : "Envoi du document",
        //         });
        //     });
         }

    }

    _addDocInGroups(pk) {
        let other_data = {
            documents: [pk],
            groups: this.state.currentGroupsList,
        };
        this._add_doc_to_group(other_data, () => {
            this.setState({
                isLoading: false,
                currentGroupsList: [],
            });
        });
    }

    _add_doc_to_group( data, callback=()=>{} ) {
        this._addAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_doc_to_group(data, callback);
            });
        });
    }

    _addKeywordsForDoc() {
        this.setState({
            isLoading: true,
        });
        let keywords = JSON.stringify(this.state.key_word);
        this._startAddKeywordForDoc(this.state.docPk, keywords, () => {
            alert("Document ajouté avec succès");
            this.setState({
                addKeywords: false,
                isLoading: false,
            });
        });
    }

    _startPostDocument(data, callback=()=>{}, onUpload) {
        this._postDocument(data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 201 ) {
                callback(response);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._startPostDocument(data, callback, onUpload);
            })
        }, onUpload);
    }

    _getDocKey(id, callback=()=>{}, onProgress) {
        this._getKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocKey(id, callback);
            });
        }, onProgress);
    }

    _getAllLevel( callback=()=>{} ) {
        this._getLevel(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getAllLevel( callback );
            });
        });
    }

    _startAddKeywordForDoc(id, data, callback=()=>{}) {
        this._addKeyword(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 201 ) {
                callback();
            }
        }, (error) => {
            this._manageError( error, () => {
                this._startAddKeywordForDoc(id, data, callback);
            })
        });
    }

    _changeCurrentCategory(pk) {
        let categories = this.state.categories;

        for( let i=0; i<categories.length; i++ ) {
            if( categories[i].pk === Number(pk) ) {
                this.setState({
                    currentCategory: categories[i],
                });
                
                return true;
            }
        }
    }

    _selectAllUsers() {
        let tab = [];
        if( this.state.currentAccountListView === 0 ) {
            this.state.accountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else if( this.state.currentAccountListView === 1 ) {
            this.state.validAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else {
            this.state.unValidAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        this.setState({
            currentAccountsList: tab,
        });
    }

    _unSelectAllUsers() {
        this.setState({
            currentAccountsList: [],
        });
    }
   
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='profile_container'>
                    <div className='profile_container_title color_primary'>
                        <span>
                            Ajouter un document
                        </span>
                    </div>
                    
                    <div className='sectionBody'>
                        <form className='registration_form_fm'
                            action="#"
                            target="_self"
                            method="post"
                            name="submitDocForm"
                            style={{background: "transparent"}}
                            onSubmit = {(e) => {
                                this._submitDoc(e);
                            }}
                        >

                            <table className='tab-style-1' >
                                <tr
                                    className="table-head-style1" 
                                >
                                    <th valign="middle">
                                        <span className="font-size-1_2" >
                                            Nom
                                        </span>
                                    </th>

                                    <th valign="middle">
                                        <span className="font-size-1_2">
                                            Valeur
                                        </span>
                                    </th>
                                </tr>

                                {
                                    this.state.categories.length > 0 && this.state.currentCategory.pk &&
                                        <tr  className='registration_form_inpContainer registration_form_cover'>
                                            <td className='registration_form_label letter-spacing-1' >
                                                <label>
                                                    Catégorie du document 
                                                </label>
                                            </td>
                                            <td>
                                                <select name='doc_category' className='upload_doc_select button-style-5' value={this.state.currentCategory.pk}
                                                    style={{marginTop: "10px"}}
                                                    onChange={(e) => {
                                                        this._changeCurrentCategory(e.target.value);
                                                    }}
                                                >
                                                    {
                                                        this.state.categories.map((category, categoryIndex) => (
                                                            <option value={category.pk} key={this._generateID(36)}>
                                                                {category.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                        </tr>
                                }

                                <tr className='registration_form_inpContainer registration_form_cover'>
                                    <td className='registration_form_label letter-spacing-1'>
                                        <label>
                                            Niveau d'authentification du document 
                                        </label>
                                    </td>
                                    <td className='registration_form_input'>
                                        <select  name="type" className='upload_doc_select button-style-5' defaultValue={1}> {/*required*/}
                                            {
                                                this.state.authLevelsList.map((level, index) => (
                                                    <option key={level.name + index} value={level.id}>
                                                        { level.name }
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                </tr>

                                <tr className='registration_form_inpContainer registration_form_cover'>
                                    <td className='registration_form_label letter-spacing-1'>
                                        <label>
                                            Titre du document 
                                        </label>
                                    </td>
                                    <td className='registration_form_input'>
                                        <input required className='input-style-2 input-style-3' type='text' name='title'/>
                                    </td>
                                </tr>

                                <tr className='registration_form_inpContainer registration_form_cover'>
                                    <td className='registration_form_label letter-spacing-1'>
                                        <label>
                                            Image de garde du document 
                                        </label>
                                    </td>
                                    <td className='registration_form_input'>
                                        <input type='file' required name='img' accept="image/*,.gif,.GIF,.Gif" className='registration_form_input_file button-style-5'/>
                                    </td>
                                </tr>

                            <tr className='registration_form_inpContainer registration_form_cover'>
                                <td className='registration_form_label letter-spacing-1'>
                                    <label>
                                        Année de publication 
                                    </label>
                                </td>
                                <td className='registration_form_input'>
                                    <input required className='input-style-2 input-style-3' type='number' name='year' defaultValue={Number(new Date().getFullYear())} min={1500} max={ Number(new Date().getFullYear()) }/>
                                </td>
                            </tr>

                            <tr className='registration_form_inpContainer registration_form_cover'>
                                <td className='registration_form_label  letter-spacing-1'>
                                    <label>
                                        Mois de publication 
                                    </label>
                                </td>
                                <td className='registration_form_input'>
                                    <select required name="month" className='upload_doc_select button-style-5' defaultValue={1}
                                        onChange={(e) => {
                                            if( document.forms["submitDocForm"]["validated_at"].value !== "" ) {
                                                let dateSelected = new Date(document.forms["submitDocForm"]["validated_at"].value);
                                                let publicationDate = new Date( Number(document.forms["submitDocForm"]["year"].value) + "-" + e.target.value + "-01" );

                                                if( dateSelected.getTime() < publicationDate.getTime() ) {
                                                    this.setState({
                                                        validationDate: "",
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        {
                                            this.month.map((elt, index) => (
                                                <option key={elt.name + index} value={elt.number}>
                                                    { elt.name }
                                                </option>
                                            ))
                                        }
                                    </select>
                                </td>
                            </tr>

                            <tr className='registration_form_inpContainer registration_form_cover'>
                                <td className='registration_form_label  letter-spacing-1'>
                                    <label>
                                        Date de validation du document 
                                    </label>
                                </td>
                                <td className='registration_form_input'>
                                    <input className='input-style-2 button-style-5' type='date' name='validated_at' min={1500} max={ new Date().toLocaleDateString('en-ca') }
                                        value={this.state.validationDate}

                                        onChange={(e) => {
                                            // console.log( e.target.value );
                                            let dateSelected = new Date(e.target.value);
                                            let currentDate = new Date();

                                            let publicationDate = new Date( Number(document.forms["submitDocForm"]["year"].value) + "-" + document.forms["submitDocForm"]["month"].value + "-01" );

                                            // console.log( publicationDate );
                                            
                                            if( dateSelected.getTime() < currentDate.getTime() ) {
                                                if( dateSelected.getTime() > publicationDate.getTime() ) {
                                                    this.setState({
                                                        validationDate: e.target.value,
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </td>
                            </tr>
                            </table>

                           <br/>
                            {/* <div
                                style={{
                                    color: "#5dc269"
                                }}
                            >
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-newspaper" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
                                    <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                                </svg>
                            </div>  */}

                            <div>
                                {
                                    this.state.currentCategory.name &&
                                    this.state.currentCategory.fields.attributes.map((attribute, attrIndex) => (
                                        <div className='registration_form_inpContainer registration_form_cover' key={attribute + "-" + attrIndex}>
                                            <div className='registration_form_label  font-size-1_2 bold letter-spacing-1'>
                                                <label>
                                                    {attribute.name}
                                                </label>
                                            </div>
                                            <br/>
                                            <div>
                                                {
                                                    attribute.type === "text-multi-line" ?
                                                        <div>
                                                            <textarea className='upload_doc_select' type='text' value={attribute.value}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "200px",
                                                                    marginTop: "10px",
                                                                    resize: "none",
                                                                }}
                                                                onChange={(e) => {
                                                                    let currentCategory = this.state.currentCategory;
                                                                    currentCategory.fields.attributes[attrIndex].value = e.target.value;

                                                                    this.setState({
                                                                        currentCategory: currentCategory,
                                                                    }, () => {
                                                                        e.target.focus();
                                                                    });
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                    :
                                                    (
                                                        attribute.type === "list" ?
                                                            (
                                                                attribute.multi_choice === false ?
                                                                    <select required value={attribute.value[0]} className='upload_doc_select' key={this._generateID(36)}
                                                                    
                                                                        onChange={(e) => {
                                                                            let currentCategory = this.state.currentCategory;
                                                                            currentCategory.fields.attributes[attrIndex].value[0] = e.target.value;

                                                                            this.setState({
                                                                                currentCategory: currentCategory,
                                                                            });
                                                                        }}>
                                                                        <option selected disabled>
                                                                            choisir
                                                                        </option>
                                                                        {
                                                                            attribute.values.map((value, index) => (
                                                                                <option key={this._generateID(36)} value={value}>
                                                                                    { value }
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                :
                                                                    <div className='registration_form_input'>
                                                                        {
                                                                            attribute.values.map((value, index) => (
                                                                                <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                    <input id={attribute.name + "/" + value} name={attribute.name + "/" + value} type='checkbox' checked={attribute.value.includes(value)}
                                                                                        onChange={(e)=>{
                                                                                            let currentCategory = this.state.currentCategory;
                                                                                            
                                                                                            if( e.target.checked === true ) {
                                                                                                currentCategory.fields.attributes[attrIndex].value.push(value);
                                                                                            }
                                                                                            else {
                                                                                                currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                            }

                                                                                            this.setState({
                                                                                                currentCategory: currentCategory,
                                                                                            });
                                                                                            return true;
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor={attribute.name + "/" + value}>
                                                                                        {value}
                                                                                    </label>
                                                                                </label>
                                                                            ))
                                                                        }
                                                                    </div>
                                                            )
                                                        :
                                                            (
                                                                attribute.type === "date" ?
                                                                    <div>
                                                                        <input className='upload_doc_select' type='date' value={attribute.value}
                                                                            onChange={(e) => {
                                                                                let currentCategory = this.state.currentCategory;
                                                                                currentCategory.fields.attributes[attrIndex].value = e.target.value;

                                                                                this.setState({
                                                                                    currentCategory: currentCategory,
                                                                                }, () => {
                                                                                    e.target.focus();
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                :
                                                                    (
                                                                        attribute.type === "text-one-line" ?
                                                                            <div>
                                                                                <input className='upload_doc_select' type='text' placeholder='Saisir du texte' value={attribute.value}
                                                                                    onChange={(e) => {
                                                                                        let currentCategory = this.state.currentCategory;
                                                                                        currentCategory.fields.attributes[attrIndex].value = e.target.value;
        
                                                                                        this.setState({
                                                                                            currentCategory: currentCategory,
                                                                                        }, () => {
                                                                                            e.target.focus();
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        :
                                                                            (
                                                                                attribute.type === "number" ?
                                                                                    <div>
                                                                                        <input className='upload_doc_select' type='number' placeholder='Saisir une valeur numé' value={attribute.value}
                                                                                            min={attribute.min}
                                                                                            max={attribute.max}
                                                                                            onChange={(e) => {
                                                                                                let currentCategory = this.state.currentCategory;
                                                                                                currentCategory.fields.attributes[attrIndex].value = e.target.value;
                                                                                                this.setState({
                                                                                                    currentCategory: currentCategory,
                                                                                                });

                                                                                                // let min = currentCategory.fields.attributes[attrIndex].min;
                                                                                                // let max = currentCategory.fields.attributes[attrIndex].max;

                                                                                                // // console.log( min, max );

                                                                                                // let isValid = true;

                                                                                                // if( e.target.value !== '' ) {
                                                                                                //     let value = Number(e.target.value);

                                                                                                //     if( min !== undefined ) {
                                                                                                //         // if( value >= Number(min) ) {
                                                                                                //         //     currentCategory.fields.attributes[attrIndex].value = value;
                                                                                                //         // }
                                                                                                //         // else {
                                                                                                //         //     isValid = false;
                                                                                                //         // }
                                                                                                //     }else {
                                                                                                //         currentCategory.fields.attributes[attrIndex].value = e.target.value;
                                                                                                //     }

                                                                                                //     if( max !== undefined ) {
                                                                                                //         if( value <= Number(max) ) {
                                                                                                //             currentCategory.fields.attributes[attrIndex].value = value;
                                                                                                //         }
                                                                                                //         else {
                                                                                                //             isValid = false;
                                                                                                //         }
                                                                                                //     }else {
                                                                                                //         currentCategory.fields.attributes[attrIndex].value = e.target.value;
                                                                                                //     }
                                                                                                // }

                                                                                                // else {
                                                                                                //     currentCategory.fields.attributes[attrIndex].value = e.target.value;
                                                                                                // }
                                                                                                

                                                                                                // if( isValid ) {
                                                                                                //     this.setState({
                                                                                                //         currentCategory: currentCategory,
                                                                                                //     }, () => {
                                                                                                //         e.target.focus();
                                                                                                //     });
                                                                                                // }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                :
                                                                                    attribute.type === "select" ?
                                                                                        (
                                                                                            <select required value={attribute.value[0]} className='upload_doc_select' key={this._generateID(36)}
                                                                                                
                                                                                                onChange={(e) => {
                                                                                                    let currentCategory = this.state.currentCategory;
                                                                                                    currentCategory.fields.attributes[attrIndex].value[0] = e.target.value;
                        
                                                                                                    this.setState({
                                                                                                        currentCategory: currentCategory,
                                                                                                    });
                                                                                                }}>
                                                                                                <option selected disabled>
                                                                                                    choisir
                                                                                                </option>
                                                                                                {
                                                                                                    attribute.values.map((value, index) => (
                                                                                                        <option key={this._generateID(36)} value={value}>
                                                                                                            { value }
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        )
                                                                                    :
                                                                                        (
                                                                                            attribute.type === "checkbox" ?
                                                                                                    <div className='registration_form_input'>
                                                                                                        {
                                                                                                            attribute.values.map((value, index) => (
                                                                                                                <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                                                    <input id={attribute.name + "/" + value} name={attribute.name + "/" + value} type='checkbox' checked={attribute.value.includes(value)}
                                                                                                                        onChange={(e)=>{
                                                                                                                            let currentCategory = this.state.currentCategory;
                                                                                                                            
                                                                                                                            if( e.target.checked === true ) {
                                                                                                                                currentCategory.fields.attributes[attrIndex].value.push(value);
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                                            }
                                
                                                                                                                            this.setState({
                                                                                                                                currentCategory: currentCategory,
                                                                                                                            });
                                                                                                                            return true;
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <label htmlFor={attribute.name + "/" + value}>
                                                                                                                        {value}
                                                                                                                    </label>
                                                                                                                </label>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                :
                                                                                                    (
                                                                                                        attribute.type === "radio" ?
                                                                                                            <div className='registration_form_input'>
                                                                                                                {
                                                                                                                    attribute.values.map((value, index) => (
                                                                                                                        <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                                                            <input id={attribute.name + "/" + value} name={attribute.name} type='radio' checked={attribute.value.includes(value)}
                                                                                                                                onChange={(e)=>{
                                                                                                                                    let currentCategory = this.state.currentCategory;
                                                                                                                                    
                                                                                                                                    if( e.target.checked === true ) {
                                                                                                                                        currentCategory.fields.attributes[attrIndex].value = [value];
                                                                                                                                    }
                                                                                                                                    // else {
                                                                                                                                    //     currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                                                    // }

                                                                                                                                    // console.log( currentCategory.fields.attributes[attrIndex].value );
                                        
                                                                                                                                    this.setState({
                                                                                                                                        currentCategory: currentCategory,
                                                                                                                                    });
                                                                                                                                    return true;
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <label htmlFor={attribute.name + "/" + value}>
                                                                                                                                {value}
                                                                                                                            </label>
                                                                                                                        </label>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </div>
                                                                                                        :
                                                                                                            null
                                                                                                    )
                                                                                        )
                                                                            )
                                                                    )
                                                            )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>



                            <div>
                                {
                                    this.state.currentCategory.name && this.state.currentCategory.fields.objects &&
                                    this.state.currentCategory.fields.objects.map((attribute, attrIndex) => (
                                        <div className='registration_form_inpContainer registration_form_cover' key={attribute + "-" + attrIndex}>
                                            <div className='registration_form_label  font-size-1_2 bold letter-spacing-1'>
                                                <label>
                                                    {attribute.name}
                                                </label>
                                            </div>
                                            <br/>
                                            <div>
                                                {
                                                   
                                                    (
                                                        attribute.type === "list" ?
                                                            (
                                                                attribute.multi_choice === false ?
                                                                    <select required className='upload_doc_select' key={this._generateID(36)}
                                                                    
                                                                        onChange={(e) => {
                                                                            let currentCategory = this.state.currentCategory;
                                                                            currentCategory.fields.attributes[attrIndex].value[0] = e.target.value;

                                                                            this.setState({
                                                                                currentCategory: currentCategory,
                                                                            });
                                                                        }}>
                                                                        <option selected disabled>
                                                                            choisir
                                                                        </option>
                                                                        {
                                                                            attribute.values.map((value, index) => (
                                                                                <option key={(value.name ? value.name : ( value.username ? value.username : null)) + index}>
                                                                                    { value.name ? value.name : ( value.username ? value.username : null) }
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                :
                                                                    <div className='registration_form_input'>
                                                                        {
                                                                            attribute.values.map((value, index) => (
                                                                                <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                    <input id={attribute.name + "/" + value} name={attribute.name + "/" + value} type='checkbox' checked={attribute.value.includes(value)}
                                                                                        onChange={(e)=>{
                                                                                            let currentCategory = this.state.currentCategory;
                                                                                            
                                                                                            if( e.target.checked === true ) {
                                                                                                currentCategory.fields.attributes[attrIndex].value.push(value);
                                                                                            }
                                                                                            else {
                                                                                                currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                            }

                                                                                            this.setState({
                                                                                                currentCategory: currentCategory,
                                                                                            });
                                                                                            return true;
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor={(value.name ? value.name : ( value.username ? value.username : null)) + "/" + index}>
                                                                                        {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                    </label>
                                                                                </label>
                                                                            ))
                                                                        }
                                                                    </div>
                                                            )
                                                        :
                                                            (
                                                               
                                                                    (
                                                                            (
                                                                                    attribute.type === "select" ?
                                                                                        (
                                                                                            <select value={this.state.currentCategory.fields.objects[attrIndex].value[0]} required className='upload_doc_select' key={this._generateID(36)}
                                                                                                
                                                                                                onChange={(e) => {
                                                                                                    let currentCategory = this.state.currentCategory;
                                                                                                    currentCategory.fields.objects[attrIndex].value[0] = e.target.value;
                        
                                                                                                    this.setState({
                                                                                                        currentCategory: currentCategory,
                                                                                                    });
                                                                                                }}>
                                                                                                <option selected disabled>
                                                                                                    choisir
                                                                                                </option>
                                                                                                {
                                                                                                    attribute.values.map((value, index) => (
                                                                                                        <option value={value.name ? value.name : ( value.username ? value.username : null)} key={(value.name ? value.name : ( value.username ? value.username : null))+index}>
                                                                                                            { value.name ? value.name : ( value.username ? value.username : null) }
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        )
                                                                                    :
                                                                                        (
                                                                                            attribute.type === "checkbox" ?
                                                                                                    <div className='registration_form_input'>
                                                                                                        {
                                                                                                            attribute.values.map((value, index) => (
                                                                                                                <label key={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} className='add_content_checkBox_'>
                                                                                                                    <input id={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} name={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} type='checkbox' checked={attribute.value.includes(value.name ? value.name : ( value.username ? value.username : null))}
                                                                                                                        onChange={(e)=>{
                                                                                                                            let currentCategory = this.state.currentCategory;
                                                                                                                            
                                                                                                                            if( e.target.checked === true ) {
                                                                                                                                currentCategory.fields.objects[attrIndex].value.push(value.name ? value.name : ( value.username ? value.username : null));
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                currentCategory.fields.objects[attrIndex].value.splice( currentCategory.fields.objects[attrIndex].value.indexOf(value), 1);
                                                                                                                            }
                                
                                                                                                                            this.setState({
                                                                                                                                currentCategory: currentCategory,
                                                                                                                            });
                                                                                                                            return true;
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <label htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))}>
                                                                                                                        {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                                                    </label>
                                                                                                                </label>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                :
                                                                                                    (
                                                                                                        attribute.type === "radio" ?
                                                                                                            <div className='registration_form_input'>
                                                                                                                {
                                                                                                                    attribute.values.map((value, index) => (
                                                                                                                        <label key={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} className='add_content_checkBox_'>
                                                                                                                            <input id={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} name={attribute.name} type='radio' checked={attribute.value.includes(value.name ? value.name : ( value.username ? value.username : null))}
                                                                                                                                onChange={(e)=>{
                                                                                                                                    let currentCategory = this.state.currentCategory;
                                                                                                                                    
                                                                                                                                    if( e.target.checked === true ) {
                                                                                                                                        currentCategory.fields.objects[attrIndex].value = [value.name ? value.name : ( value.username ? value.username : null)];
                                                                                                                                    }
                                                                                                                                    // else {
                                                                                                                                    //     currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                                                    // }

                                                                                                                                    // console.log( currentCategory.fields.attributes[attrIndex].value );
                                        
                                                                                                                                    this.setState({
                                                                                                                                        currentCategory: currentCategory,
                                                                                                                                    });
                                                                                                                                    return true;
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <label htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))}>
                                                                                                                                {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                                                            </label>
                                                                                                                        </label>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </div>
                                                                                                        :
                                                                                                            null
                                                                                                    )
                                                                                        )
                                                                            )
                                                                    )
                                                            )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>



                            <div className='registration_form_inpContainer registration_form_cover'>
                                <div className='registration_form_label  letter-spacing-1'>
                                    <span className="font-size-1_2 bold">
                                        Liste des groupes autorisés
                                    </span>
                                </div>

                                <br/>
                                <div className='flex-row flex-wrap'>
                                    <div className='button font-size-1 right-space'
                                        onClick={(e) => {
                                            this._selectAllGroups(this.state.groupsList);
                                        }}
                                    >
                                        <span>
                                            Tout sélectionner
                                        </span>
                                    </div>

                                    <div className='button font-size-1'
                                        onClick={(e) => {
                                            this.setState({
                                                currentGroupsList: [],
                                            });
                                        }}
                                    >
                                        <span>
                                            Tout désélectionner
                                        </span>
                                    </div>
                                </div>
                                <br/>

                                <div className='userListContainer_body'>
                                    <SearchInputComponent key_="name" id_="id" printSelected={false} updated={true} data={this.state.groupSearchResult.length > 0 ? this.state.groupSearchResult : this.state.groupsList} currentSelected={this.state.currentGroupsList} placeholder="Nom du groupe"
                                        onChange={(value) => {
                                            let tab = [];
                                            this.state.groupsList.map((group, index) => {
                                                if (group.name.toLowerCase() === value.toLowerCase() ||
                                                    group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                                ) {
                                                    tab.push(group);
                                                }
                                            });

                                            this.setState({
                                                groupSearchResult: tab,
                                            });
                                        }}
                                        onSelected={(selected) => {
                                            if (this.state.currentGroupsList.includes(selected.id)) {
                                                let currentGroupsList = this.state.currentGroupsList;
                                                for (var i = 0; i < currentGroupsList.length; i++) {
                                                    if (currentGroupsList[i] === selected.id) {
                                                        currentGroupsList.splice(i, 1);
                                                        this.setState({
                                                            currentGroupsList: currentGroupsList,
                                                        });
                                                        return true;
                                                    }
                                                }
                                            }

                                            else {
                                                let currentGroupsList = this.state.currentGroupsList;
                                                currentGroupsList.push(selected.id);
                                                // console.log( currentGroupsList );
                                                this.setState({
                                                    currentGroupsList: currentGroupsList,
                                                });
                                            }
                                        }}

                                        style={(elt) => {
                                            return {
                                                backgroundColor: this.state.currentGroupsList.includes(elt.id) ? "#dc354555" : "transparent",
                                                color: this.state.currentGroupsList.includes(elt.id) ? "#ffffff" : "#000000",
                                            }
                                        }}

                                        isChecked={(elt) => {
                                            return this.state.currentGroupsList.includes(elt.id) ? true : false 
                                        }}
                
                                        icon={
                                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    marginRight: "5px",
                                                }}
                                            >
                                                <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                            </svg>
                                        }
                                    />
                                </div>
  
   
                                <div className='registration_form_label  letter-spacing-1 userListContainer_body'>
                                    <span className='registration_form_label letter-spacing-1'>
                                        Liste des groupes sélectionnés 
                                    </span>
                                </div>
                                <div className='userListContainer_body'>
                                    { this.state.currentGroupsList.length > 0 &&
                                        <table>
                                            <tr
                                                style={{
                                                    background: "#0A3764ee",
                                                    color: "#ffffff",
                                                }}
                                                className="table-head"
                                            >
                                                <th>
                                                    <span
                                                        style={{
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        NOM
                                                    </span>
                                                </th>
                                                <th className='text-align-center'>
                                                    <div className='text-align-center'>
                                                    <span
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        #PERMISSIONS
                                                    </span>
                                                    </div>
                                                </th>
                                            </tr>
                                            {
                                                this.state.currentGroupsList.map((currentGroup, index) => (
                                                    this.state.groupsList.map((group, accountIndex) => (
                                                        currentGroup === group.id &&
                                                        <tr key={group.name}>
                                                            <td>
                                                                {group.name}
                                                            </td>
                                                            <td className='text-align-center'>
                                                                <div className='text-align-center'>
                                                                <span className='text-align-center'>
                                                                    {group.permissions.length}
                                                                </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ))
                                            }
                                        </table>
                                    }
                                </div>
                            </div>

                            <div className='userListContainer_body'>
                    <div className='flex-row align-center'>
                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 0,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 0 ? "#0A376455" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 0 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 0 ? "#0A3764ee" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 0 ? "#0A3764" : "#000000dd",
                                }}
                            >
                                TOUT
                            </div>
                        </div>

                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 1,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 1 ? "rgba(24, 186, 24, 0.3)" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 1 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 1 ? "rgb(24, 186, 24)" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 1 ? "rgb(24, 186, 24)" : "#000000dd",
                                }}
                            >
                                ACCRÉDITÉS
                            </div>
                        </div>

                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 2,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 2 ? "#e8990844" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 2 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 2 ? "#e89908" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 2 ? "#e89908" : "#000000dd",
                                }}
                            >
                                NON ACCRÉDITÉS
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                <div className='userListContainer'>
                    <SearchInputComponent key_="username" id_="id" printStatus={true} statusKey="permissions" printSelected={false} updated={true} data={
                        this.state.searchResult.length > 0 ? this.state.searchResult
                            :(this.state.currentAccountListView === 0 ? this.state.accountsList
                                :(this.state.currentAccountListView === 1 ? this.state.validAccountsList
                                    : this.state.unValidAccountsList
                                )
                            )
                    } currentSelected={this.state.currentAccountsList} placeholder="Nom d'utilisateur"
                        onChange={(value) => {
                            if( value !== '' ) {
                                let tab = [];
                                if( this.state.currentAccountListView === 0 ) {
                                    this.state.accountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                else if( this.state.currentAccountListView === 1 ) {
                                    this.state.validAccountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                else {
                                    this.state.unValidAccountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                this.setState({
                                    searchResult: tab,
                                });
                            }

                            else {
                                this.setState({
                                    searchResult: [],
                                });
                            }
                        }}
                        onSelected={(selected) => {
                            if (this.state.currentAccountsList.includes(selected.id)) {
                                let currentAccountsList = this.state.currentAccountsList;
                                for (var i = 0; i < currentAccountsList.length; i++) {
                                    if (currentAccountsList[i] === selected.id) {
                                        currentAccountsList.splice(i, 1);
                                        this.setState({
                                            currentAccountsList: currentAccountsList,
                                        });
                                        return true;
                                    }
                                }
                            }

                            else {
                                let currentAccountsList = this.state.currentAccountsList;
                                currentAccountsList.push(selected.id);
                                // console.log( currentAccountsList );
                                this.setState({
                                    currentAccountsList: currentAccountsList,
                                });
                            }
                        }}

                        style={(elt) => {
                            return {
                                backgroundColor: this.state.currentAccountsList.includes(elt.id) ? "#dc354555" : "transparent",
                                color: this.state.currentAccountsList.includes(elt.id) ? "#ffffff" : "#000000aa",
                            }
                        }}

                        isChecked={(elt) => {
                            return this.state.currentAccountsList.includes(elt.id) ? true : false 
                        }}

                        icon={
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                            </svg>
                        }
                    />

                    <div className='flex-row flex-wrap'>
                        <div className='selectOption'>
                            <button className='button-style-6 flex-wrap align-center'
                                style={{
                                    color: "#5dc269",
                                    borderColor: "#5dc269",
                                }}

                                onClick={(e) => {
                                    e.preventDefault();
                                    this._selectAllUsers();
                                }}
                            >
                                <span>
                                    TOUT SÉLECTIONNÉ
                                </span>
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
                                </svg>
                            </button>
                        </div>

                        <div>
                            <button className='button-style-6'
                                style={{
                                    color: "red",
                                    borderColor: "red",
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this._unSelectAllUsers();
                                }}
                            >
                                <span>
                                    TOUT DÉSÉLECTIONNÉ
                                </span>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <br/>

                <div className='userListContainer_body'>

                    {
                        this.state.currentAccountsList.length > 0 &&
                        <table>
                            <tr
                                style={{
                                    background: "#0A3764ee",
                                    color: "#ffffff",
                                }}
                                className="table-head"
                            >
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        NOM
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        PRÉNOM
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        NOM D'UTILISATEUR
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        EMAIL
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        ADMIN
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        STATUT
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        GROUPES
                                    </span>
                                </th>
                            </tr>
                            {
                                this.state.currentAccountsList.map((currentAccount, index) => (
                                    this.state.accountsList.map((account, accountIndex) => (
                                        currentAccount === account.id &&
                                        <tr key={account.username}
                                            style={{
                                                background: index%2 !== 0 ? "#0A3764ee" : "transparent",
                                                color:  index%2 !== 0 ? "#ffffff" : "#000000",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.last_name}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.first_name}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.username}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.email}
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                                className="userGroups"
                                            >
                                                {
                                                    account.is_staff ?
                                                        <span
                                                            style={{
                                                                color: "#5dc269",
                                                            }}
                                                        >
                                                            oui
                                                        </span>
                                                    :
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            non
                                                        </span>
                                                }
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {
                                                    account.permissions.length > 0 ?
                                                        <span className='acredite'>
                                                            ACCRÉDITÉS
                                                        </span>
                                                    :
                                                        <span className='no_acredite'>
                                                            NON ACCRÉDITÉS
                                                        </span>
                                                }
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                                className="userGroups"
                                            >
                                                <div className='td_text'>
                                                    {account.groups.length}
                                                </div>

                                                <div className='userGroups_list'>
                                                    <ul className='userGroups_list_ul'>
                                                        {
                                                            account.groups.map((group, index) => (
                                                                <li className='userGroups_list_ul_li' key={group.id + "-" + group.name}>
                                                                    {group.name}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ))
                            }
                        </table>
                    }
                </div>


                            {
                                this.state.addKeywords ?
                                <div className='alertSection'>
                                    <div className='registration_form_inpContainer registration_form_cover alertSection_content'>
                                        <span className='registration_form_label  font-size-1_2 letter-spacing-1'>
                                            Liste des mots-clés
                                        </span>
                                        <div className='dashboard-option-content-attr-list'>
                                            {
                                                this.state.systemKeywords.map((keyword, index) => (
                                                    <label key={keyword} htmlFor={keyword} className='add_content_checkBox_ add_content_checkBox_3'
                                                        style={{
                                                            backgroundColor: this.state.systemKeywords_selected.includes(keyword) ? "#769ea755" : "transparent"
                                                        }}
                                                    >
                                                        <input id={keyword} name={keyword} type='checkbox' checked={this.state.systemKeywords_selected.includes(keyword)}
                                                            onChange={(e)=>{
                                                                let systemKeywords_selected = this.state.systemKeywords_selected;
                                                                
                                                                if( e.target.checked === true ) {
                                                                    systemKeywords_selected.push(keyword);
                                                                }
                                                                else {
                                                                    systemKeywords_selected.splice(systemKeywords_selected.indexOf(keyword), 1);
                                                                    console.log('systemKeywords_selected', systemKeywords_selected)
                                                                }
                                                                this.setState({
                                                                    systemKeywords_selected: systemKeywords_selected,
                                                                });
                                                                return true;
                                                            }}
                                                        />
                                                        <label htmlFor={keyword}
                                                            style={{
                                                                fontSize: "1em",
                                                            }}
                                                        >
                                                            {keyword}
                                                        </label>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                        <div className='dashboard-option-content-attr-list'>
                                            {
                                                this.state.key_word.map((value, valueIndex) => (
                                                    <label key={"keyword-" + value.name + "-" + valueIndex} className='add_content_checkBox_ add_content_checkBox_3 flex-row justify-between'
                                                        style={{
                                                            maxWidth: "150px",
                                                            padding: "2px 10px",
                                                            fontSize: "0.8em",
                                                        }}
                                                    >
                                                        <span>
                                                            {value}
                                                        </span>
                                                        <div>
                                                            <button
                                                                className='edit-pen'
                                                                onClick={(e) => {
                                                                    let newValue = window.prompt( "modifier la valeur", value );
                                                                    if( newValue !== null && newValue !== undefined && newValue !== "" ) {
                                                                        let key_word = this.state.key_word;
                                                                        key_word[valueIndex] = newValue;
                                                                        this.setState({
                                                                            key_word: key_word,
                                                                        });
                                                                    }
                                                                }}
                                                                style={{
                                                                    color: "#0A3764ee",
                                                                }}
                                                            >
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className='delete-trash'
                                                                onClick={(e) => {
                                                                    let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                                    if( confirm ) {
                                                                        let key_word = this.state.key_word;
                                                                        key_word.splice(valueIndex, 1);
                                                                        this.setState({
                                                                            key_word: key_word,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </label>
                                                ))
                                            }
                                        </div>
                                        <div className='dashboard-option-content-button'>
                                            <span
                                                className='button-style-6'
                                                style={{
                                                    display: "inline-block",
                                                }}
                                                onClick={() => {
                                                    let value = window.prompt( "Mot-clé", "" );
                                                    if( value !== null && value !== undefined && value !== "" ) {
                                                        let key_word = this.state.key_word;
                                                        key_word.push(value);
                                                        this.setState({
                                                            key_word: key_word,
                                                        });
                                                    }
                                                }}
                                            >
                                                Ajouter un mot-clé
                                            </span>
                                        </div>

                                        <div className='dashboard-option-content-button flex-row justify-center'>
                                            <span
                                                className='button-style-6'
                                                onClick={() => {
                                                    this._addKeywordsForDoc();
                                                }}
                                            >
                                                Valider
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                :
                                 null
                            }

                            <div className='registration_form_inpContainer registration_form_cover'>
                                <div className='registration_form_label  letter-spacing-1'>
                                    <span className="font-size-1_2 bold">
                                        Résumé synthétique 
                                    </span>
                                </div>
                                <div className='registration_form_input'>
                                    <textarea name='summary' className='registration_form_input_area'/>
                                </div>
                            </div>

                            <div className='registration_form_inpContainer registration_form_cover'>
                                <div className='registration_form_label  letter-spacing-1'>
                                    <span className="font-size-1_2 bold">
                                    Nom du document { this.state.selectedList[0] && this.state.selectedList[0].name && this.state.selectedList[0].name }
                                    </span>
                                </div>

    
                                <div className='sectionBody_dragFile'
                                
                                    onDrop={(e) => {
                                        e.preventDefault() ;
                                        document.getElementById( "dropC" ).style.transform = "scale(1)";

                                        this._onChange( e.dataTransfer.files );
                                    }}
                
                                    onDragEnter={(e) => {
                                        document.getElementById( "dropC" ).style.transform = "scale(1.6)";
                                    }}
                
                                    onDragLeave={(e) => {
                                        document.getElementById( "dropC" ).style.transform = "scale(1)";
                                    }}
                
                                    onDragOver={(e) => {
                                        document.getElementById( "dropC" ).style.transform = "scale(1.6)";
                                        e.preventDefault() ;
                                    }}
                                
                                >
                                    <div className='sectionBody_dragFile_svg'>
                                        {/* <svg id="dropC" width="50" height="12" viewBox="0 0 106 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M86.6219 28.6167C86.6219 28.3333 86.6692 28.05 86.6692 27.7667C86.6692 12.4194 74.413 0 59.2938 0C48.3862 0 39.0165 6.46944 34.6156 15.8194C32.6991 14.8514 30.546 14.2847 28.2746 14.2847C21.2946 14.2847 15.4741 19.4556 14.3621 26.2083C5.98616 29.0889 0 37.0931 0 46.5139C0 58.3667 9.48795 68 21.1763 68H45.4286V49.1111H34.0241L53 29.3486L71.9759 49.0875H60.5714V67.9764H86.6692C97.3638 67.9764 106 59.1222 106 48.2847C106 37.4472 97.3165 28.6403 86.6219 28.6167Z" fill="#769ea7"/>
                                        </svg> */}
                                        <svg id="dropC" width="50" height="12" viewBox="0 0 16 16" className="bi bi-upload" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                            <path fillRule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                        </svg>
                                    </div>
                                    <div className='sectionBody_dragFile_text'>
                                        <span>
                                            Glissez, déposez ici ou 
                                        </span>
                                        <span className='sectionBody_dragFile_text_input'>
                                            <span> choisir</span>
                                            <input required type='file' accept='.pdf' onChange={( e ) => {
                                                this._onChange( e.target.files );
                                            }}/>
                                        </span>
                                    </div>
                                </div>
                                
                                {
                                    this.state.categories.length > 0 &&
                                        <div className='registration_form_fm_submit validationButton_container'>
                                            <input className='registration_form_fm_submit_ background_primary2 button-style-5' type='submit' value="Ajouter"/>
                                        </div>
                                }
                                <br/>

    
                                {
                                    this.state.selectedPreviewLink.map((link, index) => (
                                        <div key={this._generateID(36)}>
                                            <DocViewer
                                                documents={[{uri:link}]}
                                                pluginRenderers={DocViewerRenderers}
                                            />
                                        </div>
                                    ))
                                }

                                {
                                    this.state.categories.length > 0 && this.state.selectedPreviewLink.length > 0 &&
                                        <div className='registration_form_fm_submit validationButton_container'>
                                            <input className='registration_form_fm_submit_ background_primary2 button-style-5' type='submit' value="Ajouter"/>
                                        </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

UploadPdf = connect(mapStateToProps, null)(UploadPdf);
export default UploadPdf;
