import axios from "axios";
import PATH from '../GLOBAL-VAR/globals-urls';

const PATH_ = new PATH();

class DocumentRequests {
    _search(searchPath, access, page = 0) {
        return (
            axios({
                method: 'get',
                url: (page > 1) ? PATH_.api + PATH_.documents + "?page=" + page + "&" + searchPath : PATH_.api + PATH_.documents + "?" + searchPath,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _public_search(searchPath, page = 0) {
        return (
            axios({
                method: 'get',
                url: (page > 1) ? PATH_.api + PATH_.documents_public + "?page=" + page + "&" + searchPath : PATH_.api + PATH_.documents_public + "?" + searchPath,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _keydocs_search(value, page = 0) {
        return (
            axios({
                method: 'get',
                url: (page > 1) ? PATH_.api + PATH_.keydocs + "?page=" + page + "&search=" + value : PATH_.api + PATH_.keydocs + "?search=" + value,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _getOne(id, access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.documents_get + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _delete(id, access) {
        return (
            axios({
                method: 'delete',
                url: PATH_.api + PATH_.documents_delete + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getOne_public(id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.document_public + id,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _add(data, access, onUploadProgress) {
        return (
            // axios({
            //     method: 'post',
            //     url: PATH_.api + PATH_.documents,
            //     data: data,
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         "Authorization": "Bearer " + access
            //     },
            // })
            axios.post(
                PATH_.api + PATH_.documents_create, data, {
                    headers: {
                        // "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + access,
                    },
                    onUploadProgress
                },
            )
        )
    }

    _update(id, data, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + PATH_.documents_update + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _addEditors(id, data, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + "documents/update_actors/" + id + "/",
                data: data,
                headers: {
                    "Authorization": "Bearer " + access,
                },
            })
        )
    }

    _getPassword(id, access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.documentPassword + id,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _addKeyword(doc_id, access, data) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.key_word + doc_id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getKeyword(doc_id, access, progressEvent = () => {}) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.key_word + doc_id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
                onDownloadProgress: (event) => { progressEvent(event) }
            })
        )
    }

    _getKeywordForAllDocs(access, page = 0) {
        return (
            axios({
                method: 'get',
                url: (page > 1) ? PATH_.api + PATH_.key_ + "?page=" + page : PATH_.api + PATH_.key_,
                // url: PATH_.api + PATH_.key_,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getKeywordForAllDocs_public(page = 0) {
        return (
            axios({
                method: 'get',
                url: (page > 1) ? PATH_.api + PATH_.key_public + "?page=" + page : PATH_.api + PATH_.key_public,
                // url: PATH_.api + PATH_.key_public,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _getDocState(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.documents + PATH_.state,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access,
                },
            })
        )
    }

    _getOnDocState(access, id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.documents + PATH_.state + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access,
                },
            })
        )
    }

    _postDocState(access, data) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.documents + PATH_.state,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access,
                },
            })
        )
    }

    _getDocState_public() {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.documents + PATH_.state,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _getOnDocState_public(id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.documents + PATH_.state + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _postDocState_public(data) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.documents + PATH_.state,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _getKeyword_public(doc_id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.key_word + doc_id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _getKeyword_byId(key_id, access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.key_ + key_id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }


    _removeKeyword(key_id, access) {
        return (
            axios({
                method: 'delete',
                url: PATH_.api + PATH_.key_ + key_id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _updateKeyword(key_id, data, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + PATH_.key_ + key_id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _downloadDoc(doc_id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.key_word + doc_id + "/",
                responseType: 'blob'
            })
        )
    }

    _addLawText(data, access) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + "partners/law_text/create/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _updateLawText(id, data, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + "partners/law_text/update/" + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _updateDetailsLawText(id, data, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + "partners/law_text/update_details/" + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getAllLawText(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + "partners/law_text/list/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getOneLawText(id, access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + "partners/law_text/get/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _removeLawText(id, access) {
        return (
            axios({
                method: 'delete',
                url: PATH_.api + "partners/law_text/delete/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }
}

export default DocumentRequests;