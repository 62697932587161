import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import InputStyle1 from '../../components/input-style1.component/input-style1.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen2 from '../../class/SuperScreen_2';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




import AccessStore from '../../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}


class AUthScreen extends SuperScreen2 {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            error: false,
            errorMessage: "Vous n'êtes pas encore autorisé à accéder au système. Votre accréditation est en court de définition.",
            isAgent: false,
        }

        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        
    }

    _get_this_userPermission( access, callback=() => {}) {
        this._getCurrentUserPermissions(access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        });
    }

    _submit_login_form(e) {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        super._login(this.props.user.username, document.forms["loginForm"]["password"].value, ( response ) => {
            if( response.status === 200 ) {
                let user = {
                    username: this.props.user.username,
                    access: response.data.access,
                    refresh: response.data.refresh,
                };
                this.accessStore.setRedux('SET_USER', user, () => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.action(document.forms["loginForm"]["password"].value);
                    });
                });
            }
        },(error) => {
            if( error.response && error.response.status === 401 ) {
                alert( error.response.data.detail );
            }
            if( e.toString() === "Error: Network Error" ) {
                alert( "Problème de connexion :(" );
            }
            this.setState({
                isLoading: false,
            });
            return true;
        });
    }
   
    render() {
        return (
            <>
                {
                    super.render()
                }
                        <div className='login_screen flex-row justify-center'>
                            <div className='background-primary shadow-box-style2 login_screen_'>
                                <div className='login_screen_container'>
                                    <div className='flex-row justify-end'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            onClick={(e) => {
                                                this.props.closeAuth();
                                            }}
                                        >
                                            <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                                            <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                                        </svg>
                                    </div>
                                    <div className='color-ff text-align-center login_screen_subTitle'>
                                        <span className='letter-spacing-2 font-size-0_9 text-shadow-ff'>
                                            Confirmer votre identité
                                        </span>
                                        <div className='gLogo'>
                                            <img src={this.gLogo} alt="evodoun"/>
                                        </div>
                                    </div>
                                    <form
                                        action="#"
                                        target="_self"
                                        method="post"
                                        name="loginForm"
                                        style={{background: "transparent"}}
                                        onSubmit = {(e) => {
                                            this._submit_login_form(e);
                                        }}
                                    >
                                        <div className='login_screen_input_container'>
                                            <InputStyle1
                                                label="Entrez votre mot de passe"
                                                type="password"
                                                name="password"
                                                required={true}
                                                color="#ffffff"
                                                labelStyle ={{
                                                    fontWeight: "bold",
                                                    letterSpacing: "1px",
                                                    color: "#ffffff",
                                                }}
                                                autoFocus={true}
                                            />
                                        </div>

                                        <div className='flex-row justify-center login_screen_submit_container'>
                                            <input className='submit-style-1' type='submit' value="Confirmer"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
            </>
        )
    }
}

AUthScreen = connect(mapStateToProps, null)(AUthScreen);

export default AUthScreen;
