import React from 'react';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import AUthScreen from '../components/auth/auth.component';
import Progress from '../components/progress.component/progress.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import loader2 from '../assets/gif/loader2.gif';
import gLogo from '../assets/logos/logo.png';
import logo_nyquist from '../assets/logos/logo_nyquist-shannon-l300.jpg';
import eVodum_logo_tr_white from '../assets/logos/eVodum_logo_tr_white.png';
import evodoun_color_logo from '../assets/logos/Evodoun-logo-test-couleur-02.png';
import evodoun_color_logo_2 from '../assets/logos/eVodum_logo_tr_white.png';





/**
 * Importation des variables globals
 */
import VARIABLES from '../model-7-1/utils/_variables.js';


/**
 * Importation des helpers
 */
import AuthRequests from '../helpers/Auth.requests.helper';
import AttributesRequests from '../helpers/attributes.requests.helper';
import DocumentRequests from '../helpers/documents.requests.helper';
import CategoriesRequests from '../helpers/categories.request.helper';
import AuthLevelRequests from '../helpers/level.helper';
import tools from '../helpers/tools.helper';


import Store from '../store/store.config';


import AccessStore from '../store/accessStore';

class SuperScreen extends React.Component {
    constructor(props) {
        super(props);
        this.VAR = VARIABLES;

        this.state = {
            isLoading: false,
            currentText: [],

            isProgress: false,
            requestProgression: 0,
            progressionTile: "",

            annuaireAlert: false,
            annuaireAlertMessage: "Cette ressource n'est pas disponible pour le moment !",
            
            authAction: () => {},
            auth: false,
        }

        this.gLogo = gLogo;
        this.logo_nyquist = logo_nyquist;
        this.eVodum_logo_tr_white = eVodum_logo_tr_white;
        this.evodoun_color_logo = evodoun_color_logo;

        this.AuthRequests = new AuthRequests();
        this.DocumentRequests = new DocumentRequests();
        this.AttributesRequests = new AttributesRequests();
        this.CategoriesRequests = new CategoriesRequests();
        this.AuthLevelRequests = new AuthLevelRequests();
        this.super_accessStore = new AccessStore(this.props.dispatch);
        // console.log( this.props.dispatch );
        
        this.attributes = [
            "structures",
            "domaines",
            "sectors",
            "dates",
            "specific-study",
        ];

        this.month = [
            {
                name: "Janvier",
                number: 1,
            },
            {
                name: "Février",
                number: 2,
            },
            {
                name: "Mars",
                number: 3,
            },
            {
                name: "Avril",
                number: 4,
            },
            {
                name: "Mai",
                number: 5,
            },
            {
                name: "Juin",
                number: 6,
            },
            {
                name: "Juillet",
                number: 7,
            },
            {
                name: "Août",
                number: 8,
            },
            {
                name: "Septembre",
                number: 9,
            },
            {
                name: "Octobre",
                number: 10,
            },
            {
                name: "Novembre",
                number: 11,
            },
            {
                name: "Décembre",
                number: 12,
            },
        ]

        this.attr_types = [
            {
                name: "Text court",
                value: "text-one-line",
                type: "input",
            },
            {
                name: "Text long",
                value: "text-multi-line",
                type: "textarea",
            },
            {
                name: "Numérique",
                value: "number",
                type: "input",
            },
            {
                name: "Date",
                value: "date",
                type: "input",
            },
            {
                name: "Select",
                value: "select",
                type: "select",
            },
            {
                name: "Case à cocher",
                value: "checkbox",
                type: "checkbox",
            },
            {
                name: "Radio",
                value: "radio",
                type: "radio",
            },
        ];

        this.sysAttr = [
            {
                name: "Utilisateur",
                types: ["select", "checkbox", "radio"],
                values: [],
            },
            {
                name: "Groupe utilisateurs",
                types: ["select", "checkbox", "radio"],
                values: [],
            },
            {
                name: "Catégorie de documents",
                types: ["select", "checkbox", "radio"],
                values: [],
            },
        ]

        this.tools = new tools();
        // this.Xanimation = Xanimation();
    }

    componentDidMount() {
        // console.log(  );
    }

    _getAccounts(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getAccounts(access).then((response) => {
            success_callback( response );
        }).catch((e) => { 
	    failed_callback( e );
        });
    }

    _searchAccounts(access, search, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._SearchAccounts(access,search).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _openOrCloseAuth() {
        this.setState({
            auth: !this.state.auth,
        });
    }

    _deleteAccount(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._deleteAccount(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _AdminUpdateAccount(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._updateAccount(id, data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getAllCategories(access, success_callback=()=>{},  failed_callback=()=>{}, onDownloadProgress=()=>{}) {
        // console.log( Store.getState().User.user );

        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.CategoriesRequests._getAll(access, onDownloadProgress).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }

        else {
            this.CategoriesRequests._getAll_public().then((response) => {
                // console.log( response );
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        
    }

    _getLevel( access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthLevelRequests._getAllLevels( access ).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getOnCategory(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.CategoriesRequests._getOne( id, access ).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        else {
            this.CategoriesRequests._getOne_public( id ).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }

    _postCategory(data, access, success_callback=()=>{},  failed_callback=()=>{}, sendProgress, receiveProgress) {
        this.CategoriesRequests._add(data, access, sendProgress, receiveProgress).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _updateCategory(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.CategoriesRequests._update(id, data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _deleteCategory(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.CategoriesRequests._delete(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _addKeyword(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._addKeyword(id, access, data).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getKeyword(id, access, success_callback=()=>{},  failed_callback=()=>{}, progressEvent=()=>{}) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._getKeyword(id, access, progressEvent).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        else {
            this.DocumentRequests._getKeyword_public(id).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }

    _getOnKeyword(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._getKeyword_byId(id, access).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        else {
            // this.DocumentRequests._getKeyword_public(id).then((response) => {
            //     success_callback( response );
            // }).catch((e) => {
            //     failed_callback( e );
            // });
        }
    }

    _deleteOnKeyword(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._removeKeyword(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _updateOnKeyword(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._updateKeyword(id, data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _login(username, password, success_callback=()=>{},  failed_callback=()=>{}) {
        let data = {
            username: username,
            password: password
        };

        this.AuthRequests._login( data ).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _registration(data, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._registration(data).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _addAccount(data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._addAccount(data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _addGroup(data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._addGroup(data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }


    _addAccess(data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._addAccess(data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _removeAccess(data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._RemoveAccess(data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _getAllGroups(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getAllGroups(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _getAllGroups_(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getAllGroups_(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _updateGroup__(data, id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._initGroupExtended_(data, id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _updateOneGroup(data, id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._updateGroup(data, id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _updateOneGroup_(data, id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._updateGroup_(data, id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _addPermsToGroup(data, id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._addPermsToGroup(data, id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }
    
    // _deletePermsToGroup(data, id, access, success_callback=()=>{},  failed_callback=()=>{}) {
    //     this.AuthRequests._deletePermsToGroup(data, id, access).then((response) => {
    //         success_callback( response );
    //     }).catch((e) => {
    //         failed_callback(e);
    //     });
    // }

    _deleteGroup(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._deleteGroup(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _getAllPermissions(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getAllPermissions(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback(e);
        });
    }

    _search( searchPath, page, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._search(searchPath, access, page).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }

        else {
            this.DocumentRequests._public_search(searchPath, page).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }



    _getOneDoc( id, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._getOne(id, access).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        else {
            this.DocumentRequests._getOne_public(id).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }

    _changeDocState( data, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._postDocState(access, data).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        else {
            this.DocumentRequests._postDocState_public(data).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }

    _getOnDocStatus(id, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._getOnDocState(access, id).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        else {
            this.DocumentRequests._getOnDocState_public(id).then((response) => {
                // console.log( response );
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }

    _setDocEditors(id, data, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._addEditors(id, data, access).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        // else {
        //     this.DocumentRequests._getOnDocState_public(id).then((response) => {
        //         // console.log( response );
        //         success_callback( response );
        //     }).catch((e) => {
        //         failed_callback( e );
        //     });
        // }
    }


    _getKeywordForAllDocs( access, success_callback=()=>{},  failed_callback=()=>{}, page=0 ) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.DocumentRequests._getKeywordForAllDocs(access, page).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
        else {
            this.DocumentRequests._getKeywordForAllDocs_public(page).then((response) => {
                // console.log( response );
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }

    _deleteDoc( id, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        this.DocumentRequests._delete(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _searchKeydocs( search, page, success_callback=()=>{},  failed_callback=()=>{} ) {
        this.DocumentRequests._keydocs_search(search, page).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getAttribute(attribute, access, success_callback=()=>{},  failed_callback=()=>{}) {
        if( !this._isObjEmpty( Store.getState().User.user ) ) {
            this.AttributesRequests._get(attribute, access).then((response) => {
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }

        else {
            this.AttributesRequests._get_public(attribute, access).then((response) => {
                // console.log( response );
                success_callback( response );
            }).catch((e) => {
                failed_callback( e );
            });
        }
    }

    _getDocumentPassword(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._getPassword(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getCurrentUserPermissions(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getCurrentUserPermissions(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getUserGroups(access, id, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getUserGroups(access, id).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getGroupUsers(access, id, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getGroupUsers(access, id).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getUserPermissions(access, id, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getUserPermissions(access, id).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getIfCurrentUserIsAdmin(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getIfCurrentUserIsAdmin(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getIfCurrentUserIsSudo(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._getIfCurrentUserIsSudo(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _postDocument(data, access, success_callback=()=>{},  failed_callback=()=>{}, onUpload) {
        this.DocumentRequests._add(data, access, onUpload).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getAllLawText(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._getAllLawText(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _getOneLawText(id, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._getOneLawText(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _updateDocument(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._update(id, data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _refreshUserToken( refresh, success_callback=()=>{}, failed_callback=()=>{}) {
        this.AuthRequests._refresh_token(refresh).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _generateID(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

    _updateBasket( doc ) {
        let basket = Store.getState().Panier.panier;

        let res = this.tools._containObject( doc, basket, "id" );

        if( !res ) {
            basket = basket.concat( doc );
        }

        else {
            basket.splice( res, 1 );
        }

        Store.dispatch({ type: "SET_ON_PANIER", value: basket });
    }

    _getPdfPage(pdf) {
        let reader = new FileReader();
        let pages = 0;
        reader.readAsBinaryString(pdf);
        reader.onloadend = ()=>{
            pages = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
            console.log( pages );
            return pages;
        }
    }

    _addLawText(data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._addLawText(data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _updateDetailsLawText(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._updateDetailsLawText(id, data, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _updateLawText(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.DocumentRequests._updateLawText(id, data, access).then((response) => {
            success_callback(response);
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _deleteLawText( id, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        this.DocumentRequests._removeLawText(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _systemInfoEdit(data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._systemInfoEdit(data, access).then((response) => {
            success_callback(response);
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _systemInfoUpdate(id, data, access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._systemInfoUpdate(id, data, access).then((response) => {
            success_callback(response);
        }).catch((e) => {
            failed_callback( e );
        });
    }
    
    _systemInfoGetAll(access, success_callback=()=>{},  failed_callback=()=>{}) {
        this.AuthRequests._systemInfoGetAll(access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }

    _systemInfoDelete( id, access, success_callback=()=>{},  failed_callback=()=>{} ) {
        this.AuthRequests._systemInfoDelete(id, access).then((response) => {
            success_callback( response );
        }).catch((e) => {
            failed_callback( e );
        });
    }
   
    render() {
        return (
            <>
                <div className="superScreen flex-row justify-center align-center"
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        backgroundColor: "#000000aa",
                        zIndex: 100000000,
                        width: "100%",
                        height: "100%",
                        marginLeft: this.state.isLoading ? "0%" : "100%"
                    }}
                >
                    <img src={loader2} alt="" style={{
                        width: "60px"
                    }}/>
                </div>
 
                {
                    this.state.isProgress &&
                    <>
                        <Progress progression={Math.round(Number(this.state.requestProgression))} title={this.state.progressionTile}/>
                    </>
                }
            
                {
                    this.state.auth &&
                        <div className='auth absolute'
                            style={{
                                zIndex: 100000000,
                            }}
                        >
                            <AUthScreen
                                action={ (password) => {
                                    this.state.authAction(password)
                                }}
                                closeAuth={() => {this._openOrCloseAuth()}}
                            />
                        </div>
                }

                {
                    <div className='annuaireAlert'
                        style={{
                            display: this.state.annuaireAlert ? "block" : "none",
                        }}
                    >
                        <div className='annuaireAlert_content'
                            style={{
                                animation: this.state.annuaireAlert ? "animFromTop 1s forwards" : "animToTop 1s forwards",
                            }}
                        >
                            <div className='annuaireAlert_content_head'>
                                <a href='/annuaire/'>
                                    <img src={evodoun_color_logo_2} alt="evodoun"/>
                                </a>
                            </div>

                            <div className='annuaireAlert_content_body'>
                                <div className='annuaireAlert_content_body_content'>
                                    <div className='annuaireAlert_content_body_content_spinner'></div>
                                    <span className='annuaireAlert_content_body_content_text'>
                                        {this.state.annuaireAlertMessage}
                                    </span>
                                </div>
                            </div>

                            <div className='annuaireAlert_content_footer'>
                                <button className='button-style-8-1'
                                    onClick={(e) => {
                                        this.setState({
                                            annuaireAlert: false,
                                        });
                                    }}
                                >
                                    Fermer
                                </button>
                            </div>
                        </div>
                        <div className='annuaireAlert_back'></div>
                    </div>
                }
            </>
        )
    }
}

export default SuperScreen;
