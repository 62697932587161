import React from 'react';
import { connect } from 'react-redux';
// import words from 'an-array-of-french-words';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import ListManager from '../components/listManager.component/listManager.component';
import List2Component from '../components/list2.component/list2.component';
// import IframeDownloadComponent from '../components/iframeDownload.component/iframeDownload.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/doc_explorer.style.css';
import './responsive.style/doc_explorer.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/


/**
 * Importation des helpers
 */
 import tools from '../helpers/tools.helper';
 import text from '../GLOBAL-VAR/global-text';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class SearchDocs extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            filter: false,
            search: false,
            searchTime: 0,
            noResultFound: false,

            filterResult: false,
            sortResult: false,

            currentSuggestion: [],

            currentResultPage: 1,

            searchResult: [],

            searchResultsFilter: [],

            printMenu: false,

            basket: [],

            printBasket: false,

            copied: false,

            currentDownloadLink: [],

            currentText: [],

            viewSHareOption: false,

            filterContent: {},

            printFilterSelection: false,
            currentFilterSelection: [],

            allResultsGet: true,
            currentSearch: "",

            resultSortedByDate: [],
            typeSort: 1,
            printSortedTab: false,



            categories: [],
            currentCategories: [],
            currentCategoriesIndex: [],

            years: [],
            currentYears: [],

            currentMonths: [],
        }

        this.accessStore = new AccessStore( this.props.dispatch);

        this.sort_attributes = {
            name: "Trié par :",
            content: ["Années décroissantes", "Années croissantes"],
        };

        this.searchSuggestion = [
            { label: "lois portant sur la protection des animaux" },
            { label: "forêt" },
            { label: "arbres" },
            { label: "Télécommunication" },
            { label: "Recensement 2022" },
            { label: "Recensement 2021" },
            { label: "lois portant sur la protection de la faune et de la flore" },
            { label: "Conseil des ministres du 02/12/2021" },
        ]

        this.optionsSelected = {};
        this.tools = new tools();

        this._downloadAllBasket = this._downloadAllBasket.bind( this );
        this.text = new text();
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        this._get_all_categories(( categories )=>{
            this.setState({
                categories : categories,
            }, () => {
                this._get_attribute("dates", (dates) => {
                    let years = [];
                    dates.map((date, index) => {
                        if( !years.includes(date.year) ) {
                            years.push( date.year );
                        }
                    });

                    this.setState({
                        years: years,
                        isLoading: false,
                    });
                });
            });
        });
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    _get_attribute(attribute, callback=()=>{}) {
        this._getAttribute(attribute, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_attribute(attribute, callback);
            })
        });
    }

    _startSearchRequest( searchPath, page, callback=()=>{} ) {
        this._search(searchPath, page, this.props.user.access, ( response ) => {
            if( response.status === 200 ) {
                callback( response );
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._startSearchRequest( searchPath, page, callback );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    _search_doc(e, value, getMore=false) {
        if( !getMore ) {
            e.preventDefault();
            this.setState({
                filterContent: {},
                searchResultsFilter: [],
                currentResultPage: 1,
            });
        }
        
        this.setState({
            currentSearch: value,
        });

        let searchPath = "search=" + value;

        this.state.currentCategories.map((category) => {
            searchPath = searchPath + "&category=" + category;
        });

        this.state.currentMonths.map((month) => {
            searchPath = searchPath + "&month=" + month;
        });

        this.state.currentYears.map((year) => {
            searchPath = searchPath + "&year=" + year;
        });

        this.state.currentCategoriesIndex.map((categoryIndex, index) => {
            let category = this.state.categories[categoryIndex];
            category.fields.attributes.map((attribute, index) => {
                // console.log( this.optionsSelected[category.name + "/" + attribute.name] );
                if( this.optionsSelected[category.name + "/" + attribute.name] ) {
                    let data = "";
                    this.optionsSelected[category.name + "/" + attribute.name].content.map((elt, index) => {
                        if( elt.isChecked ) {
                            if( data !== "" ) {
                                data = data + ',"' +  elt.data + '"';
                            }else {
                                data = data + '"' +  elt.data + '"';
                            }
                        }
                    });
                    data = "[" + data + "]";
                    searchPath = searchPath + "&data={\"" + attribute.name + "\":" + data + "}" ;
                }
            });

            category.fields.objects.map((attribute, index) => {
                // console.log( this.optionsSelected[category.name + "/" + attribute.name] );
                if( this.optionsSelected[category.name + "/" + attribute.name] ) {
                    let data = "";
                    this.optionsSelected[category.name + "/" + attribute.name].content.map((elt, index) => {
                        if( elt.isChecked ) {
                            if( data !== "" ) {
                                data = data + ',"' +  elt.data + '"';
                            }else {
                                data = data + '"' +  elt.data + '"';
                            }
                        }
                    });
                    data = "[" + data + "]";
                    searchPath = searchPath + "&data={\"" + attribute.name + "\":" + data + "}" ;
                }
            })
        });

        // searchPath = searchPath.replace(/=/g, "");
        // searchPath = searchPath.replace(/&/g, "&&&");
        window.location.href = "/?searchPath=" + encodeURIComponent(searchPath) + "&keyword=" + encodeURIComponent(value);
    }

    /**
     * On met a jour la session utilisateur
     */
     _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _openOrCloseFilterMenu( close = false ) {
        if( close ) {
            this.setState({
                filterResult: false
            });
        }

        else { 
            this.setState({
                filterResult: !this.state.filterResult
            });
        }
    }

    _openOrCloseSortMenu( close = false ) {
        if( close ) {
            this.setState({
                sortResult: false
            });
        }

        else { 
            this.setState({
                sortResult: !this.state.sortResult
            });
        }
    }

    _openDoc( doc ) {
        window.open("/view-doc:?doc=" + encodeURIComponent(doc.url) + "&pk=" + encodeURIComponent(doc.pk) + "&output=embed", '_blank', 'location=yes, height=570, width=620, scrollbars=yes,status=yes');
    }

    _logout() {
        if( window.confirm('Voulez-vous vraiment vous déconnecter?') ) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }

    _updateBasket( doc ) {
        let basket = this.state.basket;

        let res = this.tools._containObject( doc, basket, "pk" );

        if( !res ) {
            basket = basket.concat( doc );
        }

        else {
            basket.splice( res, 1 );
        }

        this.setState({
            basket: basket,
        }, () => {
            let text = [];
            basket.map((elt) => {
                text = text.concat( elt.summary + " : " + elt.file + "\n\n" );
                this.setState({
                    currentText: text,
                });
                return true;
            });
        });
    }

    _printBasket() {
        this.setState({
            printBasket: !this.state.printBasket,
        });
    }

    _downloadAllBasket( index=0 ) {
        if( index === 0 ) {
            this.setState({
                currentDownloadLink: [],
            });
        }
        if( index < this.state.basket.length) {
            let downloadLinks = this.state.currentDownloadLink;
            downloadLinks = downloadLinks.concat(this.state.basket[index].file);
            this.setState({
                currentDownloadLink: downloadLinks,
            }, () => {
                this._downloadAllBasket( index + 1 );
            });
        }
    }

    _copyToClipBoard() {
        var copyText = document.getElementById("element_to_copy2");
        copyText.setAttribute("value", this.state.currentText );
        copyText.select();

        navigator.clipboard.writeText( copyText.value );

        clearTimeout( this.timeout );

        this.setState({
            copied: true,
        }, () => {
            this.timeout = setTimeout( () => {
                this.setState({
                    copied: false,
                });
            }, 5000);
        });
    }

    _printOrHideShareOptions() {
        let shareOptions = document.getElementById( "shareOptionsForAll" );

        if( !this.state.viewSHareOption ) {
            shareOptions.style.opacity = 1;
            shareOptions.style.visibility = "visible";
            shareOptions.style.marginBottom = "40px";

            this.setState({
                viewSHareOption: true,
            });
        }

        else {
            shareOptions.style.opacity = 0;
            shareOptions.style.visibility = "hidden";
            shareOptions.style.marginBottom = "0px";

            this.setState({
                viewSHareOption: false,
            });
        }
    }

    _backHome() {
        let dce = document.getElementById("doc-explorer-section#");
        dce.style.position = "relative";
        dce.style.marginLeft = "0";
        dce.style.zIndex = "10";
        this.setState({
            search: false,
            noResultFound: false,
            currentCategories: [],
            currentCategoriesIndex: [],
        });
        this.optionsSelected = {};
    }


    filterByDate( dates, callback=()=>{} ) {
        let datas = this.state.searchResult;
        let datas2 = this.state.searchResult;

        dates.map((date) => {
            if( date.isChecked === false ) {
                datas.map((data, index) => {
                    if( data.year === date.data.year ) {
                        datas2.splice( index, 1 );
                    }
                })
            }
        });

        this.setState({
            searchResult: datas,
        }, ()=> {
            callback();
        });
    }

    _changeFilterSelection(list) {
        let filterSelection = [];
        list.map((elt) => {
            filterSelection.push(this.state.searchResult[elt]);
        });
        this.setState({
            currentFilterSelection: filterSelection,
        });
        this._openFilterSelection();
    }

    _openFilterSelection() {
        this.setState({
            printFilterSelection: !this.state.printFilterSelection
        });
    }

    _openSOrtedResult() {
        this.setState({
            printSortedTab: !this.state.printSortedTab
        });
    }


    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='headerFake'></div>
                
                {
                    <div className='doc-explorer'>
                        <section id="doc-explorer-section#" className='doc-explorer-section'>
                            <div className='doc-explorer-section-contentTitle flex-row justify-center'
                                style={{
                                    marginTop: this.state.search ? "-150px" : "20px",
                                    transform: this.state.search ? "scale(0)" : "scale(1)",
                                }}
                            >
                                <div className='doc-explorer-section-contentTitle_'>
                                    <span className='color-green family-cmuntt bold dynamic-fts-1'>
                                        {this.text.appName}
                                    </span>
                                </div>
                                {/* <div className='doc-explorer-section-contentLogo-img_container'>
                                    <img className='doc-explorer-section-contentLogo-img' src={this.gLogo} alt='SIGB-logo'/>
                                </div> */}
                            </div>
                            <div className='doc-explorer-section-contentSubTitle flex-row justify-center'
                                style={{
                                    marginTop: this.state.search ? "-150px" : "0px",
                                    transform: this.state.search ? "scale(0)" : "scale(1)",
                                }}
                            >
                                <span className='color-primary-dd bold letter-spacing-0 family-cmuntt dynamic-fts-3'>
                                    {/* <span className='color-green'>simplicité</span> - <span className='color-yellow'>efficience</span> - <span className='color-red'>convivialité</span> */}
                                    <span>simplicité</span> - <span>efficience</span> - <span>convivialité</span>
                                </span>
                            </div>

                            <form
                                action="#"
                                target="_self"
                                method="post"
                                name="SearchForm1"
                                style={{background: "transparent"}}
                                onSubmit = {(e) => {
                                    this._search_doc(e, document.forms["SearchForm1"]["search-input-1"].value);
                                }}
                            >

                                <div className='doc-explorer-section-search-1 flex-row justify-center align-center'
                                    style={{
                                        marginTop: this.state.search ? "-150px" : "0px",
                                        transform: this.state.search ? "scale(0)" : "scale(1)",
                                    }}
                                >
                                    <input name='search-input-1' className='input-style-1 search-input' placeholder='Rechercher' type='search'/>

                                    <div className='doc-explorer-section-search-right'>
                                        <button className='button-style-1 scale-to-hover'
                                            style={{color: "#128657", borderLeft: "1px solid #128657"}}
                                        >
                                            <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                                <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className='search_float'>
                                    <button className='button-style-1 scale-to-hover'
                                        style={{color: "#128657", borderLeft: "0px solid #128657"}}
                                    >
                                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                            <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                        </svg>
                                    </button>
                                </div>
                            </form>

                            <br/>
                            <br/>
                            <div className='doc-explorer-section-search container-style-1'
                                style={{
                                    marginTop: this.state.search ? "-150px" : "0px",
                                    transform: this.state.search ? "scale(0)" : "scale(1)",
                                }}
                            >
                                <div className='doc-explorer-section-search-categories text-align-left'>
                                    <div className='OnProjectsView_body_head_text'>
                                        <span className='font-size-0_8'>
                                            Années de publication:
                                        </span>
                                    </div>
                                </div>
                                <br/>

                                <div>
                                    {
                                        this.state.years.length > 0 &&
                                        <ListManager _key_="lists23" fSize="1em" list={this.state.years} isSelectable={true} printKey={null}
                                            checked={ (year) => {
                                                return this.state.currentYears.includes(year);
                                            }}
                                            style={(year) => {
                                                return ({
                                                    background: this.state.currentYears.includes(year) ? "#769ea755" : "#00000000",
                                                })
                                            }}
                                            onChange={(e, year, index) => {
                                                let currentYears = this.state.currentYears;
                                                    
                                                if( e.target.checked === true ) {
                                                    currentYears.push(year);
                                                }
                                                else {
                                                    currentYears.splice( currentYears.indexOf(year), 1);
                                                }

                                                this.setState({
                                                    currentYears: currentYears,
                                                });
                                                return true;
                                            }}
                                        />
                                    }
                                </div>
                                
                                {/* {
                                    this.state.years.map((year, index) => (
                                        <label key={year} htmlFor={year} className='add_content_checkBox_ add_content_checkBox_2'
                                            style={{
                                                padding: "5px 10px",
                                                background: this.state.currentYears.includes(year) ? "#769ea755" : "#00000000",
                                            }}
                                        >
                                            <input id={year} name={year} type='checkbox' checked={this.state.currentYears.includes(year)}
                                                onChange={(e)=>{
                                                    let currentYears = this.state.currentYears;
                                                    
                                                    if( e.target.checked === true ) {
                                                        currentYears.push(year);
                                                    }
                                                    else {
                                                        currentYears.splice( currentYears.indexOf(year), 1);
                                                    }

                                                    this.setState({
                                                        currentYears: currentYears,
                                                    });
                                                    return true;
                                                }}
                                            />
                                            <label htmlFor={year}>
                                                {year}
                                            </label>
                                        </label>
                                    ))
                                } */}

                                <br/>
                                <br/>
                                <div className='doc-explorer-section-search-categories text-align-left'>
                                    <div className='OnProjectsView_body_head_text'>
                                        <span className='font-size-0_8'>
                                            Mois de publication:
                                        </span>
                                    </div>
                                </div>
                                <br/>

                                <div>
                                    {
                                        this.month.length > 0 &&
                                        <ListManager _key_="lists24" fSize="1em" list={this.month} isSelectable={true} printKey="name"
                                            checked={ (month) => {
                                                return this.state.currentMonths.includes(month.number);
                                            }}
                                            style={(month) => {
                                                return ({
                                                    background: this.state.currentMonths.includes(month.number) ? "#769ea755" : "#00000000",
                                                })
                                            }}
                                            onChange={(e, month, index) => {

                                                let currentMonths = this.state.currentMonths;
                                                    
                                                if( e.target.checked === true ) {
                                                    currentMonths.push(month.number);
                                                }
                                                else {
                                                    currentMonths.splice( currentMonths.indexOf(month.number), 1);
                                                }

                                                this.setState({
                                                    currentMonths: currentMonths,
                                                });
                                                return true;
                                            }}
                                        />
                                    }
                                </div>
                                
                                {/* {
                                    this.month.map((month, index) => (
                                        <label key={month.name} htmlFor={month.name} className='add_content_checkBox_ add_content_checkBox_2'
                                            style={{
                                                padding: "5px 10px",
                                                background: this.state.currentMonths.includes(month.number) ? "#769ea755" : "#00000000",
                                            }}
                                        >
                                            <input id={month.name} name={month.name} type='checkbox' checked={this.state.currentMonths.includes(month.number)}
                                                onChange={(e)=>{
                                                    let currentMonths = this.state.currentMonths;
                                                    
                                                    if( e.target.checked === true ) {
                                                        currentMonths.push(month.number);
                                                    }
                                                    else {
                                                        currentMonths.splice( currentMonths.indexOf(month.number), 1);
                                                    }

                                                    this.setState({
                                                        currentMonths: currentMonths,
                                                    });
                                                    return true;
                                                }}
                                            />
                                            <label htmlFor={month.name}>
                                                {month.name}
                                            </label>
                                        </label>
                                    ))
                                } */}

                                <br/>
                                <br/>
                                <div className='doc-explorer-section-search-categories text-align-left'>
                                    <div className='OnProjectsView_body_head_text'>
                                        <span className='font-size-0_8'>
                                            Catégories de documents:
                                        </span>
                                    </div>
                                </div>
                                <br/>

                                 {/* 
                                    On fais appel au composant pour traiter et afficher les catégories de documents
                                 */}
                                <div>
                                    {
                                        this.state.categories.length > 0 &&
                                        <ListManager _key_="lists25" fSize="1em" list={this.state.categories} isSelectable={true} printKey="name"
                                            checked={ (category) => {
                                                return this.state.currentCategories.includes(category.pk);
                                            }}
                                            style={(category) => {
                                                return ({
                                                    background: this.state.currentCategories.includes(category.pk) ? "#769ea755" : "#00000000",
                                                })
                                            }}
                                            onChange={(e, category, index) => {

                                                /**
                                                 * On récupere la liste des actegories cochées
                                                 * On recupere la liste des index des catégorie sélectionner
                                                 * 
                                                 * si c'est que la catégories est coché alors on ajoute a la liste
                                                 * dans le cas contraire on retire de la liste
                                                */
                                                let currentCategories = this.state.currentCategories;
                                                let currentCategoriesIndex = this.state.currentCategoriesIndex;
                                                        
                                                if( e.target.checked === true ) {
                                                    currentCategories.push(category.pk);
                                                    currentCategoriesIndex.push(index);
                                                }
                                                else {
                                                    currentCategories.splice( currentCategories.indexOf(category.pk), 1);
                                                    currentCategoriesIndex.splice( currentCategoriesIndex.indexOf(index), 1);
                                                }

                                                this.setState({
                                                    currentCategories: currentCategories,
                                                    currentCategoriesIndex: currentCategoriesIndex,
                                                });
                                                return true;
                                            }}
                                        />
                                    }
                                </div>
                                
                                {/* {
                                    this.state.categories.map((category, index) => (
                                        <label key={category.name} htmlFor={category.name} className='add_content_checkBox_ add_content_checkBox_2'
                                            style={{
                                                padding: "5px 10px",
                                                background: this.state.currentCategories.includes(category.pk) ? "#769ea755" : "#00000000",
                                            }}
                                        >
                                            <input id={category.name} name={category.name} type='checkbox' checked={this.state.currentCategories.includes(category.pk)}
                                                onChange={(e)=>{
                                                    let currentCategories = this.state.currentCategories;
                                                    let currentCategoriesIndex = this.state.currentCategoriesIndex;
                                                    
                                                    if( e.target.checked === true ) {
                                                        currentCategories.push(category.pk);
                                                        currentCategoriesIndex.push(index);
                                                    }
                                                    else {
                                                        currentCategories.splice( currentCategories.indexOf(category.pk), 1);
                                                        currentCategoriesIndex.splice( currentCategoriesIndex.indexOf(index), 1);
                                                    }

                                                    this.setState({
                                                        currentCategories: currentCategories,
                                                        currentCategoriesIndex: currentCategoriesIndex,
                                                    });
                                                    return true;
                                                }}
                                            />
                                            <label htmlFor={category.name}>
                                                {category.name}
                                            </label>
                                        </label>
                                    ))
                                } */}
                                
                                    {
                                        this.state.currentCategoriesIndex.map((categoryIndex, index) => (
                                            <div>
                                                <br/>
                                                {this.state.categories[categoryIndex].name}
                                                <div className='doc-explorer-section-search-left flex-row'>{
                                                    this.state.categories[categoryIndex].fields.attributes.map((attribute, attrIndex) => (
                                                        (attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio") &&
                                                        <div key={attribute.name + index} className='doc-explorer-section-search-left-input'>
                                                            <input className='input-style-1' type='button' value={attribute.name} />
                                                            <div className='doc-explorer-section-search-left-input-filter'>
                                                                {
                                                                    attribute.values !== null && attribute.values.length > 0 &&
                                                                    <List2Component list_name={attribute.name} list={attribute.values} id={attribute.name + attrIndex + this.state.categories[categoryIndex].name}
                                                                        OnChange={(name, content) => {
                                                                            // console.log( content );
                                                                            let attr = {
                                                                                name: name,
                                                                                content: content,
                                                                            };
                                                                            this.optionsSelected[ this.state.categories[categoryIndex].name + "/" + name ] = attr;
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }</div>
                                            
                                                {
                                                    this.state.categories[categoryIndex].fields.objects &&
                                                    <div className='doc-explorer-section-search-left flex-row'>{
                                                        this.state.categories[categoryIndex].fields.objects.map((attribute, attrIndex) => (
                                                            (attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio") &&
                                                            <div key={attribute.name + index} className='doc-explorer-section-search-left-input'>
                                                                <input className='input-style-1' type='button' value={attribute.name} />
                                                                <div className='doc-explorer-section-search-left-input-filter'>
                                                                    {
                                                                        attribute.values !== null && attribute.values.length > 0 &&
                                                                        <List2Component object={true} list_name={attribute.name} list={attribute.values} id={attribute.name + attrIndex + this.state.categories[categoryIndex].name}
                                                                            OnChange={(name, content) => {
                                                                                // console.log( content );
                                                                                let attr = {
                                                                                    name: name,
                                                                                    content: content,
                                                                                };
                                                                                this.optionsSelected[ this.state.categories[categoryIndex].name + "/" + name ] = attr;
                                                                            }}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }</div>
                                                }
                                            </div>
                                        ))
                                    }
                                
                            </div>
                        </section>
                    </div>
                }
            </>
        )
    }
}


SearchDocs = connect(mapStateToProps, null)(SearchDocs);
export default SearchDocs;
