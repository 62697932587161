// import { ReactDOM } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class ListManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           maxWidth: 150,
        }
    }

    componentDidMount() {
        /**
         * Récuperation de la chaine la plus longue
         */
        let long_string = this._getLongString( this.props.list, this.props.printKey );
        let max_width = this._getMaxWidth( long_string );

        console.log( long_string, max_width );

        this.setState({
            maxWidth: max_width,
        });

        // document.addEventListener( "readystatechange", (e) => {
        //     if( e.target.readyState === 'interactive' ) {
        //         let long_string = this._getLongString( this.props.list, this.props.printKey );
        //         let max_width = this._getMaxWidth( long_string );

        //         console.log( long_string, max_width );

        //         this.setState({
        //             maxWidth: max_width,
        //         });
        //         // console.log( this.props._key_ );
        //     }
        // });
    }

    /**Cette fonction renvois la chaine la plus longue de toutes */
    _getLongString( list, key ) {
        let long_string = "";
        
        if( key !== null ) {
            list.map((elt) => {
                if( elt[key].toString().length > long_string.length ) {
                    long_string = elt[key].toString();
                }
            });
            return long_string;
        }else {
            list.map((elt) => {
                if( elt.toString().length > long_string.length ) {
                    long_string = elt.toString();
                }
            });
            return long_string;
        }
    }

    /**
     * Obtenir la largeur maximal necessaire pour afficher l'element le plus long
    */
    _getMaxWidth( string ) {
        const minL = 5;
        let str = "";

        for(let i=0; i<string.length; i++) {
            str = str + "W";
        }
        
        if( string.length < minL ) {
            for(let i=0; i<minL-string.length; i++) {
                str = str + "W";
            }
        }

        let element = document.createElement("div");
        let manager = document.getElementsByClassName("hidden-element");

        element.innerHTML = str;
        element.style.fontSize = this.props.fSize;
        element.style.width = "auto";
        element.style.height = "auto";
        manager[0].appendChild( element );

        let clientWidth = element.clientWidth
        manager[0].innerHTML = "";

        return clientWidth;
    }

    render() {
        return (
            <div className='list-manager' id='list-manager'>
                <div id={this.props._key_} className="hidden-element"></div>
                {
                    this.props.link_ ?
                        this.props.list.map((elt, index) => (
                            <Link key={this.props.printKey!==null ? elt[this.props.printKey] : elt} to={this.props.link_(elt)} className='list-manager_content_checkBox_'>
                                <label htmlFor={this.props.printKey!==null ? elt[this.props.printKey] : elt} className=''
                                    style={{
                                        maxWidth: this.state.maxWidth + "px",
                                    }}
                                >
                                    {
                                        this.props.isSelectable &&
                                            <input id={this.props.printKey!==null ? elt[this.props.printKey] : elt} name={this.props.printKey!==null ? elt[this.props.printKey] : elt} type='checkbox'
                                                checked={this.props.checked(elt)}
                                                onChange={(e)=>{
                                                    this.props.onChange(e, elt, index);
                                                }}
                                            />
                                    }
                                    <label htmlFor={this.props.printKey!==null ? elt[this.props.printKey] : elt}>
                                        {this.props.printKey!==null ? elt[this.props.printKey] : elt}
                                    </label>
                                </label>
                            </Link>
                        ))
                    :
                        this.props.list.map((elt, index) => (
                            <label key={this.props.printKey!==null ? elt[this.props.printKey] : elt} htmlFor={this.props.printKey!==null ? elt[this.props.printKey] : elt} className='list-manager_content_checkBox_'
                                style={{...this.props.style(elt), ...{
                                    maxWidth: this.state.maxWidth + "px",
                                }}}
                            >
                                {
                                    this.props.isSelectable &&
                                        <input id={this.props.printKey!==null ? elt[this.props.printKey] : elt} name={this.props.printKey!==null ? elt[this.props.printKey] : elt} type='checkbox'
                                            checked={this.props.checked(elt)}
                                            onChange={(e)=>{
                                                this.props.onChange(e, elt, index);
                                            }}
                                        />
                                }
                                <label htmlFor={this.props.printKey!==null ? elt[this.props.printKey] : elt}>
                                    {this.props.printKey!==null ? elt[this.props.printKey] : elt}
                                </label>
                            </label>
                        ))
                }
            </div>
        )
    }
}

export default ListManager;