import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import InputStyle1 from '../components/input-style1.component/input-style1.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/login.style.css';
import './responsive.style/login.responsive.style.css';



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



/**Importation des helpers */
import text from '../GLOBAL-VAR/global-text';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}


class LoginScreen extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            error: false,
            errorMessage: "Vous n'êtes pas encore autorisé à accéder au système. Votre accréditation est en court de définition.",
            isAgent: false,
        }

        this.accessStore = new AccessStore( this.props.dispatch);
        this.text = new text();
    }

    componentDidMount() {
        
    }

    _get_this_userPermission( access, callback=() => {}) {
        this._getCurrentUserPermissions(access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        });
    }

    _get_if_IsAdmin(access, callback=()=>{}) {
        this._getIfCurrentUserIsAdmin(access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._get_if_IsAdmin( this.props.user.access, callback );
            });
        });
    }

    _submit_login_form(e) {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        super._login(document.forms["loginForm"]["username"].value, document.forms["loginForm"]["password"].value, ( response ) => {
            if( response.status === 200 ) {
                // console.log( response.data );
                localStorage.setItem('currentUserInfo', JSON.stringify(response.data))
                this._get_this_userPermission(response.data.access, (userPermissions) => {
                    // console.log( userPermissions );
                    if( userPermissions.permissions.length > 0 ) {
                        let user = {
                            username: document.forms["loginForm"]["username"].value,
                            access: response.data.access,
                            refresh: response.data.refresh,
                        };
                        this.accessStore.setRedux('SET_USER', user, () => {
                            this._get_if_IsAdmin(response.data.access, (stat) => {
                                this.accessStore.setRedux('SET_IS_ADMIN', stat.isadmin, () => {
                                    // console.log( stat );
                                    if( stat.isadmin === true ) {
                                        let currentLocation = window.location.href;
                                        if( currentLocation.split("dashboard").length > 1 ) {
                                            return true;
                                        } else {
                                            window.location.href = './dashboard';
                                        }
                                    }
                                    else {
                                        window.location.href = './gestion-compte';
                                    }
                                });
                            });
                        });
                    }

                    else {
                        this.setState({
                            error: true,
                            errorMessage: "Vous n'êtes pas encore autorisé à accéder au système. Votre accréditation est en court de définition."
                        });
                    }
                })
            }
            this.setState({
                isLoading: false,
            });
            return true;
        },(error) => {
            if( error.response && error.response.status === 401 ) {
                alert( error.response.data.detail );
            }
            if( e.toString() === "Error: Network Error" ) {
                alert( "Problème de connexion :(" );
            }
            this.setState({
                isLoading: false,
            });
            return true;
        });
    }
   
    render() {
        return (
            <>
                {
                    super.render()
                }
                {
                    this.state.error ?
                        <div className='login_screen flex-row justify-center'>
                            <div className='background-primary shadow-box-style2 login_screen_ error-message'>
                                <div className='login_screen_container'>
                                    <div className='login_screen_container_text'>
                                        <p>
                                            {
                                                this.state.errorMessage
                                            }
                                        </p>
                                    </div>
                                    <div className='flex-row justify-center login_screen_submit_container'>
                                        <input className='submit-style-1' type='submit' value="Essayer avec un autre compte"
                                            onClick={() => {
                                                this.setState({
                                                    error: false,
                                                });
                                            }}
                                        />
                                    </div>

                                    <div className='color-ff text-align-right'>
                                        <Link
                                            className=""
                                            key={this._generateID(36)}
                                            to="/"
                                            style={{
                                                color: "#ffffff",
                                            }}
                                        >
                                            <span className='letter-spacing-2 font-size-0_8 text-shadow-ff'>
                                                Retourner à l'accueil
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    :
                        <div className='login_screen flex-row justify-center'>
                            <div className='background-primary shadow-box-style2 login_screen_'>
                                <div className='login_screen_container'>
                                    <div className='color-ff bold text-align-center login_screen_title'>
                                        <span className='letter-spacing-2 font-size-1_5 text-shadow-ff family-cmuntt'>
                                            {this.text.appName}
                                        </span>
                                    </div>

                                    <div className='color-ff text-align-center login_screen_subTitle'>
                                        <span className='letter-spacing-2 font-size-0_9 text-shadow-ff'>
                                            Se connecter
                                        </span>
                                    </div>
                                    <form
                                        action="#"
                                        target="_self"
                                        method="post"
                                        name="loginForm"
                                        style={{background: "transparent"}}
                                        onSubmit = {(e) => {
                                            this._submit_login_form(e);
                                        }}
                                    >
                                        <div className='login_screen_input_container'>
                                            <InputStyle1
                                                label="Nom d'utilisateur"
                                                type="text"
                                                required={true}
                                                color="#ffffff"
                                                name="username"
                                                labelStyle ={{
                                                    fontWeight: "bold",
                                                    letterSpacing: "1px",
                                                    color: "#ffffff",
                                                }}
                                            />
                                        </div>
                                        <div className='login_screen_input_container'>
                                            <InputStyle1
                                                label="Mot de passe"
                                                type="password"
                                                name="password"
                                                required={true}
                                                color="#ffffff"
                                                labelStyle ={{
                                                    fontWeight: "bold",
                                                    letterSpacing: "1px",
                                                    color: "#ffffff",
                                                }}
                                            />
                                        </div>

                                        <div className='login_screen_input_container'>
                                            {/* <label htmlFor={"isAgent"} className='add_content_checkBox_ add_content_checkBox_2'
                                                style={{
                                                    border: "0px solid #00000000"
                                                }}
                                            >
                                                <input id={"isAgent"} name={"isAgent"} type='checkbox' checked={this.state.isAgent}
                                                    onChange={(e)=>{
                                                        let currentYears = this.state.currentYears;
                                                        
                                                        if( e.target.checked === true ) {
                                                            currentYears.push(year);
                                                        }
                                                        else {
                                                            currentYears.splice( currentYears.indexOf(year), 1);
                                                        }

                                                        this.setState({
                                                            currentYears: currentYears,
                                                        });
                                                        return true;
                                                    }}
                                                />
                                                <label htmlFor={year}>
                                                    {year}
                                                </label>
                                            </label> */}
                                        </div>

                                        <div className='flex-row justify-center login_screen_submit_container'>
                                            <input className='submit-style-1' type='submit' value="Se connecter"/>
                                        </div>

                                        <div className='flex-row justify-between flex-wrap'
                                            style={{
                                                marginTop: "30px",
                                            }}
                                        >
                                            <div className='color-ff text-align-right hoverToShadow'
                                                style={{
                                                    marginRight: "20px",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Link
                                                    className=""
                                                    key={this._generateID(36)}
                                                    to="/registration"
                                                    style={{
                                                        color: "#ffffff",
                                                    }}
                                                >
                                                    <span className='letter-spacing-2 font-size-0_8'>
                                                        S'enregistrer
                                                    </span>
                                                </Link>
                                            </div>

                                            <div className='color-ff text-align-left hoverToShadow'>
                                                <Link
                                                    className=""
                                                    key={this._generateID(36)}
                                                    to="/"
                                                    style={{
                                                        color: "#ffffff",
                                                    }}
                                                >
                                                    <span className='letter-spacing-2 font-size-0_8'>
                                                        Retourner à l'accueil
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}

LoginScreen = connect(mapStateToProps, null)(LoginScreen);

export default LoginScreen;
