import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputStyle1 from '../components/input-style1.component/input-style1.component';
import ChronogramStyle2 from '../components/ChronogramStyle2.component/ChronogramStyle2.component';
import SearchInputComponent from '../components/searchInput.component/searchInput.component';

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/dashboard.addText.style.scss';



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DashboardAddText extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            
            viewTaskManager: false,
            chronogram: [],
            chronogramTree: {},
            currentTextContentParentIndex: null,
            chronogramUpdateType: "add",
            currentTextContent: {
                name: "",
                title: "",
                title_level: 1,
                text: "",
                parent_id: null,
                parent_index: null,
            },

            task_tree: [],
            task_positions: [],
            task_list: [],
            task_id_list: [],

            commitmentList: [],
            searchResult_commitment: [],
            currentCommitmentsList: [],

            selectedPreviewLink: [],
            selectedList: [],

            groupsList: [],
            currentGroupsList: [],
            groupSearchResult: [],

            currentGroupsPrincipalList: [],
            groupSearchPrincipalResult: [],

            decreeing_bodyList: [],
            decreeing_bodySearch_result: [],

            proposing_bodyList: [],
            proposing_bodySearch_result: [],

            amplificationList: [],
            amplificationSearch_result: [],

            accountsList: [],
            currentAccountList: [],
            accountSearch_result: [],
        }

        this.renameTasks= [];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        this._get_all_groups((groups) => {
            this._get_users((accounts) => {
                this.setState({
                    accountsList: accounts,
                    isLoading: false,
                });
            });
            this.setState({
                groupsList: groups,
            });
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log('response ...+++', response.data)
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _generateOrderTable(obj) {
        let table = Object.keys(obj).map((key) => {
            return {position: (obj[key].tree).toString(), task: obj[key].task};
        });

        let table_sort = table.sort((a,b) => {
            let fa = a.position,
                fb = b.position;

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        // console.log(table_sort);
    }

    _addTask(currentTextContent, currentTextContentParentIndex) {
        let task_tree = this.state.task_tree;
        let task_positions = this.state.task_positions;
        let task_list = this.state.task_list;


        if(currentTextContentParentIndex !== null ) {
            task_list.push(currentTextContent);
            task_positions.push(task_positions[currentTextContentParentIndex] + "," + (task_list.length-1));
            task_tree.push([]);
            task_tree[currentTextContentParentIndex].push(task_tree.length-1);
        }
        else {
            task_list.push(currentTextContent);
            // let lastIndex = 0;
            // task_positions.map((position, index) => {
            //     if(Number(position[0]) > lastIndex) {
            //         console.log(position[0], lastIndex);
            //         lastIndex = Number(position[0]) + 1;
            //     }
            //     return true;
            // })
            task_positions.push((task_list.length-1).toString());
            // console.log(task_positions);
            task_tree.push([]);
        }

        this.setState({
            task_tree: task_tree,
            task_positions: task_positions,
            task_list: task_list,
            currentTextContent: {
                name: "",
                title: "",
                title_level: 1,
                text: "",
                parent_id: null,
                parent_index: null,
            },
            viewTaskManager: false,
        });
    }

    _updateTask(taskIndex, task) {
        let task_list = this.state.task_list;
        task_list[taskIndex] = task;
        this.setState({
            task_list: task_list,
            currentTextContent: {
                name: "",
                title: "",
                title_level: 1,
                text: "",
                parent_id: null,
                parent_index: null,
            },
            viewTaskManager: false,
        });
    }

    _deleteTask(taskIndex) {
        let task_tree = this.state.task_tree;
        let task_positions = this.state.task_positions;
        let task_list = this.state.task_list;

        // let child = this.get_all_child_of_node(task_tree, null, taskIndex, 0);

        /**Appel recursid de suppressionde chacun des fils */
        if(task_tree[taskIndex].length > 0) {
            for(let i=0; i<task_tree[taskIndex].length; i++) {
                this._deleteTask(task_tree[taskIndex][i]);
            }
        }

        /**Suppression de l'element dans la liste des fils du parent */
        let positionsTab = task_positions[taskIndex].split(",");
        if(positionsTab.length > 1) {
            // console.log(task_list[taskIndex].task, positionsTab);
        
            positionsTab.splice(-1);
            let parentPosition = positionsTab.toString();
            let parentIndex = task_positions.indexOf(parentPosition);
            // console.log(parentIndex, positionsTab.toString());
            
            let positionInParentOnTree = task_tree[parentIndex].indexOf(taskIndex);
            // console.log(positionInParentOnTree);
            task_tree[parentIndex].splice(positionInParentOnTree, 1);
        }

        /**Destruction du noeud courant */
        task_tree[taskIndex] = -1;

        task_positions[taskIndex] = -1;
        task_list[taskIndex] = -1;

        // this.renameTask(task_tree, task_positions, task_list);

        this.setState({
            task_tree: task_tree,
            task_positions: task_positions,
            task_list: task_list,
        });

        // console.log(task_tree, task_positions, task_list);
    }

    _startAddTasks(tab, index, task_id_list, callback=()=>{}) {
        if(index<tab.length) {
            if(tab[index] !== -1) {
                this._addTask_(tab[index], (response) => {
                    let newTask = response.data.data;
                    newTask.parent_index = tab[index].parent_index;
                    tab[index] = newTask;

                    task_id_list.push(response.data.data.id);
                    this._startAddTasks(tab, index+1, task_id_list, callback);
                });
            } else {
                this._startAddTasks(tab, index+1, task_id_list, callback);
            }
        } else {
            callback(tab, task_id_list);
        }
    }

    _startUpdateTasks(tab, index, task_id_list, callback=()=>{}) {
        if(index<tab.length) {
            if(tab[index] !== -1) {
                let data = {};
                if(tab[index].parent_index !== null && tab[tab[index].parent_index]) {
                    tab[index].parent_id = tab[tab[index].parent_index];
                    data = {parent_id: tab[tab[index].parent_index].id};
                }
                // console.log(data);
                this._updateTask_(tab[index].id, data, (response) => {
                    let newTask = response.data.data;
                    newTask.parent_index = tab[index].parent_index;
                    tab[index] = newTask;

                    task_id_list.push(response.data.data.id);
                    this._startUpdateTasks(tab, index+1, task_id_list, callback);
                });
            } else {
                this._startUpdateTasks(tab, index+1, task_id_list, callback);
            }
        } else {
            callback(tab, task_id_list);
        }
    }

    _onChange = async (e) => {
        const files = Array.from( e );
        
        let selectedList = e;

        let tab = [];

        Promise.all( files.map( file  => {
            return (
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve( ev.target.result );
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL( file );
                })
            )
        })).then( elt => {

            tab = [elt];

            this.setState({
                selectedList: selectedList,
                selectedPreviewLink: tab,
            });
        });
    }

    _startAddText() {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isProgress: true,
                    progressionTile: 'Envoi du document en court...',
                });
    
                let formData = new FormData();
                formData.append("content", this.state.selectedList[0]);
                formData.append("name", document.forms["addLawTextForm"]["name"].value);
    
                this._addLawText_(formData, (response) => {
                    // console.log( response.data );
                    clearInterval( this.submitTime );
                    this._openOrCloseAuth();
                    this.setState({
                        isProgress: false,
                        progressionTile: "",
                        requestProgression: 0,
                    });

                    let detailsFormData = {
                        "details": {
                            main_structure: this.state.currentGroupsList,
                            main_institution_issuing: this.state.currentGroupsPrincipalList,
                            proposing_body: this.state.proposing_bodyList,
                            decreeing_body: this.state.decreeing_bodyList,
                            amplification: this.state.amplificationList,
                            signatory: this.state.currentAccountList,
                            text_type: document.forms["addLawTextForm"]["lawTextType"].value,
                            text_number: document.forms["addLawTextForm"]["lawTextNumber"].value,
                            write_at: document.forms["addLawTextForm"]["lawTextDateRedaction"].value,
                            signed_at: document.forms["addLawTextForm"]["lawTextSignatureDate"].value,
                            subject: document.forms["addLawTextForm"]["lawTextSubject"].value,
                            place: document.forms["addLawTextForm"]["place"].value,
                            texts: {
                                list: this.state.task_list,
                                tree: this.state.task_tree,
                                positions: this.state.task_positions,
                            },
                        }
                    };

                    this._updateDetailsLawText_(response.data.data.id, detailsFormData, ((response_) => {
                        console.log(response.data);
                        this.setState({
                            isLoading: false,
                        });
                    }));
                    
                }, (event) => {
                    this.setState({
                        requestProgression: Math.round((100 * event.loaded) / event.total),
                        progressionTile: Math.round((100 * event.loaded) / event.total) === 100 ? "Envoi du document terminé. Extraction de mots-clés en court. Veuillez patienter!" : "Envoi du document",
                    });

                    if( Math.round((100 * event.loaded) / event.total) === 100 ) {
                        this.submitTime = setInterval(() => {
                            this._refreshUserToken(this.props.user.refresh, ( response ) => {
                                if( response.status === 200 ) {
                                    console.log("refresh_token");
                                    this._set_new_user( response );
                                }
                            },( error ) => {
                                if (error.toString() === "Error: Network Error") {
                                    alert("Aucun accès à internet :(");
                                }
                            });
                        }, 120000);
                    }
                });
            }
        }, () => {
            this._openOrCloseAuth();
        });
    }

    _addLawText_(data, callback=()=>{}) {
        this._addLawText(data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 201 ) {
                callback(response);
            }
        },(error) => {
            console.log(error.response)
            this._manageError( error, () => {
                this._addLawText_(data, callback);
            });
        });
    }

    _updateDetailsLawText_(id, data, callback=()=>{}) {
        this._updateDetailsLawText(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 201 ) {
                callback(response);
            }
        },(error) => {
            console.log(error.response)
            this._manageError( error, () => {
                this._updateDetailsLawText_(id, data, callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                */

                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à ajouter votre document pour un groupe");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='dashboard_body'>
                    <div className='dashboard_body_title'>
                        <span className='dashboard_body_title_'>
                            Ajouter un texte de lois
                        </span>
                    </div>
                    <form
                        action="#"
                        target="_self"
                        method="post"
                        name="addLawTextForm"
                        style={{ background: "transparent" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            this._startAddText();
                            return true;
                        }}
                    >
                        <div className='dashboard_body_input'>
                            <InputStyle1
                                label="Nom"
                                name="name"
                                type="text"
                                required={true}
                                color={this.VAR.color_1}
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: this.VAR.color_1,
                                }}
                            />
                        </div>

                        <div className='dashboard_body_input'>
                            <div className='task_editor_inputContent'>
                                <span>
                                    Type du texte :
                                </span>
                                <select name='lawTextType'>
                                    <option value={"Arrêté"}>Arrêté</option>
                                    <option value={"Décret"}>Décret</option>
                                    <option value={"Ordonance"}>Ordonance</option>
                                </select>
                            </div>
                        </div>

                        <div className='dashboard_body_input'>
                            <InputStyle1
                                label="Numéro du texte de loi"
                                name="lawTextNumber"
                                type="text"
                                required={true}
                                color={this.VAR.color_1}
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: this.VAR.color_1,
                                }}
                            />
                        </div>

                        <div className='dashboard_body_input'>
                            <InputStyle1
                                label="Portant"
                                name="lawTextSubject"
                                type="text"
                                required={true}
                                color={this.VAR.color_1}
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: this.VAR.color_1,
                                }}
                            />
                        </div>

                        <br/>
                        <div className='dashboard_body_input'>
                            <div className='dashboard_body_input_label'>
                                <span>Contenu du texte</span>
                            </div>

                            <div className='dashboard_body_input_task'>
                                <ChronogramStyle2
                                    tree={this.state.task_tree}
                                    positions={this.state.task_positions}
                                    list={this.state.task_list}
                                    control={true}
                                    controlText={"Ajouter du contenu"}
                                    noHoverEffect={true}

                                    editAction={(task_index) => {
                                        this.setState({
                                            chronogramUpdateType: "update",
                                        }, () => {
                                            this.setState({
                                                currentTextContentParentIndex: task_index,
                                                viewTaskManager: true,
                                                currentTextContent: this.state.task_list[task_index],
                                            })
                                        });
                                        return true;
                                    }}

                                    deleteAction={(taskIndex) => {
                                        if(window.confirm("Voulez-vous vraiment supprimer ce contenu?")) {
                                            this._deleteTask(taskIndex)
                                            return true;
                                        }
                                    }}

                                    addAction={(parent_index) => {
                                        this.setState({
                                            chronogramUpdateType: "add",
                                            currentTextContent: {
                                                name: "",
                                                title: "",
                                                title_level: 1,
                                                text: "",
                                                parent_id: null,
                                                parent_index: parent_index,
                                            },
                                        }, () => {
                                            this.setState({
                                                currentTextContentParentIndex: parent_index,
                                                viewTaskManager: true,
                                            })
                                        });
                                        return true;
                                    }}
                                />
                            </div>
                        </div>

                        <br/>
                        {/* <div className='dashboard_body_input'>
                            <div className='dashboard_body_input_label'>
                                <span>Image de garde</span>
                            </div>
                            <input type='file' required name='cover' accept="image/*,.gif,.GIF,.Gif" className='dashboard_body_input_bal'/>
                        </div> */}

                        <br/>
                        <div className='dashboard_body_input'>
                            <InputStyle1
                                label="Date de rédaction du document"
                                name="lawTextDateRedaction"
                                label_hd={true}
                                type="date"
                                required={false}
                                color={this.VAR.color_1}
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: this.VAR.color_1,
                                }}
                            />
                        </div>

                        <br/>
                        <div className='dashboard_body_input'>
                            <InputStyle1
                                label="Date de signature du document"
                                name="lawTextSignatureDate"
                                label_hd={true}
                                type="date"
                                required={false}
                                color={this.VAR.color_1}
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: this.VAR.color_1,
                                }}
                            />
                        </div>


                        <br/>
                        <div className='dashboard_body_input'>
                            <InputStyle1
                                label="Lieu"
                                name="place"
                                label_hd={true}
                                type="text"
                                required={false}
                                color={this.VAR.color_1}
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: this.VAR.color_1,
                                }}
                            />
                        </div>

                        <div className='registration_form_inpContainer registration_form_cover'>
                            <div className='registration_form_label  letter-spacing-1'>
                                <span className="font-size-1_2 bold">
                                    Structure principale liée du décret
                                </span>
                            </div>
                            <div className='userListContainer_body'>
                                <SearchInputComponent key_="name" id_="id" printSelected={false} updated={true} data={this.state.groupSearchResult.length > 0 ? this.state.groupSearchResult : this.state.groupsList} currentSelected={this.state.currentGroupsList} placeholder="Nom du groupe"
                                    onChange={(value) => {
                                        let tab = [];
                                        this.state.groupsList.map((group, index) => {
                                            if (group.name.toLowerCase() === value.toLowerCase() ||
                                                group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                            ) {
                                                tab.push(group);
                                            }
                                        });

                                        this.setState({
                                            groupSearchResult: tab,
                                        });
                                    }}
                                    onSelected={(selected) => {
                                        if (this.state.currentGroupsList.includes(selected.id)) {
                                            let currentGroupsList = this.state.currentGroupsList;
                                            for (var i = 0; i < currentGroupsList.length; i++) {
                                                if (currentGroupsList[i] === selected.id) {
                                                    currentGroupsList.splice(i, 1);
                                                    this.setState({
                                                        currentGroupsList: currentGroupsList,
                                                    });
                                                    return true;
                                                }
                                            }
                                        }

                                        else {
                                            let currentGroupsList = this.state.currentGroupsList;
                                            currentGroupsList=[selected.id];
                                            // console.log( currentGroupsList );
                                            this.setState({
                                                currentGroupsList: currentGroupsList,
                                            });
                                        }
                                    }}

                                    style={(elt) => {
                                        return {
                                            backgroundColor: this.state.currentGroupsList.includes(elt.id) ? "#dc354555" : "transparent",
                                            color: this.state.currentGroupsList.includes(elt.id) ? "#ffffff" : "#000000",
                                        }
                                    }}

                                    isChecked={(elt) => {
                                        return this.state.currentGroupsList.includes(elt.id) ? true : false 
                                    }}
            
                                    icon={
                                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                marginRight: "5px",
                                            }}
                                        >
                                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    }
                                />
                            </div>
                        </div>

                        <div className='registration_form_inpContainer registration_form_cover'>
                            <div className='registration_form_label  letter-spacing-1'>
                                <span className="font-size-1_2 bold">
                                    Institution principale qui émet le texte de loi 
                                </span>
                            </div>
                            <div className='userListContainer_body'>
                                <SearchInputComponent key_="name" id_="id" printSelected={false} updated={true} data={this.state.groupSearchPrincipalResult.length > 0 ? this.state.groupSearchPrincipalResult : this.state.groupsList} currentSelected={this.state.currentGroupsPrincipalList} placeholder="Nom du groupe"
                                    onChange={(value) => {
                                        let tab = [];
                                        this.state.groupsList.map((group, index) => {
                                            if (group.name.toLowerCase() === value.toLowerCase() ||
                                                group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                            ) {
                                                tab.push(group);
                                            }
                                        });

                                        this.setState({
                                            groupSearchPrincipalResult: tab,
                                        });
                                    }}
                                    onSelected={(selected) => {
                                        if (this.state.currentGroupsPrincipalList.includes(selected.id)) {
                                            let currentGroupsPrincipalList = this.state.currentGroupsPrincipalList;
                                            for (var i = 0; i < currentGroupsPrincipalList.length; i++) {
                                                if (currentGroupsPrincipalList[i] === selected.id) {
                                                    currentGroupsPrincipalList.splice(i, 1);
                                                    this.setState({
                                                        currentGroupsPrincipalList: currentGroupsPrincipalList,
                                                    });
                                                    return true;
                                                }
                                            }
                                        }

                                        else {
                                            let currentGroupsPrincipalList = this.state.currentGroupsPrincipalList;
                                            currentGroupsPrincipalList=[selected.id];
                                            // console.log( currentGroupsPrincipalList );
                                            this.setState({
                                                currentGroupsPrincipalList: currentGroupsPrincipalList,
                                            });
                                        }
                                    }}

                                    style={(elt) => {
                                        return {
                                            backgroundColor: this.state.currentGroupsPrincipalList.includes(elt.id) ? "#dc354555" : "transparent",
                                            color: this.state.currentGroupsPrincipalList.includes(elt.id) ? "#ffffff" : "#000000",
                                        }
                                    }}

                                    isChecked={(elt) => {
                                        return this.state.currentGroupsPrincipalList.includes(elt.id) ? true : false 
                                    }}
            
                                    icon={
                                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                marginRight: "5px",
                                            }}
                                        >
                                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    }
                                />
                            </div>
                        </div>

                        <div className='registration_form_inpContainer registration_form_cover'>
                            <div className='registration_form_label  letter-spacing-1'>
                                <span className="font-size-1_2 bold">
                                    Organe qui propose
                                </span>
                            </div>
                            <div className='userListContainer_body'>
                                <SearchInputComponent key_="name" id_="id" printSelected={false} updated={true} data={this.state.proposing_bodySearch_result.length > 0 ? this.state.proposing_bodySearch_result : this.state.groupsList} currentSelected={this.state.proposing_bodyList} placeholder="Nom du groupe"
                                    onChange={(value) => {
                                        let tab = [];
                                        this.state.groupsList.map((group, index) => {
                                            if (group.name.toLowerCase() === value.toLowerCase() ||
                                                group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                            ) {
                                                tab.push(group);
                                            }
                                        });

                                        this.setState({
                                            proposing_bodySearch_result: tab,
                                        });
                                    }}
                                    onSelected={(selected) => {
                                        if (this.state.proposing_bodyList.includes(selected.id)) {
                                            let proposing_bodyList = this.state.proposing_bodyList;
                                            for (var i = 0; i < proposing_bodyList.length; i++) {
                                                if (proposing_bodyList[i] === selected.id) {
                                                    proposing_bodyList.splice(i, 1);
                                                    this.setState({
                                                        proposing_bodyList: proposing_bodyList,
                                                    });
                                                    return true;
                                                }
                                            }
                                        }

                                        else {
                                            let proposing_bodyList = this.state.proposing_bodyList;
                                            proposing_bodyList=[selected.id];
                                            // console.log( proposing_bodyList );
                                            this.setState({
                                                proposing_bodyList: proposing_bodyList,
                                            });
                                        }
                                    }}

                                    style={(elt) => {
                                        return {
                                            backgroundColor: this.state.proposing_bodyList.includes(elt.id) ? "#dc354555" : "transparent",
                                            color: this.state.proposing_bodyList.includes(elt.id) ? "#ffffff" : "#000000",
                                        }
                                    }}

                                    isChecked={(elt) => {
                                        return this.state.proposing_bodyList.includes(elt.id) ? true : false 
                                    }}
            
                                    icon={
                                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                marginRight: "5px",
                                            }}
                                        >
                                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    }
                                />
                            </div>
                        </div>

                        <div className='registration_form_inpContainer registration_form_cover'>
                            <div className='registration_form_label  letter-spacing-1'>
                                <span className="font-size-1_2 bold">
                                    Organe qui décrète
                                </span>
                            </div>
                            <div className='userListContainer_body'>
                                <SearchInputComponent key_="name" id_="id" printSelected={false} updated={true} data={this.state.decreeing_bodySearch_result.length > 0 ? this.state.decreeing_bodySearch_result : this.state.groupsList} currentSelected={this.state.decreeing_bodyList} placeholder="Nom du groupe"
                                    onChange={(value) => {
                                        let tab = [];
                                        this.state.groupsList.map((group, index) => {
                                            if (group.name.toLowerCase() === value.toLowerCase() ||
                                                group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                            ) {
                                                tab.push(group);
                                            }
                                        });

                                        this.setState({
                                            decreeing_bodySearch_result: tab,
                                        });
                                    }}
                                    onSelected={(selected) => {
                                        if (this.state.decreeing_bodyList.includes(selected.id)) {
                                            let decreeing_bodyList = this.state.decreeing_bodyList;
                                            for (var i = 0; i < decreeing_bodyList.length; i++) {
                                                if (decreeing_bodyList[i] === selected.id) {
                                                    decreeing_bodyList.splice(i, 1);
                                                    this.setState({
                                                        decreeing_bodyList: decreeing_bodyList,
                                                    });
                                                    return true;
                                                }
                                            }
                                        }

                                        else {
                                            let decreeing_bodyList = this.state.decreeing_bodyList;
                                            decreeing_bodyList=[selected.id];
                                            // console.log( decreeing_bodyList );
                                            this.setState({
                                                decreeing_bodyList: decreeing_bodyList,
                                            });
                                        }
                                    }}

                                    style={(elt) => {
                                        return {
                                            backgroundColor: this.state.decreeing_bodyList.includes(elt.id) ? "#dc354555" : "transparent",
                                            color: this.state.decreeing_bodyList.includes(elt.id) ? "#ffffff" : "#000000",
                                        }
                                    }}

                                    isChecked={(elt) => {
                                        return this.state.decreeing_bodyList.includes(elt.id) ? true : false 
                                    }}
            
                                    icon={
                                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                marginRight: "5px",
                                            }}
                                        >
                                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    }
                                />
                            </div>
                        </div>

                        <div className='registration_form_inpContainer registration_form_cover'>
                            <div className='registration_form_label  letter-spacing-1'>
                                <span className="font-size-1_2 bold">
                                    Amplifications ( Structures devant recevoir le décret par transmission administrative )
                                </span>
                            </div>
                            <div className='userListContainer_body'>
                                <SearchInputComponent key_="name" id_="id" printSelected={false} updated={true} data={this.state.amplificationSearch_result.length > 0 ? this.state.amplificationSearch_result : this.state.groupsList} currentSelected={this.state.amplificationList} placeholder="Nom du groupe"
                                    onChange={(value) => {
                                        let tab = [];
                                        this.state.groupsList.map((group, index) => {
                                            if (group.name.toLowerCase() === value.toLowerCase() ||
                                                group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                            ) {
                                                tab.push(group);
                                            }
                                        });

                                        this.setState({
                                            amplificationSearch_result: tab,
                                        });
                                    }}
                                    onSelected={(selected) => {
                                        if (this.state.amplificationList.includes(selected.id)) {
                                            let amplificationList = this.state.amplificationList;
                                            for (var i = 0; i < amplificationList.length; i++) {
                                                if (amplificationList[i] === selected.id) {
                                                    amplificationList.splice(i, 1);
                                                    this.setState({
                                                        amplificationList: amplificationList,
                                                    });
                                                    return true;
                                                }
                                            }
                                        }

                                        else {
                                            let amplificationList = this.state.amplificationList;
                                            amplificationList.push(selected.id);
                                            // console.log( amplificationList );
                                            this.setState({
                                                amplificationList: amplificationList,
                                            });
                                        }
                                    }}

                                    style={(elt) => {
                                        return {
                                            backgroundColor: this.state.amplificationList.includes(elt.id) ? "#dc354555" : "transparent",
                                            color: this.state.amplificationList.includes(elt.id) ? "#ffffff" : "#000000",
                                        }
                                    }}

                                    isChecked={(elt) => {
                                        return this.state.amplificationList.includes(elt.id) ? true : false 
                                    }}
            
                                    icon={
                                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                marginRight: "5px",
                                            }}
                                        >
                                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    }
                                />
                            </div>
                        </div>

                        <div className='registration_form_inpContainer registration_form_cover'>
                            <div className='registration_form_label  letter-spacing-1'>
                                <span className="font-size-1_2 bold">
                                    Signataire
                                </span>
                            </div>
                            <div className='userListContainer_body'>
                                <SearchInputComponent key_="username" id_="id" printSelected={false} updated={true} data={this.state.accountSearch_result.length > 0 ? this.state.accountSearch_result : this.state.accountsList} currentSelected={this.state.currentAccountList} placeholder="Nom du groupe"
                                    onChange={(value) => {
                                        let tab = [];
                                        this.state.accountsList.map((group, index) => {
                                            if (group.name.toLowerCase() === value.toLowerCase() ||
                                                group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                            ) {
                                                tab.push(group);
                                            }
                                        });

                                        this.setState({
                                            accountSearch_result: tab,
                                        });
                                    }}
                                    onSelected={(selected) => {
                                        if (this.state.currentAccountList.includes(selected.id)) {
                                            let currentAccountList = this.state.currentAccountList;
                                            for (var i = 0; i < currentAccountList.length; i++) {
                                                if (currentAccountList[i] === selected.id) {
                                                    currentAccountList.splice(i, 1);
                                                    this.setState({
                                                        currentAccountList: currentAccountList,
                                                    });
                                                    return true;
                                                }
                                            }
                                        }

                                        else {
                                            let currentAccountList = this.state.currentAccountList;
                                            currentAccountList=[selected.id];
                                            // console.log( currentAccountList );
                                            this.setState({
                                                currentAccountList: currentAccountList,
                                            });
                                        }
                                    }}

                                    style={(elt) => {
                                        return {
                                            backgroundColor: this.state.currentAccountList.includes(elt.id) ? "#dc354555" : "transparent",
                                            color: this.state.currentAccountList.includes(elt.id) ? "#ffffff" : "#000000",
                                        }
                                    }}

                                    isChecked={(elt) => {
                                        return this.state.currentAccountList.includes(elt.id) ? true : false 
                                    }}
            
                                    icon={
                                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                marginRight: "5px",
                                            }}
                                        >
                                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    }
                                />
                            </div>
                        </div>

                        <div className='dashboard_body_input'>
                            <div className='dashboard_body_input_label'>
                                <span>Description du Texte</span>
                            </div>

                            <div className='dashboard_body_input_editor'>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.project_description}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log( 'Editor is ready to use!', editor );
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        this.setState({
                                            project_description: data,
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div className='registration_form_inpContainer registration_form_cover'>
                            { this.state.selectedList &&
                                <div className='registration_form_label  letter-spacing-1'>
                                    <span className="font-size-1_2 bold">
                                        Nom du document { this.state.selectedList[0] && this.state.selectedList[0].name && this.state.selectedList[0].name }
                                    </span>
                                </div>
                            }
    
                                <div className='sectionBody_dragFile'
                                
                                    onDrop={(e) => {
                                        e.preventDefault() ;
                                        document.getElementById( "dropC" ).style.transform = "scale(1)";

                                        this._onChange( e.dataTransfer.files );
                                    }}
                
                                    onDragEnter={(e) => {
                                        document.getElementById( "dropC" ).style.transform = "scale(1.6)";
                                    }}
                
                                    onDragLeave={(e) => {
                                        document.getElementById( "dropC" ).style.transform = "scale(1)";
                                    }}
                
                                    onDragOver={(e) => {
                                        document.getElementById( "dropC" ).style.transform = "scale(1.6)";
                                        e.preventDefault() ;
                                    }}
                                
                                >
                                    <div className='sectionBody_dragFile_svg'>
                                        {/* <svg id="dropC" width="50" height="12" viewBox="0 0 106 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M86.6219 28.6167C86.6219 28.3333 86.6692 28.05 86.6692 27.7667C86.6692 12.4194 74.413 0 59.2938 0C48.3862 0 39.0165 6.46944 34.6156 15.8194C32.6991 14.8514 30.546 14.2847 28.2746 14.2847C21.2946 14.2847 15.4741 19.4556 14.3621 26.2083C5.98616 29.0889 0 37.0931 0 46.5139C0 58.3667 9.48795 68 21.1763 68H45.4286V49.1111H34.0241L53 29.3486L71.9759 49.0875H60.5714V67.9764H86.6692C97.3638 67.9764 106 59.1222 106 48.2847C106 37.4472 97.3165 28.6403 86.6219 28.6167Z" fill="#769ea7"/>
                                        </svg> */}
                                        <svg id="dropC" width="50" height="12" viewBox="0 0 16 16" className="bi bi-upload" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                            <path fillRule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                        </svg>
                                    </div>
                                    <div className='sectionBody_dragFile_text'>
                                        <span>
                                            Glissez, déposez ici ou 
                                        </span>
                                        <span className='sectionBody_dragFile_text_input'>
                                            <span> choisir</span>
                                            <input required type='file' accept='.pdf' onChange={( e ) => {
                                                this._onChange( e.target.files );
                                            }}/>
                                        </span>
                                    </div>
                                </div>
    
                                {/* {
                                    this.state.selectedPreviewLink.map((link, index) => (
                                        <div key={this._generateID(36)}>
                                            <DocViewer
                                                documents={[{uri:link}]}
                                                pluginRenderers={DocViewerRenderers}
                                            />
                                        </div>
                                    ))
                                } */}

                                {/* {
                                    this.state.selectedPreviewLink.length > 0 &&
                                        <div className='registration_form_fm_submit validationButton_container'>
                                            <input className='registration_form_fm_submit_ background_primary2 button-style-5' type='submit' value="Valider"/>
                                        </div>
                                } */}
                            </div>


                        <div className='task_editor_button'>
                            <button className='button-style-5'
                                onClick={(e) => {
                                    return true;
                                }}
                            >
                                Valider
                                <span className='button-style-5-span'></span>
                            </button>
                        </div>
                        <br/>
                        <br/>
                    </form>
                </div>

                { this.state.viewTaskManager &&
                <div className='pop-up task_editor'>
                    <div className='pop-up-content'>
                        <div className='pop-up-content-goBack'>
                            <button className='button-style-5'
                                onClick={(e) => {
                                    this.setState({
                                        viewTaskManager: false,
                                        currentTextContent: {
                                            name: "",
                                            status: -1,
                                            plan_start_at: null,
                                            plan_end_at: null,
                                            real_start_at: null,
                                            real_end_at: null,
                                            infos: [],
                                            description: "",
                                            id_: null,
                                            others: {
                                                infos: [],
                                                isFinished: -1,
                                            },
                                            shared_costs: {
                                                "amount": "",
                                                "money_type": this.state.project_devise
                                            },
                                            parent_id: null,
                                            parent_index: null,
                                            partners: [],
                                        },
                                    });
                                }}
                            >
                                annuler
                            </button>
                        </div>
                        <div className='task_editor_inputContent'>
                            <span>
                                Nom:
                            </span>
                            <input type="text" value={this.state.currentTextContent.name}
                                onChange={(e) => {
                                    let currentTextContent = this.state.currentTextContent;
                                    currentTextContent.name = e.target.value;
                                    this.setState({
                                        currentTextContent: currentTextContent,
                                    });
                                }}
                                autoFocus
                            />
                        </div>

                        <div className='task_editor_inputContent'>
                            <span>
                                Titre
                            </span>
                            <input type="text" value={this.state.currentTextContent.title}
                                onChange={(e) => {
                                    let currentTextContent = this.state.currentTextContent;
                                    currentTextContent.title = e.target.value;
                                    this.setState({
                                        currentTextContent: currentTextContent,
                                    });
                                }}
                            />
                        </div>

                        <div className='task_editor_inputContent'
                            style={{marginRight: "60px"}}
                        >
                            <span>
                                Niveau de titre :
                            </span>
                            <select value={this.state.currentTextContent.title_level}
                                onChange={(e) => {
                                    // console.log(e.target.value);
                                    let currentTextContent = this.state.currentTextContent;
                                    currentTextContent.title_level = Number(e.target.value);
                                    this.setState({
                                        currentTextContent: currentTextContent,
                                    });
                                }}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                            </select>
                        </div>
                        <div className='task_editor_inputContent'>
                            <div className='dashboard_body_input_label'>
                                <span>Text</span>
                            </div>

                            <div className='dashboard_body_input_editor'>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.currentTextContent.text}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        let currentTextContent = this.state.currentTextContent;
                                        currentTextContent.text = data;
                                        this.setState({
                                            currentTextContent: currentTextContent,
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div className='task_editor_button'>
                            <span className='button-style-5'
                                onClick={(e) => {
                                    if(this.state.chronogramUpdateType === "add") {
                                        this._addTask(this.state.currentTextContent, this.state.currentTextContentParentIndex);
                                    } else if(this.state.chronogramUpdateType === "update") {
                                        this._updateTask(this.state.currentTextContent, this.state.currentTextContentParentIndex);
                                    }
                                }}
                            >
                                Mettre à jour
                                <span className='button-style-5-span'></span>
                            </span>
                        </div>
                    </div>
                </div>}
            </>
        )
    }
}


DashboardAddText = connect(mapStateToProps, null)(DashboardAddText);
export default DashboardAddText;