import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
// import words from 'an-array-of-french-words';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import ListManager from '../components/listManager.component/listManager.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home_view.style.css';
import './responsive.style/home_view.responsive.style.css';
import './styles/all_categories.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/

/**
 * Importation des helpers
 */
 import tools from '../helpers/tools.helper';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class AllCategoriesView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            categories: [],
            currentCategories: [],
            currentCategoriesIndex: [],
        }

        this.accessStore = new AccessStore( this.props.dispatch);

        this.optionsSelected = {};
        this.tools = new tools();

        this.query = null;
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        this._get_all_categories(( categories )=>{
            this.setState({
                categories : categories,
                isLoading: false,
            });
        });
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    /**
     * On met a jour la session utilisateur
     */
     _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _viewCategoriesDocs() {
        let path = "";
        this.state.currentCategories.map((pk, index) => {
            if( index < this.state.currentCategories.length -1 ) {
                path = path + "category=" + pk + "&";
            }else {
                path = path + "category=" + pk;
            }
        });

        window.location.replace = "/view-docs?searchPath=" + encodeURIComponent(path);
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='headerFake'></div>

                <div className='categories_content'>
                    <div>
                        <span className='bold font-size-1_2'>
                            Sélectionnez vos catégories
                        </span>
                    </div>
                    <br/>
                    <div className='selectAllCat'>
                        <label key={"selectAllCat"} htmlFor={"selectAllCat"} className='selectAllCat_ color-blue'>
                            <input id={"selectAllCat"} name={"selectAllCat"} type='checkbox'
                                checked={this.state.categories.length === this.state.currentCategories.length}
                                onChange={(e)=>{
                                    let currentCategories = [];
                                    let currentCategoriesIndex = [];

                                    if( e.target.checked === true ) {
                                        this.state.categories.map((category, index) => {
                                            currentCategories.push(category.pk);
                                            currentCategoriesIndex.push(index);
                                        });
                                    }

                                    this.setState({
                                        currentCategories: currentCategories,
                                        currentCategoriesIndex: currentCategoriesIndex,
                                    });
                                    return true;
                                }}
                            />
                            <label htmlFor={"selectAllCat"}>
                                Tout sélectionné
                            </label>
                        </label>
                    </div>
                    <br/>
                    <div>
                    {
                        this.state.categories.length > 0 &&
                        <ListManager _key_="lists25" fSize="1em" list={this.state.categories} isSelectable={true} printKey="name"
                            checked={ (category) => {
                                return this.state.currentCategories.includes(category.pk);
                            }}
                            style={(category) => {
                                return ({
                                    background: this.state.currentCategories.includes(category.pk) ? "#769ea755" : "#00000000",
                                })
                            }}
                            onChange={(e, category, index) => {

                                /**
                                 * On récupere la liste des actegories cochées
                                 * On recupere la liste des index des catégorie sélectionner
                                 * 
                                 * si c'est que la catégories est coché alors on ajoute a la liste
                                 * dans le cas contraire on retire de la liste
                                */
                                let currentCategories = this.state.currentCategories;
                                let currentCategoriesIndex = this.state.currentCategoriesIndex;
                                 
                                if( e.target.checked === true ) {
                                    currentCategories.push(category.pk);
                                    currentCategoriesIndex.push(index);
                                }
                                else {
                                    currentCategories.splice( currentCategories.indexOf(category.pk), 1);
                                    currentCategoriesIndex.splice( currentCategoriesIndex.indexOf(index), 1);
                                }
                                this.setState({
                                    currentCategories: currentCategories,
                                    currentCategoriesIndex: currentCategoriesIndex,
                                });
                                return true;
                            }}
                        />
                    }
                    {/* {
                        this.state.categories.map((category, index) => (
                            <label key={category.name} htmlFor={category.name} className='add_content_checkBox_'
                                style={{
                                    padding: "5px 10px",
                                    background: this.state.currentCategories.includes(category.pk) ? "#769ea755" : "#00000000",
                                }}
                            >
                                <input id={category.name} name={category.name} type='checkbox' checked={this.state.currentCategories.includes(category.pk)}
                                    onChange={(e)=>{
                                        let currentCategories = this.state.currentCategories;
                                        let currentCategoriesIndex = this.state.currentCategoriesIndex;
                                        
                                        if( e.target.checked === true ) {
                                            currentCategories.push(category.pk);
                                            currentCategoriesIndex.push(index);
                                        }
                                        else {
                                            currentCategories.splice( currentCategories.indexOf(category.pk), 1);
                                            currentCategoriesIndex.splice( currentCategoriesIndex.indexOf(index), 1);
                                        }
                                        this.setState({
                                            currentCategories: currentCategories,
                                            currentCategoriesIndex: currentCategoriesIndex,
                                        });
                                        return true;
                                    }}
                                />
                                <label htmlFor={category.name}>
                                    {category.name}
                                </label>
                            </label>
                        ))
                    } */}
                    </div>


                    <div className='doc-explorer-section-viewMore_'>
                        {   <div className='doc-explorer-section-viewMore flex-row align-center justify-center'>
                                <Link to={() => {
                                    let path = "";
                                    this.state.currentCategories.map((pk, index) => {
                                        if( index < this.state.currentCategories.length -1 ) {
                                            path = path + "category=" + pk + "&";
                                        }else {
                                            path = path + "category=" + pk;
                                        }
                                    });

                                    return "/view-docs?searchPath=" + encodeURIComponent(path);
                                }}>
                                    <button className='flex-row align-center justify-center'>
                                        <span>
                                            Afficher
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}


AllCategoriesView = connect(mapStateToProps, null)(AllCategoriesView);
export default AllCategoriesView;
