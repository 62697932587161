let colors = {
    color_1: "#4675d3",
    color_1aa: "#4675d3aa",
    color_155: "#4675d355",
    color_2: "#48F047",
    color_2aa: "#39bc39",
    color_3: "#ffb700",
    color_3aa: "#ffb700aa",
    color_355: "#ffb70055",
    color_4: "#4675d3",
    color_5: "#780b99",
    color_6: "#2e4e89",
    color_7: "#cccccc",
    color_8: "#a5a1a1",
};

export default colors;