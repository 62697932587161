// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class InputStyle1 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: "",
        }
    }

    componentDidMount() {
        
    }

    onFocus(e) {
        let childList = e.target.parentElement.children;

        for( var i=0; i<childList.length; i++ ) {
            if( childList[i].className === "InputStyle1_label") {
                childList[i].style.marginBottom = "25px";
                childList[i].style.marginLeft = "0px"
            }
        }
    }

    onBlur(e) {
        let childList = e.target.parentElement.children;

        for( var i=0; i<childList.length; i++ ) {
            if( childList[i].className === "InputStyle1_label") {
                if( this.state.inputValue !== "" ) {
                    return true;
                }else {
                    if(!this.props.label_hd || this.props.label_hd === false) {
                        childList[i].style.marginBottom = "8px";
                        childList[i].style.marginLeft = "10px";
                    }
                }
            }
        }
    }

    onChange(value) {
        this.setState({
            inputValue: value,
        });
        if( this.props.onChange ) {
            this.props.onChange(value);
        }
    }

    
    render() {
        return (
            <div className='InputStyle1'>
                <span className={this.props.label_hd ? 'InputStyle1_label_' : 'InputStyle1_label'}
                    style={this.props.labelStyle}
                >
                    {this.props.label}
                </span>
                <input
                    onFocus={(e)=>{
                        this.onFocus(e);
                    }}
                    onBlur={(e)=>{
                        this.onBlur(e);
                    }}
                    onChange={(e)=>{
                        this.onChange( e.target.value );
                    }}
                    className='InputStyle1_input'
                    name={this.props.name}
                    type={this.props.type}
                    required={this.props.required}
                    style={{
                        borderBottom: "1px solid " + this.props.color,
                        color: this.props.color,
                        letterSpacing: "1px",
                    }}
                    autoFocus={this.props.autoFocus ? this.props.autoFocus : false}
                />
            </div>
        )
    }
}

export default InputStyle1;