import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */


/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/footer.style.css';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import ministerLogo from '../assets/logos/MTFP.png'


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class FooterScreen extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        }
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <>
                <footer className="footer">
                    <div className="flag-container center w100 top-40 adapt top-10-in">
                        <ul className="flag flex_ row_ list_ ul_">
                            <li className="third green"></li>
                            <li className="third yellow"></li>
                            <li className="third red"></li>
                        </ul>
                    </div>

                    <div className='footer_logo'>
                        <img src={ministerLogo} alt="MTFP"/>
                    </div>

                    <div className="d-flex  justify-content-center">
                        <ul className="list-unstyled footer_media_list list-inline ">
                            <li className="list-inline-item li_">
                                <a href="https://web.facebook.com/pages/BENIN-Minist%C3%A8re-du-Travail-et-de-la-Fonction-Publique/467476996720696?_rdc=1&amp;_rdr" className="fa fa-facebook text-white" target="blank"></a>
                            </li>
                            <li className="list-inline-item li_">
                                <a href="https://twitter.com/gouvbenin" className="fa fa-twitter text-white" target="blank"></a>
                            </li>
                            <li className="list-inline-item li_">
                                <a href="https://www.youtube.com/playlist?list=PLh3-Uo-mLBzJVI7lrWZ0fCDo4nFNkNVTf" className="fa fa-youtube text-white" target="blank"></a>
                            </li>
                            <li className="list-inline-item li_">
                                <a href="https://www.flickr.com/photos/149181393@N05/albums" className="fa fa-flickr text-white" target="blank"></a>
                            </li>
                        </ul>
                    </div>

                    <div className="footer_link_container p-5 ">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 footer_row_column">
                                <h3 className="text-white">LE MINISTERE</h3>
                                <ul className="list-unstyled footer">
                                    <li className="text-white"><a href="https://travail.gouv.bj/ministre">LE MINISTRE</a></li>
                                    <li className="text-white"><a href="https://travail.gouv.bj/vision">VISION</a></li>
                                    <li className="text-white"><a href="https://travail.gouv.bj/aof">ATTRIBUTIONS, ORGANISATIONS ET FONCTIONNEMENT</a></li>
                                    <li className="text-white"><a href="https://travail.gouv.bj/directions">DIRECTIONS</a></li>
                                    <li className="text-white"><a href="https://travail.gouv.bj/structures-sous-tutelles">STRUCTURES SOUS TUTELLE</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 footer_row_column">
                                <h3 className="text-white">PRESTATIONS</h3>
                                <ul className="list-unstyled footer">
                                    <li className="text-white"><a href="https://travail.gouv.bj/prestations">NOS PRESTATIONS</a></li>
                                    <li className="text-white"><a href="https://travail.gouv.bj/eservices">NOS E-SERVICES</a></li>
                                    <li className="text-white"><a href="#">NOS SERVICES PAR SMS</a></li>
                                    <li className="text-white"><a href="#">PORTAIL DES REQUÊTES ET DES PLAINTES</a></li>
                                    <li className="text-white"><a href="#">MES ACTES EN LIGNE</a></li>
                                        <li className="text-white"><a href="#">ESPACE CONCOURS</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 footer_row_column">
                                <h3 className="text-white">ACTUALITES</h3>
                                <ul className="list-unstyled footer">
                                    <li className="text-white"><a href="https://travail.gouv.bj/actualites">COMPTES RENDU</a></li>
                                    <li className="text-white"><a href="#">MESSAGES</a></li>
                                    <li className="text-white"><a href="#">REPORTAGES</a></li>
                                    <li className="text-white"><a href="#">COMMUNIQUES</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 footer_row_column">
                                <h3 className="text-white">LIENS UTILES</h3>
                                <ul className="list-unstyled footer">
                                    <li className="text-white"><a href="https://presidence.bj/">PRESIDENCE DU BENIN</a></li>
                                    <li className="text-white"><a href="https://www.gouv.bj/">GOUVERNEMENT DU BENIN</a></li>
                                    <li className="text-white"><a href="https://beninrevele.bj/">BENIN REVELE</a></li>
                                    <li className="text-white"><a href="https://travail.gouv.bj/mentions-legales">MENTIONS LEGALES</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p className="footer_text_gouv text-center text-white">© MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE - DIRECTION DES SYSTEMES D'INFORMATION (DSI) </p>
                    <p className="footer_text_gouv text-center text-white">RÉPUBLIQUE DU BÉNIN</p>

                    {/* <div className="footer_text">
                        <span>
                            Réalisé par <a href="https://www.kondosia.com/">KONDOSIA</a> <a className='footer_text_img' href="https://www.kondosia.com/"> <img src={kandoIcon} alt="Kondosia" className='kandoIcon'/></a>
                        </span>
                    </div> */}
                </footer>
            </>
        )
    }
}


FooterScreen = connect(mapStateToProps, null)(FooterScreen);
export default FooterScreen;