const configs = {
    dev: {
        // REACT_APP_SYGED_BACKEND_URL: "https://backend.ses-mtfp.bj/",
        // REACT_APP_SYGED_APP_URL: "https://localhost:3000/"

        REACT_APP_SYGED_BACKEND_URL: "http://127.0.0.1:8000/",
        REACT_APP_SYGED_APP_URL: "https://localhost:3000/"
    },
    test: {
        // REACT_APP_SYGED_BACKEND_URL: "https://syged-backend.herokuapp.com/",
        REACT_APP_SYGED_BACKEND_URL: "https://backend.zeezaa.tech/", //process.env.REACT_APP_SYGED_BACKEND_URL
        REACT_APP_SYGED_APP_URL: "",
    }
}

const envKey = 'test' // to change like switch for different environments.

const currentEnv = configs[envKey]
export default currentEnv