class KEYS {
    constructor() {
        this.public = `-----BEGIN PUBLIC KEY-----
        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqRWc9v/jDuQtUgyCOETe
        RB4tSsb+0yByuEJUbLfYG3hBkVi3achVCeac5gMthKrusW3Nb0r5maBSSeVrQed1
        MD2jbZECtLrrkpWbBy+1h9qpWnkPOs0eoGNgX5RmdCxJrjfYTxDFzybJ7PuQtZNU
        oo/k1ws55jzG0R2Vc2g72GrRk1oFbSuE4j82ZQY5ZhpwMnGAEuBOt1F9FrSBPxPe
        z8SG+4yHoWfesG/mZk9Qt9JfY3bDu7MB3qwcPEytheZxVYFZZIo10XG8EyKnaUAK
        TZ5zFaw8xfDRvc1u+w+yNeSszqjhVyurk1FZEsO7WNjrF/678ya5v8v++DqQHV+S
        aQIDAQAB
        -----END PUBLIC KEY-----`;

        this.private = `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEAqRWc9v/jDuQtUgyCOETeRB4tSsb+0yByuEJUbLfYG3hBkVi3
        achVCeac5gMthKrusW3Nb0r5maBSSeVrQed1MD2jbZECtLrrkpWbBy+1h9qpWnkP
        Os0eoGNgX5RmdCxJrjfYTxDFzybJ7PuQtZNUoo/k1ws55jzG0R2Vc2g72GrRk1oF
        bSuE4j82ZQY5ZhpwMnGAEuBOt1F9FrSBPxPez8SG+4yHoWfesG/mZk9Qt9JfY3bD
        u7MB3qwcPEytheZxVYFZZIo10XG8EyKnaUAKTZ5zFaw8xfDRvc1u+w+yNeSszqjh
        Vyurk1FZEsO7WNjrF/678ya5v8v++DqQHV+SaQIDAQABAoIBAATHa/ComjCmjwgI
        UHnqAGTpJsXmn/c0WJRlU4uLGrGUEY5bypCpLTYiwtaBdSeQc+n8kizjb/CWpnIY
        6OeA9OqlzgO1GzV5KbAH8yId3Wyh5917GczDVWqabx6PIYzbtO1r/fzQxYvYVdFm
        lC19pOEUs2wckMi1eQ0aCp+rbN2YrjT2JeGTjPK/6aO+a56g/+1DA++6/h1fMWB/
        oC1Y3u2eUmOzIWB4HYh8nStgGE69sanymWTCPAp9biygmobImysnQOa//Q6PGXwY
        TIW7Bz6Hr9nV8+yfdcTg1pMVQI8/FmSJ7vK82SHqocy0nWwT7xoe1iLXPj5vF/IX
        XWDYcfECgYEAyKGj6+cxJgiYZBNjBaXvCgwChNM3YA0oSkHLUpuToS/Pv9NJyPVj
        D90PchY/BlX67JlSc2UpNH3xE5nbIVe7l7EGkGBoF6IiSXBeQ0KU32e+U0nON15L
        Od0w6okx7QxfjfQlwjAza09I0zbeQpJhNp0dgpqvn7ETxqp5azC0yZ0CgYEA1783
        wcF7+r+8DRpkxpvUEcNw+yVF0LdXBl9kFyXSHJKP14Io49utXtffATCzakiwMEVW
        Y3+6ek8o3DeM5AZM2t+eY2O9WQe3XwevnxFmtsaDPpb2VQXiKiSGHJ5MwFkoNILY
        grRhcTj2pENmZgWfqsxWJ78BndtOoCldKGWHKD0CgYAqw5FeCgRG2BQrWJqFrK27
        1X1/9ElcVtGcqlN9Qx3/gGJmv9F9XRiFXBgbuDQs1IzMe43AfPKAdsu44tV7UOjy
        V1BP28y0JOHsB2CAM6PpzoIZ3KRw1s9wAP6ZLy8OGNIRPtYBgyaim96o7owHYo4I
        BwswOGpwvly5vsJsn5AZXQKBgFeM5nB/NvObiRF8K3cZV2bZ++g+yOUY8XzJixGF
        rI0hwLOAw10sEV/SlFGkVLqFwtHj4VnBRIhLxVYQSOFj2R5c7qaAnB8b7YLn2JHh
        euAjELF+i3oXSB838SB98MGBrLoaewgXcQ+EQTqJi21NTOz/vNqmPV6012RRRNeW
        09E1AoGBAIn9DiHkyh3g6G+Ni62w6qJQ9zOFpk7cMgUO6ZSP4qGv6uugzjN8vD3n
        1Ndu283JiM5lo6Zx+QqyXub3rTNg5wZGgBO0h93L+U2tCJkOMi8YknKCAaoUny24
        g3Y6Ha8z0ZTWbGrrzDJZiXhGGcdcVpiKEecCGEwWVwd9PMGIb4Mk
        -----END RSA PRIVATE KEY-----`;
    }
}

export default KEYS;