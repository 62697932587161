import axios from "axios";
import PATH from '../GLOBAL-VAR/globals-urls';

const PATH_ = new PATH();

class CategoriesRequests {
    _getAll(access, receiveProgress = () => {}) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.categories,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                }
            })
        )
    }

    _getAll_public() {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.categories_public,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _getOne(id, access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.categories + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getOne_public(id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.category_public + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _add(data, access, sendProgress = () => {}, receiveProgress = () => {}) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.categories,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
                onUploadProgress: (event) => { sendProgress(event) },
                onDownloadProgress: (event) => { receiveProgress(event) }
            })
        )
    }

    _update(id, data, access) {
        return (
            axios({
                method: 'patch',
                url: PATH_.api + PATH_.categories + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _delete(id, access) {
        return (
            axios({
                method: 'delete',
                url: PATH_.api + PATH_.categories + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }
}

export default CategoriesRequests;
