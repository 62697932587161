// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class SearchInputComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: "",
        }
    }

    componentDidMount() {
        
    }

    
    render() {
        return (
            <div className='searchInput'>
                <div>
                    <input
                        type='search'
                        className='searchInput_'
                        placeholder={this.props.placeholder}
                        value={this.state.searchValue}
                        onChange={(e) => {
                            this.setState({
                                searchValue: e.target.value,
                            });
                            this.props.onChange(e.target.value);
                        }}
                    />
                </div>

                <div className='searchInput_list'>
                    <ul className='searchInput_list_ul'>
                        {
                            this.props.data.map((elt, index) => (
                                <li className='searchInput_list_ul_li'
                                    onClick={(e) => {
                                        this.props.onSelected( elt );
                                    }}

                                    style={this.props.style(elt)}
                                >
                                    <div className='searchInput_list_ul_li_'>
                                        <div>
                                            {this.props.icon && this.props.icon}
                                        </div>
                                        <div>
                                            <span>
                                                {elt[this.props.key_]}
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            this.props.printStatus ?
                                                elt[this.props.statusKey] && elt[this.props.statusKey].length > 0 ?
                                                    <span className='searchInput_list_ul_li_acredite'>
                                                        Accrédité
                                                    </span>
                                                :
                                                    <span className='searchInput_list_ul_li_no_acredite'>
                                                        Non accrédité
                                                    </span>
                                            :
                                                null
                                        }

                                        <input type='checkbox' style={{marginLeft: "5px"}} checked={this.props.isChecked(elt)}/>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className='searchInput_currentList'>
                    <ul className='searchInput_currentList_ul'>
                        {
                            this.props.printSelected &&
                            this.props.currentSelected.map((cSelected, index) => (
                                <li className='searchInput_currentList_ul_li'
                                    style={this.props.selectedStyle ? this.props.selectedStyle : {position: "relative"}}
                                >
                                    <span>
                                        {
                                            this.props.data.map((dt, index) => (
                                                dt[this.props.id_] === cSelected && dt[this.props.key_]
                                            ))
                                        }
                                    </span>
                                    {/* <button
                                        onClick={(e)=>{ alert("Fonctionnalité indisponible pour le moment!")}}
                                        className='searchInput_currentList_ul_li_button'
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </button>
                                    <button style={{color: "red"}}
                                        onClick={(e)=>{ alert("Fonctionnalité indisponible pour le moment!")}}
                                        className='searchInput_currentList_ul_li_button'
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                        </svg>
                                    </button> */}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default SearchInputComponent;