import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
// import CheckboxComponent from '../components/checkbox.component/checkbox.component';
import TextViewer from '../components/textViewer.component/textViewer.component';
import LawTextCard from '../components/LawTextCard/LawTextCard.component';
// import ChronogramStyle2 from '../components/ChronogramStyle2.component/ChronogramStyle2.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class Struct extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            textLawList: [],
            group: {}
        }

        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.query = new URLSearchParams(this.props.location.search);
        let struct =  Number(decodeURIComponent(this.query.get("struct")));
        // console.log(struct);

        this.setState({
            isLoading: true,
        });

        this._get_all_groups((groups) => {
            // console.log(groups);
            let group = {};
            groups.map((group_, index) => {
                if(group_.id == struct) {
                    group = group_;

                    this._get_all_groups_((groups_) => {
                        groups_.map((group_, index) => {
                            if(group_.group == struct){
                                group["more"] = group_;
                                this.setState({
                                    group: group,
                                    isLoading: false,
                                });
                            }
                        });
                    });
                }
            });
        });

        this._get_all_lawText_((response) => {
            console.log(response);
            let textLawList = [];
            response.data.map((lawText) => {
                if(
                    lawText.details.amplification.includes(struct) ||
                    lawText.details.decreeing_body.includes(struct) ||
                    lawText.details.main_structure.includes(struct) ||
                    lawText.details.proposing_body.includes(struct) ||
                    lawText.details.main_institution_issuing.includes(struct)
                ) {
                    console.log(lawText);
                    textLawList.push(lawText);
                }
            });
            this.setState({
                textLawList: textLawList,
            });
        });
    }

    _get_all_lawText_(callback=()=>{}) {
        this._getAllLawText(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_lawText_(callback);
            });
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log('response ...+++', response.data)
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _get_all_groups_(callback=()=>{}) {
        this._getAllGroups_(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log(response.data);
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups_(callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                */

                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à ajouter votre document pour un groupe");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='headerFake'></div>

                <div className="OnProjectView_top_text">
                    <br/>
                    <h1 className="title_L1 title text-center">
                        {this.state.group.name && this.state.group.name}
                    </h1>
                </div>
                <div className='viewContent_ screen'>
                    <div className='flex-row justify-center flex-wrap card_list'>
                        {
                            this.state.textLawList.map((lawText, index) => (
                                <div className='card_list_content' key={"doc-list-1-" + index}>
                                    <LawTextCard
                                        lawText={lawText}
                                        link={"/texte:?texte=" + lawText.id}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </>
        )
    }
}

Struct = connect(mapStateToProps, null)(Struct);
export default Struct;
