import axios from "axios";
import PATH from '../GLOBAL-VAR/globals-urls';

const PATH_ = new PATH();

class AuthLevelRequests {
    _getAllLevels(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.level,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }
}

export default AuthLevelRequests;