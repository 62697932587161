// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.scss';

/**Importation des composants */
import PointerComponent from '../pointer.component/pointer.component';


/**Importation des images*/


let VARIABLES = {
    color_1: "#2e4e89",
    color_2: "#2e4e89",
    color_3: "#2e4e89",
    color_4: "#2e4e89",
    color_5: "#2e4e89",
    color_6: "#2e4e89",
};


class ChronogramStyle1 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewPending: true,
            viewInGoodProgress: true,
            viewInBadProgress: true,
            viewLate: true,
            viewGoodFinished: true,
            viewBadFinished: true,
        }
    }

    componentDidMount() {
        // console.log(VARIABLES);
    }

    _getTaskStatus(task) {
        /**Si la tache est planifié mais non debutée (gris)*/
        if(
            (Number(task.status)===-1) &&
            (this._getCurrentDateInSnd() < this._getDateInSnd(task.plan_start_at)) 
        ) {
            return 1;
        }
        /**Si la tache est demarre et dans les temps (vert) */
        else if(
            (Number(task.status)===1) &&
            (Number(task.others.isFinished)===-1) &&
            (this._getCurrentDateInSnd() < (this._getDateInSnd(task.plan_end_at) - (5*24*3600)))
        ) {
            return 2;
        }
        /**Si la tache est demarre et commence a etre en retard (orange) */
        else if(
            (Number(task.status)===1) &&
            (Number(task.others.isFinished)===-1) &&
            (
                (this._getCurrentDateInSnd() >= (this._getDateInSnd(task.plan_end_at) - (5*24*3600))) &&
                (this._getCurrentDateInSnd() < this._getDateInSnd(task.plan_end_at))
            )
        ) {
            return 3;
        }

        /**Si la tache est demarre et est en retard (rouge) */
        else if(
            (
                (Number(task.status)===1) &&
                (Number(task.others.isFinished)===-1) &&
                (
                    (this._getCurrentDateInSnd() >= this._getDateInSnd(task.plan_end_at))
                )
            ) ||
            (
                (Number(task.status)===-1) &&
                (this._getCurrentDateInSnd() >= this._getDateInSnd(task.plan_start_at))
            )
        ) {
            return 4;
        }

        /**Si la tache est finis mais en retard (violet) */
        else if(
            (
                (Number(task.status)===1) &&
                (Number(task.others.isFinished)===1) &&
                (this._getDateInSnd(task.real_end_at) > this._getDateInSnd(task.plan_end_at))
            )
        ) {
            return 5;
        }

         /**Si la tache est finis et dans les temps (bleu) */
         else if(
            (
                (Number(task.status)===1) &&
                (Number(task.others.isFinished)===1) &&
                (this._getDateInSnd(task.real_end_at) <= this._getDateInSnd(task.plan_end_at))
            )
        ) {
            return 6;
        }
    }

    _getDateInSnd(date) {
        return ( (new Date(date).getTime()) / 1000);
    }

    _getCurrentDateInSnd() {
        return ( (new Date().getTime()) / 1000);
    }

    _generateChronogram(list, state=0, parent=null) {
        return(
            <ul className={ state===0 ? 'chronogram_list_' : "chronogram_list"}>
                {
                    list.map((elt, index) => (
                        elt!==-1 && index !==-1 &&
                        ((state===0 && (this.props.positions[index] && ((this.props.positions[index].split(",")).length===1)) ) || (state!==0)) && 
                        <li className={state===0 ? 'chronogram_list_elt chronogram_list_' : "chronogram_list_elt"}>
                            <div className='chronogram_list_elt_text'>
                                <span className='chronogram_list_elt_text_'>
                                    {/* <div className={this.props.control ? "chronogram_list_elt_text_ctn_" : "chronogram_list_elt_text_ctn"} style={{
                                        fontWeight: "bold",
                                        letterSpacing: "0px",
                                        fontSize: "0.85em",
                                        color: Number(this.props.list[state === 0 ? index : elt].status)===1 ? VARIABLES.color_2 : ( Number(this.props.list[state === 0 ? index : elt].status)===0 ? VARIABLES.color_3 : (Number(this.props.list[state === 0 ? index : elt].status)===-1 ? "#00000055" : "#2e4e89"))
                                    }}>
                                        
                                    </div> */}
                                    {/* <a href={'/tache:?id=' + this.props.list[state === 0 ? index : elt].id}> */}
                                        <PointerComponent
                                            noPointer={this.props.tree[state === 0 ? index : elt].length===0 ? false : true}
                                            text={this.props.list[state === 0 ? index : elt].acronym}
                                            style={{
                                                fontWeight: "bold",
                                                width: "100%",
                                                textAlign: "center",
                                                letterSpacing: "0px",
                                                fontSize: "0.85em",
                                                borderRadius: "0px",
                                                border: "1px solid #000000",
                                                boxShadow: "0px 0px 0px 0px " + (
                                                    this._getTaskStatus(this.props.list[state === 0 ? index : elt])===1 ? "#00000055" :
                                                    (
                                                        this._getTaskStatus(this.props.list[state === 0 ? index : elt])===2 ? VARIABLES.color_2 :
                                                        (
                                                            this._getTaskStatus(this.props.list[state === 0 ? index : elt])===3 ? VARIABLES.color_3 :
                                                            (
                                                                this._getTaskStatus(this.props.list[state === 0 ? index : elt])===4 ? "#2e4e89" :
                                                                (
                                                                    this._getTaskStatus(this.props.list[state === 0 ? index : elt])===5 ? VARIABLES.color_5 :
                                                                    (
                                                                        this._getTaskStatus(this.props.list[state === 0 ? index : elt])===6 ? VARIABLES.color_1 : VARIABLES.color_1
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                ),
                                                color: (
                                                    this._getTaskStatus(this.props.list[state === 0 ? index : elt])===1 ? "#00000055" :
                                                    (
                                                        this._getTaskStatus(this.props.list[state === 0 ? index : elt])===2 ? VARIABLES.color_2 :
                                                        (
                                                            this._getTaskStatus(this.props.list[state === 0 ? index : elt])===3 ? VARIABLES.color_3 :
                                                            (
                                                                this._getTaskStatus(this.props.list[state === 0 ? index : elt])===4 ? "#2e4e89" :
                                                                (
                                                                    this._getTaskStatus(this.props.list[state === 0 ? index : elt])===5 ? VARIABLES.color_5 :
                                                                    (
                                                                        this._getTaskStatus(this.props.list[state === 0 ? index : elt])===6 ? VARIABLES.color_1 : VARIABLES.color_1
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                ),
                                                opacity: (
                                                    (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===1 && this.state.viewPending) ||
                                                    (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===2 && this.state.viewInGoodProgress) ||
                                                    (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===3 && this.state.viewInBadProgress) ||
                                                    (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===4 && this.state.viewLate) ||
                                                    (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===5 && this.state.viewBadFinished) ||
                                                    (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===6 && this.state.viewGoodFinished)
                                                ) ? "1" : "1"
                                            }}
                                            arrowStyle={{
                                                boxShadow: "0px 0px 0px 0px " + (
                                                    this._getTaskStatus(this.props.list[state === 0 ? index : elt])===1 ? "#00000055" :
                                                    (
                                                        this._getTaskStatus(this.props.list[state === 0 ? index : elt])===2 ? VARIABLES.color_2 :
                                                        (
                                                            this._getTaskStatus(this.props.list[state === 0 ? index : elt])===3 ? VARIABLES.color_3 :
                                                            (
                                                                this._getTaskStatus(this.props.list[state === 0 ? index : elt])===4 ? "#2e4e89" :
                                                                (
                                                                    this._getTaskStatus(this.props.list[state === 0 ? index : elt])===5 ? VARIABLES.color_5 :
                                                                    (
                                                                        this._getTaskStatus(this.props.list[state === 0 ? index : elt])===6 ? VARIABLES.color_1 : "black"
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            }}
                                        />
                                    {/* </a> */}

                                    {
                                        (
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===1 && this.state.viewPending) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===2 && this.state.viewInGoodProgress) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===3 && this.state.viewInBadProgress) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===4 && this.state.viewLate) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===5 && this.state.viewBadFinished) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===6 && this.state.viewGoodFinished)
                                        ) &&
                                            <div className='chronogram_list_elt_text_info'>
                                                {
                                                    this.props.list[state === 0 ? index : elt].others.infos.map((info, index_)=>(
                                                        <div className='chronogram_list_elt_text_info_elt' key={"info" + index + "_" + index_}>
                                                            - <span>{info}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                    
                                </span>
                                {state!==0? <div className='chronogram_list_elt_text_line'
                                    style={{
                                        background: Number(this.props.list[elt].status)===1 ? VARIABLES.color_2 : ( Number(this.props.list[elt].status)===0 ? VARIABLES.color_3 : (Number(this.props.list[elt].status)===-1 ? "#00000055" : "#2e4e89")),
                                        opacity: (
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===1 && this.state.viewPending) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===2 && this.state.viewInGoodProgress) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===3 && this.state.viewInBadProgress) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===4 && this.state.viewLate) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===5 && this.state.viewBadFinished) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===6 && this.state.viewGoodFinished)
                                        ) ? "1" : "1"
                                    }}
                                ></div> : null}
                                {state!==0? <div className='chronogram_list_elt_text_line_hr'
                                    style={{
                                        background: Number(this.props.list[elt].status)===1 ? VARIABLES.color_2 : ( Number(this.props.list[elt].status)===0 ? VARIABLES.color_3 : (Number(this.props.list[elt].status)===-1 ? "#00000055" : "#2e4e89")),
                                        opacity: (
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===1 && this.state.viewPending) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===2 && this.state.viewInGoodProgress) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===3 && this.state.viewInBadProgress) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===4 && this.state.viewLate) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===5 && this.state.viewBadFinished) ||
                                            (this._getTaskStatus(this.props.list[state === 0 ? index : elt])===6 && this.state.viewGoodFinished)
                                        ) ? "0" : "0"
                                    }}
                                ></div> : null}
                            </div>
                            { state===0 ? (elt.length > 0 ? this._generateChronogram(elt, state+1, index) : "") : (this.props.tree[elt].length > 0 ?  (this._generateChronogram(this.props.tree[elt], state+1, elt)) : "")}
                        </li>
                    ))
                }
            </ul>
        )
    }

    
    render() {
        return (
            <div>
                {/* <div className='chronogram_filter'>
                    <div className='chronogram_filter_circle pending'
                        onClick={(e) => {
                            this.setState({
                                viewPending: !this.state.viewPending
                            })
                        }}
                        title='En attente'
                    >
                        <div className='chronogram_filter_circle_dot pending_'
                            style={{
                                transform: this.state.viewPending ? "scale(1)" : "scale(0)",
                            }}
                        ></div>
                    </div>

                    <div className='chronogram_filter_circle goodProgress'
                        onClick={(e) => {
                            this.setState({
                                viewInGoodProgress: !this.state.viewInGoodProgress
                            })
                        }}
                        title="Démarrer à le date prévue et qui est dans les temps et n'est pas encore terminée"
                    >
                        <div className='chronogram_filter_circle_dot goodProgress_'
                            style={{
                                transform: this.state.viewInGoodProgress ? "scale(1)" : "scale(0)",
                            }}
                        ></div>
                    </div>

                    <div className='chronogram_filter_circle badProgress'
                        onClick={(e) => {
                            this.setState({
                                viewInBadProgress: !this.state.viewInBadProgress
                            })
                        }}
                        title="Démarrer à le date prévue et qui est dans les temps et n'est pas encore terminée mais que la date prévue pour finir est proche"
                    >
                        <div className='chronogram_filter_circle_dot badProgress_'
                            style={{
                                transform: this.state.viewInBadProgress ? "scale(1)" : "scale(0)",
                            }}
                        ></div>
                    </div>

                    <div className='chronogram_filter_circle goodFinished'
                        onClick={(e) => {
                            this.setState({
                                viewGoodFinished: !this.state.viewGoodFinished
                            })
                        }}
                        title="Démarrer à le date prévue et est finis avant ou à la date prévue"
                    >
                        <div className='chronogram_filter_circle_dot goodFinished_'
                            style={{
                                transform: this.state.viewGoodFinished ? "scale(1)" : "scale(0)",
                            }}
                        ></div>
                    </div>

                    <div className='chronogram_filter_circle badFinished'
                        onClick={(e) => {
                            this.setState({
                                viewBadFinished: !this.state.viewBadFinished
                            })
                        }}
                        title="Démarrer à le date prévue et est finis après la date prévue"
                    >
                        <div className='chronogram_filter_circle_dot badFinished_'
                            style={{
                                transform: this.state.viewBadFinished ? "scale(1)" : "scale(0)",
                            }}
                        ></div>
                    </div>

                    <div className='chronogram_filter_circle late'
                        onClick={(e) => {
                            this.setState({
                                viewLate: !this.state.viewLate
                            })
                        }}
                        title="Démarrer à le date prévue, n'est pas finis et la date de fin prévue est dépassée"
                    >
                        <div className='chronogram_filter_circle_dot late_'
                            style={{
                                transform: this.state.viewLate ? "scale(1)" : "scale(0)",
                            }}
                        ></div>
                    </div>
                </div> */}
                <div className='chronogram'>
                    {this._generateChronogram(this.props.tree)}
                </div>
                {
                    this.props.control &&
                        <div className='chronogram_control'>
                            <span className='chronogram_control_button button-style-5'
                                onClick={(e) => {
                                    this.props.addAction(null);
                                }}
                            >
                                {this.props.controlText}
                                <span className='button-style-5-span'></span>
                            </span>
                        </div>
                }
            </div>
        )
    }
}

export default ChronogramStyle1;