import React from 'react';
import { connect } from 'react-redux';



/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/doc_explorer.style.css';
import './responsive.style/doc_explorer.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/


/**
 * Importation des helpers
 */
 import tools from '../helpers/tools.helper';
 import Xanimation from '../helpers/x-animations';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

const animer = new Xanimation();
animer.init( 50 );

class AboutUsScreen extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        }

        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.onload = animer.start();
    }

    _logout() {
        if( window.confirm('Voulez-vous vraiment vous déconnecter?') ) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }


    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='headerFake'></div>

                <div className='viewContent_'>
                    <div className="OnProjectsView_top_text">
                        <span className="bold dynamic-fts-5"
                            style={{
                                color: "#0A3764",
                                textShadow: "none",
                                fontFamily: 'Montserrat-Regular',
                            }}
                        >
                            SES-MTFP
                        </span>

                        <br/>
                        {/* <span className="bold dynamic-fts-4"
                            style={{
                                color: "#0A3764",
                                textShadow: "none",
                                fontFamily: 'Montserrat-Regular',
                            }}
                        >
                            Un HUB sécurisé et innovant
                        </span> */}
                        <br/>
                    </div>
                </div>

                {/* <div className='onContent'
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <div className="OnProjectsView_body_subDescription text-sizes-1 left-border">
                        <p className="text-form">
                            au service de tous les acteurs du droit simplifiant leurs accès à la documentation juridique ainsi qu’à un annuaire des professionnels du droit de l’espace OHADA.
                        </p>
                    </div>
                </div>

                <div className='onContent'
                    x-animation="from-bottom"
                    x-animation-delay="0s"
                    x-animation-duration="1s"
                >
                    <div className="OnProjectsView_body_subDescription text-sizes-1 left-border">
                        <p className="text-form">
                            
                            La référence en documentation juridique et annuaire des professionnels du droit de l’espace OHADA,
                        </p>
                    </div>
                </div> */}

                <br/>
                <br/>
            </>
        )
    }
}


AboutUsScreen = connect(mapStateToProps, null)(AboutUsScreen);
export default AboutUsScreen;
