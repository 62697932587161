function SET_ON_PANIER(state, value) {
    let nextState = {
        ...state,
        panier: value,
        count: value.length,
    }
    return nextState || state
}

export default SET_ON_PANIER;
