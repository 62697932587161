import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardAddCategories extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            sysAttr: [],
            category: {
                name: "",
                fields: {
                    attributes: [
                        {
                            name: "Afficher à l'accueil",
                            type: "text-one-line",
                            multi_choice: false,
                            required: false,
                            values: null,
                            value: ["oui"],
                            min: undefined,
                            max: undefined,
                            disabled: false,
                        },
                        {
                            name: "Position",
                            type: "number",
                            multi_choice: false,
                            required: false,
                            values: null,
                            value: ["0"],
                            min: undefined,
                            max: undefined,
                            disabled: false,
                        },
                    ],
                    objects: []
                }
            },

            default_attributes: [],
        }

        this.tools = new tools();
        this.accessStore = new AccessStore(this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        let month_values = [];
        let categories_values = [];
        let levels_values = [];

        this._get_all_categories(( categories )=>{
            this._get_users((accounts) => {
                this._get_all_groups((groups) => {
                    this.sysAttr.map((attr, index) => {
                        if(attr.name === "Utilisateur") {
                            attr.values=accounts;
                        } else if(attr.name === "Groupe utilisateurs") {
                            attr.values=groups;
                        } else if(attr.name === "Catégorie de documents") {
                            attr.values=categories;
                        }

                        if(index === this.sysAttr.length-1) {
                            this.setState({
                                sysAttr: this.sysAttr,
                            });
                        }
                    })
                })
            });
            this._getAllLevel((levels) => {
                levels.results.map((level) => {
                    levels_values.push(level.name);
                });

                categories.map((category) => {
                    categories_values.push( category.name );
                });
    
                this.month.map((value) => {
                    month_values.push(value.name);
                });
    
                this.setState({
                    default_attributes: [
                        {
                            name: "Catégorie du document",
                            type: "select",
                            multi_choice: false,
                            required: true,
                            values: categories_values,
                            value: [],
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Niveau d'authentification du document",
                            type: "select",
                            multi_choice: false,
                            required: true,
                            values: levels_values,
                            value: [],
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Titre du document",
                            type: "text-one-line",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Image du document",
                            type: "text-one-line",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Résumé synthétique",
                            type: "text-multi-line",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Année de publication",
                            type: "number",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Mois de publication",
                            type: "select",
                            multi_choice: false,
                            required: true,
                            values: month_values,
                            value: [],
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Date de validation du document",
                            type: "date",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        }
                    ]
                });
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _addAttribute() {
        let attributeName = window.prompt("Entrer le nom de l'attribut ici", "");
        if (attributeName !== null && attributeName !== undefined && attributeName !== "") {
            let category = this.state.category;
            category.fields.attributes.push({
                name: attributeName,
                type: "text-one-line",
                multi_choice: false,
                required: true,
                values: null,
                value: "",
                min: undefined,
                max: undefined,
            });

            this.setState({
                category: category,
            });
        }
    }

    _addObjectAttribute() {
        let attributeName = window.prompt("Entrer le nom de l'attribut ici", "");
        if (attributeName !== null && attributeName !== undefined && attributeName !== "") {
            let category = this.state.category;
            category.fields.objects.push({
                name: attributeName,
                objectName: this.state.sysAttr[0].name,
                type: "select",
                multi_choice: false,
                required: true,
                values: this.state.sysAttr[0].values,
                value: [],
                min: undefined,
                max: undefined,
            });

            this.setState({
                category: category,
            });
        }

        // console.log(this.state.sysAttr[0].values);
    }

    _getAllLevel( callback=()=>{} ) {
        this._getLevel(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getAllLevel( callback );
            });
        });
    }

    _get_all_categories(callback = () => { }) {
        this._getAllCategories(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_all_categories(callback);
            })
        });
    }

    _manageError(error, callback = () => { }) {
        if (error.response) {
            if (error.response.status === 401) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if (error.response.data.code === "token_not_valid") {
                    this._refreshUserToken(this.props.user.refresh, (response) => {
                        if (response.status === 200) {
                            this._set_new_user(response);
                            callback();
                        }
                    }, (error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else {
                console.log(error.response.data);
            }
        }
    }

    /**
     * On met a jour la session utilisateur
    */
    _set_new_user(response) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user);
    }

    _addCategory() {

        if (this.state.category.name !== "") {
            // this.setState({
            //     isLoading: true,
            // });
            // this._post_category(this.state.category, (response) => {
            //     this.setState({
            //         isLoading: false,
            //     });
            //     alert("Catégorie ajoutée avec succès");
            // });

            this.setState({
                authAction: (password) => {
                    this._post_category(this.state.category, (response) => {
                        this.setState({
                            isLoading: false,
                        });
                        alert("Catégorie ajoutée avec succès");
                        window.location.reload();
                    });
                }
            }, () => {
                this._openOrCloseAuth();
            });
        }

        else {
            alert("Veuillez renseigner le nom de la catégorie svp!");
        }
    }

    _post_category(data, callback = () => { }) {
        this._postCategory(data, this.props.user.access, (response) => {
            if (response.status === 201) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._post_category(data, callback);
            })
        });
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='dashboard-option'>
                    <div className='dashboard-option-content'>
                        <div className='dashboard-option-content-label'>
                            <span>
                                Nom de la catégorie
                            </span>
                        </div>
                        <input
                            className='dashboard-option-content-input'
                            type='text'
                            value={this.state.category.name}
                            onChange={(e) => {
                                let category = this.state.category;
                                category.name = e.target.value;

                                this.setState({
                                    category: category,
                                });
                            }}
                        />
                    </div>

                        <div className='dashboard-option-content-label'>
                            <span>
                                Liste des attributs par défaut dans le système
                            </span>
                        </div>
                        <br />
                        <table className='tab-style-1'>
                            <tr
                                className="table-head-style1"
                            >
                                <th>
                                    Nom
                                </th>

                                <th>
                                    Requis
                                </th>

                                <th>
                                    Type
                                </th>
                                
                                <th>
                                    Valeur(s)
                                </th>

                                <th
                                    style={{
                                        textAlign: "center",
                                        color: "#00000055"
                                    }}
                                >
                                    Ajouter des valeurs
                                </th>

                                <th
                                    style={{
                                        color: "#bb7474",
                                        textAlign: "center",
                                    }}
                                >
                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </th>
                            </tr>

                            {
                                this.state.default_attributes.map((attribute, attributeIndex) => (
                                    <>
                                        <tr
                                            
                                            style={{
                                                backgroundColor: attributeIndex % 2===0 ? "#0A376411" : "#ffffff",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td>
                                                <input
                                                    className='dashboard-option-content-input2'
                                                    type='text'
                                                    value={attribute.name}
                                                    disabled={attribute.disabled}
                                                />
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.required}
                                                    disabled={attribute.disabled}
                                                >
                                                    <option value={true}>
                                                        oui
                                                    </option>
                                                    <option value={false}>
                                                        non
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.type}
                                                    disabled={attribute.disabled}
                                                >
                                                    {
                                                        this.attr_types.map((type, index) => (
                                                            <option key={"type-" + index} value={type.value}>
                                                                {type.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>

                                                {
                                                    attribute.type === "number" &&
                                                    <div>
                                                        <div className='number-rang'>
                                                            <label>
                                                                min
                                                            </label>
                                                            <input type='number' value={attribute.min}
                                                                disabled={attribute.disabled}
                                                            />
                                                        </div>

                                                        <div className='number-rang'>
                                                            <label>
                                                                max
                                                            </label>
                                                            <input type='number' value={attribute.max}
                                                                disabled={attribute.disabled}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </td>

                                            <td className="userGroups">
                                                {
                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <div className='userGroups_list'>
                                                            <ul className='userGroups_list_ul'>
                                                                {
                                                                    attribute.values.map((value, valueIndex) => (
                                                                        <li className='userGroups_list_ul_li_2' key={value + "-" + valueIndex}>
                                                                            <div className='dashboard-option-content-attr-list-elt-2 flex-row'>
                                                                                <span>
                                                                                    {value}
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                       

                                                        :
                                                            (
                                                                attribute.type === "text-one-line" || attribute.type === "text-multi-line"?
                                                                    <input
                                                                        className='dashboard-option-content-input2'
                                                                        disabled={attribute.disabled}
                                                                        type='text'
                                                                        value={attribute.value}
                                                                    />
                                                                :
                                                                    (
                                                                        attribute.type === "date"?
                                                                            <input
                                                                                className='dashboard-option-content-input2'
                                                                                type='date'
                                                                                disabled={attribute.disabled}
                                                                                value={attribute.value}
                                                                            />
                                                                        :
                                                                            (
                                                                                attribute.type === "number"?
                                                                                    <input
                                                                                        className='dashboard-option-content-input2'
                                                                                        type='number'
                                                                                        disabled={attribute.disabled}
                                                                                        value={attribute.value}
                                                                                        min={attribute.min}
                                                                                        max={attribute.max}
                                                                                    />
                                                                                :
                                                                                    null
                                                                            )
                                                                    )
                                                            )
                                                }

                                                <div className='td_text text-align-center'>
                                                    <span className='text-align-center'
                                                        style={{
                                                            fontSize: "1.7em"
                                                        }}
                                                    >
                                                        {attribute.values && attribute.values.length}
                                                    </span>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                ))
                            }
                        </table>
                        <br/>
                        <br/>
                   
                        <div className='dashboard-option-content-label'>
                            <span>
                                Liste des attributs complémentaires
                            </span>
                        </div>
                        <br />
                        <table className='tab-style-1'>
                            <tr
                                className="table-head-style1"
                            >
                                <th>
                                    Nom
                                </th>

                                <th>
                                    Requis
                                </th>

                                <th>
                                    Type
                                </th>
                                
                                <th>
                                    Valeur(s)
                                </th>

                                <th
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Ajouter des valeurs
                                </th>

                                <th
                                    style={{
                                        color: "red",
                                        textAlign: "center",
                                    }}
                                >
                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </th>
                            </tr>
                            {
                                this.state.category.fields.attributes.map((attribute, attributeIndex) => (
                                    <>
                                        <tr
                                            style={{
                                                backgroundColor: attributeIndex % 2===0 ? "#0A376411" : "#ffffff",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td>
                                                <input
                                                    className='dashboard-option-content-input2'
                                                    type='text'
                                                    value={attribute.name}
                                                    onChange={(e) => {
                                                        let category = this.state.category;
                                                        category.fields.attributes[attributeIndex].name = e.target.value;

                                                        this.setState({
                                                            category: category,
                                                        });
                                                    }}
                                                />
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.required}
                                                    onChange={(e) => {
                                                        let category = this.state.category;
                                                        category.fields.attributes[attributeIndex].required = e.target.value;

                                                        this.setState({
                                                            category: category,
                                                        });
                                                    }}
                                                >
                                                    <option value={true}>
                                                        oui
                                                    </option>
                                                    <option value={false}>
                                                        non
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.type}
                                                    onChange={(e) => {
                                                        let category = this.state.category;
                                                        category.fields.attributes[attributeIndex].type = e.target.value;

                                                        if (e.target.value === "list" || e.target.value === "select" || e.target.value === "checkbox" || e.target.value === "radio") {
                                                            category.fields.attributes[attributeIndex].value = [];
                                                            category.fields.attributes[attributeIndex].values = [];
                                                        }

                                                        else {
                                                            category.fields.attributes[attributeIndex].value = "";
                                                            category.fields.attributes[attributeIndex].values = null;
                                                            category.fields.attributes[attributeIndex].min = undefined;
                                                            category.fields.attributes[attributeIndex].max = undefined;
                                                            category.fields.attributes[attributeIndex].multi_choice = false;
                                                        }

                                                        this.setState({
                                                            category: category,
                                                        });
                                                    }}
                                                >
                                                    {
                                                        this.attr_types.map((type, index) => (
                                                            <option key={"type-" + index} value={type.value}>
                                                                {type.name}
                                                            </option>
                                                        ))
                                                    }
                                                    {/* <option value={"void"}>
                                                        Nature quelconque
                                                    </option>
                                                    <option value={"list"}>
                                                        Liste
                                                    </option> */}
                                                </select>

                                                {
                                                    attribute.type === "number" &&
                                                    <div>
                                                        <div className='number-rang'>
                                                            <label>
                                                                min
                                                            </label>
                                                            <input type='number' value={attribute.min}
                                                                onChange={(e) => {

                                                                    let category = this.state.category;

                                                                    let value = undefined;

                                                                    if( e.target.value !== '' ) {
                                                                        value = e.target.value;
                                                                    }

                                                                    if( attribute.max !== undefined && value !== undefined ) {
                                                                        if( Number(value) <= Number(attribute.max) ) {
                                                                            category.fields.attributes[attributeIndex].min = value;
                                                                            this.setState({
                                                                                category: category,
                                                                            });
                                                                        }
                                                                    }

                                                                    else {
                                                                        category.fields.attributes[attributeIndex].min = value;
                                                                        this.setState({
                                                                            category: category,
                                                                        });
                                                                    }
                                                                    
                                                                }}
                                                            />
                                                        </div>

                                                        <div className='number-rang'>
                                                            <label>
                                                                max
                                                            </label>
                                                            <input type='number' value={attribute.max}
                                                                onChange={(e) => {
                                                                    let category = this.state.category;
                                                                    let value = undefined;

                                                                    if( e.target.value !== '' ) {
                                                                        value = e.target.value;
                                                                    }

                                                                    if( attribute.min !== undefined && value !== undefined ) {
                                                                        if( Number(value) >= Number(attribute.min) ) {
                                                                            category.fields.attributes[attributeIndex].max = value;
                                                                            this.setState({
                                                                                category: category,
                                                                            });
                                                                        }
                                                                    }

                                                                    else {
                                                                        category.fields.attributes[attributeIndex].max = value;
                                                                        this.setState({
                                                                            category: category,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </td>

                                            {/* <td>
                                                {
                                                    attribute.type === "list" ?
                                                        <select
                                                            className='dashboard-option-content-input2'
                                                            value={attribute.multi_choice}
                                                            onChange={(e) => {
                                                                let category = this.state.category;
                                                                category.fields.attributes[attributeIndex].multi_choice = e.target.value;
                                                                this.setState({
                                                                    category: category,
                                                                });
                                                            }}
                                                        >
                                                            <option value={true}>
                                                                oui
                                                            </option>
                                                            <option value={false}>
                                                                non
                                                            </option>
                                                        </select>
                                                        :
                                                        null
                                                }
                                            </td> */}

                                            <td className="userGroups">
                                                {
                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <div className='userGroups_list'>
                                                            <ul className='userGroups_list_ul'>
                                                                {
                                                                    attribute.values.map((value, valueIndex) => (
                                                                        <li className='userGroups_list_ul_li_2' key={value + "-" + valueIndex}>
                                                                        <div className='dashboard-option-content-attr-list-elt-2 flex-row'>
                                                                            <span>
                                                                                {value}
                                                                            </span>
                                                                            <div className='flex-row flex-column dashboard-option-content-attr-list-elt_'
                                                                                style={{
                                                                                    background: "#00000000",
                                                                                }}
                                                                            >
                                                                                <button
                                                                                    className='edit-pen-2'
                                                                                    onClick={(e) => {
                                                                                        let newValue = window.prompt("modifier la valeur", value);
                                                                                        if (newValue !== null && newValue !== undefined && newValue !== "") {
                                                                                            let category = this.state.category;
                                                                                            category.fields.attributes[attributeIndex].values[valueIndex] = newValue;
            
                                                                                            this.setState({
                                                                                                category: category,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    className='delete-trash'
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                                                        if (confirm) {
                                                                                            let category = this.state.category;
                                                                                            category.fields.attributes[attributeIndex].values.splice(valueIndex, 1);
            
                                                                                            this.setState({
                                                                                                category: category,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                                {/* {
                                                                    group.users &&
                                                                    group.users.map((user, index) => (
                                                                        <li className='userGroups_list_ul_li' key={user.id + "-" + user.username}>
                                                                            {user.username}
                                                                        </li>
                                                                    ))
                                                                } */}
                                                            </ul>
                                                        </div>
                                                       

                                                        :
                                                            (
                                                                attribute.type === "text-one-line" || attribute.type === "text-multi-line"?
                                                                    <input
                                                                        className='dashboard-option-content-input2'
                                                                        type='text'
                                                                        value={attribute.value}
                                                                        onChange={(e) => {
                                                                            let category = this.state.category;
                                                                            category.fields.attributes[attributeIndex].value = e.target.value;
                    
                                                                            this.setState({
                                                                                category: category,
                                                                            });
                                                                        }}
                                                                    />
                                                                :
                                                                    (
                                                                        attribute.type === "date"?
                                                                            <input
                                                                                className='dashboard-option-content-input2'
                                                                                type='date'
                                                                                value={attribute.value}
                                                                                onChange={(e) => {
                                                                                    let category = this.state.category;
                                                                                    category.fields.attributes[attributeIndex].value = e.target.value;
                            
                                                                                    this.setState({
                                                                                        category: category,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        :
                                                                            (
                                                                                attribute.type === "number"?
                                                                                    <input
                                                                                        className='dashboard-option-content-input2'
                                                                                        type='number'
                                                                                        value={attribute.value}
                                                                                        min={attribute.min}
                                                                                        max={attribute.max}
                                                                                        onChange={(e) => {
                                                                                            let category = this.state.category;
                                                                                            let currentAttribute = category.fields.attributes[attributeIndex];
                                                                                            currentAttribute.value = e.target.value;

                                                                                            this.setState({
                                                                                                category: category,
                                                                                            });

                                                                                            // let min = currentAttribute.min;
                                                                                            // let max = currentAttribute.max;

                                                                                                // console.log( min, max );

                                                                                            // let isValid = true;

                                                                                            // if( e.target.value !== '' ) {
                                                                                            //     let value = Number(e.target.value);

                                                                                            //     if( min !== undefined ) {
                                                                                            //         // if( value >= Number(min) ) {
                                                                                            //         //     currentAttribute.value = value;
                                                                                            //         // }
                                                                                            //         // else {
                                                                                            //         //     isValid = false;
                                                                                            //         // }
                                                                                            //     }else {
                                                                                            //         currentAttribute.value = e.target.value;
                                                                                            //     }

                                                                                            //     if( max !== undefined ) {
                                                                                            //         if( value <= Number(max) ) {
                                                                                            //             currentAttribute.value = value;
                                                                                            //         }
                                                                                            //         else {
                                                                                            //             isValid = false;
                                                                                            //         }
                                                                                            //     }else {
                                                                                            //         currentAttribute.value = e.target.value;
                                                                                            //     }
                                                                                            // }

                                                                                            // else {
                                                                                            //     currentAttribute.value = e.target.value;
                                                                                            // }
                                                                                                

                                                                                            // if( isValid ) {
                                                                                            //     this.setState({
                                                                                            //         category: category,
                                                                                            //     });
                                                                                            // }
                                                                                        }}
                                                                                    />
                                                                                :
                                                                                    null
                                                                            )
                                                                    )
                                                            )
                                                }

                                                <div className='td_text text-align-center'>
                                                    <span className='text-align-center'
                                                        style={{
                                                            fontSize: "1.7em"
                                                        }}
                                                    >
                                                        {attribute.values && attribute.values.length}
                                                    </span>
                                                </div>
                                            </td>

                                            <td
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                {
                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <button
                                                            style={{
                                                                backgroundColor: "#2f749200",
                                                                color: "#5dc269",
                                                                border: "1px solid #5dc26900",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                let value = window.prompt("nom de la valeur", "");
                                                                if (value !== null && value !== undefined && value !== "") {
                                                                    let category = this.state.category;
                                                                    category.fields.attributes[attributeIndex].values.push(value);

                                                                    this.setState({
                                                                        category: category,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                            </svg>
                                                        </button>
                                                    :
                                                        null
                                                }
                                            </td>

                                            <td
                                                style={{
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <button
                                                    onClick={() => {
                                                        let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                        if (confirm) {
                                                            let category = this.state.category;
                                                            category.fields.attributes.splice(attributeIndex, 1);
                                                            this.setState({
                                                                category: category,
                                                            });
                                                        }
                                                    }}
                                                    style={{
                                                        background: "transparent",
                                                        color: "red",
                                                    }}
                                                >
                                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                ))
                            }
                        </table>
                        <br/>
                        <br/>
                        <div className='dashboard-option-content-button'>
                            <button
                                onClick={() => {
                                    this._addAttribute();
                                }}
                                className="button-style-5"
                            >
                                Ajouter un attribut
                            </button>
                        </div>

                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div className='dashboard-option-content-label'>
                            <span>
                                Liste des attributs d'objets
                            </span>
                        </div>
                        <br />
                        <table className='tab-style-1'>
                            <tr
                                className="table-head-style1"
                            >
                                <th>
                                    Nom
                                </th>

                                <th>
                                    Requis
                                </th>

                                <th>
                                    Objet
                                </th>

                                <th>
                                    Type
                                </th>
                                
                                <th>
                                    Valeur(s)
                                </th>

                                {/* <th
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Ajouter des valeurs
                                </th> */}

                                <th
                                    style={{
                                        color: "red",
                                        textAlign: "center",
                                    }}
                                >
                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </th>
                            </tr>
                            {
                                this.state.category.fields.objects.map((attribute, attributeIndex) => (
                                    <>
                                        <tr
                                            style={{
                                                backgroundColor: attributeIndex % 2===0 ? "#0A376411" : "#ffffff",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td>
                                                <input
                                                    className='dashboard-option-content-input2'
                                                    type='text'
                                                    value={attribute.name}
                                                    onChange={(e) => {
                                                        let category = this.state.category;
                                                        category.fields.objects[attributeIndex].name = e.target.value;

                                                        this.setState({
                                                            category: category,
                                                        });
                                                    }}
                                                />
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.required}
                                                    onChange={(e) => {
                                                        let category = this.state.category;
                                                        category.fields.objects[attributeIndex].required = e.target.value;

                                                        this.setState({
                                                            category: category,
                                                        });
                                                    }}
                                                >
                                                    <option value={true}>
                                                        oui
                                                    </option>
                                                    <option value={false}>
                                                        non
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    defaultValue={this.state.category.fields.objects[attributeIndex].objectName}
                                                    onChange={(e) => {
                                                        let val = (JSON.parse(e.target.value));
                                                        // console.log(val, "-----", this.state.category.fields.objects[attributeIndex].objectName);
                                                        let category = this.state.category;

                                                        category.fields.objects[attributeIndex].values = val.values;
                                                        category.fields.objects[attributeIndex].objectName = val.name;

                                                        this.setState({
                                                            category: category,
                                                        });
                                                    }}
                                                >
                                                    {
                                                        this.state.sysAttr.map((attr, index) => (
                                                            <option value={JSON.stringify(attr)}>
                                                                {attr.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.type}
                                                    onChange={(e) => {
                                                        let category = this.state.category;
                                                        category.fields.objects[attributeIndex].type = e.target.value;

                                                        if (e.target.value === "list" || e.target.value === "select" || e.target.value === "checkbox" || e.target.value === "radio") {
                                                            
                                                        }

                                                        else {
                                                            category.fields.objects[attributeIndex].value = "";
                                                            category.fields.objects[attributeIndex].values = null;
                                                            category.fields.objects[attributeIndex].min = undefined;
                                                            category.fields.objects[attributeIndex].max = undefined;
                                                            category.fields.objects[attributeIndex].multi_choice = false;
                                                        }

                                                        this.setState({
                                                            category: category,
                                                        });
                                                    }}
                                                >
                                                    {
                                                        this.attr_types.map((type, index) => (
                                                            (type.type === "list" || type.type === "select" || type.type === "checkbox" || type.type === "radio") ?
                                                                <option key={"type-" + index} value={type.value}>
                                                                    {type.name}
                                                                </option>
                                                            :
                                                                null
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                            <td className="userGroups">
                                                {
                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <div className='userGroups_list'>
                                                            <ul className='userGroups_list_ul'>
                                                                {
                                                                    attribute.values.map((value, valueIndex) => (
                                                                        <li className='userGroups_list_ul_li_2' key={value + "-" + valueIndex}>
                                                                            <div className='dashboard-option-content-attr-list-elt-2 flex-row'>
                                                                                <span>
                                                                                    {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                       

                                                        :
                                                            (
                                                                attribute.type === "text-one-line" || attribute.type === "text-multi-line"?
                                                                    <input
                                                                        className='dashboard-option-content-input2'
                                                                        type='text'
                                                                        value={attribute.value}
                                                                        onChange={(e) => {
                                                                            let category = this.state.category;
                                                                            category.fields.attributes[attributeIndex].value = e.target.value;
                    
                                                                            this.setState({
                                                                                category: category,
                                                                            });
                                                                        }}
                                                                    />
                                                                :
                                                                    (
                                                                        attribute.type === "date"?
                                                                            <input
                                                                                className='dashboard-option-content-input2'
                                                                                type='date'
                                                                                value={attribute.value}
                                                                                onChange={(e) => {
                                                                                    let category = this.state.category;
                                                                                    category.fields.attributes[attributeIndex].value = e.target.value;
                            
                                                                                    this.setState({
                                                                                        category: category,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        :
                                                                            (
                                                                                attribute.type === "number"?
                                                                                    <input
                                                                                        className='dashboard-option-content-input2'
                                                                                        type='number'
                                                                                        value={attribute.value}
                                                                                        min={attribute.min}
                                                                                        max={attribute.max}
                                                                                        onChange={(e) => {
                                                                                            let category = this.state.category;
                                                                                            let currentAttribute = category.fields.attributes[attributeIndex];
                                                                                            currentAttribute.value = e.target.value;

                                                                                            this.setState({
                                                                                                category: category,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                :
                                                                                    null
                                                                            )
                                                                    )
                                                            )
                                                }

                                                <div className='td_text text-align-center'>
                                                    <span className='text-align-center'
                                                        style={{
                                                            fontSize: "1.7em"
                                                        }}
                                                    >
                                                        {attribute.values && attribute.values.length}
                                                    </span>
                                                </div>
                                            </td>

                                            {/* <td
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                {
                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <button
                                                            style={{
                                                                backgroundColor: "#2f749200",
                                                                color: "#5dc269",
                                                                border: "1px solid #5dc26900",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                let value = window.prompt("nom de la valeur", "");
                                                                if (value !== null && value !== undefined && value !== "") {
                                                                    let category = this.state.category;
                                                                    category.fields.attributes[attributeIndex].values.push(value);

                                                                    this.setState({
                                                                        category: category,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                            </svg>
                                                        </button>
                                                    :
                                                        null
                                                }
                                            </td> */}

                                            <td
                                                style={{
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <button
                                                    onClick={() => {
                                                        let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                        if (confirm) {
                                                            let category = this.state.category;
                                                            category.fields.objects.splice(attributeIndex, 1);
                                                            this.setState({
                                                                category: category,
                                                            });
                                                        }
                                                    }}
                                                    style={{
                                                        background: "transparent",
                                                        color: "red",
                                                    }}
                                                >
                                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                ))
                            }
                        </table>
                        <br/>
                        <br/>
                        <div className='dashboard-option-content-button'>
                            <button
                                onClick={() => {
                                    this._addObjectAttribute();
                                }}
                                className="button-style-5"
                            >
                                Ajouter un attribut
                            </button>
                        </div>
                </div>


                <div className='flex-row justify-center'>
                    <div>
                        <button
                            onClick={() => {
                                this._addCategory();
                                return true;
                            }}
                            className="button-style-6"
                            style={{
                                border: "1px solid #5dc269",
                                color: "#5dc269",
                            }}
                        >
                            Ajouter cette catégorie
                        </button>
                    </div>
                </div>
                <br />
                <br />
            </>
        )
    }
}

DashboardAddCategories = connect(mapStateToProps, null)(DashboardAddCategories);
export default DashboardAddCategories;
