// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.scss';


/**Importation des images*/


class CheckboxComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
        }
    }

    componentDidMount() {
        this.setState({
            isActive: this.props.isActive,
        });
    }

    
    render() {
        return (
            <div className='checkbox'>
                <div className='CheckBox flex-row align-center'
                    onClick={() => {
                        if(this.props.action) {
                            this.props.action();
                        } else {
                            return true;
                        }
                    }}
                >
                    <div className='CheckBox_back'
                        style={{
                            background: this.props.isActive ? this.props.activeBackground : this.props.inactiveBackground,
                        }}
                    >
                        <div className='CheckBox_dot'
                            style={{
                                marginLeft: this.props.isActive ? "50%" : "0%",
                                background: this.props.isActive ? this.props.activeColor : this.props.inactiveColor,
                            }}
                        ></div>
                    </div>
                    <div className='CheckBox_text'
                        style={{
                            color: this.props.isActive ? this.props.activeColorText : this.props.inactiveColorText,
                        }}
                    >
                        {this.props.label}
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckboxComponent;