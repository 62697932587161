import React from 'react';
import { connect } from 'react-redux';

// import words from 'an-array-of-french-words';

/**----------------------------------------------------------------------------------------*/
// /**Importation des composants de la page */
// import WebViewer from '@pdftron/pdfjs-express';
import DocViewer, {DocViewerRenderers} from 'react-doc-viewer';


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/doc_viewer.style.css';


/**----------------------------------------------------------------------------------------*/
// /**Importation des images*/



/**
 * Importation de valeurs globals
*/
import KEYS from '../GLOBAL-VAR/global-keys';


/**
 * Importation de shelpers
 */
import tools from '../helpers/tools.helper';
import PATH from '../GLOBAL-VAR/globals-urls';

import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DocViewerScreen extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            docIsReady: false,
            password: "",
            docLink: "",
        }

        this.accessStore = new AccessStore( this.props.dispatch);

        this.tools = new tools();

        this.query = new URLSearchParams( this.props.location.search );

        this.KEYS = new KEYS();

        this._getPassword = this._getPassword.bind( this );
        this.path = new PATH();

        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.setState({
            isLoading: true,
        });

        // console.log(  this.path.api_ + decodeURIComponent( this.query.get( "doc" ) ) + "?token=" + this.props.user.access );
        
        document.addEventListener( "readystatechange", (e) => {
            if( e.target.readyState === 'interactive' ) {
                if( !this._isObjEmpty( this.props.user ) ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );

                            // WebViewer(
                            //     {
                            //       path: '/public',
                            //       initialDoc: this.path.api_ + decodeURIComponent( this.query.get( "doc" ) ) + "?token=" + this.props.user.access,
                            //     },
                            //     this.canvasRef.current,
                            // ).then((instance) => {
                          
                            // });

                            this.setState({
                                docLink: this.path.api_ + decodeURIComponent( this.query.get( "doc" ) ) + "?token=" + this.props.user.access,
                                isLoading: false,
                            });
                            
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
                else {
                    // WebViewer(
                    //     {
                    //       path: '/public',
                    //       initialDoc: this.path.api_ + decodeURIComponent( this.query.get( "doc" ) ),
                    //     },
                    //     this.canvasRef.current,
                    // ).then((instance) => {
                    //   
                    // });
                    this.setState({
                        docLink: this.path.api_ + decodeURIComponent( this.query.get( "doc" ) ),
                        isLoading: false,
                    });
                }
            }
        });
    }

    _addViewToDoc(data, callback=()=>{}) {
        this._changeDocState(data, this.props.user.access, (response) => {
            if( response.status === 201 ) {
                callback( response );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._addViewToDoc(data, callback);
            });
        });
    }

    _getDocState(id, callback=()=>{}) {
        this._getOnDocStatus(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocState(id, callback);
            });
        });
    }

    _getPassword(id, callback=()=>{}) {
        this._getDocumentPassword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getPassword(id, callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    /**
    * On met a jour la session utilisateur
    */
      _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    render() {
        return (
            <div
                style={{
                    position: "relative",
                    top: 0,
                    left: 0,
                    zIndex: 1000000000000000,
                    width: "100%",
                    height: "auto",
                    overflow: "auto"
                }}
            >
                <div
                    
                >
                    {/* {
                        <div ref={this.canvasRef} style={{width: "100%", height: "100vh"}}></div>
                    } */}
                    {
                        this.state.docLink !== '' &&
                            <DocViewer
                                documents={[{uri:this.state.docLink}]}
                                pluginRenderers={DocViewerRenderers}
                            />
                    }
                </div>
            </div>
        )
    }
}


DocViewerScreen = connect(mapStateToProps, null)(DocViewerScreen);
export default DocViewerScreen;