import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
// import words from 'an-array-of-french-words';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import ImageSlider1 from '../components/imageSlider1/imageSlider1.component';
import ProjectCard from '../components/projectCard/projectCard.component';
import SearchSuggestionComponent from '../components/search-suggestion.component/search-suggestion.component';
import WordCloud from '../components/wordCloud.component/wordCloud.component';
// import IframeDownloadComponent from '../components/iframeDownload.component/iframeDownload.component';

import NoAccess from '../components/no_access/no_access.component';

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home_view.style.css';
import './responsive.style/home_view.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/


/**
 * Importation des helpers
 */
 import tools from '../helpers/tools.helper';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
        panier: state.Panier.panier,
    };
}

class HomeView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            no_access: false,
            portefolioImageList: [
                {
                    src: 'back3.png',
                    text: [],
                },
                // {
                //     src: 'bg4.jpeg',
                //     text: [],
                // },
                // {
                //     src: 'bg5.jpg',
                //     text: [],
                // },
            ],

            homeDocs: [],
            currentPath: "",

            fromOtherSOurce: false,

            filter: false,
            search: false,
            searchTime: 0,
            noResultFound: false,

            filterResult: false,
            sortResult: false,

            currentSuggestion: [],

            currentResultPage: 1,

            searchResult: [],

            searchResultsFilter: [],

            printMenu: false,

            basket: [],

            printBasket: false,

            copied: false,

            currentDownloadLink: [],

            currentText: [],

            viewSHareOption: false,

            filterContent: {},

            printFilterSelection: false,
            currentFilterSelection: [],

            allResultsGet: true,
            currentSearch: "",

            resultSortedByDate: [],
            typeSort: 1,
            printSortedTab: false,



            categories: [],
            currentCategories: [],
            currentCategoriesIndex: [],

            years: [],
            currentYears: [],

            currentMonths: [],

            searchSuggestion: [],

            allKeyWords: [],
            _allKeyWords: [],

            usersList: {},
        }

        this.accessStore = new AccessStore( this.props.dispatch);

        this.optionsSelected = {};
        this.tools = new tools();

        this.query = null;
    }

    componentDidMount() {
        // console.log( this.props.user.access );
        // this.accessStore.setRedux('LOG_OUT', {});
        this.query = new URLSearchParams(this.props.location.search);
        let init_path = decodeURIComponent(this.query.get("searchPath"));

        // init_path = init_path.replace(/!!!/g, "=");
        // init_path = init_path.replace(/&&&/g, "&");

        // console.log( init_path );

        this._get_users((accounts) => {
            let usersList = {};
            accounts.map((account, index) => {
                usersList[account.id] = account;
                // console.log(usersList);
                if(index === accounts.length - 1) {
                    this.setState({
                        usersList: usersList,
                    });
                }
            });
        });

        if( init_path !== null && init_path !== 'null' && init_path !== undefined && init_path !== 'undefined' ) {
            this.setState({
                fromOtherSOurce: true,
                currentSearch: decodeURIComponent(this.query.get("keyword")),
            }, () => {
                this._search_doc_(null, null, true, init_path);
            });
        }else {
            this.setState({
                isLoading: true,
            });
            this._get_all_categories(( categories )=>{
                // console.log( categories );

                let portefolioImageList = this.state.portefolioImageList;
                
                for(let i=0; i<portefolioImageList.length; i++) {
                    categories.map((category, index) => {
                        category.fields.attributes.map((attr, index) => {
                            // console.log(attr);
                            if(attr.name==="Afficher à l'accueil" && attr.value[0] === "oui") {
                                portefolioImageList[i].text.push(category);
                            }
                        });
                    });
                }

                this.setState({
                    categories : categories,
                    portefolioImageList: portefolioImageList,
                }, () => {
                    this._get_attribute("dates", (dates) => {
                        let years = [];
                        dates.map((date, index) => {
                            if( !years.includes(date.year) ) {
                                years.push( date.year );
                            }
                        });
                        this.setState({
                            years: years,
                        });
                        this._getAllTypeDocOfCategories(0, ()=>{
                            this.setState({
                                isLoading: false,
                            });
                        });
                    });
                });
            });
        }

        this._start_getKeyword_for_allDocs(0, [], (response, ls) => {
            this._startSearchKeydocs("", 1, [], (res)=>{
                let myList = response;
                for(let i=0; i<res.length; i++) {
                    if( myList[res[i]['key_word']] ) {
                        myList[res[i]['key_word']]['score'] = myList[res[i]['key_word']]['score'] + res[i]['score']
                    }
                    if( i === res.length -1 ) {
                        let array = Object.keys(myList).map((key) => myList[key]);
                        array = this.tools._sortByKeyDesc(array, "score");
                        this.setState({
                            allKeyWords: myList,
                            _allKeyWords: array,
                        });
                    }
                }
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _start_getKeyword_for_allDocs(page, tab=[], callback = () => { }) {
        this._getKeyword_for_allDocs((response) => {
            // console.log( response );
            if( response.results ) {
                tab = tab.concat(response.results );
            }else {
                tab = tab.concat(response)
            }
            if( response.next !== null && response.next !== undefined ) {
                this._start_getKeyword_for_allDocs(page+1, tab, callback);
            }
            else {
                let list = {};
                for(let i=0; i<tab.length; i++) {
                    if(!list[tab[i].id]) {
                        list[tab[i].id] = {
                            score: 0,
                            word: tab[i],
                        };
                    }
                }
                callback( list, tab );
            }
        },page);
    }

    _startSearchKeydocs(search, page, tab=[], callback = () => { }) {
        this._searchKeydocs(search, page, (response) => {
            // console.log( response.data );
            if (response.status === 200) {
                tab = tab.concat(response.data.results);
                // tab = this.tools._sortByKey(tab, "score");
                // console.log( tab );

                if(response.data.next !== null && response.data.next !== "null") {
                    this._startSearchKeydocs(search, page+1, tab, callback);
                }
                else {
                    callback(tab);
                }
            }
        }, (error) => {
            this._manageError(error, () => {
                this._startSearchKeydocs(search, page, tab, callback);
            });
        });
    }


    _getAllTypeDocOfCategories( index_=0, callback=()=>{} ) {
        if( this.state.categories.length > 0 ) {
            let category = this.state.categories[index_];
            // console.log( this.state.categories[index] );
            this._getCategoriesRecentDocs(category.pk, (docList) => {

                this._getAllDocsKeywords(0, docList.results, (newList) => {
                    let newCategory = {
                        name: category.name,
                        pk: category.pk,
                        recentDocs: newList,
                        moreDownload: [],
                    };
                    this._getCategoriesMoreDownloadDocs(category.pk, (list) => {

                        /**Obtenir la liste de mots-clés pour chaque document de la liste obtenue */
                        this._getAllDocsKeywords(0,list.results, (newList) => {
                            // console.log( list );
                            newCategory.moreDownload = newList;
                            let homeDocs = this.state.homeDocs;
                            homeDocs.push(newCategory);
                            // console.log( homeDocs );
                            this.setState({
                                homeDocs: homeDocs,
                            }, ()=> {
                                // console.log( index_ );
                                if( index_ < this.state.categories.length - 1 ) {
                                    this._getAllTypeDocOfCategories( index_ + 1, callback );
                                }else {
                                    // console.log( newList );
                                    callback();
                                }
                            });
                        })
                    });
                });
            })
        }

        else {
            this.setState({
                isLoading: false,
            });
        }
    }

    _getCategoriesRecentDocs(category, callback=()=>{}) {
        let searchPath = "category=" + category;
        this._startSearchRequest(searchPath, 1, (response)=>{
            callback( response.data );
        });
    }

    _getCategoriesMoreDownloadDocs(category, callback=()=>{}) {
        let searchPath = "category=" + category;
        this._startSearchRequest(searchPath, 1, (response)=>{
            callback( response.data );
        });
    }

    _getAllDocsKeywords( index=0, docList, callback=()=>{} ) {
        if( docList.length > 0 ) {
            let doc = docList[index];
            this._getDocKey(doc.id, (keywords) => {
                let docs = docList;
                docs[index]["keywords"] = keywords;
                // console.log( index );
                if( index < docList.length - 1 ) {
                    this._getAllDocsKeywords( index + 1, docs, callback );
                }else {
                    callback(docs);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _getDocKey(id, callback=()=>{}) {
        this._getKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log( response.data );
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocKey(id, callback);
            })
        });
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    _get_attribute(attribute, callback=()=>{}) {
        this._getAttribute(attribute, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_attribute(attribute, callback);
            })
        });
    }

    _getKeyword_for_allDocs(callback=()=>{}, page) {
        this._getKeywordForAllDocs(this.props.user.access, (response) => {
            // console.log( response.data );
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getKeyword_for_allDocs(callback);
            })
        }, page);
    }

    _startSearchRequest( searchPath, page, callback=()=>{} ) {
        this._search(searchPath, page, this.props.user.access, ( response ) => {
            if( response.status === 200 ) {
                callback( response );
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._startSearchRequest( searchPath, page, callback );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                // console.log( error.response );
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else if(error.response.status === 403){
                this.setState({
                    isLoading: false,
                    no_access: true,
                });
            }
        }
    }

    _search_doc_(e, value, getMore=false, path=null, returnToTop=true) {
        if( !getMore ) {
            if( e!== null ) {
                e.preventDefault();
                this.setState({
                    filterContent: {},
                    searchResultsFilter: [],
                    currentResultPage: 1,
                });
            }
        }
        
        let startTime = new Date();

        let searchPath =  path===null ? "search="+value : path;

        if( path===null ) {
            this.setState({
                currentSearch: value,
            });
        }
        
        this.setState({
            isLoading: true,
            currentPath: searchPath,
        });

        this._startSearchRequest( searchPath, this.state.currentResultPage, ( response ) => {

            // console.log( response.data );
            if( response.data.count > 0 ) {
                let searchResult = [];

                if( !getMore ) {
                    searchResult = response.data.results;
                }else {
                    searchResult = this.state.searchResult;
                    searchResult = searchResult.concat( response.data.results );
                }
                
                // console.log( searchResult );

                let flt_data = [];
                
                let data_year = {
                    name: "Années",
                    content: [],
                };

                let data_categories = {
                    name: "Catégories",
                    content: [{
                        name: "Tout",
                        number_result: 0,
                        content: [],
                    }],
                };

                searchResult.map((result, index) => {
                    if( data_year.content.length === 0 ) {
                        data_year.content.push(
                            {
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            },
                            {
                                name: "Tout",
                                number_result: 1,
                                content: [index],
                            }
                        );
                    }else {
                        let isContain = false;
                        for( var i=0; i<data_year.content.length; i++) {
                            if( data_year.content[i].name === result.year ) {
                                data_year.content[i].number_result =  data_year.content[i].number_result + 1;
                                data_year.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            data_year.content.push({
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            });
                        }
                    }


                    /**les categories */

                    if( data_categories.content.length === 0 ) {
                        this.state.categories.map((category) => {
                            if( category.pk === result.category ) {
                                data_categories.content.push(
                                    {
                                        name: category.name,
                                        pk: category.pk,
                                        number_result: 1,
                                        content: [index],
                                    }
                                );
                                data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                                data_categories.content[0].content.push(index);
                                return true;
                            }
                        });
                    }else {
                        let isContain = false;
                        for( var i=0; i<data_categories.content.length; i++) {
                            if( data_categories.content[i].pk === result.category ) {
                                data_categories.content[i].number_result =  data_categories.content[i].number_result + 1;
                                data_categories.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            this.state.categories.map((category) => {
                                if( category.pk === result.category ) {
                                    data_categories.content.push(
                                        {
                                            name: category.name,
                                            pk: category.pk,
                                            number_result: 1,
                                            content: [index],
                                        }
                                    );
                                    return true;
                                }
                            });
                        }

                        data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                        data_categories.content[0].content.push(index);
                    }
                });

                flt_data.push( data_year );
                flt_data.push( data_categories );

                // console.log( flt_data );

                this.setState({
                    resultSortedByDate: this.tools._sortByKey(searchResult, 'year'),
                    noResultFound: false,
                    allResultsGet: (response.data.next !== null && response.data.next !== undefined ) ? false : true,
                });
                
                let endTime = new Date();

                this.setState({
                    search: true,
                    isLoading: false,
                    searchResult: searchResult,
                    searchResultsFilter: flt_data,
                    searchTime: ( endTime - startTime ) / 1000,
                }, () => {
                    this._getAllDocsKeywords(0,this.state.searchResult, (newList) => {
                        this.setState({
                            searchResult: newList,
                        });
                        if(returnToTop===true) {
                            window.scrollTo(0, document.getElementById("searchResultContainer").offsetTop);
                        }
                    });
                });
            }

            else {
                let endTime = new Date();

                this._getKeyword_for_allDocs((response) => {
                    if( response.results ) {
                        this._searchKeywordForSuggestion(response.results);
                    }
                    else {
                        this._searchKeywordForSuggestion(response);
                    }


                    this.setState({
                        search: true,
                        isLoading: false,
                        noResultFound: true,
                        searchTime: ( endTime - startTime ) / 1000,
                        searchResult: [],
                    }, () => {
                        window.scrollTo(0, document.getElementById("searchResultContainer").offsetTop);
                    });
                });
            }
        });
    }

    _searchKeywordForSuggestion(tab) {
        let suggestionTab = [];

        let searchValue = this.state.currentSearch;
        searchValue = searchValue.replace(/ /g, "");
        searchValue = searchValue.toLowerCase();
        // console.log( searchValue );

        for( let i=searchValue.length-1; i>1; i-- ) {
            tab.map((value, index) => {
                
                let word = value.word.replace(/ /g, "");
                word = word.toLowerCase();

                if( word.includes(searchValue.slice(0, i)) && !this.tools._containObject(value, suggestionTab, "word") ) {
                    suggestionTab.push({word: value.word});
                }
            });
        }

        this.setState({
            searchSuggestion: suggestionTab,
        });
    }

    /**
     * On met a jour la session utilisateur
     */
     _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }
    _openDoc( doc ) {
        window.open("/view-doc:?doc=" + encodeURIComponent(doc.url) + "&pk=" + encodeURIComponent(doc.id) + "&output=embed", '_blank', 'location=yes, height=570, width=620, scrollbars=yes,status=yes');
    }

    _logout() {
        if( window.confirm('Voulez-vous vraiment vous déconnecter?') ) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }

    _copyToClipBoard() {
        var copyText = document.getElementById("element_to_copy2");
        copyText.setAttribute("value", this.state.currentText );
        copyText.select();

        navigator.clipboard.writeText( copyText.value );

        clearTimeout( this.timeout );

        this.setState({
            copied: true,
        }, () => {
            this.timeout = setTimeout( () => {
                this.setState({
                    copied: false,
                });
            }, 5000);
        });
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='absolute imageSlider'>
                    <ImageSlider1
                        delay="5000"
                        onClickText={(text) => {
                            // alert(9);
                            this.setState({
                                currentResultPage: 0
                            }, () => {
                                let path = "category=" + text;
                                this._search_doc_(null, null, false, path);
                            });
                        }}
                        imageList={this.state.portefolioImageList}
                        anotherContent={() => {
                            return (
                                <div className='speed_search'>
                                    <form
                                        action="#"
                                        target="_self"
                                        method="post"
                                        name="SearchForm1"
                                        style={{background: "transparent", width: "100%"}}
                                        onSubmit = {(e) => {
                                            this.setState({
                                                fromOtherSOurce: false,
                                            });
                                            this._search_doc_(e, document.forms["SearchForm1"]["search-input-1"].value);
                                        }}
                                    >
                                        <div className='doc-explorer-section-search-1 flex-row justify-center align-center'>
                                            <input name='search-input-1' className='input-style-1 search-input search-input-m1' placeholder='Rechercher un document' type='search'/>
                                            <div className='doc-explorer-section-search-right'>
                                                <button className='button-style-1 scale-to-hover'
                                                    style={{color: "#5dc269", borderLeft: "1px solid #5dc269"}}
                                                >
                                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )
                        }}
                    />
                </div>

                <div style={{
                    height: "100vh",
                    minHeight: "350px",
                }}></div>

                <div className='search-doc-st1'>
                    <div className='search-doc-st1-title'>
                        <span>
                            RECHERCHER UN DOCUMENT
                        </span>
                    </div>

                    {/* <form className='search-doc-st1-form'>
                        <div className='search-doc-st1-formContent flex-row flex-wrap justify-end'>
                            <div className='search-doc-st1-formContent_'>
                                <div className='search-doc-st1-formContent_label'>
                                    <span>
                                        TYPE DE DOCUMENTS :
                                    </span>
                                </div>
                                <div className='search-doc-st1-formContent_input'>
                                    <select placeholder='Tout les document'>
                                        <option>
                                            Tout les document
                                        </option>

                                        {
                                            this.state.categories.map((category, index) => (
                                                <option value={category.pk}
                                                    style={{
                                                        color: "#000000aa"
                                                    }}
                                                >
                                                    {category.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>


                            <div className='search-doc-st1-formContent_'>
                                <div className='search-doc-st1-formContent_label'>
                                    <span>
                                        Du :
                                    </span>
                                </div>
                                <div className='search-doc-st1-formContent_input'>
                                    <input type="date" />
                                </div>
                            </div>

                            <div className='search-doc-st1-formContent_'>
                                <div className='search-doc-st1-formContent_label'>
                                    <span>
                                        Au :
                                    </span>
                                </div>
                                <div className='search-doc-st1-formContent_input'>
                                    <input type="date" />
                                </div>
                            </div>

                            <div className='search-doc-st1-formContent_'>
                                <div className='search-doc-st1-formContent_label'>
                                    <span>
                                        MOTS-CLÉS :
                                    </span>
                                </div>
                                <div className='search-doc-st1-formContent_input'>
                                    <input type="text" placeholder='Saisissez des mots-clés' className='inputText_'/>
                                </div>
                            </div>

                            <div className='search-doc-st1-formContent_ search-doc-st1-formButton flex-row justify-end'>
                                <button>
                                    RECHERCHER
                                </button>
                            </div>
                        </div>
                    </form> */}


                    <div className=''>
                        <form
                            action="#"
                            target="_self"
                            method="post"
                            name="SearchForm1"
                            style={{background: "transparent", width: "100%"}}
                            onSubmit = {(e) => {
                                this.setState({
                                    fromOtherSOurce: false,
                                    currentResultPage: 0
                                }, () => {
                                    this._search_doc_(e, document.forms["SearchForm1"]["search-input-1"].value);
                                });
                            }}
                        >
                            <div className='doc-explorer-section-search-1 flex-row justify-center align-center'>
                                <input name='search-input-1' className='input-style-1 search-input search-input-m1' placeholder='Rechercher un document' type='search'/>
                                <div className='doc-explorer-section-search-right'>
                                    <button className='button-style-1 scale-to-hover'
                                        style={{color: "#ffffff", borderLeft: "1px solid #ffffff"}}
                                    >
                                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                            <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <br/>
                        <div className='flex-row justify-center'>
                            <Link
                                className=""
                                to="./search-doc"
                            >
                                <button className="button-style-4">
                                    Recherche approfondie
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='cloudTitle'>
                        <span>
                            #NUAGE DE MOTS-CLÉS
                        </span>
                    </div>
                    {
                        this.state._allKeyWords.length > 0 &&
                            <div className='cloud_container'>
                                <WordCloud list={this.state._allKeyWords} max={4} min={0.7} viewNumberMaxWord={true}/>
                            </div>
                    }
                </div>


                {
                    !this.state.search ?
                    <div className='not-search-container'>
                        {
                            this.state.homeDocs.map((category) => (
                                category.recentDocs.length > 0 &&
                                <div key={category.name}>
                                    <div className='card_container'>
                                        <div className='card_container-title'>
                                            <div className='card_container-title-square'></div>
                                            <div className='card_container-title-text'>
                                                <span>{category.name} à la une</span>
                                            </div>
                                        </div>

                                        <div className='flex-row justify-center flex-wrap card_list'>
                                            {
                                                category.recentDocs.map((doc, index) => (
                                                    <div className='card_list_content' key={"doc-list-1-" + index}>
                                                        <ProjectCard
                                                            addToPanier={() => {
                                                                this._updateBasket(doc);
                                                            }}
                                                            owner={this.state.usersList[doc.owner] ? this.state.usersList[doc.owner] : ""}
                                                            project={doc}
                                                            link={"/view-doc-info:?doc=" + encodeURIComponent(doc.id) + "&cover=" + encodeURIComponent(doc.img)  + "&owner=" + encodeURIComponent(this.state.usersList[doc.owner] ? this.state.usersList[doc.owner].username : "")}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    {/* <div className='card_container'>
                                        <div className='card_container-title-2'>
                                            <div className='card_container-title-2-square'></div>
                                            <div className='card_container-title-2-text'>
                                                <span>{category.name} les plus téléchargés</span>
                                            </div>
                                        </div>

                                        <div className='flex-row justify-center flex-wrap card_list'>
                                            {
                                                category.moreDownload.map((doc, index) => (
                                                    <div className='card_list_content'>
                                                        <ProjectCard project={doc} link={"/view-doc-info:?doc=" + encodeURIComponent(doc.pk) + "&cover=" + encodeURIComponent(doc.img)}/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div> */}
                                </div>  
                            ))
                        }
                        {/* <div className='card_container'>
                            <div className='card_container-title'>
                                <div className='card_container-title-square'></div>
                                <div className='card_container-title-text'>
                                    <span>LES RAPPORTS D'ÉTUDES À LA UNE</span>
                                </div>
                            </div>

                            <div className='flex-row justify-center flex-wrap card_list'>
                                {
                                    this.state.docList.map((doc, index) => (
                                        <div className='card_list_content'>
                                            <ProjectCard project={doc} link={"/view-doc-info:?doc=" + encodeURIComponent(doc.name) + "&cover=" + encodeURIComponent(doc.cover)}/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='card_container'>
                            <div className='card_container-title-2'>
                                <div className='card_container-title-2-square'></div>
                                <div className='card_container-title-2-text'>
                                    <span>LES RAPPORTS D'ÉTUDES LES PLUS TÉLÉCHARGÉS</span>
                                </div>
                            </div>

                            <div className='flex-row justify-center flex-wrap card_list'>
                                {
                                    this.state.docList.map((doc, index) => (
                                        <div className='card_list_content'>
                                            <ProjectCard project={doc} link={"/view-doc-info:?doc=" + encodeURIComponent(doc.name) + "&cover=" + encodeURIComponent(doc.cover)}/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div> */}
                    </div>
                    :
                        <div id='searchResultContainer'>
                            {
                                this.state.noResultFound ?
                                    <section className='search-notFound-section notFoundSection'>
                                        <div className='search-notFound-section-title text-align-center'>
                                            <span className='font-size-1_2 color-primary-dd letter-spacing-1 family-lato-light bold'>
                                                Aucun document trouvé.
                                            </span>
                                        </div>

                                        <div className='search-notFound-section-suggestion w-100 flex-row flex-wrap justify-center'
                                            style={{
                                                fontSize: "0.8em"
                                            }}
                                        >
                                            {
                                                this.state.searchSuggestion.map((suggestion, suggestIndex) => (
                                                    <div className='search-notFound-section-suggestion-one' key={this._generateID(32)}>
                                                        <SearchSuggestionComponent onClick={(e, word) => {
                                                            this._search_doc_(e, word);
                                                        }} suggestion={suggestion}/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </section>
                                :
                                    <div>
                                        <div className='card_container'>
                                            <div className='card_container-title-2'>
                                                <div className='card_container-title-2-square'></div>
                                                <div className='card_container-title-2-text'>
                                                    <span>Résultats de recherche</span>
                                                </div>
                                            </div>

                                            <br/>
                                            <div className='search-result-section-resultNumber'>
                                                <span className='font-size-0_9 color-primary-99 letter-spacing-1 family-sofia-black'>
                                                    {this.state.searchResult.length} résultat(s), { this.state.searchTime }s
                                                </span>
                                            </div>

                                            <div className='flex-row justify-center flex-wrap card_list'>
                                                {
                                                    this.state.searchResult.map((doc, index) => (
                                                        <div className='card_list_content' key={"doc-list-2-" + index}>
                                                            
                                                            <ProjectCard
                                                                project={doc}
                                                                owner={this.state.usersList[doc.owner] ? this.state.usersList[doc.owner] : ""}
                                                                addToPanier={() => {
                                                                    this._updateBasket(doc);
                                                                }}
                                                                link={"/view-doc-info:?doc=" + encodeURIComponent(doc.id) + "&cover=" + encodeURIComponent(doc.img)}
                                                            />
                                                            
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className='doc-explorer-section-viewMore_'>
                                            {
                                                !this.state.allResultsGet ?
                                                    <div className='doc-explorer-section-viewMore flex-row align-center justify-center'>
                                                        <button className='flex-row align-center justify-center' 
                                                            onClick={(e) => {
                                                                this.setState({
                                                                    currentResultPage: this.state.currentResultPage + 1,
                                                                }, () => {
                                                                    this.state.fromOtherSOurce ?
                                                                        this._search_doc_(null, null, true, this.state.currentPath, false)
                                                                    :
                                                                        this._search_doc_(e, null, true, this.state.currentPath, false);
                                                                });
                                                            }}
                                                        >
                                                            <span>
                                                                Afficher plus
                                                            </span>
                                                        </button>
                                                    </div>
                                                :
                                                    null
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                }

                {
                    this.state.no_access &&
                        <div className='alert'>
                            <div className='alert-content'>
                                <NoAccess onClick={() => {
                                    this._logout();
                                    this.setState({
                                        no_access: true,
                                    });
                                    window.location.reload();
                                }} />
                            </div>
                        </div>
                }
            </>
        )
    }
}


HomeView = connect(mapStateToProps, null)(HomeView);
export default HomeView;
