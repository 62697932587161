import React from 'react';
import { connect } from 'react-redux';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/




/**
 * 
 * Importation des scomposants
 */
import SearchInputComponent from '../components/searchInput.component/searchInput.component';
import ChronogramAOF from '../components/ChronogramAOF.component/ChronogramAOF.component';

/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}


class DashboardGroupsList extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            accountsList: [],
            currentAccountsList: [],
            searchResult: [],
            searchResult2: [],

            viewTaskManager: false,
            currentGroupSelected: {},
            currentGroupSelected_accountsToAdd: [],
            currentGroupSelected_accountsToRemove: [],

            groupsList: [],
            groupsList_:{},
            groupsList_more: {},
            currentChronogramList: [],
            chronogramSearchResult: [],

            permissionList: [],
            groupsPermissions: [],
            currentGroupPermissionsList: [],
            permissionsDeletedList: [],
            currentPermissionList: [],

            addInGroups: false,
            RemoveFromGroups: false,

            chronogram: [],
            viewCartographyDetails: false,
            currentCartography: {},
            currentGroupSelectedParentIndex: null,
        }

        this.tools = new tools();
        this.accessStore = new AccessStore(this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            isLoading: true,
            currentAccountsList: [],
            searchResult: [],
            currentChronogramList: [],
            chronogramSearchResult: [],
        });
        this._get_users((accounts) => {
            // console.log( accounts );
            this.setState({
                accountsList: accounts,
            });
            this._get_all_groups((groups) => {
                // console.log( groups );
                this.setState({
                    groupsList: groups,
                    // currentPermissionList: groups.permissions,
                }, () => {
                    this._get_all_permissions(( permissionList )=>{
                        // console.log( permissionList );
                        this.setState({
                            permissionList : permissionList,
                        },() => {

                            let groupsPermissions = [];
                            this.state.permissionList.map((permission, index) => {
                                
                                let inGroupsPermissions = false;
                                let group_name = permission.name.split("Can add").length === 2 ? permission.name.split("Can add")[1] : (
                                    permission.name.split("Can change").length === 2 ? permission.name.split("Can change")[1]: (
                                        permission.name.split("Can delete").length === 2 ? permission.name.split("Can delete")[1] : (
                                            permission.name.split("Can view").length === 2 ? permission.name.split("Can view")[1] : ""
                                        )
                                    )
                                );
                                
                                groupsPermissions.map((group, groupIndex) => {
                                    if(group.name === group_name) {
                                        inGroupsPermissions = groupIndex;
                                    }
                                })

                                if( inGroupsPermissions === false ) {
                                    groupsPermissions.push({
                                        name: group_name,
                                        permissions: [permission]
                                    });
                                }

                                else {
                                    groupsPermissions[inGroupsPermissions].permissions.push(permission);
                                }

                                // console.log( groupsPermissions );
                            });

                            this.setState({
                                groupsPermissions: groupsPermissions,
                            });

                            this._get_all_groups_((groups_) => {
                                // console.log(groups_);
                                let groupsList_more = {};
                                groups_.map((group_, index) => {
                                    groupsList_more[group_.group] = group_;
                                    if( index === groups_.length-1) {
                                        let groupsList = this.state.groupsList;
                                        // let currentPermissionList = [];
                                        // for(let i=0; i<this.state.groupsList.length; i++) {
                                        //     // this.state.groupsList[i]["another"] = groupsList_more[this.state.groupsList[i].id];
                                        //     for( let j=0; j<groupsList[i].permissions.length; j++ ) {
                                        //         for( let k=0; k<this.state.permissionList.length; k++ ) {
                                        //             if(this.state.permissionList[k].id === this.state.groupsList[i].permissions[j]) {
                                        //                 groupsList[i].permissions[j] = this.state.permissionList[k];
                                        //             }
                                        //         }
                                        //     }
                                        // }
                                        this.setState({
                                            groupsList_more: groupsList_more,
                                        }, () => {
                                            this._startGetGroupUsers(0, groupsList, (groups) => {
                                                // console.log( groups );
                                                this.setState({
                                                    isLoading: false,
                                                    groupsList: groups,
                                                });

                                                let groupsList_ = {};
                                                groups.map((grp, index) => {
                                                    groupsList_[grp.id] = grp;
                                                });;
                                                // console.log(groupsList_);

                                                this.setState({
                                                    groupsList_: groupsList_
                                                });
                                            })
                                        });
                                    }
                                });
                                this._getAllChronogram((response) => {
                                    let chr = [];
                                    response.map((elt) => {
                                        if(elt.active) {
                                            chr.push(elt);
                                        }
                                    });
                                    this.setState({
                                        chronogram: chr,
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    _getAllChronogram(callback=()=>{}) {
        this._systemInfoGetAll(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log(response.data);
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getAllChronogram(callback);
            });
        });
    }

    _get_all_permissions(callback=()=>{}) {
        this._getAllPermissions(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_permissions(callback);
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _startGetGroupUsers( index=0, groupList, callback=()=>{} ) {
        if( groupList.length > 0 ) {
            let group = groupList[index];
            this._get_group_users(group.id, (users) => {
                let groups = groupList;
                groups[index]["users"] = users.users;
                if( index < groupList.length - 1 ) {
                    this._startGetGroupUsers( index + 1, groups, callback );
                }else {
                    callback(groups);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _get_group_users(id, callback=()=>{}) {
        this._getGroupUsers(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_groups(callback);
            })
        });
    }

    _set_new_user(response) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user);
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log(response.data);
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _get_all_groups_(callback=()=>{}) {
        this._getAllGroups_(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log(response.data);
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups_(callback);
            });
        });
    }
    

    startGroupsDeletion( index=0, groupList, callback=()=>{} ) {
        let group = groupList[index];

        this.setState({
            isLoading: true,
            progressionTile: "Suppression de : " + (index + 1) + "/" + groupList.length,
            requestProgression: Math.round((100 * (index+1)) /groupList.length),
        });

        this._delete_group(group, (response) => {
            if( index < groupList.length - 1 ) {
                this.startGroupsDeletion( index + 1, groupList, callback );
            }else {
                callback();
            }
        });
    }

    _delete_group(id, callback=()=>{}) {
        this._deleteGroup(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._delete_group(id, callback);
            });
        });
    }

    _update_group(data, id, callback=()=>{}) {
        this._updateOneGroup(data, id, this.props.user.access, (response) => {
            callback( response );
        }, (error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._update_group(data, id, callback);
            });
        });
    }

    _update_group_(data, id, callback=()=>{}) {
        this._updateOneGroup_(data, id, this.props.user.access, (response) => {
            callback( response );
        }, (error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._update_group_(data, id, callback);
            });
        });
    }

    _add_perms_group(data, id, callback=()=>{}) {
        this._addPermsToGroup(data, id, this.props.user.access, (response) => {
            callback( response );
        }, (error) => {
            // console.log( error.response );
            this._manageError( error, () => {
                this._add_perms_group(data, id, callback);
            });
        });
    }

    _choiceUsersForGroups() {
        this.setState({
            addInGroups: !this.state.addInGroups,
        });
    }

    _choiceUsersForGroupsRemove() {
        this.setState({
            RemoveFromGroups: !this.state.RemoveFromGroups,
        });
    }

    _addUsersInGroups() {
        let other_data = {
            users: this.state.currentAccountsList,
            groups: this.state.currentChronogramList,
        }

        this.setState({
            isLoading: true,
        });

        this._add_user_to_group(other_data, () => {
            this.setState({
                isLoading: false,
                currentAccountsList: [],
            });

            this._choiceUsersForGroups();
        });
    }

    _removeUsersFromGroups() {
        let other_data = {
            users: this.state.currentAccountsList,
            groups: this.state.currentChronogramList,
        }

        this.setState({
            isLoading: true,
        });

        this._remove_user_from_group(other_data, () => {
            alert("Les utilisateurs ont été retiré des groupes avec succès");
            this.setState({
                isLoading: false,
                currentAccountsList: [],
            });

            this._choiceUsersForGroupsRemove();
        });
    }

    _add_user_to_group( data, callback=()=>{} ) {
        this._addAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_user_to_group(data, callback);
            });
        });
    }

    _remove_user_from_group( data, callback=()=>{} ) {
        this._removeAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_user_to_group(data, callback);
            });
        });
    }

    _manageError(error, callback = () => { }) {
        if (error.response) {
            if (error.response.status === 401) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if (error.response.data.code === "token_not_valid") {
                    this._refreshUserToken(this.props.user.refresh, (response) => {
                        if (response.status === 200) {
                            this._set_new_user(response);
                            callback();
                        }
                    }, (error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à interagir avec cette section");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _selectAllGroups() {
        let tab = [];
        this.state.chronogram.map((chronogram_, index) => {
            tab.push(chronogram_.id);
        });

        this.setState({
            currentChronogramList: tab,
        });
    }

    _unSelectAllGroups() {
        this.setState({
            currentChronogramList: [],
        });
    }

    _viewGroupDetails() {
        this.setState({
            viewTaskManager: !this.state.viewTaskManager,
        });
    }

    _viewCartographyDetails() {
        this.setState({
            viewCartographyDetails: !this.state.viewCartographyDetails,
        });
    }

    _updateGroup(group) {
        this.setState({
            isLoading: true,
        });

        let permissionsList1 = [];

        // console.log(this.state.groupsPermissions);
        // console.log(this.state.currentGroupPermissionsList);
        // console.log(this.state.permissionsDeletedList);
        // console.log(this.state.currentPermissionList);

        this.state.currentGroupPermissionsList.map((permission, index) => {
            if( !this.state.permissionsDeletedList.includes(permission) ) {
                permissionsList1.push(permission);
            }
        });

        // console.log( this.state.currentGroupPermissionsList );

        let data = {
            name: group.name,
            permissions: [],
        };

        // let data_ = {
        //     description: this.state.groupsList_more[group.id].description ? this.state.groupsList_more[group.id].description : "",
        //     others: this.state.groupsList_more[group.id].others ? this.state.groupsList_more[group.id].others : {
        //         attributes: [],
        //     },
        // }

        let data_ = {
            description: "",
            others: {
                attributes: [],
            }
        };

        // console.log(this.state.groupsList_more[group.id].description);

        if( this.state.groupsList_more[group.id] ) {
            data_ = {
                description: this.state.groupsList_more[group.id].description ? this.state.groupsList_more[group.id].description : "",
                others: this.state.groupsList_more[group.id].others ? this.state.groupsList_more[group.id].others : {
                    attributes: [],
                    acronym: "",
                },
                order: this.state.groupsList_more[group.id].order ? this.state.groupsList_more[group.id].order : {},
            }
        }

        this._update_group_(data_, group.id, (response) => {
            // console.log(response);
            return true;
        });

        // console.log( data );
        // return 0;

        let data1 = {
            users: this.state.currentGroupSelected_accountsToAdd,
            groups: [group.id],
        };

        let data2 = {
            users: this.state.currentGroupSelected_accountsToRemove,
            groups: [group.id],
        };

        let data3 = {
            permissions: permissionsList1.concat(this.state.currentPermissionList),
        };

        // console.log(data3);

        this._update_group(data, group.id, (response) => {
            // console.log( response );
            if( response.status === 200 ) {
                this._add_user_to_group(data1, () => {
                    
                    this._remove_user_from_group(data2, () => {
                        
                        this._add_perms_group(data3, group.id, (response) => {
                            if( response.status === 201 || response.status === 200 ) {

                                // this.state.chronogram.map((chr, index) => {
                                //     if(chr.id === this.state.currentCartography.id) {
                                        
                                //     }
                                // })
                                let groupSystemInfo = {
                                    name: this.state.currentCartography.name,
                                    "details": {
                                        type: "cartography",
                                        task_tree: this.state.currentCartography.details.task_tree,
                                        task_positions: this.state.currentCartography.details.task_positions,
                                        task_list: this.state.currentCartography.details.task_list,
                                    }
                                }

                                groupSystemInfo.details.task_list[this.state.currentGroupSelectedParentIndex]["name"] = this.state.currentGroupSelected.name;
                                groupSystemInfo.details.task_list[this.state.currentGroupSelectedParentIndex]["acronym"] = this.state.groupsList_more[group.id].others["acronym"];

                                this._update_group_systemInfo(this.state.currentCartography.id, groupSystemInfo, (res) => {
                                    alert("Les informations du groupe on été mis à jour avec succès");
                                    this.setState({
                                        isLoading: false,
                                    });
                                });
                                
                                // window.location.reload();
                            }
                        });
                    });
                });
            }
        });
    }

    _deleteTask(taskIndex) {
        this.setState({
            isLoading: true,
        });

        let chronogram = this.state.currentCartography;

        let currentGroupId = [];
        if(chronogram.details.task_list[taskIndex]["group"]){
            currentGroupId.push(chronogram.details.task_list[taskIndex]["group"])
        } else {
            this.state.groupsList.map((group, index) => {
                if(group.name === chronogram.details.task_list[taskIndex].name) {
                    currentGroupId.push(group.id);
                }
            });
        }

        this.startGroupsDeletion(0, currentGroupId, (res) => {
            this.setState({
                isLoading: false,
            });
        });

        // let child = this.get_all_child_of_node(task_tree, null, taskIndex, 0);

        /**Appel recursid de suppressionde chacun des fils */
        if(chronogram.details.task_tree[taskIndex].length > 0) {
            for(let i=0; i<chronogram.details.task_tree[taskIndex].length; i++) {
                this._deleteTask(chronogram.details.task_tree[taskIndex][i]);
            }
        }

        /**Suppression de l'element dans la liste des fils du parent */
        let positionsTab = chronogram.details.task_positions[taskIndex].split(",");
        if(positionsTab.length > 1) {
            // console.log(task_list[taskIndex].task, positionsTab);
        
            positionsTab.splice(-1);
            let parentPosition = positionsTab.toString();
            let parentIndex = chronogram.details.task_positions.indexOf(parentPosition);
            // console.log(parentIndex, positionsTab.toString());
            
            let positionInParentOnTree = chronogram.details.task_tree[parentIndex].indexOf(taskIndex);
            // console.log(positionInParentOnTree);
            chronogram.details.task_tree[parentIndex].splice(positionInParentOnTree, 1);
        }

        /**Destruction du noeud courant */
        chronogram.details.task_tree[taskIndex] = -1;

        chronogram.details.task_positions[taskIndex] = -1;
        chronogram.details.task_list[taskIndex] = -1;

        this.setState({
            currentCartography: chronogram,
        }, () => {
            let groupSystemInfo = {
                name: this.state.currentCartography.name,
                "details": {
                    type: "cartography",
                    task_tree: this.state.currentCartography.details.task_tree,
                    task_positions: this.state.currentCartography.details.task_positions,
                    task_list: this.state.currentCartography.details.task_list,
                }
            }
            this._update_group_systemInfo(this.state.currentCartography.id, groupSystemInfo, (res) => {
                this.setState({
                    isLoading: false,
                    currentGroupContent: {
                        name: "",
                        acronym: "",
                        currentPermissionList: [],
                        currentAccountsList: [],
                        parent_id: null,
                        parent_index: null,
                    },
                    viewTaskManager: false,
                });
            });
        });
    }

    _addGroup__(group) {
        this.setState({
            isLoading: true,
        });
        this._addTask(group, this.state.currentGroupSelectedParentIndex, (response)=>{
            group['group'] = response.id;
            group["acronym"] = group.others.acronym;
            let chronogram = this.state.currentCartography;
            if(this.state.currentGroupSelectedParentIndex !== null ) {
                chronogram.details.task_list.push(group);
                chronogram.details.task_positions.push(chronogram.details.task_positions[this.state.currentGroupSelectedParentIndex] + "," + (chronogram.details.task_list.length-1));
                chronogram.details.task_tree.push([]);
                chronogram.details.task_tree[this.state.currentGroupSelectedParentIndex].push(chronogram.details.task_tree.length-1);
            }
            else {
                chronogram.details.task_list.push(group);
                chronogram.details.task_positions.push((chronogram.details.task_list.length-1).toString());
                chronogram.details.task_tree.push([]);
            }

            this.setState({
                currentCartography: chronogram,
            }, () => {
                let groupSystemInfo = {
                    name: this.state.currentCartography.name,
                    "details": {
                        type: "cartography",
                        task_tree: this.state.currentCartography.details.task_tree,
                        task_positions: this.state.currentCartography.details.task_positions,
                        task_list: this.state.currentCartography.details.task_list,
                    }
                }
                this._update_group_systemInfo(this.state.currentCartography.id, groupSystemInfo, (res) => {
                    alert("Les informations on été mis à jour avec succès");
                    this.setState({
                        isLoading: false,
                        currentGroupContent: {
                            name: "",
                            acronym: "",
                            currentPermissionList: [],
                            currentAccountsList: [],
                            parent_id: null,
                            parent_index: null,
                        },
                        viewTaskManager: false,
                    });
                    this.componentDidMount();
                });
            });
        });
    }

    _addTask(currentGroupContent, currentGroupContentParentIndex, callback=()=>{}) {
        this._submit_addGroup_form(currentGroupContent, (res) => {
            callback(res);
        });
    }

    _submit_addGroup_form(currentGroup, callback=()=>{}) {
        let data = {};

        if( this.state.groupsList_more[currentGroup.id] ) {
            let groupsList_more = this.state.groupsList_more[currentGroup.id];
            groupsList_more['others']['acronym'] = currentGroup.others.acronym;
            data = {
                name: currentGroup.name,
                description: groupsList_more.description ? groupsList_more.description : currentGroup.description,
                others: groupsList_more.others ? groupsList_more.others : {
                    attributes: [],
                    acronym: currentGroup.others.acronym,
                },
                order: groupsList_more.order ? groupsList_more.order : {},
            }
        } else {
            data = {
                name: currentGroup.name,
                "description": currentGroup.description,
                acronym: currentGroup.others.acronym,
                "others": (this.state.groupsList_more[currentGroup.id] && this.state.groupsList_more[currentGroup.id].others) ? this.state.groupsList_more[currentGroup.id].others : {
                    attributes: [],
                    acronym: currentGroup.others.acronym,
                },
                "order": {},
            };
        }
        this._add_group(data, (res) => {
            this._update_group_(data, res.id, (response) => {
                let data1 = {
                    users: this.state.currentGroupSelected_accountsToAdd,
                    groups: [res.id],
                };

                let data3 = {
                    permissions: this.state.currentPermissionList,
                };
                this._add_user_to_group(data1, () => {
                    
                    this._add_perms_group(data3, res.id, (response) => {
                        if( response.status === 201 || response.status === 200 ) {
                            callback(res);
                        }
                    });
                });
                return true;
            });
        });
        
    }

    _add_group( data, callback=()=>{} ) {
        this._addGroup(data, this.props.user.access, (response) => {
            if( response.status === 201 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._add_group(data, callback);
            })
        });
    }

    _add_group_systemInfo( data, callback=()=>{} ) {
        this._systemInfoEdit(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._add_group_systemInfo(data, callback);
            })
        });
    }

    _update_group_initExtended( data, id, callback=()=>{} ) {
        this._updateGroup__(data, id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._update_group_initExtended(data, id, callback);
            })
        });
    }

    _update_chronogram() {
        this.setState({
            isLoading: true,
        });

        let groupSystemInfo = {
            name: this.state.currentCartography.name,
            "details": this.state.currentCartography.details,
        };

        this._update_group_systemInfo(this.state.currentCartography.id, groupSystemInfo, (res) => {
            alert("Les informations on été mis à jour avec succès");
            this.setState({
                isLoading: false,
            });
        });
    }

    _update_group_systemInfo(id, data, callback=()=>{} ) {
        this._systemInfoUpdate(id, data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._update_group_systemInfo(id, data, callback);
            })
        });
    }

    _addAttribute() {
        let attributeName = window.prompt("Entrer le nom de l'attribut ici", "");
        if( attributeName !== null && attributeName !== undefined && attributeName !== "" ) {
            let currentGroupSelected = this.state.currentGroupSelected;
            let groupsList_more = this.state.groupsList_more;

            // console.log(groupsList_more);
            if(!groupsList_more[currentGroupSelected.id]) {

                groupsList_more[currentGroupSelected.id] = {
                    others: {
                        attributes: [],
                    }
                }
            } else {
                if(groupsList_more[currentGroupSelected.id]['others']==null) {

                    groupsList_more[currentGroupSelected.id].others = {
                        attributes: [],
                        acronym: "",
                    }
                }
            }
            // console.log(groupsList_more[currentGroupSelected.id]);
            if(!groupsList_more[currentGroupSelected.id]['others']['attributes']) {
                groupsList_more[currentGroupSelected.id]['others']['attributes'] = [];
            }
            groupsList_more[currentGroupSelected.id]['others']['attributes'].push({
                name: attributeName,
                type: "void",
                multi_choice: false,
                required: true,
                values: null,
                value: "",
                min: undefined,
                max: undefined,
            });

            this.setState({
                currentGroupSelected: currentGroupSelected,
                groupsList_more: groupsList_more,
            });
        }
    }

    _deleteChr(current){
        // console.log(current.id);
        this.setState({
            isLoading: true,
        });
        let groups = [];
        current.details.task_list.map((task) => {
            if(task!==-1) {
                groups.push(task.group);
            }
        });
        if(groups.length > 0) {
            this.startGroupsDeletion(0, groups, (res) => {
                this._delete_SystemInfo(current.id, (res) => {
                    this.setState({
                        isLoading: false,
                    });
                });
            });
        } else {
            this._delete_SystemInfo(current.id, (res) => {
                this.setState({
                    isLoading: false,
                });
            });
        }
    }

    _delete_SystemInfo(id, callback=()=>{}) {
        this._systemInfoDelete(id, this.props.user.access, (response) => {
            callback( response.data );
        }, (error) => {
            this._manageError( error, () => {
                this._delete_SystemInfo(id, callback);
            });
        });
    }

    _getGrpNumber(list) {
        let nb=0;
        list.map((elt)=>{
            if(elt!==-1 && elt.group) {
                nb++;
            }
        });

        return nb;
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                {/* {
                    this.state.currentAccountsList.length > 0 &&
                        <div className="right-float">
                            <span>
                                { this.state.currentAccountsList.length }
                            </span>
                        </div>
                } */}

                <div className='userListContainer'>
                    <SearchInputComponent key_="name" id_="id" updated={true} data={this.state.chronogramSearchResult.length > 0 ? this.state.chronogramSearchResult : this.state.chronogram} currentSelected={this.state.currentChronogramList} placeholder="Nom du groupe"
                        onChange={(value) => {
                            let tab = [];
                            this.state.chronogram.map((group, index) => {
                                if (group.name.toLowerCase() === value.toLowerCase() ||
                                    group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                ) {
                                    tab.push(group);
                                }
                            });

                            this.setState({
                                chronogramSearchResult: tab,
                            });
                        }}
                        onSelected={(selected) => {
                            if (this.state.currentChronogramList.includes(selected.id)) {
                                let currentChronogramList = this.state.currentChronogramList;
                                for (var i = 0; i < currentChronogramList.length; i++) {
                                    if (currentChronogramList[i] === selected.id) {
                                        currentChronogramList.splice(i, 1);
                                        this.setState({
                                            currentChronogramList: currentChronogramList,
                                        });
                                        return true;
                                    }
                                }
                            }

                            else {
                                let currentChronogramList = this.state.currentChronogramList;
                                currentChronogramList.push(selected.id);
                                // console.log( currentChronogramList );
                                this.setState({
                                    currentChronogramList: currentChronogramList,
                                });
                            }
                        }}

                        style={(elt) => {
                            return {
                                backgroundColor: this.state.currentChronogramList.includes(elt.id) ? "#dc354555" : "transparent",
                                color: this.state.currentChronogramList.includes(elt.id) ? "#ffffff" : "#000000aa",
                            }
                        }}

                        isChecked={(elt) => {
                            return this.state.currentChronogramList.includes(elt.id) ? true : false 
                        }}

                        icon={
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-plus-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                        }
                    />

                    <div className='flex-row flex-wrap'>
                        <div className='selectOption'>
                            <button className='button-style-6 flex-wrap align-center'
                                style={{
                                    color: "#5dc269",
                                    borderColor: "#5dc269",
                                }}

                                onClick={() => {
                                    this._selectAllGroups();
                                }}
                            >
                                <span>
                                    TOUT SÉLECTIONNÉ
                                </span>
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
                                </svg>
                            </button>
                        </div>

                        <div>
                            <button className='button-style-6'
                                style={{
                                    color: "red",
                                    borderColor: "red",
                                }}
                                onClick={() => {
                                    this._unSelectAllGroups();
                                }}
                            >
                                <span>
                                    TOUT DÉSÉLECTIONNÉ
                                </span>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <br/>

                <div className='userListContainer_body'>
                    {
                        this.state.currentChronogramList.length > 0 &&
                        <table>
                            <tr
                                style={{
                                    background: "#0A3764ee",
                                    color: "#ffffff",
                                }}
                                className="table-head"
                            >
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        NOM
                                    </span>
                                </th>
                                <th>
                                    <div className='text-align-center'>
                                        <span
                                            style={{
                                                color: "#ffffff",
                                            }}
                                            className="text-align-center"
                                        >
                                            MÉTIERS
                                        </span>
                                    </div>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        <button style={{color: "red"}}
                                            className='searchInput_currentList_ul_li_button'
                                        >
                                            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </button>
                                    </span>
                                </th>
                            </tr>
                            {
                                this.state.currentChronogramList.map((currentChronogram, index) => (
                                    this.state.chronogram.map((chr, accountIndex) => (
                                        currentChronogram === chr.id &&
                                        <tr key={chr.name}
                                            style={{
                                                background: index%2 !== 0 ? "#0A3764ee" : "transparent",
                                                color:  index%2 !== 0 ? "#ffffff" : "#000000",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                onClick={(e) => {
                                                    this._viewCartographyDetails();
                                                    this.setState({
                                                        currentCartography: chr,
                                                    });
                                                }}
                                            >
                                                {chr.name}
                                            </td>

                                            <td className="userGroups">
                                                <div className='userGroups_list'>
                                                    <ul className='userGroups_list_ul'>
                                                        {
                                                            chr.details && chr.details.task_list &&
                                                            chr.details.task_list.map((elt, index) => (
                                                                elt !== -1 && elt.group &&
                                                                <li className='userGroups_list_ul_li' key={elt.name + "-" + elt.acronym}>
                                                                    {elt.name}({elt.acronym})
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <div className='td_text text-align-center'>
                                                    <span className='text-align-center'>
                                                        {chr.details && chr.details.task_list && this._getGrpNumber(chr.details.task_list)}
                                                    </span>
                                                </div>
                                            </td>

                                            <td>
                                                <span>
                                                    <button style={{color: "red"}}
                                                        className='searchInput_currentList_ul_li_button'
                                                        onClick={(e) => {
                                                            if( window.confirm("Voulez-vous vraiment supprimer " + chr.name + " ?") ) {
                                                                this._deleteChr(chr);
                                                            }
                                                        }}
                                                    >
                                                        <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                        </svg>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ))
                            }
                        </table>
                    }

                    <div className='userListContainer-buttons'>
                        {
                            this.state.currentChronogramList.length > 0 &&
                                <div className="flex-row justify-center">
                                    <button style={{background: "red"}} className="flex-row justify-center"
                                        onClick={() => {
                                            if( window.confirm("voulez-vous vraiment supprimr ce chronogramme ?") ) {
                                                // this.setState({
                                                //     isLoading: true,
                                                // });
                                                // this.startGroupsDeletion(0, this.state.currentChronogramList, () => {
                                                //     alert("Groupe(s) supprimé(s) avec succès");
                                                //     this.setState({
                                                //         isLoading: false,
                                                //     });
                                                //     this.componentDidMount();
                                                // });
                                            }
                                        }}
                                    >
                                        Supprimer
                                    </button>
                                </div>
                        }
                    </div>
                </div>

                {
                    this.state.viewCartographyDetails &&
                    <div className='alert'>
                        <div className='alert-content'
                            style={{
                                padding: "20px",
                            }}
                        >
                            <div className='flex-row justify-end'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    onClick={(e) => {
                                        this._viewCartographyDetails();
                                    }}
                                >
                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                </svg>
                            </div>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Nom
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' Value={this.state.currentCartography.name}
                                            onChange={(e) => {
                                                let currentCartography = this.state.currentCartography;
                                                currentCartography.name = e.target.value;

                                                this.setState({
                                                    currentCartography: currentCartography,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='dashboard_body_input_task'>
                                    <ChronogramAOF
                                        tree={this.state.currentCartography.details.task_tree}
                                        positions={this.state.currentCartography.details.task_positions}
                                        list={this.state.currentCartography.details.task_list}
                                        control={true}
                                        controlText={"Ajouter un élément"}
                                        noHoverEffect={true}

                                        editAction={(task_index) => {
                                            this.setState({
                                                chronogramUpdateType: "update",
                                            }, () => {
                                                // console.log(this.state.groupsList_[this.state.currentCartography.details.task_list[task_index].group].permissions);
                                                this.setState({
                                                    currentGroupSelectedParentIndex: task_index,
                                                    viewTaskManager: true,
                                                    currentGroupSelected: this.state.groupsList_[this.state.currentCartography.details.task_list[task_index].group],
                                                    currentGroupPermissionsList: this.state.groupsList_[this.state.currentCartography.details.task_list[task_index].group].permissions
                                                }, () => {
                                                    // console.log(this.state.currentGroupPermissionsList);
                                                });
                                            });
                                            return true;
                                        }}

                                        deleteAction={(taskIndex) => {
                                            if(window.confirm("Voulez-vous vraiment supprimer ce contenu?")) {
                                                this._deleteTask(taskIndex)
                                                return true;
                                            }
                                        }}

                                        addAction={(parent_index) => {
                                            // console.log();
                                            this.setState({
                                                chronogramUpdateType: "add",
                                                currentGroupSelected: {
                                                    name: "",
                                                    acronym: "",
                                                    description: "",
                                                    currentPermissionList: [],
                                                    currentAccountsList: [],
                                                    users: [],
                                                    others: {
                                                        acronym: "",
                                                    },
                                                    parent_id: null,
                                                    parent_index: null,
                                                },
                                            }, () => {
                                                this.setState({
                                                    currentGroupSelectedParentIndex: parent_index,
                                                    viewTaskManager: true,
                                                })
                                            });
                                            return true;
                                        }}
                                    />
                                </div>
                            </div>
                            <br/>

                            <button className='button-style-16'
                                onClick={(e) => {
                                    this._update_chronogram();
                                }}
                            >
                                VALIDER
                                <span className='button-style-5-span'></span>
                            </button>
                        </div>
                    </div>
                }


                {
                    this.state.viewTaskManager &&
                    <div className='alert'>
                        <div className='alert-content'
                            style={{
                                padding: "20px",
                            }}
                        >
                            <div className='flex-row justify-end'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    onClick={(e) => {
                                        this._viewGroupDetails();
                                    }}
                                >
                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                </svg>
                            </div>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Nom
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' Value={this.state.currentGroupSelected.name}
                                            onChange={(e) => {
                                                let currentGroupSelected = this.state.currentGroupSelected;
                                                currentGroupSelected.name = e.target.value;

                                                this.setState({
                                                    currentGroupSelected: currentGroupSelected,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Acronyme
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' Value={this.state.groupsList_more[this.state.currentGroupSelected.id] ? this.state.groupsList_more[this.state.currentGroupSelected.id]["others"]["acronym"] : this.state.currentGroupSelected.others.acronym}
                                            onChange={(e) => {
                                                if(this.state.chronogramUpdateType === "update") {
                                                    let currentGroupSelected = this.state.currentGroupSelected;
                                                    let groupsList_more = this.state.groupsList_more;
                                                    if(!groupsList_more[currentGroupSelected.id]) {
                                                        groupsList_more[currentGroupSelected.id] = {};
                                                    }
                                                    groupsList_more[currentGroupSelected.id]["others"]["acronym"] = e.target.value;
                                                    this.setState({
                                                        currentGroupSelected: currentGroupSelected,
                                                        groupsList_more: groupsList_more,
                                                    });
                                                } else {
                                                    let currentGroupSelected = this.state.currentGroupSelected;
                                                    currentGroupSelected["others"]["acronym"] = e.target.value;
                                                    this.setState({
                                                        currentGroupSelected: currentGroupSelected,
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Description
                                    </span>
                                    <div className='registration_form_input'>
                                        <textarea name='summary' className='registration_form_input_area'
                                            // Value={this.state.currentGroupSelected.description ? this.state.currentGroupSelected.description : ""}
                                            onChange={(e) => {
                                                if(this.state.chronogramUpdateType === "update") {
                                                    let currentGroupSelected = this.state.currentGroupSelected;
                                                    let groupsList_more = this.state.groupsList_more;
                                                    if(!groupsList_more[currentGroupSelected.id]) {
                                                        groupsList_more[currentGroupSelected.id] = {};
                                                    }
                                                    groupsList_more[currentGroupSelected.id]["description"] = e.target.value;
                                                    this.setState({
                                                        currentGroupSelected: currentGroupSelected,
                                                        groupsList_more: groupsList_more,
                                                    });
                                                } else {
                                                    let currentGroupSelected = this.state.currentGroupSelected;
                                                    currentGroupSelected["description"] = e.target.value;
                                                    this.setState({
                                                        currentGroupSelected: currentGroupSelected,
                                                    });
                                                }
                                            }}
                                        >
                                            { this.state.groupsList_more[this.state.currentGroupSelected.id] ? this.state.groupsList_more[this.state.currentGroupSelected.id].description : this.state.currentGroupSelected.description}
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div className='dashboard-option-content-label'>
                                <span>
                                    Liste des attributs complémentaires
                                </span>
                            </div>
                            <br />

                            <table className='tab-style-1'>
                                <tr
                                    className="table-head-style1"
                                >
                                    <th>
                                        Nom
                                    </th>

                                    <th>
                                        Requis
                                    </th>

                                    <th>
                                        Type
                                    </th>
                                    
                                    <th>
                                        Valeur(s)
                                    </th>

                                    <th
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        Ajouter des valeurs
                                    </th>

                                    <th
                                        style={{
                                            color: "red",
                                            textAlign: "center",
                                        }}
                                    >
                                        <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                    </th>
                                </tr>

                                {
                                    this.state.groupsList_more[this.state.currentGroupSelected.id] &&
                                    this.state.groupsList_more[this.state.currentGroupSelected.id].others &&
                                    this.state.groupsList_more[this.state.currentGroupSelected.id].others.attributes &&
                                    this.state.groupsList_more[this.state.currentGroupSelected.id].others.attributes.map((attribute, attributeIndex) => (
                                        <>
                                            <tr
                                                style={{
                                                    backgroundColor: attributeIndex % 2===0 ? "#0A376411" : "#ffffff",
                                                    zIndex: "10000"
                                                }}
                                            >
                                                <td>
                                                    <input
                                                        className='dashboard-option-content-input2'
                                                        type='text'
                                                        value={attribute.name}
                                                        onChange={(e) => {
                                                            let currentGroupSelected = this.state.currentGroupSelected;
                                                            let groupsList_more = this.state.groupsList_more;
                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].name = e.target.value;

                                                            this.setState({
                                                                groupsList_more: groupsList_more,
                                                            });
                                                        }}
                                                    />
                                                </td>

                                                <td>
                                                    <select
                                                        className='dashboard-option-content-input2'
                                                        value={attribute.required}
                                                        onChange={(e) => {
                                                            let currentGroupSelected = this.state.currentGroupSelected;
                                                            let groupsList_more = this.state.groupsList_more;
                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].required = e.target.value;

                                                            this.setState({
                                                                groupsList_more: groupsList_more,
                                                            });
                                                        }}
                                                    >
                                                        <option value={true}>
                                                            oui
                                                        </option>
                                                        <option value={false}>
                                                            non
                                                        </option>
                                                    </select>
                                                </td>

                                                <td>
                                                    <select
                                                        className='dashboard-option-content-input2'
                                                        value={attribute.type}
                                                        onChange={(e) => {
                                                            let currentGroupSelected = this.state.currentGroupSelected;
                                                            let groupsList_more = this.state.groupsList_more;
                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].type = e.target.value;

                                                            if(e.target.value === "list" || e.target.value === "select" || e.target.value === "checkbox" || e.target.value === "radio") {
                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].value = [];
                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].values = [];
                                                            }

                                                            else {
                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].value = "";
                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].values = null;
                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].min = undefined;
                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].max = undefined;
                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].multi_choice = false;
                                                            }

                                                            this.setState({
                                                                groupsList_more: groupsList_more,
                                                            });
                                                        }}
                                                    >
                                                        {
                                                            this.attr_types.map((type, index) => (
                                                                <option key={"type-" + index} value={type.value}>
                                                                    {type.name}
                                                                </option>
                                                            ))
                                                        }
                                                        {/* <option value={"void"}>
                                                            Nature quelconque
                                                        </option>
                                                        <option value={"list"}>
                                                            Liste
                                                        </option> */}
                                                    </select>

                                                    {
                                                        attribute.type === "number" &&
                                                        <div>
                                                            <div className='number-rang'>
                                                                <label>
                                                                    min
                                                                </label>
                                                                <input type='number' value={attribute.min}
                                                                    onChange={(e) => {

                                                                        let currentGroupSelected = this.state.currentGroupSelected;
                                                                        let groupsList_more = this.state.groupsList_more;

                                                                        let value = undefined;

                                                                        if( e.target.value !== '' ) {
                                                                            value = e.target.value;
                                                                        }

                                                                        if( attribute.max !== undefined && value !== undefined ) {
                                                                            if( Number(value) <= Number(attribute.max) ) {
                                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].min = value;
                                                                                this.setState({
                                                                                    currentGroupSelected: currentGroupSelected,
                                                                                });
                                                                            }
                                                                        }

                                                                        else {
                                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].min = value;
                                                                            this.setState({
                                                                                currentGroupSelected: currentGroupSelected,
                                                                                groupsList_more: groupsList_more,
                                                                            });
                                                                        }
                                                                        
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className='number-rang'>
                                                                <label>
                                                                    max
                                                                </label>
                                                                <input type='number' value={attribute.max}
                                                                    onChange={(e) => {
                                                                        let currentGroupSelected = this.state.currentGroupSelected;
                                                                        let groupsList_more = this.state.groupsList_more;

                                                                        let value = undefined;

                                                                        if( e.target.value !== '' ) {
                                                                            value = e.target.value;
                                                                        }

                                                                        if( attribute.min !== undefined && value !== undefined ) {
                                                                            if( Number(value) >= Number(attribute.min) ) {
                                                                                groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].max = value;
                                                                                this.setState({
                                                                                    currentGroupSelected: currentGroupSelected,
                                                                                    groupsList_more: groupsList_more,
                                                                                });
                                                                            }
                                                                        }

                                                                        else {
                                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].max = value;
                                                                            this.setState({
                                                                                currentGroupSelected: currentGroupSelected,
                                                                                groupsList_more: groupsList_more,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </td>

                                                {/* <td>
                                                    {
                                                        attribute.type === "list" ?
                                                            <select
                                                                className='dashboard-option-content-input2'
                                                                value={attribute.multi_choice}
                                                                onChange={(e) => {
                                                                    let currentGroupSelected = this.state.currentGroupSelected;
                                                                    currentGroupSelected.others.attributes[attributeIndex].multi_choice = e.target.value;

                                                                    this.setState({
                                                                        currentGroupSelected: currentGroupSelected,
                                                                    });
                                                                }}
                                                            >
                                                                <option value={true}>
                                                                    oui
                                                                </option>
                                                                <option value={false}>
                                                                    non
                                                                </option>
                                                            </select>
                                                        :
                                                            null
                                                    }
                                                </td> */}


                                                <td className="userGroups">
                                                    {
                                                        attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <div className='userGroups_list'>
                                                            <ul className='userGroups_list_ul'>
                                                                {
                                                                    attribute.values.map((value, valueIndex) => (
                                                                        <li className='userGroups_list_ul_li_2' key={value + "-" + valueIndex}>
                                                                        <div className='dashboard-option-content-attr-list-elt-2 flex-row'>
                                                                            <span>
                                                                                {value}
                                                                            </span>
                                                                            <div className='flex-column dashboard-option-content-attr-list-elt_'>
                                                                                <button
                                                                                    className='edit-pen-2'
                                                                                    onClick={(e) => {
                                                                                        let newValue = window.prompt( "modifier la valeur", value );
                                                                                        if( newValue !== null && newValue !== undefined && newValue !== "" ) {
                                                                                            let currentGroupSelected = this.state.currentGroupSelected;
                                                                                            let groupsList_more = this.state.groupsList_more;

                                                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].values[valueIndex] = newValue;

                                                                                            this.setState({
                                                                                                currentGroupSelected: currentGroupSelected,
                                                                                                groupsList_more: groupsList_more,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    className='delete-trash'
                                                                                    onClick={(e) => {
                                                                                        let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                                                        if( confirm ) {
                                                                                            let currentGroupSelected = this.state.currentGroupSelected;
                                                                                            let groupsList_more = this.state.groupsList_more;

                                                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].values.splice(valueIndex, 1);

                                                                                            this.setState({
                                                                                                currentGroupSelected: currentGroupSelected,
                                                                                                groupsList_more: groupsList_more,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                        :
                                                        (
                                                            attribute.type === "text-one-line" || attribute.type === "text-multi-line"?
                                                                <input
                                                                    className='dashboard-option-content-input2'
                                                                    type='text'
                                                                    value={attribute.value}
                                                                    onChange={(e) => {
                                                                        let currentGroupSelected = this.state.currentGroupSelected;
                                                                        let groupsList_more = this.state.groupsList_more;
                                                                        
                                                                        groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].value = e.target.value;
                
                                                                        this.setState({
                                                                            currentGroupSelected: currentGroupSelected,
                                                                            groupsList_more: groupsList_more,
                                                                        });
                                                                    }}
                                                                />
                                                            :
                                                                (
                                                                    attribute.type === "date"?
                                                                        <input
                                                                            className='dashboard-option-content-input2'
                                                                            type='date'
                                                                            value={attribute.value}
                                                                            onChange={(e) => {
                                                                                let currentGroupSelected = this.state.currentGroupSelected;

                                                                                let groupsList_more = this.state.groupsList_more;
                                                                                groupsList_more[currentGroupSelected.id].attributes[attributeIndex].value = e.target.value;
                        
                                                                                this.setState({
                                                                                    currentGroupSelected: currentGroupSelected,
                                                                                    groupsList_more: groupsList_more,
                                                                                });
                                                                            }}
                                                                        />
                                                                    :
                                                                        (
                                                                            attribute.type === "number"?
                                                                                <input
                                                                                    className='dashboard-option-content-input2'
                                                                                    type='number'
                                                                                    value={attribute.value}
                                                                                    min={attribute.min}
                                                                                    max={attribute.max}

                                                                                    onChange={(e) => {
                                                                                        let currentGroupSelected = this.state.currentGroupSelected;
                                                                                        let groupsList_more = this.state.groupsList_more;

                                                                                        let currentAttribute = groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex];

                                                                                        
                                                                                        currentAttribute.value = e.target.value;
                                                                                        this.setState({
                                                                                            currentGroupSelected: currentGroupSelected,
                                                                                            groupsList_more: groupsList_more,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            :
                                                                                null
                                                                        )
                                                                )
                                                        )
                                                    }

                                                    <div className='td_text text-align-center'>
                                                        <span className='text-align-center'
                                                            style={{
                                                                fontSize: "1.7em"
                                                            }}
                                                        >
                                                            {attribute.values && attribute.values.length}
                                                        </span>
                                                    </div>
                                                </td>

                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {
                                                        attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio" ?
                                                            <div className='dashboard-option-content-button'>
                                                                <button
                                                                    style={{
                                                                        backgroundColor: "#2f749200",
                                                                        color: "#5dc269",
                                                                        border: "1px solid #5dc26900",
                                                                        marginTop: "-15px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        let value = window.prompt( "nom de la valeur", "" );
                                                                        if( value !== null && value !== undefined && value !== "" ) {
                                                                            let currentGroupSelected = this.state.currentGroupSelected;
                                                                            let groupsList_more = this.state.groupsList_more;

                                                                            groupsList_more[currentGroupSelected.id].others.attributes[attributeIndex].values.push(value);

                                                                            this.setState({
                                                                                currentGroupSelected: currentGroupSelected,
                                                                                groupsList_more: groupsList_more,
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </td>

                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                            if( confirm ) {
                                                                let currentGroupSelected = this.state.currentGroupSelected;
                                                                let groupsList_more = this.state.groupsList_more;

                                                                groupsList_more[currentGroupSelected.id].others.attributes.splice(attributeIndex, 1);
                                                                this.setState({
                                                                    currentGroupSelected: currentGroupSelected,
                                                                    groupsList_more: groupsList_more,
                                                                });
                                                            }
                                                        }}
                                                        style={{
                                                            background: "transparent",
                                                            color: "red",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </table>
                            <div className='dashboard-option-content-button'>
                                <button
                                    onClick={() => {
                                        this._addAttribute();
                                    }}
                                    className="button-style-5"
                                >
                                    Ajouter un attribut
                                </button>
                            </div>
                            <br/>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Liste des permissions assignées au groupe ( décocher pour retirer )
                                    </span>
                                    <div>
                                        {
                                            this.state.permissionList.map((permission, index) => (
                                                this.state.currentGroupPermissionsList.includes(permission.id) &&
                                                <label key={permission.name + "/" + permission.id} htmlFor={permission.name + "/" + permission.id} className='add_content_checkBox_ add_content_checkBox_3'
                                                    style={{
                                                        backgroundColor: !this.state.permissionsDeletedList.includes(permission.id) ? "#769ea755" : "transparent"
                                                    }}
                                                >
                                                    <input id={permission.name + "/" + permission.id} name={permission.name + "/" + permission.id} type='checkbox' checked={!this.state.permissionsDeletedList.includes(Number(permission.id))}
                                                        onChange={(e)=>{
                                                            let permissionsDeletedList = this.state.permissionsDeletedList;
                                                            
                                                            if( e.target.checked === true ) {
                                                                permissionsDeletedList.splice( permissionsDeletedList.indexOf(Number(permission.id)), 1);
                                                            }
                                                            else {
                                                                permissionsDeletedList.push(Number(permission.id));
                                                            }
                                                            this.setState({
                                                                permissionsDeletedList: permissionsDeletedList,
                                                            });
                                                            return true;
                                                        }}
                                                    />
                                                    <label htmlFor={permission.name + "/" + permission.id}
                                                        style={{
                                                            fontSize: "0.8em",
                                                        }}
                                                    >
                                                        {
                                                            permission.name
                                                        }
                                                    </label>
                                                </label>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Ajouter des permissions
                                    </span>

                                    <br/>
                                    <div className="flex-row align-center">
                                        <input id={"selectAllPerm"} name={"selectAllPerm"} style={{marginRight: "5px"}} type="checkbox"
                                            onChange={(e)=>{
                                                let currentGroupPermissions = [];

                                                if( e.target.checked === true ) {
                                                    this.state.groupsPermissions.map((permissionGroup, index) => {
                                                        document.getElementById(permissionGroup.name).checked = true;
                                                        permissionGroup.permissions.map((permission, permIndex) => {
                                                            currentGroupPermissions.push(permission.id);
                                                        });
                                                    });

                                                    this.setState({
                                                        currentPermissionList: currentGroupPermissions,
                                                    });
                                                }
                                                else {
                                                    this.state.groupsPermissions.map((permissionGroup, index) => {
                                                        document.getElementById(permissionGroup.name).checked = false;
                                                    });
                                                    this.setState({
                                                        currentPermissionList: [],
                                                    });
                                                }
                                                return true;
                                            }}
                                        />
                                        <span className='font-size-1_1'>
                                            <label htmlFor={"selectAllPerm"}>
                                                Sélectionné toutes les permissions
                                            </label>
                                        </span>
                                    </div>

                                    <div className=''>
                                        {
                                            this.state.groupsPermissions.map((permissionGroup, index) => (
                                                <div className='flex-row justify-between align-center permissionGroup'>
                                                    <div className="flex-row align-center">
                                                        <input id={permissionGroup.name} name={permissionGroup.name} style={{marginRight: "5px"}} type="checkbox"
                                                            onChange={(e)=>{
                                                                // console.log( e.target.checked );
                                                                let currentPermissionList = this.state.currentPermissionList;
                                                                if( e.target.checked === true ) {
                                                                    permissionGroup.permissions.map((permission, permIndex) => {
                                                                        if( !currentPermissionList.includes(permission.id) && !this.state.currentGroupPermissionsList.includes(permission.id)) {
                                                                            currentPermissionList.push(permission.id);
                                                                        }
                                                                    });

                                                                    e.target.checked = true;
                                                                }
                                                                else {
                                                                    permissionGroup.permissions.map((permission, permIndex) => {
                                                                        currentPermissionList.splice( currentPermissionList.indexOf(permission.id), 1);
                                                                    });
                                                                    e.target.checked = false;
                                                                }

                                                                this.setState({
                                                                    currentPermissionList: currentPermissionList,
                                                                });
                                                                return true;
                                                            }}
                                                        />
                                                        <span className='font-size-1_1'>
                                                            <label htmlFor={permissionGroup.name}>
                                                                {permissionGroup.name} permissions
                                                            </label>
                                                        </span>
                                                    </div>
                                                
                                                    <div
                                                        className="registration_form_input flex-row flex-wrap"
                                                    >
                                                        {
                                                            permissionGroup.permissions.map((permission, permIndex) => (
                                                                !this.state.currentGroupPermissionsList.includes(permission.id) &&
                                                                <div className='permission-option'
                                                                    style={{
                                                                        backgroundColor: this.state.currentPermissionList.includes(permission.id) ? "#769ea755" : "transparent"
                                                                    }}
                                                                >
                                                                    {
                                                                        permission.name.split("view").length > 1 ?
                                                                            <div className='color-green'>
                                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"/>
                                                                                    <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                                                </svg>
                                                                            </div>
                                                                        :
                                                                            (
                                                                                permission.name.split("change").length > 1 ?
                                                                                    <div className='color-blue'>
                                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fillRule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                :
                                                                                    (
                                                                                        permission.name.split("add").length > 1 ?
                                                                                            <div className='color-blue'>
                                                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                                                                </svg>
                                                                                            </div>
                                                                                        :
                                                                                            (
                                                                                                permission.name.split("delete").length > 1 ?
                                                                                                    <div className='color-red'>
                                                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                :
                                                                                                    null
                                                                                            )
                                                                                    )
                                                                            )
                                                                    }

                                                                    <div className='permission-option-view'>
                                                                        <p>
                                                                            <label key={permission.name + "/" + permission.id} htmlFor={permission.name + "/" + permission.id} className='flex-row align-center'>
                                                                                <input id={permission.name + "/" + permission.id} name={permission.name + "/" + permission.id} type='checkbox' checked={this.state.currentPermissionList.includes(permission.id)}
                                                                                    onChange={(e)=>{
                                                                                        let currentPermissionList = this.state.currentPermissionList;
                                                                                        
                                                                                        if( e.target.checked === true ) {
                                                                                            currentPermissionList.push(permission.id);
                                                                                        }
                                                                                        else {
                                                                                            currentPermissionList.splice( currentPermissionList.indexOf(permission.id), 1);
                                                                                        }
                                                                                        this.setState({
                                                                                            currentPermissionList: currentPermissionList,
                                                                                        });
                                                                                        return true;
                                                                                    }}
                                                                                />
                                                                                <label htmlFor={permission.name + "/" + permission.id}
                                                                                    style={{
                                                                                        fontSize: "0.8em",
                                                                                        marginLeft: "5px",
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        permission.name
                                                                                    }
                                                                                </label>
                                                                            </label>
                                                                        </p>
                                                                        <div className='permission-option-view-bottom'></div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Liste des utilisateurs du groupe
                                    </span>
                                    <div>
                                        <table>
                                            <tr
                                                style={{
                                                    background: "#0A3764ee",
                                                    color: "#ffffff",
                                                }}
                                                className="table-head"
                                            >
                                                <th>
                                                    NOM De L'UTILISATEUR
                                                </th>
                                                <th className='text-align-center flex-wrap'
                                                    onClick={() => {
                                                        if( this.state.currentGroupSelected_accountsToRemove.length !== this.state.currentGroupSelected.users.length) {
                                                            let currentGroupSelected_accountsToRemove = [];
                                                            this.state.currentGroupSelected.users.map((group, index) => {
                                                                currentGroupSelected_accountsToRemove.push(group.id);
                                                            });
                                                            this.setState({
                                                                currentGroupSelected_accountsToRemove: currentGroupSelected_accountsToRemove,
                                                            });
                                                        }
                                                        else {
                                                            let currentGroupSelected_accountsToRemove = this.state.currentGroupSelected_accountsToRemove;
                                                            for (var i = 0; i < this.state.currentGroupSelected.users.length; i++) {
                                                                currentGroupSelected_accountsToRemove.splice( currentGroupSelected_accountsToRemove.indexOf(this.state.currentGroupSelected.users[i]) , 1);
                                                            }
                                                            this.setState({
                                                                currentGroupSelected_accountsToRemove: currentGroupSelected_accountsToRemove,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <div className='text-align-center flex-row justify-center align-center'>
                                                        RETIRER DE "{this.state.currentGroupSelected.name}"
                                                        <input type='checkbox' checked={this.state.currentGroupSelected.users.length === this.state.currentGroupSelected_accountsToRemove.length} style={{
                                                            marginLeft: "4px",
                                                        }}/>
                                                    </div>
                                                </th>
                                            </tr>

                                            {
                                                this.state.currentGroupSelected.users.map((user, index) => (
                                                    <tr
                                                        onClick={() => {
                                                            if (this.state.currentGroupSelected_accountsToRemove.includes(user.id)) {
                                                                let currentGroupSelected_accountsToRemove = this.state.currentGroupSelected_accountsToRemove;
                                                                for (var i = 0; i < currentGroupSelected_accountsToRemove.length; i++) {
                                                                    if (currentGroupSelected_accountsToRemove[i] === user.id) {
                                                                        currentGroupSelected_accountsToRemove.splice(i, 1);
                                                                        this.setState({
                                                                            currentGroupSelected_accountsToRemove: currentGroupSelected_accountsToRemove,
                                                                        });
                                                                        return true;
                                                                    }
                                                                }
                                                            }
                        
                                                            else {
                                                                let currentGroupSelected_accountsToRemove = this.state.currentGroupSelected_accountsToRemove;
                                                                currentGroupSelected_accountsToRemove.push(user.id);
                                                                this.setState({
                                                                    currentGroupSelected_accountsToRemove: currentGroupSelected_accountsToRemove,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <td>
                                                            {user.username}
                                                        </td>
                                                        <td className='text-center flex-row justify-center'>
                                                            <input type='checkbox' checked={this.state.currentGroupSelected_accountsToRemove.includes(user.id)} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                    {/* <div>
                                        {
                                            this.state.currentGroupSelected.users.map((user, index) => (
                                                <label className='add_content_checkBox_ add_content_checkBox_3'>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                    </svg>
                                                    {user.username}
                                                </label>
                                            ))
                                        }
                                    </div> */}
                                </div>
                            </div>
                            <br/>
                            <br/>

                            <div>
                                <div>
                                    <span>
                                        Ajouter des utilisateurs dans le groupe
                                    </span>
                                </div>
                                <div>
                                    <SearchInputComponent key_="username" printSelected={true} id_="id" data={this.state.searchResult.length > 0 ? this.state.searchResult : this.state.accountsList} currentSelected={this.state.currentGroupSelected_accountsToAdd} placeholder="Nom d'utilisateur"
                                        onChange={(value) => {
                                            let tab = [];
                                            this.state.accountsList.map((account, index) => {
                                                if (account.username.toLowerCase() === value.toLowerCase() ||
                                                    account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                                ) {
                                                    tab.push(account);
                                                }
                                            });

                                            this.setState({
                                                searchResult: tab,
                                            });
                                        }}
                                        onSelected={(selected) => {
                                            if (this.state.currentGroupSelected_accountsToAdd.includes(selected.id)) {
                                                let currentGroupSelected_accountsToAdd = this.state.currentGroupSelected_accountsToAdd;
                                                for (var i = 0; i < currentGroupSelected_accountsToAdd.length; i++) {
                                                    if (currentGroupSelected_accountsToAdd[i] === selected.id) {
                                                        currentGroupSelected_accountsToAdd.splice(i, 1);
                                                        this.setState({
                                                            currentGroupSelected_accountsToAdd: currentGroupSelected_accountsToAdd,
                                                        });
                                                        return true;
                                                    }
                                                }
                                            }

                                            else {
                                                let currentGroupSelected_accountsToAdd = this.state.currentGroupSelected_accountsToAdd;
                                                currentGroupSelected_accountsToAdd.push(selected.id);
                                                // console.log( currentGroupSelected_accountsToAdd );
                                                this.setState({
                                                    currentGroupSelected_accountsToAdd: currentGroupSelected_accountsToAdd,
                                                });
                                            }
                                        }}

                                        style={(elt) => {
                                            return {
                                                backgroundColor: this.state.currentGroupSelected_accountsToAdd.includes(elt.id) ? "#dc354555" : "transparent",
                                                color: this.state.currentGroupSelected_accountsToAdd.includes(elt.id) ? "#ffffff" : "#000000",
                                            }
                                        }}

                                        isChecked={(elt) => {
                                            return this.state.currentGroupSelected_accountsToAdd.includes(elt.id) ? true : false 
                                        }}
        
                                        icon={
                                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-plus-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    marginRight: "5px",
                                                }}
                                            >
                                                <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                            </svg>
                                        }
                                    />
                                </div>
                            </div>
                            <br/>
                            <br/>


                            <div>
                                <div>
                                    <span>
                                        Retirer des utilisateurs du groupe
                                    </span>
                                </div>
                                <div>
                                    <SearchInputComponent key_="username" printSelected={true} id_="id" data={this.state.searchResult2.length > 0 ? this.state.searchResult2 : this.state.accountsList} currentSelected={this.state.currentGroupSelected_accountsToRemove} placeholder="Nom d'utilisateur"
                                        onChange={(value) => {
                                            let tab = [];
                                            this.state.accountsList.map((account, index) => {
                                                if (account.username.toLowerCase() === value.toLowerCase() ||
                                                    account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                                ) {
                                                    tab.push(account);
                                                }
                                            });

                                            this.setState({
                                                searchResult2: tab,
                                            });
                                        }}
                                        onSelected={(selected) => {
                                            if (this.state.currentGroupSelected_accountsToRemove.includes(selected.id)) {
                                                let currentGroupSelected_accountsToRemove = this.state.currentGroupSelected_accountsToRemove;
                                                for (var i = 0; i < currentGroupSelected_accountsToRemove.length; i++) {
                                                    if (currentGroupSelected_accountsToRemove[i] === selected.id) {
                                                        currentGroupSelected_accountsToRemove.splice(i, 1);
                                                        this.setState({
                                                            currentGroupSelected_accountsToRemove: currentGroupSelected_accountsToRemove,
                                                        });
                                                        return true;
                                                    }
                                                }
                                            }

                                            else {
                                                let currentGroupSelected_accountsToRemove = this.state.currentGroupSelected_accountsToRemove;
                                                currentGroupSelected_accountsToRemove.push(selected.id);
                                                // console.log( currentGroupSelected_accountsToRemove );
                                                this.setState({
                                                    currentGroupSelected_accountsToRemove: currentGroupSelected_accountsToRemove,
                                                });
                                            }
                                        }}

                                        selectedStyle={{
                                            backgroundColor: "red",
                                        }}

                                        style={(elt) => {
                                            return {
                                                backgroundColor: this.state.currentGroupSelected_accountsToRemove.includes(elt.id) ? "#dc354555" : "transparent",
                                                color: this.state.currentGroupSelected_accountsToRemove.includes(elt.id) ? "#ffffff" : "#000000",
                                            }
                                        }}

                                        isChecked={(elt) => {
                                            return this.state.currentGroupSelected_accountsToRemove.includes(elt.id) ? true : false 
                                        }}
                                        icon={
                                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-plus-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    marginRight: "5px",
                                                }}
                                            >
                                                <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                            </svg>
                                        }
                                    />
                                </div>
                            </div>

                            <br/>
                            <br/>
                            <div className='flex-row justify-center'>
                                <button className='button-style-5'
                                    onClick={() => {
                                        if(this.state.chronogramUpdateType === "update") {
                                            this._updateGroup(this.state.currentGroupSelected);
                                        } else {
                                            this._addGroup__(this.state.currentGroupSelected)
                                        }
                                    }}
                                >
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

DashboardGroupsList = connect(mapStateToProps, null)(DashboardGroupsList);

export default DashboardGroupsList;
