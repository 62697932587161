// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class SearchSuggestionComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        
    }

    
    render() {
        return (
            <div className='suggestion'
                onClick={(e)=> {
                    this.props.onClick(e, this.props.suggestion.word)
                }}
            >
                <div className='suggestion-label'>
                    <span>
                        { this.props.suggestion.word }
                    </span>
                </div>
                <div className='suggestion-icon'>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                    </svg>
                </div>
                <div className='suggestion-info'>
                    <p>
                        { this.props.suggestion.word }
                    </p>
                    <div className='suggestion-info-bottom'></div>
                </div>
            </div>
        )
    }
}

export default SearchSuggestionComponent;