import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import ProjectCard from '../components/projectCard/projectCard.component';
import SearchInputComponent from '../components/searchInput.component/searchInput.component';




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/doc_list.style.css';
import './responsive.style/doc_list.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DocListScreen extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            filterResult: false,

            searchResult: [],
            categories: [],
            currentCategories: [],
            currentCategoriesIndex: [],

            years: [],
            currentYears: [],

            currentMonths: [],

            filterContent: {},
            searchResultsFilter: [],
            currentResultPage: 1,

            groupsList: [],
            currentGroupsList: [],
            groupSearchResult: [],

            authLevelsList: [],

            attribute_target: {},
            selectedList: [],
            selectedPreviewLink: [],
            currentCategory: {},
            systemKeywords: [],
            key_word: [],
            addKeywords: false,
            docPk: null,

            printBasket: false,
            basket: [],

            copied: false,

            current_doc: {},
            update_doc: false,

            usersList: {},

            accountsList: [],
            validAccountsList: [],
            unValidAccountsList: [],
            currentAccountsList: [],
            currentAccountListView: 0,
            searchResult_: [],
        }

        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.setState({
            isLoading: true,
        });
        

        this._get_users((accounts) => {
            let usersList = {};
            accounts.map((account, index) => {
                usersList[account.id] = account;
                if(index === accounts.length - 1) {
                    this.setState({
                        usersList: usersList,
                    }, () => {
                        this._get_all_categories(( categories )=>{
                            this.setState({
                                categories : categories,
                                currentCategory: categories.length > 0 ? categories[0] : {},
                            }, () => {
                                this._get_attribute("dates", (dates) => {
                                    let years = [];
                                    dates.map((date, index) => {
                                        if( !years.includes(date.year) ) {
                                            years.push( date.year );
                                        }
                                    });
                
                                    this.setState({
                                        years: years,
                                    }, () => {
                                        this._search_doc(null, "", false);
                                    });
                
                                    this._get_all_groups((groups) => {
                                        this.setState({
                                            groupsList: groups,
                                        });
                        
                                        this._getAllLevel((response)=>{
                                            // console.log( response );
                                            this.setState({
                                                authLevelsList: response.results,
                                                isLoading: false,
                                            });
                                        })
                                    });
                                });
                            });
                        });
                    });
                }
            });
        });
        this._get_users((accounts) => {
            this.setState({
                accountsList: accounts,
            }, ()=> {
                this._startGetUsersGroups(0, this.state.accountsList, (userList) => {
                    this.setState({
                        accountsList: userList,
                    }, () => {
                        this._startGetUsersPermissions(0, this.state.accountsList, (userList_) => {
                            this.setState({
                                accountsList: userList_,
                            }, () => {
                                let validAccountsList = [];
                                let unValidAccountsList = [];
                                this.state.accountsList.map((account, accountIndex) => {
                                    if( account.permissions.length > 0 ) {
                                        validAccountsList.push( account );
                                    }

                                    else {
                                        unValidAccountsList.push( account );
                                    }
                                });
                                this.setState({
                                    validAccountsList: validAccountsList,
                                    unValidAccountsList: unValidAccountsList,
                                });
                                this._get_all_groups((groups) => {
                                    this.setState({
                                        groupsList: groups,
                                        isLoading: false,
                                    }, () => {
                                        let accountsList = this.state.accountsList;

                                        for(let i=0; i<accountsList.length; i++) {
                                            let not_member_of = [];

                                            for(let j=0; j<this.state.groupsList.length; j++) {

                                                let inGroup = false;

                                                for(let k=0; k<accountsList[i].groups.length; k++) {
                                                    if( this.state.groupsList[j].id === accountsList[i].groups[k].id ) {
                                                        inGroup = true;
                                                        // return true;
                                                    }
                                                }

                                                if( inGroup === false ) {
                                                    let isContent = false;
                                                    
                                                    for(let l=0; l<not_member_of.length; l++) {
                                                        if(not_member_of[l].id === this.state.groupsList[j].id) {
                                                            isContent = true;
                                                        }
                                                    }
                                                    if( isContent === false ) {
                                                        not_member_of = not_member_of.concat( this.state.groupsList[j] );
                                                    }
                                                }
                                            }

                                            // console.log( accountsList[i].username, not_member_of );
                                            accountsList[i]["not_member_of"] = not_member_of;
                                            this.setState({
                                                accountsList: accountsList,
                                            });
                                        }
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _startGetUsersGroups( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_groups(user.id, (groups) => {
                let users = userList;
                users[index]["groups"] = groups.groups;
                if( index < userList.length - 1 ) {
                    this._startGetUsersGroups( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _startGetUsersPermissions( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_permissions(user.id, (permissions) => {
                let users = userList;
                users[index]["permissions"] = permissions.permissions;
                if( index < userList.length - 1 ) {
                    this._startGetUsersPermissions( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _get_user_groups(id, callback=()=>{}) {
        this._getUserGroups(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_groups(callback);
            })
        });
    }

    _get_user_permissions(id, callback=()=>{}) {
        this._getUserPermissions(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_permissions(callback);
            })
        });
    }

    _selectAllUsers() {
        let tab = [];
        if( this.state.currentAccountListView === 0 ) {
            this.state.accountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else if( this.state.currentAccountListView === 1 ) {
            this.state.validAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else {
            this.state.unValidAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        this.setState({
            currentAccountsList: tab,
        });
    }

    _unSelectAllUsers() {
        this.setState({
            currentAccountsList: [],
        });
    }
   

    _updateBasket( doc ) {
        let basket = this.state.basket;

        let res = this.tools._containObject( doc, basket, "pk" );

        if( !res ) {
            basket = basket.concat( doc );
        }

        else {
            basket.splice( res, 1 );
        }

        this.setState({
            basket: basket,
        }, () => {
            let text = [];
            basket.map((elt) => {
                text = text.concat( elt.summary + " : " + elt.file + "\n\n" );
                this.setState({
                    currentText: text,
                });
                return true;
            });
        });
    }

    _selectAllGroups(list) {
        let tab = [];

        list.map((group, index) => {
            tab.push(group.id);
        });

        this.setState({
            currentGroupsList: tab,
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            })
        });
    }

    _getAllLevel( callback=()=>{} ) {
        this._getLevel(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getAllLevel( callback );
            });
        });
    }

    _get_attribute(attribute, callback=()=>{}) {
        this._getAttribute(attribute, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_attribute(attribute, callback);
            })
        });
    }

    _setSelectedOption() {
        let optionsSelected = {};

        this.attributes.map((attribute) => {
            let attr = {
                name: attribute,
                content: [],
            };
            optionsSelected[attribute] = attr;
        });

        this.optionsSelected = optionsSelected;
    }

    _get_search_options(optionsList, index=0, callback=()=>{}) {
        this._getAttribute(optionsList[ index ], this.props.user.access, (response) => {
            if( response.status === 200 ) {
                this.setState({
                    [optionsList[index]] : response.data,
                });

                if( index < optionsList.length - 1 ) {
                    this._get_search_options( optionsList, index + 1, callback);
                }

                else {
                    callback();
                }

                // console.log( this.state );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_search_options(optionsList, index, callback);
            })
        });
    }

    _startSearchRequest( searchPath, page, callback=()=>{} ) {
        this._search(searchPath, page, this.props.user.access, ( response ) => {
            if( response.status === 200 ) {
                callback( response );
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._startSearchRequest( searchPath, page, callback );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    _search_doc(e, value, getMore=false, currentResultPage=1) {
        if( !getMore ) {
            // e.preventDefault();
            this.setState({
                filterContent: {},
                searchResultsFilter: [],
                currentResultPage: 1,
            });
        }
        
        this.setState({
            isLoading: true,
            currentSearch: value,
        });

        let startTime = new Date();

        let searchPath = "search=" + value;

        this.state.currentCategories.map((category) => {
            searchPath = searchPath + "&category=" + category;
        });

        this.state.currentMonths.map((month) => {
            searchPath = searchPath + "&month=" + month;
        });

        this.state.currentYears.map((year) => {
            searchPath = searchPath + "&year=" + year;
        })

        this.state.currentCategoriesIndex.map((categoryIndex, index) => {
            let category = this.state.categories[categoryIndex];
            category.fields.attributes.map((attribute, index) => {
                // console.log( this.optionsSelected[category.name + "/" + attribute.name] );
                if( this.optionsSelected[category.name + "/" + attribute.name] ) {
                    let data = "";
                    this.optionsSelected[category.name + "/" + attribute.name].content.map((elt, index) => {
                        if( elt.isChecked ) {
                            if( data !== "" ) {
                                data = data + ',"' +  elt.data + '"';
                            }else {
                                data = data + '"' +  elt.data + '"';
                            }
                        }
                    });
                    data = "[" + data + "]";
                    searchPath = searchPath + "&data={\"" + attribute.name + "\":" + data + "}" ;
                }
            })
        })

        // console.log( searchPath );
        this._startSearchRequest( searchPath, currentResultPage, ( response ) => {

            // console.log( response.data );
            if( response.data.count > 0 ) {
                let searchResult = [];

                if( !getMore ) {
                    searchResult = response.data.results;
                }else {
                    searchResult = this.state.searchResult;
                    searchResult = searchResult.concat( response.data.results );
                }
                
                // console.log( searchResult );

                let flt_data = [];
                
                let data_year = {
                    name: "Années",
                    content: [],
                };

                let data_categories = {
                    name: "Catégories",
                    content: [{
                        name: "Tout",
                        number_result: 0,
                        content: [],
                    }],
                };

                searchResult.map((result, index) => {
                    if( data_year.content.length === 0 ) {
                        data_year.content.push(
                            {
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            },
                            {
                                name: "Tout",
                                number_result: 1,
                                content: [index],
                            }
                        );
                    }else {
                        let isContain = false;
                        for( var i=0; i<data_year.content.length; i++) {
                            if( data_year.content[i].name === result.year ) {
                                data_year.content[i].number_result =  data_year.content[i].number_result + 1;
                                data_year.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            data_year.content.push({
                                name: result.year,
                                number_result: 1,
                                content: [index],
                            });
                        }
                    }


                    /**les categories */

                    if( data_categories.content.length === 0 ) {
                        this.state.categories.map((category) => {
                            if( category.pk === result.category ) {
                                data_categories.content.push(
                                    {
                                        name: category.name,
                                        pk: category.pk,
                                        number_result: 1,
                                        content: [index],
                                    }
                                );
                                data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                                data_categories.content[0].content.push(index);
                                return true;
                            }
                        });
                    }else {
                        let isContain = false;
                        for(let i=0; i<data_categories.content.length; i++) {
                            if( data_categories.content[i].pk === result.category ) {
                                data_categories.content[i].number_result =  data_categories.content[i].number_result + 1;
                                data_categories.content[i].content.push(index);
                                isContain = true;
                            }
                        }

                        if( isContain === false ) {
                            this.state.categories.map((category) => {
                                if( category.pk === result.category ) {
                                    data_categories.content.push(
                                        {
                                            name: category.name,
                                            pk: category.pk,
                                            number_result: 1,
                                            content: [index],
                                        }
                                    );
                                    return true;
                                }
                            });
                        }

                        data_categories.content[0].number_result =  data_categories.content[0].number_result + 1;
                        data_categories.content[0].content.push(index);
                    }
                });

                flt_data.push( data_year );
                flt_data.push( data_categories );

                // console.log( flt_data );

                this.setState({
                    resultSortedByDate: this.tools._sortByKey(searchResult, 'year'),
                    noResultFound: false,
                    allResultsGet: (response.data.next !== null && response.data.next !== undefined ) ? false : true,
                });
                
                let endTime = new Date();

                this.setState({
                    search: true,
                    searchResult: searchResult,
                    searchResultsFilter: flt_data,
                    searchTime: ( endTime - startTime ) / 1000,
                },() => {
                    this._getAllDocsKeywords(0,this.state.searchResult, (newList) => {
                        this.setState({
                            searchResult: newList,
                        }, () => {
                            if( response.data.next !== null && response.data.next !== undefined ) {
                                this._search_doc(e, value, true, currentResultPage + 1);
                            }else {
                                // console.log( this.state.searchResult );
                                this.setState({
                                    isLoading: false,
                                });
                            }
                        });
                    });
                });
            }

            else {
                let endTime = new Date();

                this.setState({
                    search: true,
                    isLoading: false,
                    noResultFound: true,
                    searchTime: ( endTime - startTime ) / 1000,
                },() => {
                    window.scrollTo(0, 0);
                });
            }
        });
    }

    /**
     * On met a jour la session utilisateur
     */
    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _getAllDocsKeywords( index=0, docList, callback=()=>{} ) {
        if( docList.length > 0 ) {
            let doc = docList[index];
            this._getDocKey(doc.id, (keywords) => {
                let docs = docList;
                docs[index]["keywords"] = keywords;
                // console.log( index );
                if( index < docList.length - 1 ) {
                    this._getAllDocsKeywords( index + 1, docs, callback );
                }else {
                    callback(docs);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _getDocKey(id, callback=()=>{}) {
        this._getKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocKey(id, callback);
            })
        });
    }

    _openOrCloseFilterMenu( close = false ) {
        if( close ) {
            this.setState({
                filterResult: false
            });
        }

        else { 
            this.setState({
                filterResult: !this.state.filterResult
            });
        }
    }

    _openDoc( doc ) {
        window.open("/view-doc:?doc=" + encodeURIComponent(doc.url) + "&pk=" + encodeURIComponent(doc.id) + "&output=embed", '_blank', 'location=yes, height=570, width=620, scrollbars=yes,status=yes');
    }

    _printBasket() {
        this.setState({
            printBasket: !this.state.printBasket,
        });
    }


    _delete_doc(id, callback=()=>{}) {
        // console.log(id);
        this._deleteDoc(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                callback(response.data);
            }
        }, (error) => {
            // console.log(error.response);
            this._manageError( error, () => {
                this._delete_doc(id, callback);
            });
        });
    }

    _get_on_keyword(id, callback=()=>{}) {
        this._getOnKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_on_keyword(id, callback);
            });
        });
    }

    _delete_on_keyword(id, callback=()=>{}) {
        this._deleteOnKeyword(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._delete_on_keyword(id, callback);
            });
        });
    }

    _update_on_keyword(id, data, callback=()=>{}) {
        this._updateOnKeyword(id, data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            // console.log( error.response );
            this._manageError( error, () => {
                this._update_on_keyword(id, callback);
            });
        });
    }


    _getAllKeywordsForCurrentDoc(list=[], index=0, callback=()=>{}) {
        this._get_on_keyword( this.state.current_doc.key_words[index], (keyword)=>{
            list = list.concat(keyword);
            if( index < this.state.current_doc.key_words.length - 1 ) {
                this._getAllKeywordsForCurrentDoc(list, index+1, callback);
            }
            else {
                callback(list);
            }
        })
    }

    printUpdateDoc() {
        this.setState({
            update_doc: !this.state.update_doc,
        });
    }

    _changeCurrentCategory(pk, callback=()=>{}) {
        let categories = this.state.categories;

        for( let i=0; i<categories.length; i++ ) {
            if( categories[i].pk === Number(pk) ) {
                // console.log(  categories[i] );
                let current = categories[i];

                if( this.state.current_doc.category === pk ) {
                    current.fields.attributes.map((attribute, index) => {
                        attribute.value = this.state.current_doc.data[attribute.name];
                    });
                }

                // console.log( current );
                this.setState({
                    currentCategory: current,
                }, () => {
                    callback();
                });
                
                return true;
            }
        }
    }

    _submitDoc(e) {

        e.preventDefault();


        let errorMessage = "Veuillez renseigner les informations suivantes:\n\n";
        let numberMessage = 1;
        let error = false;

        this.state.currentCategory.fields.attributes.map((attribute, index) => {
            if( attribute.required ) {
                if( attribute.value.length <= 0 || attribute.value === '' ) {
                    error = true;
                    errorMessage = errorMessage + numberMessage + ": " + attribute.name + "\n";
                    numberMessage ++;
                }
            }
        });
        

        if( error === true ) {
            alert( errorMessage );
            return false;
        }else {

            this.setState({
                authAction: (password) => {
                    this.setState({
                        isLoading: true,
                    });

                    let formData = new FormData();

                    // console.log( document.forms["submitDocForm"]["month"].value );

                    // formData.append("img", (document.forms["submitDocForm"]["img"].value !== '' && document.forms["submitDocForm"]["img"].value !== null && document.forms["submitDocForm"]["img"].value !== undefined) ? document.forms["submitDocForm"]["img"].files[0] : null);
                    // formData.append("file", this.state.current_doc.file);
                    formData.append("title", document.forms["submitDocForm"]["title"].value);
                    formData.append("summary", document.forms["submitDocForm"]["summary"].value);
                    formData.append("year", Number(document.forms["submitDocForm"]["year"].value));
                    formData.append("month", Number(document.forms["submitDocForm"]["month"].value));

                    formData.append("authlev", Number(document.forms["submitDocForm"]["type"].value));

                    // if( Number(document.forms["submitDocForm"]["type"].value) === 0 ) {
                        
                    // }

                    // else {
                    //     formData.append("authlev", 1);
                    // }
                    // formData.append("validated_at", document.forms["submitDocForm"]["validated_at"].value);
                    formData.append("category", this.state.currentCategory.pk);

                    let data = {};
                    this.state.currentCategory.fields.attributes.map((attribute, index) => {
                        if( attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio" ) {
                            data[attribute.name] = attribute.value;
                        }
                        else {
                            data[attribute.name] = [attribute.value];
                        }
                    });

                    if(this.state.currentCategory.fields.objects) {
                        this.state.currentCategory.fields.objects.map((attribute, index) => {
                            if( attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio" ) {
                                data[attribute.name] = attribute.value;
                            }
                            else {
                                data[attribute.name] = [attribute.value];
                            }
                        });
                    }

                    // console.log( data );
                    // return 0;

                    let categoryData = JSON.stringify(data);

                    // console.log( categoryData );

                    formData.append("data", categoryData);


                    this._startUpdateDocument(this.state.current_doc.id, formData, (response) => {
                        // console.log( response.data );
                        let editors = {
                            "editors": this.state.currentAccountsList,
                            "owners": [response.data.owner],
                        };
                        this._setDocEditors(response.data.id, editors, this.props.user.access, (response) => {
                            return true;
                        });
                        this.setState({
                            isLoading: false,
                        });
                        alert("Modifications enregistrées avec succès");
                        window.location.reload();
                    });
                }
            }, () => {
                this._openOrCloseAuth();
            });
        }

    }

    _startUpdateDocument(id, data, callback=()=>{}) {
        this._updateDocument(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 200 ) {
                callback(response);
            }
        }, (error) => {
            console.log(  error.response.data );
            if( error.response && error.response.data && error.response.data.error_message && error.response.data.error_message[0].split("duplicate key value violates unique constraint").length > 1) {
                this.setState({
                    isLoading: false,
                });
                alert("Modifications enregistrées avec succès");
                window.location.reload();
            }
            this._manageError( error, () => {
                this._startUpdateDocument(data, callback);
            })
        });
    }

    _startAddKeywordForDoc(id, data, callback=()=>{}) {
        this._addKeyword(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 201 ) {
                callback();
            }
        }, (error) => {
            this._manageError( error, () => {
                this._startAddKeywordForDoc(id, data, callback);
            })
        });
    }

    _addKeywordsForDoc(docId, keyword) {
        this.setState({
            isLoading: true,
        });

        this._startAddKeywordForDoc(docId, JSON.stringify([keyword]), () => {
            alert("Mot-clé ajouté avec succès");
            this.setState({
                isLoading: false,
            });
        });
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div>
                    {
                        this.state.categories.map((category, index) => (
                            <div>
                                <div className='search-result-section-filter-list-title'>
                                    <span>
                                        { category.name }
                                    </span>
                                </div>
                                <div className='search-result-section-filter-list-content flex-row flex-wrap justify-center'>
                                    {
                                        this.state.searchResult.map((result, resultIndex) => (
                                            category.pk === result.category &&
                                                <div className='search-result-section-results-one' key={"doc" + index + resultIndex}>
                                                    <ProjectCard
                                                        project={result}
                                                        isModifiable={true}
                                                        owner={this.state.usersList[result.owner] ? this.state.usersList[result.owner] : ""}
                                                        deleteACtion={() => {
                                                            // if( window.confirm("Voulez-vous vraiment supprimer ce document?") ) {
                                                            //     this.setState({
                                                            //         isLoading: true,
                                                            //     });
                                                            //     this._delete_doc( result.id, () => {
                                                            //         this.setState({
                                                            //             isLoading: false,
                                                            //         });
                                                            //         this.componentDidMount();
                                                            //     } );
                                                            // }

                                                            if( window.confirm("Voulez-vous vraiment supprimer ce document?") ) {
                                                                this.setState({
                                                                    authAction: (password) => {
                                                                        this.setState({
                                                                            isLoading: true,
                                                                        });
                                                                        this._delete_doc( result.id, () => {
                                                                            this.setState({
                                                                                isLoading: false,
                                                                            });
                                                                            this._openOrCloseAuth();
                                                                            this.componentDidMount();
                                                                        } );
                                                                    }
                                                                }, () => {
                                                                    this._openOrCloseAuth();
                                                                });
                                                            }
                                                        }}
                                                        updateAction={()=> {
                                                            
                                                            this.setState({
                                                                current_doc: result,
                                                                currentAccountsList: result.editors,
                                                            }, () => {
                                                                // console.log(this.state.currentCategory);
                                                                this._changeCurrentCategory(this.state.current_doc.category, () => {
                                                                    this.state.currentCategory.fields.attributes.map((attribute, index) => {
                                                                        if( this.state.current_doc.data[attribute.name] ) {
                                                                            attribute.value = this.state.current_doc.data[attribute.name];
                                                                        }
                                                                    });
    
                                                                    if(this.state.currentCategory.fields.objects) {
                                                                        this.state.currentCategory.fields.objects.map((attribute, index) => {
                                                                            // console.log(attribute);
                                                                            if( this.state.current_doc.data[attribute.name] ) {
                                                                                attribute.value = this.state.current_doc.data[attribute.name];
                                                                            }
                                                                        });
                                                                    }
    
                                                                    this._getAllKeywordsForCurrentDoc([], 0, (list)=>{
                                                                        this.setState({
                                                                            systemKeywords: list,
                                                                        });
                                                                    });
                                                                    // console.log( this.state.current_doc.category );
                                                                    
                                                                    this.printUpdateDoc();
                                                                });
                                                            });
                                                        }}
                                                        link={"/view-doc-info:?doc=" + encodeURIComponent(result.id) + "&cover=" + encodeURIComponent(result.img) + "&owner=" + encodeURIComponent(this.state.usersList[result.owner] ? this.state.usersList[result.owner].username : "")}
                                                    />
                                                </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div>
                    {
                        this.state.update_doc &&
                        <div className='alertSection'>
                            <div className='registration_form_inpContainer registration_form_cover alertSection_content'>
                                <div className='flex-row justify-end'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        onClick={(e) => {
                                            this.printUpdateDoc();
                                        }}
                                    >
                                        <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                        <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                    </svg>
                                </div>
                                <div className='profile_container_title color_primary'>
                                    <span>
                                        Modifier le document
                                    </span>
                                </div>
                                
                                <div className='sectionBody'>
                                    <form className='registration_form_fm'
                                        action="#"
                                        target="_self"
                                        method="post"
                                        name="submitDocForm"
                                        style={{background: "transparent"}}
                                        onSubmit = {(e) => {
                                            this._submitDoc(e);
                                        }}
                                    >

                                        {
                                            this.state.currentCategory.pk &&
                                                <div className='registration_form_inpContainer registration_form_cover'>
                                                    <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                        <label>
                                                            Catégorie du document :
                                                        </label>
                                                    </div>
                                                    <select name='doc_category' className='upload_doc_select' value={this.state.currentCategory.pk}
                                                        style={{marginTop: "10px"}}
                                                        onChange={(e) => {
                                                            this._changeCurrentCategory(e.target.value);
                                                        }}
                                                    >
                                                        {
                                                            this.state.categories.map((category, categoryIndex) => (
                                                                <option value={category.pk} key={"category-" + category.name + "-" + category.pk }>
                                                                    {category.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                        }

                                        <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Niveau du document :
                                                </label>
                                            </div>
                                            <div className='registration_form_input'>
                                                <select name="type" className='upload_doc_select' defaultValue={this.state.current_doc.authlev}>
                                                    {
                                                        this.state.authLevelsList.map((level, index) => (
                                                            <option key={level.name + index} value={level.id}>
                                                                { level.name }
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Titre du document :
                                                </label>
                                            </div>
                                            <div className='registration_form_input'>
                                                <input required className='input-style-2 input-style-3' defaultValue={this.state.current_doc.title} type='text' name='title'/>
                                            </div>
                                        </div>

                                        {/* <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Image de garde du document :
                                                </label>
                                            </div>
                                            <div className='registration_form_input'>
                                                <input type='file' name='img' accept="image/*,.gif,.GIF,.Gif" className='registration_form_input_file'/>
                                            </div>
                                        </div> */}

                                        <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Résumé synthétique :
                                                </label>
                                            </div>
                                            <div className='registration_form_input'>
                                                <textarea defaultValue={this.state.current_doc.summary} name='summary' className='registration_form_input_area'/>
                                            </div>
                                        </div>

                                        <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Année de publication :
                                                </label>
                                            </div>
                                            <div className='registration_form_input'>
                                                <input required className='input-style-2 input-style-3' type='number' name='year' defaultValue={Number(new Date().getFullYear())} min={1500} max={ Number(new Date().getFullYear()) }/>
                                            </div>
                                        </div>

                                        <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Mois de publication :
                                                </label>
                                            </div>
                                            <div className='registration_form_input'>
                                                <select required name="month" className='upload_doc_select' defaultValue={this.state.current_doc.month}>
                                                    {
                                                        this.month.map((elt, index) => (
                                                            <option key={elt.name + index} value={elt.number}>
                                                                { elt.name }
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        {/* <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Date de validation du document :
                                                </label>
                                            </div>
                                            <div className='registration_form_input'>
                                                <input className='input-style-2 input-style-3' type='date' name='validated_at' min={1500} max={ Number(new Date().getFullYear()) }/>
                                            </div>
                                        </div> */}

                                        <div>
                                            {
                                                this.state.currentCategory.name &&
                                                this.state.currentCategory.fields.attributes.map((attribute, attrIndex) => (
                                                    <div className='registration_form_inpContainer registration_form_cover' key={attribute + "-" + attrIndex}>
                                                        <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                            <label>
                                                                {attribute.name}
                                                            </label>
                                                        </div>
                                                        <div>
                                                            {
                                                                attribute.type === "text-multi-line" ?
                                                                    <div>
                                                                        <textarea className='upload_doc_select' type='text' value={attribute.value}
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "200px",
                                                                                marginTop: "10px",
                                                                                resize: "none",
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let currentCategory = this.state.currentCategory;
                                                                                currentCategory.fields.attributes[attrIndex].value = e.target.value;

                                                                                this.setState({
                                                                                    currentCategory: currentCategory,
                                                                                }, () => {
                                                                                    e.target.focus();
                                                                                });
                                                                            }}
                                                                        ></textarea>
                                                                    </div>
                                                                :
                                                                (
                                                                    attribute.type === "list" ?
                                                                        (
                                                                            attribute.multi_choice === false ?
                                                                                <select required value={attribute.value[0]} className='upload_doc_select' key={this._generateID(36)}
                                                                                
                                                                                    onChange={(e) => {
                                                                                        let currentCategory = this.state.currentCategory;
                                                                                        currentCategory.fields.attributes[attrIndex].value[0] = e.target.value;

                                                                                        this.setState({
                                                                                            currentCategory: currentCategory,
                                                                                        });
                                                                                    }}>
                                                                                    <option selected disabled>
                                                                                        choisir
                                                                                    </option>
                                                                                    {
                                                                                        attribute.values.map((value, index) => (
                                                                                            <option key={this._generateID(36)} value={value}>
                                                                                                { value }
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            :
                                                                                <div className='registration_form_input'>
                                                                                    {
                                                                                        attribute.values.map((value, index) => (
                                                                                            <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                                <input id={attribute.name + "/" + value} name={attribute.name + "/" + value} type='checkbox' checked={attribute.value.includes(value)}
                                                                                                    onChange={(e)=>{
                                                                                                        let currentCategory = this.state.currentCategory;
                                                                                                        
                                                                                                        if( e.target.checked === true ) {
                                                                                                            currentCategory.fields.attributes[attrIndex].value.push(value);
                                                                                                        }
                                                                                                        else {
                                                                                                            currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            currentCategory: currentCategory,
                                                                                                        });
                                                                                                        return true;
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor={attribute.name + "/" + value}>
                                                                                                    {value}
                                                                                                </label>
                                                                                            </label>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                        )
                                                                    :
                                                                    (
                                                                        attribute.type === "date" ?
                                                                            <div>
                                                                                <input className='upload_doc_select' type='date' value={attribute.value}
                                                                                    onChange={(e) => {
                                                                                        let currentCategory = this.state.currentCategory;
                                                                                        currentCategory.fields.attributes[attrIndex].value = e.target.value;
        
                                                                                        this.setState({
                                                                                            currentCategory: currentCategory,
                                                                                        }, () => {
                                                                                            e.target.focus();
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        :
                                                                            (
                                                                                attribute.type === "text-one-line" ?
                                                                                    <div>
                                                                                        <input className='upload_doc_select' type='text' placeholder='Saisir du texte' value={attribute.value}
                                                                                            onChange={(e) => {
                                                                                                let currentCategory = this.state.currentCategory;
                                                                                                currentCategory.fields.attributes[attrIndex].value = e.target.value;
                
                                                                                                this.setState({
                                                                                                    currentCategory: currentCategory,
                                                                                                }, () => {
                                                                                                    e.target.focus();
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                :
                                                                                    (
                                                                                        attribute.type === "number" ?
                                                                                            <div>
                                                                                                <input className='upload_doc_select' type='number' placeholder='Saisir une valeur numérique' value={Number(attribute.value)}
                                                                                                    min={attribute.min}
                                                                                                    max={attribute.max}
                                                                                                    onChange={(e) => {
                                                                                                        // let currentCategory = this.state.currentCategory;
                                                                                                        // currentCategory.fields.attributes[attrIndex].value[0] = e.target.value;
                        
                                                                                                        // this.setState({
                                                                                                        //     currentCategory: currentCategory,
                                                                                                        // }, () => {
                                                                                                        //     e.target.focus();
                                                                                                        // });

                                                                                                        let currentCategory = this.state.currentCategory;
                                                                                                        currentCategory.fields.attributes[attrIndex].value = e.target.value

                                                                                                        this.setState({
                                                                                                            currentCategory: currentCategory,
                                                                                                        });

                                                                                                        // let min = currentCategory.fields.attributes[attrIndex].min;
                                                                                                        // let max = currentCategory.fields.attributes[attrIndex].max;

                                                                                                        // // console.log( min, max );

                                                                                                        // let isValid = true;

                                                                                                        // if( e.target.value !== '' ) {
                                                                                                        //     let value = Number(e.target.value);

                                                                                                        //     if( min !== undefined ) {
                                                                                                        //         if( value >= Number(min) ) {
                                                                                                        //             currentCategory.fields.attributes[attrIndex].value = value;
                                                                                                        //         }
                                                                                                        //         else {
                                                                                                        //             isValid = false;
                                                                                                        //         }
                                                                                                        //         // currentCategory.fields.attributes[attrIndex].value = value;
                                                                                                        //     }else {
                                                                                                        //         currentCategory.fields.attributes[attrIndex].value = e.target.value;
                                                                                                        //     }

                                                                                                        //     if( max !== undefined ) {
                                                                                                        //         if( value <= Number(max) ) {
                                                                                                        //             currentCategory.fields.attributes[attrIndex].value = value;
                                                                                                        //         }
                                                                                                        //         else {
                                                                                                        //             isValid = false;
                                                                                                        //         }
                                                                                                        //     }else {
                                                                                                        //         currentCategory.fields.attributes[attrIndex].value = e.target.value;
                                                                                                        //     }
                                                                                                        // }

                                                                                                        // else {
                                                                                                        //     currentCategory.fields.attributes[attrIndex].value = e.target.value;
                                                                                                        // }
                                                                                                        

                                                                                                        // if( isValid ) {
                                                                                                        //     this.setState({
                                                                                                        //         currentCategory: currentCategory,
                                                                                                        //     }, () => {
                                                                                                        //         e.target.focus();
                                                                                                        //     });
                                                                                                        // }
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        :
                                                                                            attribute.type === "select" ?
                                                                                                (
                                                                                                    <select required value={attribute.value[0]} className='upload_doc_select' key={attribute.name}
                                                                                                        
                                                                                                        onChange={(e) => {
                                                                                                            let currentCategory = this.state.currentCategory;
                                                                                                            currentCategory.fields.attributes[attrIndex].value[0] = e.target.value;
                                
                                                                                                            this.setState({
                                                                                                                currentCategory: currentCategory,
                                                                                                            });
                                                                                                        }}>
                                                                                                        <option selected disabled>
                                                                                                            choisir
                                                                                                        </option>
                                                                                                        {
                                                                                                            attribute.values.map((value, index) => (
                                                                                                                <option key={this._generateID(36)} value={value}>
                                                                                                                    { value }
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </select>
                                                                                                )
                                                                                            :
                                                                                                (
                                                                                                    attribute.type === "checkbox" ?
                                                                                                        <div className='registration_form_input'>
                                                                                                            {
                                                                                                                attribute.values.map((value, index) => (
                                                                                                                    <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                                                        <input id={attribute.name + "/" + value} name={attribute.name + "/" + value} type='checkbox' checked={attribute.value.includes(value)}
                                                                                                                            onChange={(e)=>{
                                                                                                                                let currentCategory = this.state.currentCategory;
                                                                                                                                
                                                                                                                                if( e.target.checked === true ) {
                                                                                                                                    currentCategory.fields.attributes[attrIndex].value.push(value);
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                                                }
                        
                                                                                                                                this.setState({
                                                                                                                                    currentCategory: currentCategory,
                                                                                                                                });
                                                                                                                                return true;
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <label htmlFor={attribute.name + "/" + value}>
                                                                                                                            {value}
                                                                                                                        </label>
                                                                                                                    </label>
                                                                                                                ))
                                                                                                            }
                                                                                                        </div>
                                                                                                        :
                                                                                                            (
                                                                                                                attribute.type === "radio" ?
                                                                                                                    <div className='registration_form_input'>
                                                                                                                        {
                                                                                                                            attribute.values.map((value, index) => (
                                                                                                                                <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                                                                    <input id={attribute.name + "/" + value} name={attribute.name} type='radio' checked={attribute.value.includes(value)}
                                                                                                                                        onChange={(e)=>{
                                                                                                                                            let currentCategory = this.state.currentCategory;
                                                                                                                                            
                                                                                                                                            if( e.target.checked === true ) {
                                                                                                                                                currentCategory.fields.attributes[attrIndex].value = [value];
                                                                                                                                            }
                                                                                                                                            // else {
                                                                                                                                            //     currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                                                            // }

                                                                                                                                            // console.log( currentCategory.fields.attributes[attrIndex].value );
                                                
                                                                                                                                            this.setState({
                                                                                                                                                currentCategory: currentCategory,
                                                                                                                                            });
                                                                                                                                            return true;
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <label htmlFor={attribute.name + "/" + value}>
                                                                                                                                        {value}
                                                                                                                                    </label>
                                                                                                                                </label>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                :
                                                                                                            null
                                                                                                    )
                                                                                                    
                                                                                        )
                                                                                    )
                                                                            )
                                                                    )
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div>
                                            {
                                                this.state.currentCategory.name && this.state.currentCategory.fields.objects &&
                                                this.state.currentCategory.fields.objects.map((attribute, attrIndex) => (
                                                    <div className='registration_form_inpContainer registration_form_cover' key={attribute + "-" + attrIndex}>
                                                        <div className='registration_form_label  font-size-1_2 bold letter-spacing-1'>
                                                            <label>
                                                                {attribute.name}
                                                            </label>
                                                        </div>
                                                        <br/>
                                                        <div>
                                                            {
                                                            
                                                                (
                                                                    attribute.type === "list" ?
                                                                        (
                                                                            attribute.multi_choice === false ?
                                                                                <select required className='upload_doc_select' key={this._generateID(36)}
                                                                                
                                                                                    onChange={(e) => {
                                                                                        let currentCategory = this.state.currentCategory;
                                                                                        currentCategory.fields.attributes[attrIndex].value[0] = e.target.value;

                                                                                        this.setState({
                                                                                            currentCategory: currentCategory,
                                                                                        });
                                                                                    }}>
                                                                                    <option selected disabled>
                                                                                        choisir
                                                                                    </option>
                                                                                    {
                                                                                        attribute.values.map((value, index) => (
                                                                                            <option key={(value.name ? value.name : ( value.username ? value.username : null)) + index}>
                                                                                                { value.name ? value.name : ( value.username ? value.username : null) }
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            :
                                                                                <div className='registration_form_input'>
                                                                                    {
                                                                                        attribute.values.map((value, index) => (
                                                                                            <label key={attribute.name + "/" + value} htmlFor={attribute.name + "/" + value} className='add_content_checkBox_'>
                                                                                                <input id={attribute.name + "/" + value} name={attribute.name + "/" + value} type='checkbox' checked={attribute.value.includes(value)}
                                                                                                    onChange={(e)=>{
                                                                                                        let currentCategory = this.state.currentCategory;
                                                                                                        
                                                                                                        if( e.target.checked === true ) {
                                                                                                            currentCategory.fields.attributes[attrIndex].value.push(value);
                                                                                                        }
                                                                                                        else {
                                                                                                            currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            currentCategory: currentCategory,
                                                                                                        });
                                                                                                        return true;
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor={(value.name ? value.name : ( value.username ? value.username : null)) + "/" + index}>
                                                                                                    {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                                </label>
                                                                                            </label>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                        )
                                                                    :
                                                                        (
                                                                        
                                                                                (
                                                                                        (
                                                                                                attribute.type === "select" ?
                                                                                                    (
                                                                                                        <select value={attribute.value[0]} required className='upload_doc_select'
                                                                                                            
                                                                                                            onChange={(e) => {
                                                                                                                let currentCategory = this.state.currentCategory;
                                                                                                                currentCategory.fields.objects[attrIndex].value[0] = e.target.value;
                                    
                                                                                                                this.setState({
                                                                                                                    currentCategory: currentCategory,
                                                                                                                });
                                                                                                            }}>
                                                                                                            <option selected disabled>
                                                                                                                choisir
                                                                                                            </option>
                                                                                                            {
                                                                                                                attribute.values.map((value, index) => (
                                                                                                                    <option value={value.name ? value.name : ( value.username ? value.username : null)} key={(value.name ? value.name : ( value.username ? value.username : null))+index}>
                                                                                                                        { value.name ? value.name : ( value.username ? value.username : null) }
                                                                                                                    </option>
                                                                                                                ))
                                                                                                            }
                                                                                                        </select>
                                                                                                    )
                                                                                                :
                                                                                                    (
                                                                                                        attribute.type === "checkbox" ?
                                                                                                                <div className='registration_form_input'>
                                                                                                                    {
                                                                                                                        attribute.values.map((value, index) => (
                                                                                                                            <label key={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} className='add_content_checkBox_'>
                                                                                                                                <input id={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} name={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} type='checkbox' checked={attribute.value.includes(value.name ? value.name : ( value.username ? value.username : null))}
                                                                                                                                    onChange={(e)=>{
                                                                                                                                        let currentCategory = this.state.currentCategory;
                                                                                                                                        let val = value.name ? value.name : ( value.username ? value.username : null);
                                                                                                                                        
                                                                                                                                        if( e.target.checked === true ) {
                                                                                                                                            currentCategory.fields.objects[attrIndex].value.push(val);
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            currentCategory.fields.objects[attrIndex].value.splice( currentCategory.fields.objects[attrIndex].value.indexOf(val), 1);
                                                                                                                                        }
                                            
                                                                                                                                        this.setState({
                                                                                                                                            currentCategory: currentCategory,
                                                                                                                                        });
                                                                                                                                        return true;
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                                <label htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))}>
                                                                                                                                    {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                                                                </label>
                                                                                                                            </label>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </div>
                                                                                                            :
                                                                                                                (
                                                                                                                    attribute.type === "radio" ?
                                                                                                                        <div className='registration_form_input'>
                                                                                                                            {
                                                                                                                                attribute.values.map((value, index) => (
                                                                                                                                    <label key={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} className='add_content_checkBox_'>
                                                                                                                                        <input id={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))} name={attribute.name} type='radio' checked={attribute.value.includes(value.name ? value.name : ( value.username ? value.username : null))}
                                                                                                                                            onChange={(e)=>{
                                                                                                                                                let currentCategory = this.state.currentCategory;
                                                                                                                                                
                                                                                                                                                if( e.target.checked === true ) {
                                                                                                                                                    currentCategory.fields.objects[attrIndex].value = [value.name ? value.name : ( value.username ? value.username : null)];
                                                                                                                                                }
                                                                                                                                                // else {
                                                                                                                                                //     currentCategory.fields.attributes[attrIndex].value.splice( currentCategory.fields.attributes[attrIndex].value.indexOf(value), 1);
                                                                                                                                                // }

                                                                                                                                                // console.log( currentCategory.fields.attributes[attrIndex].value );
                                                    
                                                                                                                                                this.setState({
                                                                                                                                                    currentCategory: currentCategory,
                                                                                                                                                });
                                                                                                                                                return true;
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                        <label htmlFor={attribute.name + "/" + (value.name ? value.name : ( value.username ? value.username : null))}>
                                                                                                                                            {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                                                                        </label>
                                                                                                                                    </label>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    :
                                                                                                                        null
                                                                                                                )
                                                                                                    )
                                                                                        )
                                                                                )
                                                                        )
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className='registration_form_inpContainer registration_form_cover'>
                                            <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                <label>
                                                    Liste des groupes autorisés
                                                </label>
                                            </div>

                                            <br/>
                                            <div className='flex-row flex-wrap'>
                                                <div className='button font-size-1 right-space'
                                                    onClick={(e) => {
                                                        this._selectAllGroups(this.state.groupsList);
                                                    }}
                                                >
                                                    <span>
                                                        Tout sélectionner
                                                    </span>
                                                </div>

                                                <div className='button font-size-1'
                                                    onClick={(e) => {
                                                        this.setState({
                                                            currentGroupsList: [],
                                                        });
                                                    }}
                                                >
                                                    <span>
                                                        Tout désélectionner
                                                    </span>
                                                </div>
                                            </div>
                                            <br/>

                                            <div className=''>
                                                <SearchInputComponent key_="name" id_="id" updated={true} data={this.state.groupSearchResult.length > 0 ? this.state.groupSearchResult : this.state.groupsList} currentSelected={this.state.currentGroupsList} placeholder="Nom du groupe"
                                                    onChange={(value) => {
                                                        let tab = [];
                                                        this.state.groupsList.map((group, index) => {
                                                            if (group.name.toLowerCase() === value.toLowerCase() ||
                                                                group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                                            ) {
                                                                tab.push(group);
                                                            }
                                                        });

                                                        this.setState({
                                                            groupSearchResult: tab,
                                                        });
                                                    }}
                                                    onSelected={(selected) => {
                                                        if (this.state.currentGroupsList.includes(selected.id)) {
                                                            let currentGroupsList = this.state.currentGroupsList;
                                                            for (var i = 0; i < currentGroupsList.length; i++) {
                                                                if (currentGroupsList[i] === selected.id) {
                                                                    currentGroupsList.splice(i, 1);
                                                                    this.setState({
                                                                        currentGroupsList: currentGroupsList,
                                                                    });
                                                                    return true;
                                                                }
                                                            }
                                                        }

                                                        else {
                                                            let currentGroupsList = this.state.currentGroupsList;
                                                            currentGroupsList.push(selected.id);
                                                            // console.log( currentGroupsList );
                                                            this.setState({
                                                                currentGroupsList: currentGroupsList,
                                                            });
                                                        }
                                                    }}

                                                    style={(elt) => {
                                                        return {
                                                            backgroundColor: this.state.currentGroupsList.includes(elt.id) ? "#dc354555" : "transparent",
                                                            color: this.state.currentGroupsList.includes(elt.id) ? "#ffffff" : "#000000",
                                                        }
                                                    }}

                                                    isChecked={(elt) => {
                                                        return this.state.currentGroupsList.includes(elt.id) ? true : false 
                                                    }}
                            
                                                    icon={
                                                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                            style={{
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                        </svg>
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className='userListContainer_body'>
                    <div className='flex-row align-center'>
                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 0,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 0 ? "#0A376455" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 0 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 0 ? "#0A3764ee" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 0 ? "#0A3764" : "#000000dd",
                                }}
                            >
                                TOUT
                            </div>
                        </div>

                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 1,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 1 ? "rgba(24, 186, 24, 0.3)" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 1 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 1 ? "rgb(24, 186, 24)" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 1 ? "rgb(24, 186, 24)" : "#000000dd",
                                }}
                            >
                                ACCRÉDITÉS
                            </div>
                        </div>

                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 2,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 2 ? "#e8990844" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 2 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 2 ? "#e89908" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 2 ? "#e89908" : "#000000dd",
                                }}
                            >
                                NON ACCRÉDITÉS
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                <div className='userListContainer'>
                    <SearchInputComponent key_="username" id_="id" printStatus={true} statusKey="permissions" printSelected={false} updated={true} data={
                        this.state.searchResult_.length > 0 ? this.state.searchResult_
                            :(this.state.currentAccountListView === 0 ? this.state.accountsList
                                :(this.state.currentAccountListView === 1 ? this.state.validAccountsList
                                    : this.state.unValidAccountsList
                                )
                            )
                    } currentSelected={this.state.currentAccountsList} placeholder="Nom d'utilisateur"
                        onChange={(value) => {
                            if( value !== '' ) {
                                let tab = [];
                                if( this.state.currentAccountListView === 0 ) {
                                    this.state.accountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                else if( this.state.currentAccountListView === 1 ) {
                                    this.state.validAccountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                else {
                                    this.state.unValidAccountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                this.setState({
                                    searchResult_: tab,
                                });
                            }

                            else {
                                this.setState({
                                    searchResult_: [],
                                });
                            }
                        }}
                        onSelected={(selected) => {
                            if (this.state.currentAccountsList.includes(selected.id)) {
                                let currentAccountsList = this.state.currentAccountsList;
                                for (var i = 0; i < currentAccountsList.length; i++) {
                                    if (currentAccountsList[i] === selected.id) {
                                        currentAccountsList.splice(i, 1);
                                        this.setState({
                                            currentAccountsList: currentAccountsList,
                                        });
                                        return true;
                                    }
                                }
                            }

                            else {
                                let currentAccountsList = this.state.currentAccountsList;
                                currentAccountsList.push(selected.id);
                                // console.log( currentAccountsList );
                                this.setState({
                                    currentAccountsList: currentAccountsList,
                                });
                            }
                        }}

                        style={(elt) => {
                            return {
                                backgroundColor: this.state.currentAccountsList.includes(elt.id) ? "#dc354555" : "transparent",
                                color: this.state.currentAccountsList.includes(elt.id) ? "#ffffff" : "#000000aa",
                            }
                        }}

                        isChecked={(elt) => {
                            return this.state.currentAccountsList.includes(elt.id) ? true : false 
                        }}

                        icon={
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                            </svg>
                        }
                    />

                    <div className='flex-row flex-wrap'>
                        <div className='selectOption'>
                            <button className='button-style-6 flex-wrap align-center'
                                style={{
                                    color: "#5dc269",
                                    borderColor: "#5dc269",
                                }}

                                onClick={(e) => {
                                    e.preventDefault();
                                    this._selectAllUsers();
                                }}
                            >
                                <span>
                                    TOUT SÉLECTIONNÉ
                                </span>
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
                                </svg>
                            </button>
                        </div>

                        <div>
                            <button className='button-style-6'
                                style={{
                                    color: "red",
                                    borderColor: "red",
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this._unSelectAllUsers();
                                }}
                            >
                                <span>
                                    TOUT DÉSÉLECTIONNÉ
                                </span>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <br/>

                <div className='userListContainer_body'>

                    {
                        this.state.currentAccountsList.length > 0 &&
                        <table>
                            <tr
                                style={{
                                    background: "#0A3764ee",
                                    color: "#ffffff",
                                }}
                                className="table-head"
                            >
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        NOM
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        PRÉNOM
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        NOM D'UTILISATEUR
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        EMAIL
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        ADMIN
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        STATUT
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        GROUPES
                                    </span>
                                </th>
                            </tr>
                            {
                                this.state.currentAccountsList.map((currentAccount, index) => (
                                    this.state.accountsList.map((account, accountIndex) => (
                                        currentAccount === account.id &&
                                        <tr key={account.username}
                                            style={{
                                                background: index%2 !== 0 ? "#0A3764ee" : "transparent",
                                                color:  index%2 !== 0 ? "#ffffff" : "#000000",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.last_name}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.first_name}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.username}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.email}
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                                className="userGroups"
                                            >
                                                {
                                                    account.is_staff ?
                                                        <span
                                                            style={{
                                                                color: "#5dc269",
                                                            }}
                                                        >
                                                            oui
                                                        </span>
                                                    :
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            non
                                                        </span>
                                                }
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {
                                                    account.permissions.length > 0 ?
                                                        <span className='acredite'>
                                                            ACCRÉDITÉS
                                                        </span>
                                                    :
                                                        <span className='no_acredite'>
                                                            NON ACCRÉDITÉS
                                                        </span>
                                                }
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                                className="userGroups"
                                            >
                                                <div className='td_text'>
                                                    {account.groups.length}
                                                </div>

                                                <div className='userGroups_list'>
                                                    <ul className='userGroups_list_ul'>
                                                        {
                                                            account.groups.map((group, index) => (
                                                                <li className='userGroups_list_ul_li' key={group.id + "-" + group.name}>
                                                                    {group.name}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ))
                            }
                        </table>
                    }
                </div>

                                        {
                                            <div className=''>
                                                <div className='registration_form_inpContainer registration_form_cover'>
                                                    <span className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                                        Liste des mots-clés
                                                    </span>
                                                    <div className='dashboard-option-content-attr-list flex-row justify-center'>
                                                        {
                                                            this.state.systemKeywords.map((value, valueIndex) => (
                                                                <label key={"keyword-" + value.name + "-" + value.id} className='add_content_checkBox_ add_content_checkBox_3 flex-row justify-between'
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        padding: "2px 10px",
                                                                        fontSize: "0.8em",
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {value.word}
                                                                    </span>
                                                                    <div className='flex-column'>
                                                                        <span
                                                                            className='edit-pen'
                                                                            onClick={(e) => {
                                                                                let newValue = window.prompt( "modifier la valeur", value.word );
                                                                                if( newValue !== null && newValue !== undefined && newValue !== "" ) {
                                                                                    let data = {
                                                                                        word: newValue,
                                                                                    };
                                                                                    this.setState({
                                                                                        isLoading: true,
                                                                                    });
                                                                                    this._update_on_keyword(value.id, data, (res) => {
                                                                                        let systemKeywords = this.state.systemKeywords;
                                                                                        systemKeywords[valueIndex].word = newValue;
                                                                                        this.setState({
                                                                                            systemKeywords: systemKeywords,
                                                                                            isLoading: false,
                                                                                        });
                                                                                        alert("Mot-clé modifié avec succès");
                                                                                    });
                                                                                   
                                                                                }
                                                                            }}
                                                                            style={{
                                                                                color: "#2f7492",
                                                                            }}
                                                                        >
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                            </svg>
                                                                        </span>
                                                                        <span
                                                                            className='delete-trash'
                                                                            onClick={(e) => {
                                                                                let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                                                this.setState({
                                                                                    isLoading: false,
                                                                                });
                                                                                if( confirm ) {
                                                                                    this._delete_on_keyword(value.id, ()=> {
                                                                                        let systemKeywords = this.state.systemKeywords;
                                                                                        systemKeywords.splice(valueIndex, 1);
                                                                                        this.setState({
                                                                                            systemKeywords: systemKeywords,
                                                                                            isLoading: false,
                                                                                        });
                                                                                        alert("Mot-clé supprimé avec succès");
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </label>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='dashboard-option-content-attr-list'>
                                                        {
                                                            this.state.key_word.map((value, valueIndex) => (
                                                                <label key={"keyword-" + value.name + "-" + valueIndex} className='add_content_checkBox_ add_content_checkBox_3 flex-row justify-between'
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        padding: "2px 10px",
                                                                        fontSize: "0.8em",
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {value}
                                                                    </span>
                                                                    <div>
                                                                        <span
                                                                            className='edit-pen'
                                                                            onClick={(e) => {
                                                                                let newValue = window.prompt( "modifier la valeur", value );
                                                                                if( newValue !== null && newValue !== undefined && newValue !== "" ) {
                                                                                    let key_word = this.state.key_word;
                                                                                    key_word[valueIndex] = newValue;
                                                                                    this.setState({
                                                                                        key_word: key_word,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                            </svg>
                                                                        </span>
                                                                        <span
                                                                            className='delete-trash'
                                                                            onClick={(e) => {
                                                                                let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                                                if( confirm ) {
                                                                                    let key_word = this.state.key_word;
                                                                                    key_word.splice(valueIndex, 1);
                                                                                    this.setState({
                                                                                        key_word: key_word,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </label>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='dashboard-option-content-button'>
                                                        <span
                                                            className='button-style-5'
                                                            style={{
                                                                display: "inline-block"
                                                            }}
                                                            onClick={(e) => {
                                                                let value = window.prompt( "Mot-clé", "" );
                                                                if( value !== null && value !== undefined && value !== "" ) {
                                                                    let key_word = this.state.key_word;
                                                                    key_word.push(value);
                                                                    this.setState({
                                                                        key_word: key_word,
                                                                    }, () => {
                                                                        this._addKeywordsForDoc(this.state.current_doc.id, value);
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            Ajouter un mot-clé
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className='registration_form_fm_submit validationButton_container'>
                                            <input className='registration_form_fm_submit_ button-style-6 validationButton' type='submit' value="Modifier"
                                                style={{
                                                    color: "#5dc269",
                                                    borderColor: "#5dc269",
                                                }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

DocListScreen = connect(mapStateToProps, null)(DocListScreen);
export default DocListScreen;
