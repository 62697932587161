import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
// import CheckboxComponent from '../components/checkbox.component/checkbox.component';
import TextViewer from '../components/textViewer.component/textViewer.component';
import GroupCard from '../components/GroupCard/GroupCard.component';
// import ChronogramStyle2 from '../components/ChronogramStyle2.component/ChronogramStyle2.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class LawText extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            text: {},
            textGroups: {},
            textGroupsMore: {},
            textSignatory: {},
        }

        this.Text = {
            type: {
                name: "Arrêté",
                number: "1",
            },
            date: "30/07/2020",
            signature_date: "30/07/2020",
            place: "Cotonou",
            text_parents: [],
            amplifications: [
                {
                    name: "PR",
                    number: "01",
                }, {
                    name: "AN",
                    number: "01",
                }, {
                    name: "Autres directions du MTFP",
                    number: "20",
                }
            ],
            subject: "portant attributions, organisation et fonctionnement de la direction de l'administration et des finances",
            country: "REPUBLIQUE DU BENIN",
            number: "2020 - 030/MTFP/DC/SGM/DAF/SA/031SGG20",
            title: "",
            signatory_id: "",
            signatory: {
                name: "Adidjatou A. MATHYS",
                post: "LE MINISTRE DU TRAVAIL ET DE LA FONCTION PUBLIQUE",
                signature: "Signature",
            },
            principal_structure: {
                name: "LE MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE",
            },
            principal_organ: {
                name: "LE MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE",
            },
            others_structures: [
                {
                    name: "CABINET DU MINISTERE",
                },
                {
                    name: "SECRETARIAT GENERAL DU MINISTERE"
                },
                {
                    name: "DIRECTION DE L'ADMINISTRATION ET DES FINANCES"
                }
            ],
            views: [
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta voluptates praesentium magni optio. Officiis nulla laborum fugiat architecto quam vel laboriosam cupiditate ipsam dolorem, modi totam quas aspernatur autem iure!"
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam, adipisci officia ad, quisquam maiores sint animi itaque amet et non possimus nobis! At vero minus rem soluta ut blanditiis. Illum!"
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. "
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, itaque! Nisi, ab! Quam ipsum repellendus rerum ducimus beatae nostrum alias iure dicta vitae odio, ipsam doloribus consequuntur, doloremque aliquam repudiandae."
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. "
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. "
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, itaque!"
                },
                {
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, itaque! Nisi, ab! Quam ipsum repellendus rerum ducimus beatae nostrum alias iure dicta vitae odio."
                },
            ],
            content: [
                {
                    name: "CHAPITRE I",
                    title: "Mission et attributions",
                    title_level: 1,
                    text: "",
                    parent_index: null,
                },
                {
                    name: "Article premier",
                    title: "",
                    title_level: 3,
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui sint voluptas debitis nemo earum tempore id in ex voluptates? Consequatur soluta vero obcaecati dicta doloremque blanditiis cupiditate iusto necessitatibus aliquam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia, quod. Praesentium alias libero architecto aspernatur. Distinctio voluptatibus maiores nulla alias dignissimos ipsam, hic nobis quos commodi recusandae? Tempora, quidem amet.",
                    parent_index: 0,
                },
                {
                    name: "CHAPITRE II",
                    title: "Organisation et fonctionnement",
                    title_level: 1,
                    text: "",
                    parent_index: null,
                },
                {
                    name: "Article 2",
                    title: "",
                    title_level: 3,
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui sint voluptas debitis nemo earum tempore id in ex voluptates? Consequatur soluta vero obcaecati dicta doloremque blanditiis cupiditate iusto necessitatibus aliquam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia, quod. Praesentium alias libero architecto aspernatur. Distinctio voluptatibus maiores nulla alias dignissimos ipsam, hic nobis quos commodi recusandae? Tempora, quidem amet.",
                    parent_index: null,
                }
            ],
            "tree": [
                [
                    1,
                ],
                [],
                [3],
                [],
            ],
            "positions": [
                "0",
                "0,1",
                "2",
                "2,3"
            ]
        };

        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.query = new URLSearchParams(this.props.location.search);
        let texte =  Number(decodeURIComponent(this.query.get("texte")));

        this.setState({
            isLoading: true,
        });

        this._get_one_text(texte, (text) => {
            this.setState({
                text: text.data,
                isLoading: false,
            });

            this._get_users((accounts) => {
                accounts.map((account, index) => {
                    if(account.id === text.data.details.signatory[0]) {
                        // console.log(account);
                        this.setState({
                            textSignatory: account
                        });
                    }
                })
                
            });
        });

        this._get_all_groups((groups) => {
            // console.log( groups );
            let textGroups = {};
            groups.map((group_, index) => {
                textGroups[group_.id] = group_;
                if( index === groups.length-1) {
                    // console.log(textGroups);
                    this.setState({
                        textGroups: textGroups,
                    });
                }
            });
            this._get_all_groups_((groups_) => {
                // console.log(groups_)
                let textGroupsMore = {};
                groups_.map((group_, index) => {
                    textGroupsMore[group_.group] = group_;
                    if( index === groups_.length-1) {
                        this.setState({
                            textGroupsMore: textGroupsMore,
                        });
                    }
                });
                // console.log( textGroupsMore );
            });
        });
        // console.log(this.props.user);
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log('response ...+++', response.data)
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _get_one_text(id, callback=()=>{}) {
        this._getOneLawText(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log('response ...+++', response.data)
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_one_text(id, callback);
            });
        });
    }

    _get_all_groups_(callback=()=>{}) {
        this._getAllGroups_(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log(response.data);
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups_(callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                */

                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à ajouter votre document pour un groupe");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='headerFake'></div>

                <div className='viewContent_ screen'>
                    <div className="OnCompetenceView">
                        
                        <div className="OnCompetenceView_body">
                            <div className="OnCompetenceView_body_">
                                <div className="TextHead">
                                    <div className="TextHead_struct">
                                        <ul className='TextHead_struct_list'>
                                            <li>
                                                REPUBLIQUE DU BENIN
                                            </li>
                                            <li>
                                                { (this.state.text.details && this.state.textGroups[this.state.text.details.main_structure[0]]) ? this.state.textGroups[this.state.text.details.main_structure[0]].name : ""}
                                            </li>
                                            {/* {
                                                this.Text.others_structures.map((struct, index) => (
                                                    <li key={"struct" + index}>
                                                        {struct.name}
                                                    </li>
                                                ))
                                            } */}
                                        </ul>
                                    </div>
                                    <div className="TextHead_number">
                                        <p>
                                            {this.state.text.details && this.state.text.details.text_type} no {this.state.text.details && this.state.text.details.text_number} portant {this.state.text.details && this.state.text.details.subject}
                                        </p>
                                    </div>
                                </div>
                                <div className="TextHead_structView">
                                    <p className='TextHead_structView_post title_L1 text-center'>
                                        {/* {this.Text.signatory.post} */}
                                        { (this.state.text.details && this.state.textGroups[this.state.text.details.main_institution_issuing[0]]) ? this.state.textGroups[this.state.text.details.main_institution_issuing[0]].name : ""}
                                    </p>

                                    {/* <ul className='TextHead_structView_list'>
                                        {
                                            this.Text.views.map((view, index) => (
                                                <li>
                                                    <span>Vu</span><p>{view.text}</p>
                                                </li>
                                            ))
                                        }
                                    </ul> */}
                                </div>
                                <div className="TextHead_struct text-center">
                                    <span className='title_L1'>{this.state.text.details && this.state.text.details.text_type}</span>
                                </div>
                                <div className=''>
                                    <TextViewer
                                        tree={this.state.text.details ? this.state.text.details.texts.tree : []}
                                        positions={this.state.text.details ? this.state.text.details.texts.positions : []}
                                        list={this.state.text.details ? this.state.text.details.texts.list : []}
                                        control={false}
                                        noHoverEffect={true}
                                        api_={""}
                                    />
                                </div>
                                <div className='Text_datePlace'>
                                    <span>Fait à</span> <span>{this.state.text.details && this.state.text.details.place}</span>, le <span>{this.state.text.details && this.state.text.details.signed_at}</span>
                                </div>

                                <div className='Text_signature'>
                                    <span>
                                        {this.state.textSignatory.username ? this.state.textSignatory.first_name + " " + this.state.textSignatory.last_name : ""}
                                    </span>
                                </div>

                                <div className='Text_amplifications'>
                                    <div>
                                        <span className='title_L3'>
                                            Amplifications
                                        </span>
                                    </div>
                                    <div className='Text_amplifications_content'>
                                        {
                                            this.state.text.details &&
                                            this.state.text.details.amplification.map((amp, index) => (
                                                <span>
                                                    {this.state.textGroups[amp] && this.state.textGroups[amp].name} ; {" "}
                                                </span>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

LawText = connect(mapStateToProps, null)(LawText);
export default LawText;
