import React from 'react';
import Routes from './routes/routes';
import './global-style/animation.css';
import './plugin/font-awesome-4.7.0/css/font-awesome.min.css';
import './global-style/default.scss';

class App extends React.Component {
    render() {
        return (
            <div className="app">
               <Routes/>
            </div>
        )
    }
}

export default App;