import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import CheckboxComponent from '../components/checkbox.component/checkbox.component';
import ChronogramStyle1 from '../components/ChronogramStyle1.component/ChronogramStyle1.component';
import ChronogramStyle2 from '../components/ChronogramStyle2.component/ChronogramStyle2.component';



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class AOFScreen extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            chronogram: [],
        }

        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        window.scrollTo(0, 0);
        this._getAllChronogram((response) => {
            this.setState({
                chronogram: response,
                isLoading: false,
            });
        });
    }

    _getAllChronogram(callback=()=>{}) {
        this._systemInfoGetAll(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                // console.log(response.data);
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getAllChronogram(callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                */

                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à ajouter votre document pour un groupe");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }


    render() {
        return (
            <>
                {
                    super.render()
                }

                <div className='headerFake'></div>

                <div className='viewContent_'>
                    <div className=''>
                        {
                            this.state.chronogram.map((chr, index) => (
                                chr.details && chr.details.type === "cartography" &&
                                <div>
                                    <div className="OnProjectsView_top_text">
                                        <span className="bold dynamic-fts-5"
                                            style={{
                                                color: this.VAR.color_1,
                                                textShadow: "none",
                                                fontFamily: 'Montserrat-Regular',
                                            }}
                                        >
                                            {chr.name}
                                        </span>
                                    </div>
                                    <ChronogramStyle1
                                        tree={chr.details.task_tree}
                                        positions={chr.details.task_positions}
                                        list={chr.details.task_list}
                                        control={false}
                                        noHoverEffect={true}
                                        api_={""}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </>
        )
    }
}

AOFScreen = connect(mapStateToProps, null)(AOFScreen);
export default AOFScreen;
