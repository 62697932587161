import React from 'react';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import 'moment/locale/fr';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/




/**
 * 
 * Importation des scomposants
 */
import SearchInputComponent from '../components/searchInput.component/searchInput.component';
import AUthScreen from '../components/auth/auth.component';

/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}


class DashboardUsersList extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            accountsList: [],
            validAccountsList: [],
            unValidAccountsList: [],
            currentAccountsList: [],
            currentAccountListView: 0,
            
            currentAccountSelected: {},
            currentAccountSelected_groupsToAdd: [],
            currentAccountSelected_groupsToRemove: [],
            currentAccountSelected_registrationNumber: "",
            currentAccountSelected_phoneNumber: "",
            
            searchResult: [],

            viewAccountDetails: false,


            groupsList: [],
            currentGroupsList: [],
            groupSearchResult: [],

            addInGroups: false,
            RemoveFromGroups: false,

            authAction: () => {},
            auth: false,
        }

        this.tools = new tools();
        this.accessStore = new AccessStore(this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.setState({
            isLoading: true,
        });
        this._get_users((accounts) => {
            console.log( accounts );
            this.setState({
                accountsList: accounts,
            }, ()=> {
                this._startGetUsersGroups(0, this.state.accountsList, (userList) => {
                    this.setState({
                        accountsList: userList,
                    }, () => {
                        this._startGetUsersPermissions(0, this.state.accountsList, (userList_) => {
                            this.setState({
                                accountsList: userList_,
                            }, () => {
                                let validAccountsList = [];
                                let unValidAccountsList = [];
                                this.state.accountsList.map((account, accountIndex) => {
                                    if( account.permissions.length > 0 ) {
                                        validAccountsList.push( account );
                                    }

                                    else {
                                        unValidAccountsList.push( account );
                                    }
                                });
                                this.setState({
                                    validAccountsList: validAccountsList,
                                    unValidAccountsList: unValidAccountsList,
                                });
                                this._get_all_groups((groups) => {
                                    this.setState({
                                        groupsList: groups,
                                        isLoading: false,
                                    }, () => {
                                        let accountsList = this.state.accountsList;

                                        for(let i=0; i<accountsList.length; i++) {
                                            let not_member_of = [];

                                            for(let j=0; j<this.state.groupsList.length; j++) {

                                                let inGroup = false;

                                                for(let k=0; k<accountsList[i].groups.length; k++) {
                                                    if( this.state.groupsList[j].id === accountsList[i].groups[k].id ) {
                                                        inGroup = true;
                                                        // return true;
                                                    }
                                                }

                                                if( inGroup === false ) {
                                                    let isContent = false;
                                                    
                                                    for(let l=0; l<not_member_of.length; l++) {
                                                        if(not_member_of[l].id === this.state.groupsList[j].id) {
                                                            isContent = true;
                                                        }
                                                    }
                                                    if( isContent === false ) {
                                                        not_member_of = not_member_of.concat( this.state.groupsList[j] );
                                                    }
                                                }
                                            }

                                            // console.log( accountsList[i].username, not_member_of );
                                            accountsList[i]["not_member_of"] = not_member_of;
                                            this.setState({
                                                accountsList: accountsList,
                                            });
                                        }

                                        // this.state.accountsList.map((account, index) => {
                                        //     let not_member_of = [];
                                        //     this.state.groupsList.map((group, group_index) => {
                                        //         account.groups.map((group_, group_index_) => {
                                        //             let inGroup = false;
                                        //             // console.log( group.pk, group_.id );
                                        //             if( group.pk === group_.id ) {
                                        //                 inGroup = true;
                                        //                 return true;
                                        //             }
                                        //             // console.log( group_index_);
                                        //             if( inGroup === false ) {
                                        //                 let isContent = false;
                                                        
                                        //                 not_member_of.map((elt, index_) => {
                                        //                     if(elt.pk === group.pk) {
                                        //                         isContent = true;
                                        //                         return true;
                                        //                     }
                                        //                 });
                                        //                 if( isContent === false ) {
                                        //                     not_member_of.push( group );
                                        //                 }
                                        //             }
                                        //         });
                                        //     });
                                        //     console.log( account.username, not_member_of );
                                        //     accountsList[index]["not_member_of"] = not_member_of;
                                        //     this.setState({
                                        //         accountsList: accountsList,
                                        //     });
                                        // });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _startGetUsersGroups( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_groups(user.id, (groups) => {
                let users = userList;
                users[index]["groups"] = groups.groups;
                if( index < userList.length - 1 ) {
                    this._startGetUsersGroups( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _startGetUsersPermissions( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_permissions(user.id, (permissions) => {
                let users = userList;
                users[index]["permissions"] = permissions.permissions;
                if( index < userList.length - 1 ) {
                    this._startGetUsersPermissions( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _get_user_groups(id, callback=()=>{}) {
        this._getUserGroups(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_groups(callback);
            })
        });
    }

    _get_user_permissions(id, callback=()=>{}) {
        this._getUserPermissions(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_permissions(callback);
            })
        });
    }

    _set_new_user(response) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user);
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _choiceGroupsForUsers() {
        this.setState({
            addInGroups: !this.state.addInGroups,
        });
    }

    _choiceGroupsForUsersRemove() {
        this.setState({
            RemoveFromGroups: !this.state.RemoveFromGroups,
        });
    }

    _viewAccountDetails() {
        this.setState({
            viewAccountDetails: !this.state.viewAccountDetails,
        });
    }

    _addUsersInGroups() {
        let other_data = {
            users: this.state.currentAccountsList,
            groups: this.state.currentGroupsList,
        }

        this.setState({
            isLoading: true,
        });

        this._add_user_to_group(other_data, () => {
            alert("Les utilisateurs ont été ajouté dans les groupes avec succès");
            window.location.reload();
            this.setState({
                isLoading: false,
                currentGroupsList: [],
            });
            this._choiceGroupsForUsers();
        });
    }

    _removeUsersFromGroups() {
        let other_data = {
            users: this.state.currentAccountsList,
            groups: this.state.currentGroupsList,
        }

        this.setState({
            isLoading: true,
        });

        this._remove_user_from_group(other_data, () => {
            alert("Les utilisateurs ont été retiré des groupes avec succès");
            window.location.reload();
            this.setState({
                isLoading: false,
                currentGroupsList: [],
            });
            this._choiceGroupsForUsersRemove();
        });
    }

    _add_user_to_group( data, callback=()=>{} ) {
        this._addAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_user_to_group(data, callback);
            });
        });
    }

    _remove_user_from_group( data, callback=()=>{} ) {
        this._removeAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_user_to_group(data, callback);
            });
        });
    }

    _selectAllUsers() {
        let tab = [];
        if( this.state.currentAccountListView === 0 ) {
            this.state.accountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else if( this.state.currentAccountListView === 1 ) {
            this.state.validAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else {
            this.state.unValidAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        this.setState({
            currentAccountsList: tab,
        });
    }

    _unSelectAllUsers() {
        this.setState({
            currentAccountsList: [],
        });
    }

    _manageError(error, callback = () => { }) {
        if (error.response) {
            if (error.response.status === 401) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if (error.response.data.code === "token_not_valid") {
                    this._refreshUserToken(this.props.user.refresh, (response) => {
                        if (response.status === 200) {
                            this._set_new_user(response);
                            callback();
                        }
                    }, (error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à interagir avec cette section");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _admin_update_account(id, data, callback=()=>{}) {
        this._AdminUpdateAccount(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._admin_update_account(id, data, callback);
            });
        });
    }

    _updateUser( user, password) {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isLoading: true,
                });

                let data = {
                    password: password,
                    last_name: this.state.currentAccountSelected.last_name,
                    first_name: this.state.currentAccountSelected.first_name,
                    email: this.state.currentAccountSelected.email,
                    is_staff: this.state.currentAccountSelected.is_staff,
                    username: this.state.currentAccountSelected.username,
                    matricule: this.state.currentAccountSelected_registrationNumber,
                    phone: this.state.currentAccountSelected_phoneNumber,
                    others: {
                        attributes: []
                    },
                };

                // console.log(data);

                let data1 = {
                    users: [user.id],
                    groups: this.state.currentAccountSelected_groupsToAdd,
                };
        
                let data2 = {
                    users: [user.id],
                    groups: this.state.currentAccountSelected_groupsToRemove,
                }

                this._admin_update_account(user.id, data, (response) => {
                    // console.log( response );
                    this._add_user_to_group(data1, () => {
                        this._remove_user_from_group(data2, () => {
                            alert("Les informations de l'utilisateur on été mis àjour avec succès");
                            this.setState({
                                isLoading: false,
                            });
                            window.location.reload();
                        });
                    });
                });
            }
        }, () => {
            this._openOrCloseAuth();
        })
    }

    startUsersDeletion( index=0, usersList, callback=()=>{} ) {
        let user = usersList[index];

        this.setState({
            isLoading: true,
            isProgress: true,
            progressionTile: "Suppression de : " + (index + 1) + "/" + usersList.length,
            requestProgression: Math.round((100 * (index+1)) /usersList.length),
        });
        
        this._delete_account(user, (response) => {
            if( index < usersList.length - 1 ) {
                this.startUsersDeletion( index + 1, usersList, callback );
            }else {
                callback();
                this._openOrCloseAuth();
            }
        });
    }

    _delete_account(id, callback=()=>{}) {
        this.setState({
            isLoading: true,
        });
        this._deleteAccount(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                this.setState({
                    isLoading: false,
                });
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._delete_account(id, callback);
            });
        });
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                {
                    this.state.currentAccountsList.length > 0 &&
                        <div className="right-float">
                            <span>
                                { this.state.currentAccountsList.length }
                            </span>
                        </div>
                }

                <div className='userListContainer_body'>
                    <div className='flex-row align-center'>
                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 0,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 0 ? "#0A376455" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 0 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 0 ? "#0A3764ee" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 0 ? "#0A3764" : "#000000dd",
                                }}
                            >
                                TOUT
                            </div>
                        </div>

                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 1,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 1 ? "rgba(24, 186, 24, 0.3)" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 1 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 1 ? "rgb(24, 186, 24)" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 1 ? "rgb(24, 186, 24)" : "#000000dd",
                                }}
                            >
                                ACCRÉDITÉS
                            </div>
                        </div>

                        <div className='customCheckBox flex-row align-center'
                            onClick={() => {
                                this.setState({
                                    currentAccountListView: 2,
                                });
                            }}
                        >
                            <div className='customCheckBox_back'
                                style={{
                                    background: this.state.currentAccountListView === 2 ? "#e8990844" : "#00000055",
                                }}
                            >
                                <div className='customCheckBox_dot'
                                    style={{
                                        marginLeft: this.state.currentAccountListView === 2 ? "50%" : "0%",
                                        background: this.state.currentAccountListView === 2 ? "#e89908" : "#ffffff",
                                    }}
                                ></div>
                            </div>
                            <div className='customCheckBox_text'
                                style={{
                                    color: this.state.currentAccountListView === 2 ? "#e89908" : "#000000dd",
                                }}
                            >
                                NON ACCRÉDITÉS
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                <div className='userListContainer'>
                    <SearchInputComponent key_="username" id_="id" printStatus={true} statusKey="permissions" printSelected={false} updated={true} data={
                        this.state.searchResult.length > 0 ? this.state.searchResult
                            :(this.state.currentAccountListView === 0 ? this.state.accountsList
                                :(this.state.currentAccountListView === 1 ? this.state.validAccountsList
                                    : this.state.unValidAccountsList
                                )
                            )
                    } currentSelected={this.state.currentAccountsList} placeholder="Nom d'utilisateur"
                        onChange={(value) => {
                            if( value !== '' ) {
                                let tab = [];
                                if( this.state.currentAccountListView === 0 ) {
                                    this.state.accountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                else if( this.state.currentAccountListView === 1 ) {
                                    this.state.validAccountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                else {
                                    this.state.unValidAccountsList.map((account, index) => {
                                        if (account.username.toLowerCase() === value.toLowerCase() ||
                                            account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(account);
                                        }
                                    });
                                }

                                this.setState({
                                    searchResult: tab,
                                });
                            }

                            else {
                                this.setState({
                                    searchResult: [],
                                });
                            }
                        }}
                        onSelected={(selected) => {
                            if (this.state.currentAccountsList.includes(selected.id)) {
                                let currentAccountsList = this.state.currentAccountsList;
                                for (var i = 0; i < currentAccountsList.length; i++) {
                                    if (currentAccountsList[i] === selected.id) {
                                        currentAccountsList.splice(i, 1);
                                        this.setState({
                                            currentAccountsList: currentAccountsList,
                                        });
                                        return true;
                                    }
                                }
                            }

                            else {
                                let currentAccountsList = this.state.currentAccountsList;
                                currentAccountsList.push(selected.id);
                                // console.log( currentAccountsList );
                                this.setState({
                                    currentAccountsList: currentAccountsList,
                                });
                            }
                        }}

                        style={(elt) => {
                            return {
                                backgroundColor: this.state.currentAccountsList.includes(elt.id) ? "#dc354555" : "transparent",
                                color: this.state.currentAccountsList.includes(elt.id) ? "#ffffff" : "#000000aa",
                            }
                        }}

                        isChecked={(elt) => {
                            return this.state.currentAccountsList.includes(elt.id) ? true : false 
                        }}

                        icon={
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                            </svg>
                        }
                    />

                    <div className='flex-row flex-wrap'>
                        <div className='selectOption'>
                            <button className='button-style-6 flex-wrap align-center'
                                style={{
                                    color: "#5dc269",
                                    borderColor: "#5dc269",
                                }}

                                onClick={() => {
                                    this._selectAllUsers();
                                }}
                            >
                                <span>
                                    TOUT SÉLECTIONNÉ
                                </span>
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
                                </svg>
                            </button>
                        </div>

                        <div>
                            <button className='button-style-6'
                                style={{
                                    color: "red",
                                    borderColor: "red",
                                }}
                                onClick={() => {
                                    this._unSelectAllUsers();
                                }}
                            >
                                <span>
                                    TOUT DÉSÉLECTIONNÉ
                                </span>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <br/>

                <div className='userListContainer_body'>

                    {
                        this.state.currentAccountsList.length > 0 &&
                        <table>
                            <tr
                                style={{
                                    background: "#0A3764ee",
                                    color: "#ffffff",
                                }}
                                className="table-head"
                            >
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        NOM
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        PRÉNOM
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        NOM D'UTILISATEUR
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        EMAIL
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        ADMIN
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        STATUT
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        GROUPES
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        Dernière connexion
                                    </span>
                                </th>
                                <th>
                                    <span
                                        style={{
                                            color: "#ffffff",
                                        }}
                                    >
                                        <button style={{color: "red"}}
                                            className='searchInput_currentList_ul_li_button'
                                        >
                                            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </button>
                                    </span>
                                </th>
                            </tr>
                            {
                                this.state.currentAccountsList.map((currentAccount, index) => (
                                    this.state.accountsList.map((account, accountIndex) => (
                                        currentAccount === account.id &&
                                        <tr key={account.username}
                                            style={{
                                                background: index%2 !== 0 ? "#0A3764ee" : "transparent",
                                                color:  index%2 !== 0 ? "#ffffff" : "#000000",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                        currentAccountSelected_registrationNumber: account.extended.matricule ? account.extended.matricule : "",
                                                        currentAccountSelected_phoneNumber: account.extended.phone ? account.extended.phone : "",
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.last_name}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                        currentAccountSelected_registrationNumber: account.extended.matricule ? account.extended.matricule : "",
                                                        currentAccountSelected_phoneNumber: account.extended.phone ? account.extended.phone : "",
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.first_name}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                        currentAccountSelected_registrationNumber: account.extended.matricule ? account.extended.matricule : "",
                                                        currentAccountSelected_phoneNumber: account.extended.phone ? account.extended.phone : "",
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.username}
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                        currentAccountSelected_registrationNumber: account.extended.matricule ? account.extended.matricule : "",
                                                        currentAccountSelected_phoneNumber: account.extended.phone ? account.extended.phone : "",
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {account.email}
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                                className="userGroups"
                                            >
                                                {
                                                    account.is_staff ?
                                                        <span
                                                            style={{
                                                                color: "#5dc269",
                                                            }}
                                                        >
                                                            oui
                                                        </span>
                                                    :
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            non
                                                        </span>
                                                }
                                            </td>
                                            <td className='accountUsername' title='Cliquer ici pour voir plus de détails'
                                                 onClick={(e) => {
                                                    // console.log( account );
                                                    this.setState({
                                                        currentAccountSelected: account,
                                                    }, () => {
                                                        this._viewAccountDetails();
                                                    });
                                                }}
                                            >
                                                {
                                                    account.permissions.length > 0 ?
                                                        <span className='acredite'>
                                                            ACCRÉDITÉS
                                                        </span>
                                                    :
                                                        <span className='no_acredite'>
                                                            NON ACCRÉDITÉS
                                                        </span>
                                                }
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                                className="userGroups"
                                            >
                                                <div className='td_text'>
                                                    {account.groups.length}
                                                </div>

                                                <div className='userGroups_list'>
                                                    <ul className='userGroups_list_ul'>
                                                        {
                                                            account.groups.map((group, index) => (
                                                                <li className='userGroups_list_ul_li' key={group.id + "-" + group.name}>
                                                                    {group.name}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                }}
                                                className="userGroups"
                                            >
                                                <div className=''>
                                                    {account.last_login ? <Moment fromNow>{account.last_login}</Moment> : "---"}
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    style={{
                                                        color: "#ffffff",
                                                    }}
                                                >
                                                    <button style={{color: "red"}}
                                                        className='searchInput_currentList_ul_li_button'
                                                        onClick={(e) => {
                                                            if( window.confirm("Voulez-vous vraiment supprimer " + account.username + " ?") ) {
                                                                this.setState({
                                                                    authAction: (password) => {
                                                                        this._delete_account(account.id, () => {

                                                                            let currentAccountsList = this.state.currentAccountsList;

                                                                            alert( "Compte suprimé avec succès!" );
                                                                            this._openOrCloseAuth();
                                                                            this.componentDidMount();
                                                                            currentAccountsList.splice(index, 1);
                                                                            this.setState({
                                                                                currentAccountsList: currentAccountsList
                                                                            });
                                                                        })
                                                                    }
                                                                }, () => {
                                                                    this._openOrCloseAuth();
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                        </svg>
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ))
                            }
                        </table>
                    }

                    <div className='userListContainer-buttons'>
                        {
                            this.state.currentAccountsList.length > 0 &&
                                <div className="flex-row justify-center">
                                    <button className="button-style-6" style={{background: "red"}}
                                        onClick={() => {
                                            if( window.confirm("voulez-vous vraiment supprimé?") ) {  
                                                this.setState({
                                                    authAction: (password) => {
                                                        this.startUsersDeletion(0, this.state.currentAccountsList, () => {
                                                            alert("Tous les comptes sélectionnés ont été suprimés avec succès!");

                                                            this.setState({
                                                                isLoading: false,
                                                                isProgress: false,
                                                                progressionTile: "",
                                                                requestProgression: 0,
                                                                currentAccountsList: [],
                                                            });
                                                            this.componentDidMount();
                                                        })
                                                    }
                                                }, () => {
                                                    this._openOrCloseAuth();
                                                });
                                            }
                                        }}
                                    >
                                        Supprimer les utilisateurs sélectionnés
                                    </button>

                                    <button
                                        onClick={(e) => {
                                            this._choiceGroupsForUsers();
                                        }}
                                        className="button-style-5"
                                    >
                                        Ajouter dans des groupes
                                    </button>

                                    <button
                                        onClick={(e) => {
                                            this._choiceGroupsForUsersRemove();
                                        }}
                                        className="button-style-6"
                                        style={{
                                            background: "transparent",
                                            color: "red",
                                            borderColor: "red",
                                        }}
                                    >
                                        Retirer de groupes
                                    </button>
                                </div>
                        }
                    </div>
                </div>

                {
                    this.state.addInGroups &&
                    <div className='alert'>
                        <div className='alert-content'
                            style={{
                                padding: "20px",
                            }}
                        >
                            <div className='flex-row justify-end'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    onClick={(e) => {
                                        this._choiceGroupsForUsers();
                                    }}
                                >
                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                </svg>
                            </div>
                            <SearchInputComponent key_="name" printSelected={true} id_="id" data={this.state.groupSearchResult.length > 0 ? this.state.groupSearchResult : this.state.groupsList} currentSelected={this.state.currentGroupsList} placeholder="Nom du groupe"
                                onChange={(value) => {
                                    let tab = [];
                                    this.state.groupsList.map((group, index) => {
                                        if (group.name.toLowerCase() === value.toLowerCase() ||
                                            group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(group);
                                        }
                                    });

                                    this.setState({
                                        groupSearchResult: tab,
                                    });
                                }}
                                onSelected={(selected) => {
                                    if (this.state.currentGroupsList.includes(selected.id)) {
                                        let currentGroupsList = this.state.currentGroupsList;
                                        for (var i = 0; i < currentGroupsList.length; i++) {
                                            if (currentGroupsList[i] === selected.id) {
                                                currentGroupsList.splice(i, 1);
                                                this.setState({
                                                    currentGroupsList: currentGroupsList,
                                                });
                                                return true;
                                            }
                                        }
                                    }

                                    else {
                                        let currentGroupsList = this.state.currentGroupsList;
                                        currentGroupsList.push(selected.id);
                                        // console.log( currentGroupsList );
                                        this.setState({
                                            currentGroupsList: currentGroupsList,
                                        });
                                    }
                                }}

                                style={(elt) => {
                                    return {
                                        backgroundColor: this.state.currentGroupsList.includes(elt.id) ? "#dc354555" : "transparent",
                                        color: this.state.currentGroupsList.includes(elt.id) ? "#ffffff" : "#000000aa",
                                    }
                                }}

                                isChecked={(elt) => {
                                    return this.state.currentGroupsList.includes(elt.id) ? true : false 
                                }}

                                icon={
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-plus-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            marginRight: "5px",
                                        }}
                                    >
                                        <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                    </svg>
                                }
                            />

                            <div>
                                <div className='dashboard-option-content-button flex-row justify-center'>
                                    <span
                                        className='button-style-5'
                                        onClick={() => {
                                            this._addUsersInGroups();
                                        }}
                                    >
                                        Ajouter les utilisateurs dans ses groupes
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }



                {
                    this.state.RemoveFromGroups &&
                    <div className='alert'>
                        <div className='alert-content'
                            style={{
                                padding: "20px",
                            }}
                        >
                            <div className='flex-row justify-end'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    onClick={(e) => {
                                        this._choiceGroupsForUsersRemove();
                                    }}
                                >
                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                </svg>
                            </div>
                            <SearchInputComponent key_="name" id_="id" printSelected={true} data={this.state.groupSearchResult.length > 0 ? this.state.groupSearchResult : this.state.groupsList} currentSelected={this.state.currentGroupsList} placeholder="Nom du groupe"
                                onChange={(value) => {
                                    let tab = [];
                                    this.state.groupsList.map((group, index) => {
                                        if (group.name.toLowerCase() === value.toLowerCase() ||
                                            group.name.toLowerCase().split(value.toLowerCase()).length > 1
                                        ) {
                                            tab.push(group);
                                        }
                                    });

                                    this.setState({
                                        groupSearchResult: tab,
                                    });
                                }}
                                onSelected={(selected) => {
                                    if (this.state.currentGroupsList.includes(selected.id)) {
                                        let currentGroupsList = this.state.currentGroupsList;
                                        for (var i = 0; i < currentGroupsList.length; i++) {
                                            if (currentGroupsList[i] === selected.id) {
                                                currentGroupsList.splice(i, 1);
                                                this.setState({
                                                    currentGroupsList: currentGroupsList,
                                                });
                                                return true;
                                            }
                                        }
                                    }

                                    else {
                                        let currentGroupsList = this.state.currentGroupsList;
                                        currentGroupsList.push(selected.id);
                                        // console.log( currentGroupsList );
                                        this.setState({
                                            currentGroupsList: currentGroupsList,
                                        });
                                    }
                                }}

                                style={(elt) => {
                                    return {
                                        backgroundColor: this.state.currentGroupsList.includes(elt.id) ? "#dc354555" : "transparent",
                                        color: this.state.currentGroupsList.includes(elt.id) ? "#ffffff" : "#000000aa",
                                    }
                                }}

                                isChecked={(elt) => {
                                    return this.state.currentGroupsList.includes(elt.id) ? true : false 
                                }}
                                icon={
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-plus-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            marginRight: "5px",
                                        }}
                                    >
                                        <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                    </svg>
                                }
                            />

                            <div>
                                <div className='dashboard-option-content-button flex-row justify-center'>
                                    <span
                                        className='button-style-6'
                                        onClick={() => {
                                            this._removeUsersFromGroups();
                                        }}
                                    >
                                        Retirer les utilisateurs de ses groupes
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.viewAccountDetails &&
                    <div className='alert'>
                        <div className='alert-content'
                            style={{
                                padding: "20px",
                            }}
                        >
                            <div className='flex-row justify-end'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    onClick={(e) => {
                                        this._viewAccountDetails();
                                    }}
                                >
                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                </svg>
                            </div>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Nom
                                    </span>
                                    <div>
                                        <input type={"text"} className='input-style-1-1' value={this.state.currentAccountSelected.last_name}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.last_name = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Prénom
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' value={this.state.currentAccountSelected.first_name}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.first_name = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Email
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' value={this.state.currentAccountSelected.email}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.email = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Nom d'utilisateur
                                    </span>
                                    <div>
                                        <input type={"text"} className='input-style-1-1' value={this.state.currentAccountSelected.username}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.username = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Numéro matricule
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' value={this.state.currentAccountSelected_registrationNumber}
                                            onChange={(e) => {
                                                this.setState({
                                                    currentAccountSelected_registrationNumber: e.target.value
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Numéro de téléphone
                                    </span>
                                    <div>
                                        <input type={"email"} className='input-style-1-1' value={this.state.currentAccountSelected_phoneNumber}
                                            onChange={(e) => {
                                                this.setState({
                                                    currentAccountSelected_phoneNumber: e.target.value
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span style={{display: "inline"}}>
                                        Dernière connexion: -
                                    </span>
                                    <span style={{color: "#000000", display: "inline", fontWeight: "bold", fontSize: "0.9em"}}>
                                        {this.state.currentAccountSelected.last_login ? <Moment fromNow>{this.state.currentAccountSelected.last_login}</Moment> : "---"}
                                    </span>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Liste des groupes de {this.state.currentAccountSelected.username} :
                                    </span>
                                    <div>
                                        <table>
                                            <tr
                                                style={{
                                                    background: "#0A3764ee",
                                                    color: "#ffffff",
                                                }}
                                                className="table-head"
                                            >
                                                <th>
                                                    NOM DU GROUPE
                                                </th>
                                                <th className='text-align-center flex-wrap'
                                                    onClick={() => {
                                                        if( this.state.currentAccountSelected_groupsToRemove.length !== this.state.currentAccountSelected.groups.length) {
                                                            let currentAccountSelected_groupsToRemove = [];
                                                            this.state.currentAccountSelected.groups.map((group, index) => {
                                                                currentAccountSelected_groupsToRemove.push(group.id);
                                                            });

                                                            this.setState({
                                                                currentAccountSelected_groupsToRemove: currentAccountSelected_groupsToRemove,
                                                            });
                                                        }
                                                        else {
                                                            this.setState({
                                                                currentAccountSelected_groupsToRemove: [],
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <div className='text-align-center flex-row justify-center align-center'>
                                                        RETIRER "{this.state.currentAccountSelected.username}"
                                                        <input type='checkbox' checked={this.state.currentAccountSelected.groups.length === this.state.currentAccountSelected_groupsToRemove.length} style={{
                                                            marginLeft: "4px",
                                                        }}/>
                                                    </div>
                                                </th>
                                            </tr>

                                            {
                                                this.state.currentAccountSelected.groups.map((group, index) => (
                                                    <tr
                                                        onClick={() => {
                                                            if (this.state.currentAccountSelected_groupsToRemove.includes(group.id)) {
                                                                let currentAccountSelected_groupsToRemove = this.state.currentAccountSelected_groupsToRemove;
                                                                for (var i = 0; i < currentAccountSelected_groupsToRemove.length; i++) {
                                                                    if (currentAccountSelected_groupsToRemove[i] === group.id) {
                                                                        currentAccountSelected_groupsToRemove.splice(i, 1);
                                                                        this.setState({
                                                                            currentAccountSelected_groupsToRemove: currentAccountSelected_groupsToRemove,
                                                                        });
                                                                        return true;
                                                                    }
                                                                }
                                                            }
                        
                                                            else {
                                                                let currentAccountSelected_groupsToRemove = this.state.currentAccountSelected_groupsToRemove;
                                                                currentAccountSelected_groupsToRemove.push(group.id);
                                                                this.setState({
                                                                    currentAccountSelected_groupsToRemove: currentAccountSelected_groupsToRemove,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <td>
                                                            {group.name}
                                                        </td>
                                                        <td className='text-center flex-row justify-center'>
                                                            <input type='checkbox' checked={this.state.currentAccountSelected_groupsToRemove.includes(group.id)} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                </div>
                                <br/>


                                <div className='input-container'>
                                    <span>
                                        Liste des groupes auquels "{this.state.currentAccountSelected.username}" n'appartient pas:
                                    </span>
                                    <div>
                                        <table>
                                            <tr
                                                style={{
                                                    background: "#0A3764ee",
                                                    color: "#ffffff",
                                                }}
                                                className="table-head"
                                            >
                                                <th>
                                                    NOM DU GROUPE
                                                </th>
                                                <th className='text-align-center flex-wrap'
                                                    onClick={() => {
                                                        if( this.state.currentAccountSelected_groupsToAdd.length !== this.state.currentAccountSelected.not_member_of.length) {
                                                            let currentAccountSelected_groupsToAdd = [];
                                                            this.state.currentAccountSelected.not_member_of.map((group, index) => {
                                                                currentAccountSelected_groupsToAdd.push(group.id);
                                                            });

                                                            this.setState({
                                                                currentAccountSelected_groupsToAdd: currentAccountSelected_groupsToAdd,
                                                            });
                                                        }
                                                        else {
                                                            this.setState({
                                                                currentAccountSelected_groupsToAdd: [],
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <div className='text-align-center flex-row justify-center align-center'>
                                                        AJOUTER "{this.state.currentAccountSelected.username}"
                                                        <input type='checkbox' checked={this.state.currentAccountSelected.not_member_of.length === this.state.currentAccountSelected_groupsToAdd.length} style={{
                                                            marginLeft: "4px",
                                                        }}/>
                                                    </div>
                                                </th>
                                            </tr>

                                            {
                                                this.state.currentAccountSelected.not_member_of.map((group, index) => (
                                                    <tr
                                                        onClick={() => {
                                                            if (this.state.currentAccountSelected_groupsToAdd.includes(group.id)) {
                                                                let currentAccountSelected_groupsToAdd = this.state.currentAccountSelected_groupsToAdd;
                                                                for (var i = 0; i < currentAccountSelected_groupsToAdd.length; i++) {
                                                                    if (currentAccountSelected_groupsToAdd[i] === group.id) {
                                                                        currentAccountSelected_groupsToAdd.splice(i, 1);
                                                                        this.setState({
                                                                            currentAccountSelected_groupsToAdd: currentAccountSelected_groupsToAdd,
                                                                        });
                                                                        return true;
                                                                    }
                                                                }
                                                            }
                        
                                                            else {
                                                                let currentAccountSelected_groupsToAdd = this.state.currentAccountSelected_groupsToAdd;
                                                                currentAccountSelected_groupsToAdd.push(group.id);
                                                                this.setState({
                                                                    currentAccountSelected_groupsToAdd: currentAccountSelected_groupsToAdd,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <td>
                                                            {group.name}
                                                        </td>
                                                        <td className='text-center flex-row justify-center'>
                                                            <input type='checkbox' checked={this.state.currentAccountSelected_groupsToAdd.includes(group.id)} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            <br/>
                            <div>
                                <label key={this.state.currentAccountSelected.username} htmlFor={this.state.currentAccountSelected.username} className='flex-row align-center'>
                                    <input id={this.state.currentAccountSelected.username} name={this.state.currentAccountSelected.username} type='checkbox' checked={this.state.currentAccountSelected.is_staff && (this.state.currentAccountSelected_groupsToAdd.length>0 || this.state.currentAccountSelected.groups.length>0)}
                                        onChange={(e)=>{
                                            let currentAccountSelected = this.state.currentAccountSelected;
                                            
                                            if( e.target.checked === true ) {
                                                if(this.state.currentAccountSelected_groupsToAdd.length>0 || this.state.currentAccountSelected.groups.length>0) {
                                                    currentAccountSelected.is_staff = true;
                                                } else {
                                                    alert("vous ne pouvez pas définir cet utilisateur comme administrateur avant de l'avoir accrédité. Ajouter le dans des groupes pour cela.");
                                                }
                                            }
                                            else {
                                                currentAccountSelected.is_staff = false;
                                            }
                                            this.setState({
                                                currentAccountSelected: currentAccountSelected,
                                            });
                                            return true;
                                        }}
                                    />
                                    <label htmlFor={this.state.currentAccountSelected.username}
                                        style={{
                                            fontSize: "0.8em",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        Définir comme administrateur
                                    </label>
                                </label>
                            </div>
                            <br/>

                            <br/>
                            <br/>
                            <div className='flex-row justify-center'>
                                <button className='button-style-5'
                                    onClick={() => {
                                        this._updateUser(this.state.currentAccountSelected);
                                    }}
                                >
                                    Modifier
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {
                    // this.state.auth &&
                    //     <div className='auth absolute'>
                    //         <AUthScreen
                    //             action={ (password) => {
                    //                 this.state.authAction(password)
                    //             }}
                    //             closeAuth={() => {this._openOrCloseAuth()}}
                    //         />
                    //     </div>
                }
            </>
        )
    }
}

DashboardUsersList = connect(mapStateToProps, null)(DashboardUsersList);

export default DashboardUsersList;
