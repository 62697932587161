function SET_ADMIN_USERS_LIST(state, value) {
    // console.log( state );
    let nextState = {
        ...state,
    }
    nextState.admin.usersList  = value;

    return nextState;
}

export default SET_ADMIN_USERS_LIST;
