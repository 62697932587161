import axios from "axios";
import PATH from '../GLOBAL-VAR/globals-urls';

const PATH_ = new PATH();

class AttributesRequests {
    _get(attribute, access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + attribute + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _get_public(attribute) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + attribute + "/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }
}

export default AttributesRequests;