/**Importation des actions du store */
import SET_ON_PANIER from '../store_actions/panierActions/set_on_panier.action';

const initialState = {
    panier: [],
    count: 0,
}

function Panier(state = initialState, action) {
    switch (action.type) {
        case 'SET_ON_PANIER':
            return SET_ON_PANIER(state, action.value);

        default:
            return state
    }

}

export default Panier;