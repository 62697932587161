import React from 'react';
import { connect } from 'react-redux';
// import words from 'an-array-of-french-words';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/





/**
 * 
 * Importation des scomposants
 */
import InputStyle1 from '../components/input-style1.component/input-style1.component';
import SearchInputComponent from '../components/searchInput.component/searchInput.component';
import ChronogramAOF from '../components/ChronogramAOF.component/ChronogramAOF.component';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}


class DashboardAddGroup extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            permissionList: [],
            groupsPermissions: [],

            accountsList: [],

            searchResult: [],

            viewTaskManager: false,
            chronogram: [],
            chronogramTree: {},
            currentGroupContentParentIndex: null,
            chronogramUpdateType: "add",
            currentGroupContent: {
                name: "",
                acronym: "",
                currentPermissionList: [],
                currentAccountsList: [],
                parent_id: null,
                parent_index: null,
            },

            cartographyName: "",
            task_tree: [],
            task_positions: [],
            task_list: [],
        }

        this._submit_addGroup_form = this._submit_addGroup_form.bind(this);
        this.accessStore = new AccessStore(this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            isLoading: true,
        });

        this._get_all_permissions(( permissionList )=>{
            // console.log( permissionList );
            this.setState({
                permissionList : permissionList,
            }, () => {
                let groupsPermissions = [];
                this.state.permissionList.map((permission, index) => {
                    
                    let inGroupsPermissions = false;
                    let group_name = permission.name.split("Can add").length === 2 ? permission.name.split("Can add")[1] : (
                        permission.name.split("Can change").length === 2 ? permission.name.split("Can change")[1]: (
                            permission.name.split("Can delete").length === 2 ? permission.name.split("Can delete")[1] : (
                                permission.name.split("Can view").length === 2 ? permission.name.split("Can view")[1] : ""
                            )
                        )
                    );
                    
                    groupsPermissions.map((group, groupIndex) => {
                        if(group.name === group_name) {
                            inGroupsPermissions = groupIndex;
                        }
                    })

                    if( inGroupsPermissions === false ) {
                        groupsPermissions.push({
                            name: group_name,
                            permissions: [permission]
                        });
                    }

                    else {
                        groupsPermissions[inGroupsPermissions].permissions.push(permission);
                    }

                    // console.log( groupsPermissions );
                });

                this.setState({
                    groupsPermissions: groupsPermissions,
                });

                this._get_users((accounts) => {
                    this.setState({
                        accountsList: accounts,
                        isLoading: false,
                    });
                })
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else if( error.response.status === 400 ) {
                if( error.response.data.name ) {
                    alert(error.response.data.name[0]);
                }
                this.setState({
                    isLoading: false,
                });
                // console.log( error.response.data );
            }

            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à interagir avec cette section");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _start_submit_group(index){
        this.setState({
            isLoading: true,
        });
        if(index < this.state.task_list.length -1) {
            this._submit_addGroup_form(index, () => {
                this._start_submit_group(index+1);
            });
        } else {
            this._submit_addGroup_form(index, () => {
                let groupSystemInfo = {
                    "name": this.state.cartographyName,
                    "details": {
                        type: "cartography",
                        task_tree: this.state.task_tree,
                        task_positions: this.state.task_positions,
                        task_list: this.state.task_list,
                    }
                }
                this._add_group_systemInfo(groupSystemInfo, (res) => {
                    alert("Groupe ajouter avec succès");
                    this.setState({
                        isLoading: false,
                    });
                });
            });
        }
    }

    _submit_addGroup_form(index, callback=()=>{}) {
        // console.log(this.state.task_list[index].currentPermissionList);
        let data = {
            name: this.state.task_list[index].name,
            "description": "...",
            "others": {
                acronym: this.state.task_list[index].acronym
            },
            "order": {},
            permissions: [this.state.task_list[index].currentPermissionList],
        };

        // console.log( data );
        this._add_group(data, (res) => {
            // console.log(res);
            this._update_group_initExtended(data, res.id,(response) => {
                let groupList = this.state.task_list;
                this.state.task_list[index]["group"] = res.id;
                this.setState({
                    task_list: groupList,
                });
                let other_data = {
                    users: this.state.task_list[index].currentAccountsList,
                    groups: [res.id],
                }

                this._add_user_to_group(other_data, () => {
                    callback();
                });
            });
        });
        
    }

    _add_group( data, callback=()=>{} ) {
        this._addGroup(data, this.props.user.access, (response) => {
            if( response.status === 201 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._add_group(data, callback);
            })
        });
    }

    _add_group_systemInfo( data, callback=()=>{} ) {
        this._systemInfoEdit(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._add_group_systemInfo(data, callback);
            })
        });
    }

    _update_group_initExtended( data, id, callback=()=>{} ) {
        this._updateGroup__(data, id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._update_group_initExtended(data, id, callback);
            })
        });
    }
    _add_user_to_group( data, callback=()=>{} ) {
        this._addAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_user_to_group(data, callback);
            });
        });
    }

    _get_all_permissions(callback=()=>{}) {
        this._getAllPermissions(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_permissions(callback);
            });
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _get_users(callback=()=>{}) {
        this._getAccounts(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_users(callback);
            })
        });
    }

    _addTask(currentTextContent, currentTextContentParentIndex) {
        let task_tree = this.state.task_tree;
        let task_positions = this.state.task_positions;
        let task_list = this.state.task_list;


        if(currentTextContentParentIndex !== null ) {
            task_list.push(currentTextContent);
            task_positions.push(task_positions[currentTextContentParentIndex] + "," + (task_list.length-1));
            task_tree.push([]);
            task_tree[currentTextContentParentIndex].push(task_tree.length-1);
        }
        else {
            task_list.push(currentTextContent);
            // let lastIndex = 0;
            // task_positions.map((position, index) => {
            //     if(Number(position[0]) > lastIndex) {
            //         console.log(position[0], lastIndex);
            //         lastIndex = Number(position[0]) + 1;
            //     }
            //     return true;
            // })
            task_positions.push((task_list.length-1).toString());
            // console.log(task_positions);
            task_tree.push([]);
        }

        this.setState({
            task_tree: task_tree,
            task_positions: task_positions,
            task_list: task_list,
            currentTextContent: {
                name: "",
                currentPermissionList: [],
                currentAccountsList: [],
                parent_id: null,
                parent_index: null,
            },
            viewTaskManager: false,
        });
    }

    _updateTask(taskIndex, task) {
        let task_list = this.state.task_list;
        task_list[taskIndex] = task;
        this.setState({
            task_list: task_list,
            currentTextContent: {
                name: "",
                currentPermissionList: [],
                currentAccountsList: [],
                parent_id: null,
                parent_index: null,
            },
            viewTaskManager: false,
        });
    }

    _deleteTask(taskIndex) {
        let task_tree = this.state.task_tree;
        let task_positions = this.state.task_positions;
        let task_list = this.state.task_list;

        // let child = this.get_all_child_of_node(task_tree, null, taskIndex, 0);

        /**Appel recursid de suppressionde chacun des fils */
        if(task_tree[taskIndex].length > 0) {
            for(let i=0; i<task_tree[taskIndex].length; i++) {
                this._deleteTask(task_tree[taskIndex][i]);
            }
        }

        /**Suppression de l'element dans la liste des fils du parent */
        let positionsTab = task_positions[taskIndex].split(",");
        if(positionsTab.length > 1) {
            // console.log(task_list[taskIndex].task, positionsTab);
        
            positionsTab.splice(-1);
            let parentPosition = positionsTab.toString();
            let parentIndex = task_positions.indexOf(parentPosition);
            // console.log(parentIndex, positionsTab.toString());
            
            let positionInParentOnTree = task_tree[parentIndex].indexOf(taskIndex);
            // console.log(positionInParentOnTree);
            task_tree[parentIndex].splice(positionInParentOnTree, 1);
        }

        /**Destruction du noeud courant */
        task_tree[taskIndex] = -1;

        task_positions[taskIndex] = -1;
        task_list[taskIndex] = -1;

        // this.renameTask(task_tree, task_positions, task_list);

        this.setState({
            task_tree: task_tree,
            task_positions: task_positions,
            task_list: task_list,
        });

        // console.log(task_tree, task_positions, task_list);
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                
                <div className='dashboardUser'>
                    <div className='text-align-center login_screen_subTitle'>
                        <span className='letter-spacing-2 font-size-1_5 bold text-shadow-ff'>
                            Cartographie
                        </span>
                    </div>

                    <div className='dashboard_body_input'>
                        {/* <div className='dashboard_body_input_label'>
                            <span>Cartographie</span>
                        </div> */}
                        <div className='task_editor_inputContent'>
                            <span>
                                Nom de la cartographie:
                            </span>
                            <input type="text" value={this.state.cartographyName}
                                onChange={(e) => {
                                    this.setState({
                                        cartographyName: e.target.value,
                                    });
                                }}
                                autoFocus
                            />
                        </div>
                        <br/>

                        <div className='dashboard_body_input_task'>
                            <ChronogramAOF
                                tree={this.state.task_tree}
                                positions={this.state.task_positions}
                                list={this.state.task_list}
                                control={true}
                                controlText={"Ajouter un élément"}
                                noHoverEffect={true}

                                editAction={(task_index) => {
                                    this.setState({
                                        chronogramUpdateType: "update",
                                    }, () => {
                                        this.setState({
                                            currentGroupContentParentIndex: task_index,
                                            viewTaskManager: true,
                                            currentGroupContent: this.state.task_list[task_index],
                                        })
                                    });
                                    return true;
                                }}

                                deleteAction={(taskIndex) => {
                                    if(window.confirm("Voulez-vous vraiment supprimer ce contenu?")) {
                                        this._deleteTask(taskIndex)
                                        return true;
                                    }
                                }}

                                addAction={(parent_index) => {
                                    // console.log();
                                    this.setState({
                                        chronogramUpdateType: "add",
                                        currentGroupContent: {
                                            name: "",
                                            acronym: "",
                                            currentPermissionList: [],
                                            currentAccountsList: [],
                                            parent_id: null,
                                            parent_index: null,
                                        },
                                    }, () => {
                                        this.setState({
                                            currentGroupContentParentIndex: parent_index,
                                            viewTaskManager: true,
                                        })
                                    });
                                    return true;
                                }}
                            />
                        </div> 
                    </div>
                    <button className='button-style-16'
                        onClick={(e) => {
                            this._start_submit_group(0);
                        }}
                    >
                        VALIDER
                        <span className='button-style-5-span'></span>
                    </button>
                </div>

                { this.state.viewTaskManager &&
                    <div className='pop-up task_editor'>
                        <div className='pop-up-content' style={{
                            animation: "none",
                            opacity: 1,
                            transform: "scale(1)",
                        }}>
                            <div className='pop-up-content-goBack'>
                                <button className='button-style-5'
                                    onClick={(e) => {
                                        this.setState({
                                            viewTaskManager: false,
                                            currentGroupContent: {
                                                name: "",
                                                acronym: "",
                                                currentPermissionList: [],
                                                currentAccountsList: [],
                                                parent_id: null,
                                                parent_index: null,
                                            },
                                        });
                                    }}
                                >
                                    annuler
                                </button>
                            </div>
                            
                            <form
                                action="#"
                                target="_self"
                                method="post"
                                name="addGroupForm"
                                style={{ background: "transparent", display: "block" }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if(this.state.chronogramUpdateType === "add") {
                                        this._addTask(this.state.currentGroupContent, this.state.currentGroupContentParentIndex);
                                    } else if(this.state.chronogramUpdateType === "update") {
                                        this._updateTask(this.state.currentGroupContent, this.state.currentGroupContentParentIndex);
                                    }
                                }}
                            >
                                <div className='task_editor_inputContent'>
                                    <span>
                                        Nom du groupe:
                                    </span>
                                    <input type="text" value={this.state.currentGroupContent.name}
                                        onChange={(e) => {
                                            let currentGroupContent = this.state.currentGroupContent;
                                            currentGroupContent.name = e.target.value;
                                            this.setState({
                                                currentGroupContent: currentGroupContent,
                                            });
                                        }}
                                        autoFocus
                                    />
                                </div>
                                <br/>
                                <div className='task_editor_inputContent'>
                                    <span>
                                        Acronyme du groupe:
                                    </span>
                                    <input type="text" value={this.state.currentGroupContent.acronym}
                                        onChange={(e) => {
                                            let currentGroupContent = this.state.currentGroupContent;
                                            currentGroupContent.acronym = e.target.value;
                                            this.setState({
                                                currentGroupContent: currentGroupContent,
                                            });
                                        }}
                                    />
                                </div>
                                <br/>

                                <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                    <label>
                                        Sélectionner les permissions du groupe:
                                    </label>
                                </div>

                                <br/>
                                <div className="flex-row align-center">
                                    <input id={"selectAllPerm"} name={"selectAllPerm"} style={{marginRight: "5px"}} type="checkbox"
                                        onChange={(e)=>{
                                            let currentGroupPermissions = [];
                                            let currentGroupContent = this.state.currentGroupContent;

                                            if( e.target.checked === true ) {
                                                this.state.groupsPermissions.map((permissionGroup, index) => {
                                                    document.getElementById(permissionGroup.name).checked = true;
                                                    permissionGroup.permissions.map((permission, permIndex) => {
                                                        currentGroupPermissions.push(permission.id);
                                                    });
                                                });
                                                
                                                currentGroupContent.currentPermissionList = currentGroupPermissions;

                                                this.setState({
                                                    currentGroupContent: currentGroupContent,
                                                });
                                            }
                                            else {
                                                currentGroupContent.currentPermissionList = [];
                                                this.state.groupsPermissions.map((permissionGroup, index) => {
                                                    document.getElementById(permissionGroup.name).checked = false;
                                                });
                                                this.setState({
                                                    currentGroupContent: currentGroupContent,
                                                });
                                            }
                                            return true;
                                        }}
                                    />
                                    <span className='font-size-1_1'>
                                        <label htmlFor={"selectAllPerm"}>
                                            Sélectionné toutes les permissions
                                        </label>
                                    </span>
                                </div>
                                <br/>
                                <div className=''>
                                    {
                                        this.state.groupsPermissions.map((permissionGroup, index) => (
                                            <div className='flex-row justify-between align-center permissionGroup'>
                                                <div className="flex-row align-center">
                                                    <input id={permissionGroup.name} name={permissionGroup.name} style={{marginRight: "5px"}} type="checkbox"
                                                        onChange={(e)=>{
                                                            // console.log( e.target.checked );
                                                            let currentGroupContent = this.state.currentGroupContent;
                                                            if( e.target.checked === true ) {
                                                                permissionGroup.permissions.map((permission, permIndex) => {
                                                                    if( !currentGroupContent.currentPermissionList.includes(permission.id) ) {
                                                                        currentGroupContent.currentPermissionList.push(permission.id);
                                                                    }
                                                                });

                                                                e.target.checked = true;
                                                            }
                                                            else {
                                                                permissionGroup.permissions.map((permission, permIndex) => {
                                                                    currentGroupContent.currentPermissionList.splice( currentGroupContent.currentPermissionList.indexOf(permission.id), 1);
                                                                });
                                                                e.target.checked = false;
                                                            }

                                                            // console.log(currentGroupContent);
                                                            this.setState({
                                                                currentGroupContent: currentGroupContent,
                                                            });
                                                            return true;
                                                        }}
                                                    />
                                                    <span className='font-size-1_1'>
                                                        <label htmlFor={permissionGroup.name}>
                                                            {permissionGroup.name} permissions
                                                        </label>
                                                    </span>
                                                </div>
                                            
                                                <div
                                                    className="registration_form_input flex-row flex-wrap"
                                                >
                                                    {
                                                        permissionGroup.permissions.map((permission, permIndex) => (
                                                            <div className='permission-option'
                                                                style={{
                                                                    backgroundColor: this.state.currentGroupContent.currentPermissionList.includes(permission.id) ? "#0A376433" : "transparent"
                                                                }}
                                                            >
                                                                {
                                                                    permission.name.split("view").length > 1 ?
                                                                        <div className='color-green'>
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"/>
                                                                                <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                                            </svg>
                                                                        </div>
                                                                    :
                                                                        (
                                                                            permission.name.split("change").length > 1 ?
                                                                                <div className='color-blue'>
                                                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fillRule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                                                                    </svg>
                                                                                </div>
                                                                            :
                                                                                (
                                                                                    permission.name.split("add").length > 1 ?
                                                                                        <div className='color-blue'>
                                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                                                            </svg>
                                                                                        </div>
                                                                                    :
                                                                                        (
                                                                                            permission.name.split("delete").length > 1 ?
                                                                                                <div className='color-red'>
                                                                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                                                    </svg>
                                                                                                </div>
                                                                                            :
                                                                                                null
                                                                                        )
                                                                                )
                                                                        )
                                                                }

                                                                <div className='permission-option-view'>
                                                                    <p>
                                                                        <label key={permission.name + "/" + permission.id} htmlFor={permission.name + "/" + permission.id} className='flex-row align-center'>
                                                                            <input id={permission.name + "/" + permission.id} name={permission.name + "/" + permission.id} type='checkbox' checked={this.state.currentGroupContent.currentPermissionList.includes(permission.id)}
                                                                                onChange={(e)=>{
                                                                                    let currentGroupContent = this.state.currentGroupContent;
                                                                                    
                                                                                    if( e.target.checked === true ) {
                                                                                        currentGroupContent.currentPermissionList.push(permission.id);
                                                                                    }
                                                                                    else {
                                                                                        currentGroupContent.currentPermissionList.splice( currentGroupContent.currentPermissionList.indexOf(permission.id), 1);
                                                                                    }
                                                                                    this.setState({
                                                                                        currentGroupContent: currentGroupContent,
                                                                                    });
                                                                                    return true;
                                                                                }}
                                                                            />
                                                                            <label htmlFor={permission.name + "/" + permission.id}
                                                                                style={{
                                                                                    fontSize: "0.8em",
                                                                                    marginLeft: "5px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    permission.name
                                                                                }
                                                                            </label>
                                                                        </label>
                                                                    </p>
                                                                    <div className='permission-option-view-bottom'></div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <br/>

                                <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                                    <label>
                                        Sélectionner les utilisateurs du groupe:
                                    </label>
                                </div>

                                <div>
                                    <SearchInputComponent key_="username" printSelected={true} id_="id" data={ this.state.searchResult.length > 0 ? this.state.searchResult : this.state.accountsList } currentSelected={this.state.currentGroupContent.currentAccountsList} placeholder="Nom d'utilisateur"
                                        onChange={(value) => {
                                            let tab = [];
                                            this.state.accountsList.map((account, index) => {
                                                if( account.username.toLowerCase() === value.toLowerCase() ||
                                                    account.username.toLowerCase().split(value.toLowerCase()).length > 1
                                                ) {
                                                    tab.push(account);
                                                }
                                            });

                                            this.setState({
                                                searchResult: tab,
                                            });
                                        }}
                                        onSelected={(selected) => {
                                            if( this.state.currentGroupContent.currentAccountsList.includes(selected.id) )  {
                                                let currentGroupContent = this.state.currentGroupContent;
                                                for( var i=0; i<currentGroupContent.currentAccountsList.length; i++) {
                                                    if( currentGroupContent.currentAccountsList[i] === selected.id ) {
                                                        currentGroupContent.currentAccountsList.splice(i, 1);
                                                        this.setState({
                                                            currentGroupContent: currentGroupContent,
                                                        });
                                                        return true;
                                                    }
                                                }
                                            }

                                            else {
                                                let currentGroupContent = this.state.currentGroupContent;
                                                currentGroupContent.currentAccountsList.push(selected.id);
                                                // console.log( currentAccountsList );
                                                this.setState({
                                                    currentGroupContent: currentGroupContent,
                                                });
                                            }
                                        }}

                                        style={(elt)=>{
                                            return {
                                                backgroundColor: this.state.currentGroupContent.currentAccountsList.includes(elt.id) ? "#dc354555" : "transparent",
                                                color: this.state.currentGroupContent.currentAccountsList.includes(elt.id) ? "#ffffff" : "#000000",
                                            }
                                        }}

                                        isChecked={(elt) => {
                                            return this.state.currentGroupContent.currentAccountsList.includes(elt.id) ? true : false 
                                        }}
                
                                        icon={
                                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    marginRight: "5px",
                                                }}
                                            >
                                                <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                            </svg>
                                        }
                                    />
                                </div>

                                <div className='task_editor_button'>
                                    <button className='button-style-5'>
                                        Ajouter
                                        <span className='button-style-5-span'></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>}
            </>
        )
    }
}

DashboardAddGroup = connect(mapStateToProps, null)(DashboardAddGroup);

export default DashboardAddGroup;
