// import { ReactDOM } from 'react';
import React from 'react';
import parse from 'html-react-parser';

/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.scss';

/**Importation des composants */

/**Importation des images*/

class ChronogramStyle2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewPending: true,
            viewInGoodProgress: true,
            viewInBadProgress: true,
            viewLate: true,
            viewGoodFinished: true,
            viewBadFinished: true,
        }
    }

    componentDidMount() {
        
    }

    _generateChronogram(list, state=0, parent=null) {
        return(
            <ul className={ state===0 ? 'textView_list_2_' : "textView_list_2"}>
                {
                    list.map((elt, index) => (
                        elt!==-1 && index !==-1 &&
                        ((state===0 && (this.props.positions[index] && ((this.props.positions[index].split(",")).length===1)) ) || (state!==0)) && 
                        <li className={state===0 ? 'textView_list_2_elt textView_list_2_' : "textView_list_2_elt"}>
                            <div className='textView_list_2_elt_text'>
                                <span className='chronogram2_list_elt_text_'>
                                    <div className={this.props.control ? "chronogram2_list_elt_text_ctn_" : "chronogram2_list_elt_text_ctn"}>
                                        <span className={'textView_list_2_elt_text_ title_L' + this.props.list[state === 0 ? index : elt].title_level}>
                                            {this.props.list[state === 0 ? index : elt].name} : {this.props.list[state === 0 ? index : elt].title}
                                        </span>
                                        {
                                            this.props.control &&
                                                <div className='chronogram2_list_elt_text_ctn_control'>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill chronogram2_list_elt_text_ctn_control_edit" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                        onClick={(e) => {
                                                            this.props.editAction(state === 0 ? index : elt);
                                                            return true;
                                                        }}
                                                    >
                                                        <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                    </svg>
                                                    <svg className='chronogram2_list_elt_text_ctn_control_delete' width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                        onClick={(e) => {
                                                            this.props.deleteAction(state === 0 ? index : elt);
                                                            return true;
                                                        }}
                                                    >
                                                        <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z"/>
                                                        <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z"/>
                                                    </svg>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                        onClick={(e) => {
                                                            this.props.addAction(state === 0 ? index : elt);
                                                            return true;
                                                        }}
                                                    >
                                                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                    </svg>
                                                </div>
                                        }
                                    </div>
                                </span>
                            </div>
                            {
                                this.props.list[state === 0 ? index : elt].text ? 
                                    <div className='textView_list_2_elt_content'>
                                        {
                                            parse(this.props.list[state === 0 ? index : elt].text)
                                        }
                                    </div>
                                :
                                    null
                            }
                            { state===0 ? (elt.length > 0 ? this._generateChronogram(elt, state+1, index) : "") : (this.props.tree[elt].length > 0 ?  (this._generateChronogram(this.props.tree[elt], state+1, elt)) : "")}
                        </li>
                    ))
                }
            </ul>
        )
    }

    
    render() {
        return (
            <div>
                <div className='textView'>
                    {this._generateChronogram(this.props.tree)}
                </div>
                {
                    this.props.control &&
                        <div className='chronogram2_control'>
                            <span className='chronogram2_control_button button-style-5'
                                onClick={(e) => {
                                    this.props.addAction(null);
                                }}
                            >
                                {this.props.controlText}
                                <span className='button-style-5-span'></span>
                            </span>
                        </div>
                }
            </div>
        )
    }
}

export default ChronogramStyle2;