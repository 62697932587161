import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputStyle1 from '../components/input-style1.component/input-style1.component';
import ChronogramStyle2 from '../components/ChronogramStyle2.component/ChronogramStyle2.component';
import SearchInputComponent from '../components/searchInput.component/searchInput.component';
import CheckboxComponent from '../components/checkbox.component/checkbox.component';
// import ImageSelector from '../components/imageSelector/imageSelector.component';
import parse from 'html-react-parser';


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/dashboard.addText.style.scss';



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DashboardUpdateText extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            allLawText: [],
            _allLawText: [],
            currentLawTextList: [],
            searchLawTextResults: [],
            currentDeleted: 0,

            viewTextManager: false,
            viewTaskManager: false,

            currentTextLaw: {
                name: "",
            },
            currentTextContent: {
                name: "",
                title: "",
                title_level: 1,
                text: "",
                parent_id: null,
                parent_index: null,
            },
            currentTextContentParentIndex: null,
            chronogramUpdateType: "add",
        }

        this.renameTasks= [];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        
        this._get_all_lawText_((response) => {
            // console.log(response.data);
            this.setState({
                allLawText: response.data,
                _allLawText: response.data,
                isLoading: false,
            });
        });
    }

    _get_all_lawText_(callback=()=>{}) {
        this._getAllLawText(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_lawText_(callback);
            });
        });
    }

    _ifAllSearchIsChecked() {
        let allIsChecked = true;
        this.state.searchLawTextResults.map((lawText, index) => {
            if(!this.state.currentLawTextList.includes(lawText.id)) {
                allIsChecked = false;
                return false;
            }
        });
        return allIsChecked;
    }
    
    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                */

                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à ajouter votre document pour un groupe");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _addTask(currentTextContent, currentTextContentParentIndex) {

        let currentTextLaw = this.state.currentTextLaw;
        if(currentTextContentParentIndex !== null ) {
            currentTextLaw.details.texts.list.push(currentTextContent);
            currentTextLaw.details.texts.positions.push(currentTextLaw.details.texts.positions[currentTextContentParentIndex] + "," + (currentTextLaw.details.texts.list.length-1));
            currentTextLaw.details.texts.tree.push([]);
            currentTextLaw.details.texts.tree[currentTextContentParentIndex].push(currentTextLaw.details.texts.tree.length-1);
        }
        else {
            currentTextLaw.details.texts.list.push(currentTextContent);
            currentTextLaw.details.texts.positions.push((currentTextLaw.details.texts.list.length-1).toString());
            currentTextLaw.details.texts.tree.push([]);
        }

        this.setState({
            currentTextLaw: currentTextLaw,
            currentTextContent: {
                name: "",
                title: "",
                title_level: 1,
                text: "",
                parent_id: null,
                parent_index: null,
            },
            viewTaskManager: false,
        });
    }

    _updateTask(taskIndex, task) {
        let currentTextLaw = this.state.currentTextLaw;
        currentTextLaw.details.texts.list[taskIndex] = task;
        this.setState({
            currentTextLaw: currentTextLaw,
            currentTextContent: {
                name: "",
                title: "",
                title_level: 1,
                text: "",
                parent_id: null,
                parent_index: null,
            },
            viewTaskManager: false,
        });
    }

    _deleteTask(taskIndex) {
        let currentTextLaw = this.state.currentTextLaw;

        /**Appel recursid de suppressionde chacun des fils */
        if(currentTextLaw.details.texts.tree[taskIndex].length > 0) {
            for(let i=0; i<currentTextLaw.details.texts.tree[taskIndex].length; i++) {
                this._deleteTask(currentTextLaw.details.texts.tree[taskIndex][i]);
            }
        }

        /**Suppression de l'element dans la liste des fils du parent */
        let positionsTab = currentTextLaw.details.texts.positions[taskIndex].split(",");
        if(positionsTab.length > 1) {
            // console.log(currentTextLaw.details.texts.list[taskIndex].task, positionsTab);
        
            positionsTab.splice(-1);
            let parentPosition = positionsTab.toString();
            let parentIndex = currentTextLaw.details.texts.positions.indexOf(parentPosition);
            // console.log(parentIndex, positionsTab.toString());
            
            let positionInParentOnTree = currentTextLaw.details.texts.tree[parentIndex].indexOf(taskIndex);
            // console.log(positionInParentOnTree);
            currentTextLaw.details.texts.tree[parentIndex].splice(positionInParentOnTree, 1);
        }

        /**Destruction du noeud courant */
        currentTextLaw.details.texts.tree[taskIndex] = -1;

        currentTextLaw.details.texts.positions[taskIndex] = -1;
        currentTextLaw.details.texts.list[taskIndex] = -1;

        // this.renameTask(task_tree, task_positions, task_list);

        this.setState({
            currentTextLaw: currentTextLaw,
        });

        // console.log(task_tree, task_positions, task_list);
    }

    _startUpdateText() {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isLoading: true,
                });
    
                let formData = new FormData();
                formData.append("name", this.state.currentTextLaw.name);
    
                this._updateLawText_(this.state.currentTextLaw.id, formData, (response) => {
                    // console.log( response.data );
                    // clearInterval( this.submitTime );
                    this._openOrCloseAuth();
                    // this.setState({
                    //     isProgress: false,
                    //     progressionTile: "",
                    //     requestProgression: 0,
                    // });

                    let detailsFormData = {
                        "details": this.state.currentTextLaw.details,
                    };

                    this._updateDetailsLawText_(this.state.currentTextLaw.id, detailsFormData, ((response_) => {
                        // console.log(response.data);
                        this.setState({
                            isLoading: false,
                        });
                    }));
                    
                });
            }
        }, () => {
            this._openOrCloseAuth();
        });
    }

    _updateLawText_(id, data, callback=()=>{}) {
        this._updateLawText(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 200 ) {
                callback(response);
            }
        },(error) => {
            // console.log(error)
            this._manageError( error, () => {
                this._updateLawText_(data, callback);
            });
        });
    }

    _updateDetailsLawText_(id, data, callback=()=>{}) {
        this._updateDetailsLawText(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 200 ) {
                callback(response);
            }
        },(error) => {
            console.log(error.response)
            this._manageError( error, () => {
                this._updateDetailsLawText_(id, data, callback);
            });
        });
    }

    _startDeleteAllLawText(callback=()=>{}) {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isProgress: true,
                    progressionTile: 'Suppressions en court...',
                });
                this._delete_all_lawText(this.state.currentLawTextList, 0, ()=>{
                    this.setState({
                        isProgress: false,
                        progressionTile: 'Suppressions terminée',
                        requestProgression: 0,
                    });
                    let currentLawTextList = this.state.currentLawTextList;
                    let _allLawText = this.state._allLawText;
                    let _allLawText_ = [];

                    let searchLawTextResults = this.state.searchLawTextResults;
                    let searchLawTextResults_ = [];

                    searchLawTextResults.map((lawText, index) => {
                        if(!currentLawTextList.includes(lawText.id)) {
                            searchLawTextResults_.push(lawText);
                        }
                        if( index === searchLawTextResults.length-1 ) {
                            this.setState({
                                searchLawTextResults: searchLawTextResults_,
                            });
                        }
                    });

                    _allLawText.map((lawText, index) => {
                        if(!currentLawTextList.includes(lawText.id)) {
                            _allLawText_.push(lawText);
                        }
                        if( index === _allLawText.length-1 ) {
                            this.setState({
                                currentLawTextList: [],
                                _allLawText: _allLawText_,
                            });
        
                            callback();
                            this._openOrCloseAuth();
                        }
                    });
                })
            }
        }, () => {
            this._openOrCloseAuth();
        });
    }

    _startDeleteOneLawText(id, callback=()=>{}) {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isLoading: true,
                });
                this._delete_on_lawText(id, ()=>{
                    alert("Texte de lois supprimé avec succès");
                    this.setState({
                        isLoading: false,
                    });
                    callback();
                    this._openOrCloseAuth();
                });
            }
        }, () => {
            this._openOrCloseAuth();
        });
    }

    _delete_on_lawText(id, callback=()=>{}) {
        this._deleteLawText(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._delete_on_lawText(id, callback);
            });
        });
    }

    _delete_all_lawText(list, index, callback=()=>{}) {
        if( index < list.length ) {
            this._delete_on_lawText(list[index], ()=>{
                this.setState({
                    requestProgression: Math.round((100 * index+1) / list.length),
                }, () => {
                    this._delete_all_lawText(list, index+1, callback);
                });
            })
        }else {
            callback();
        }
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='dashboardUser'>
                    <div className='text-align-center login_screen_subTitle'>
                        <span className='letter-spacing-2 font-size-1_5 bold text-shadow-ff'>
                            Liste des textes de loi dans le système : {this.state._allLawText.length}
                        </span>
                    </div>
                    {/* <form
                        action="#"
                        target="_self"
                        method="post"
                        name="addGroupForm"
                        style={{ background: "transparent" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    > */}
                        <div className='login_screen_input_container'>
                            <InputStyle1
                                label="Rechercher un text"
                                name="keyword"
                                type="text"
                                required={false}
                                color={this.VAR.color_1}
                                labelStyle={{
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    color: this.VAR.color_1,
                                }}
                                onChange={(value)=>{
                                    let tab = [];
                                    this.state._allLawText.map((lawText, index) => {
                                        if( lawText.details.text_number ) {
                                            if( lawText.details.text_number.toLowerCase() === value.toLowerCase() ||
                                                lawText.details.text_number.toLowerCase().split(value.toLowerCase()).length > 1
                                            ) {
                                                tab.push(lawText);
                                            }
                                        }
                                    });

                                    this.setState({
                                        searchLawTextResults: tab,
                                    });
                                }}
                            />
                        </div>
                        <br/>

                        <div className='registration_form_label bold font-size-1_2 letter-spacing-1'>
                            <label htmlFor='selectAllKeywords'>
                                Sélectionner tout les textes de loi:
                            </label>
                            <input id="selectAllKeywords" name="selectAllKeywords" checked={
                                this.state.searchLawTextResults.length > 0 ?
                                    this._ifAllSearchIsChecked()
                                :
                                    this.state._allLawText.length===this.state.currentLawTextList.length
                            } style={{marginLeft: "10px", marginRight: "10px"}} type="checkbox"
                                onChange={(e)=>{
                                    if( this.state.searchLawTextResults.length > 0 ) {
                                        let currentLawTextList = this.state.currentLawTextList;
                                        if( e.target.checked === true ) {
                                            this.state.searchLawTextResults.map((lawText, index)=>{
                                                if( !currentLawTextList.includes(lawText.id) ) {
                                                    currentLawTextList.push(lawText.id);
                                                }
                                                e.target.checked = true;
                                            })
                                        }
                                        else {
                                            this.state.searchLawTextResults.map((lawText, index)=>{
                                                if( currentLawTextList.includes(lawText.id) ) {
                                                    currentLawTextList.splice( currentLawTextList.indexOf(lawText.id), 1);
                                                }
                                            })
                                        }
                                        this.setState({
                                            currentLawTextList: currentLawTextList,
                                        });
                                    } else {
                                        let currentLawTextList = this.state.currentLawTextList;
                                        if( e.target.checked === true ) {
                                            this.state._allLawText.map((lawText, index)=>{
                                                if( !currentLawTextList.includes(lawText.id) ) {
                                                    currentLawTextList.push(lawText.id);
                                                }
                                                e.target.checked = true;
                                            })
                                        }
                                        else {
                                            currentLawTextList = [];
                                        }
                                        this.setState({
                                            currentLawTextList: currentLawTextList,
                                        });
                                    }
                                    return true;
                                }}
                            />
                            {this.state.searchLawTextResults.length > 0 ? this.state.searchLawTextResults.length : this.state._allLawText.length}
                        </div>
                        <br/>

                        <div className='flex-row justify-center'>
                            <button className='button-style-6'
                                disabled={!this.state.currentLawTextList.length>0}
                                style={{
                                    color: this.state.currentLawTextList.length>0 ? "red" : "#00000044",
                                    borderColor: this.state.currentLawTextList.length>0 ? "red" : "#00000044",
                                    fontSize: "0.8em",
                                }}
                                onClick={() => {
                                    this._startDeleteAllLawText(()=>{
                                        alert("Mots-clés supprimés avec succès");
                                    })
                                }}
                            >
                                <span>
                                    Supprimer les texts Sélectionnés
                                </span>
                            </button>
                        </div>

                        <br/>
                        <div className=''>
                            {
                                this.state[this.state.searchLawTextResults.length > 0 ? "searchLawTextResults" : "_allLawText"].map((lawText, index) => (
                                    <div className='flex-row justify-between align-center permissionGroup' key={lawText.id + lawText.name}>
                                        <div className="flex-row align-center">
                                            <input id={lawText.id} name={lawText.id} checked={this.state.currentLawTextList.includes(lawText.id)} style={{marginRight: "5px"}} type="checkbox"
                                                onChange={(e)=>{
                                                    let currentLawTextList = this.state.currentLawTextList;
                                                    if( e.target.checked === true ) {
                                                        if( !currentLawTextList.includes(lawText.id) ) {
                                                            currentLawTextList.push(lawText.id);
                                                        }
                                                        e.target.checked = true;
                                                    }
                                                    else {
                                                        currentLawTextList.splice( currentLawTextList.indexOf(lawText.id), 1);
                                                        e.target.checked = false;
                                                    }

                                                    this.setState({
                                                        currentLawTextList: currentLawTextList,
                                                    });
                                                    return true;
                                                }}
                                            />
                                            <span className='font-size-1_1'
                                                onClick={(e) => {
                                                    this.setState({
                                                        viewTextManager: true,
                                                        currentTextLaw: lawText,
                                                    });
                                                }}
                                            >
                                                <label htmlFor={lawText.details.text_number}>
                                                    {
                                                        lawText.details.text_type && lawText.details.text_number ?
                                                            lawText.details.text_type + " No " + lawText.details.text_number
                                                        :
                                                            null
                                                    }
                                                </label>
                                            </span>
                                        </div>
                                    
                                        <div
                                            className="registration_form_input keyword_options_input_ flex-row flex-wrap"
                                        >
                                            <div className='permission-option keyword-option-score'
                                                style={{
                                                    backgroundColor: this.state.currentLawTextList.includes(lawText.id) ? "#0A376433" : "transparent"
                                                }}
                                            >
                                                <div className='color-blue'>
                                                    <label htmlFor={lawText.id}>
                                                        {
                                                            lawText.details.write_at ?
                                                                lawText.details.write_at
                                                            :
                                                                null
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <button className='permission-option'
                                                style={{
                                                    backgroundColor: this.state.currentLawTextList.includes(lawText.id) ? "#0A376433" : "transparent"
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this._startDeleteOneLawText(lawText.id, () => {
                                                        return true;
                                                    });
                                                }}
                                            >
                                                <div className='color-red'>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    {/* </form> */}
                </div>

                { this.state.viewTextManager &&
                <div className='pop-up task_editor'>
                    <div className='pop-up-content'>
                        <div className='pop-up-content-goBack'>
                            <button className='button-style-5'
                                onClick={(e) => {
                                    this.setState({
                                        viewTextManager: false,
                                        currentTextLaw: {
                                            name: "",
                                        },
                                    });
                                }}
                            >
                                annuler
                            </button>
                        </div>
                        <div className='task_editor_inputContent'>
                            <span>
                                Nom:
                            </span>
                            <input type="text" value={this.state.currentTextLaw.name}
                                onChange={(e) => {
                                    let currentTextLaw = this.state.currentTextLaw;
                                    currentTextLaw.name = e.target.value;
                                    this.setState({
                                        currentTextLaw: currentTextLaw,
                                    });
                                }}
                                autoFocus
                            />
                        </div>

                        <div className='task_editor_inputContent'>
                            <span>
                                Type du texte :
                            </span>
                            <select name='lawTextType' value={this.state.currentTextLaw.details.text_type}
                                onChange={(e) => {
                                    let currentTextLaw = this.state.currentTextLaw;
                                    currentTextLaw.details.text_type = e.target.value;
                                    this.setState({
                                        currentTextLaw: currentTextLaw,
                                    });
                                }}
                            >
                                <option value={"Arrêté"}>Arrêté</option>
                                <option value={"Décret"}>Décret</option>
                                <option value={"Ordonance"}>Ordonance</option>
                            </select>
                        </div>

                        <div className='task_editor_inputContent'>
                            <span>
                                Numéro du texte de loi :
                            </span>
                            <input type="text" value={this.state.currentTextLaw.details.text_number}
                                onChange={(e) => {
                                    let currentTextLaw = this.state.currentTextLaw;
                                    currentTextLaw.details.text_number = e.target.value;
                                    this.setState({
                                        currentTextLaw: currentTextLaw,
                                    });
                                }}
                            />
                        </div>

                        <div className='task_editor_inputContent'>
                            <span>
                                Portant : 
                            </span>
                            <input type="text" value={this.state.currentTextLaw.details.subject}
                                onChange={(e) => {
                                    let currentTextLaw = this.state.currentTextLaw;
                                    currentTextLaw.details.subject = e.target.value;
                                    this.setState({
                                        currentTextLaw: currentTextLaw,
                                    });
                                }}
                            />
                        </div>

                        <div className='dashboard_body_input'>
                            <div className='dashboard_body_input_label'>
                                <span>Contenu du texte</span>
                            </div>

                            <div className='dashboard_body_input_task'>
                                <ChronogramStyle2
                                    tree={this.state.currentTextLaw.details.texts.tree}
                                    positions={this.state.currentTextLaw.details.texts.positions}
                                    list={this.state.currentTextLaw.details.texts.list}
                                    control={true}
                                    controlText={"Ajouter du contenu"}
                                    noHoverEffect={true}

                                    editAction={(task_index) => {
                                        this.setState({
                                            chronogramUpdateType: "update",
                                        }, () => {
                                            this.setState({
                                                currentTextContentParentIndex: task_index,
                                                viewTaskManager: true,
                                                currentTextContent: this.state.currentTextLaw.details.texts.list[task_index],
                                            })
                                        });
                                        return true;
                                    }}

                                    deleteAction={(taskIndex) => {
                                        if(window.confirm("Voulez-vous vraiment supprimer ce contenu?")) {
                                            this._deleteTask(taskIndex)
                                            return true;
                                        }
                                    }}

                                    addAction={(parent_index) => {
                                        this.setState({
                                            chronogramUpdateType: "add",
                                            currentTextContent: {
                                                name: "",
                                                title: "",
                                                title_level: 1,
                                                text: "",
                                                parent_id: null,
                                                parent_index: parent_index,
                                            },
                                        }, () => {
                                            this.setState({
                                                currentTextContentParentIndex: parent_index,
                                                viewTaskManager: true,
                                            });
                                        });
                                        return true;
                                    }}
                                />
                            </div>
                        </div>

                        <br/>

                        <div className='task_editor_button'>
                            <span className='button-style-5'
                                onClick={(e) => {
                                    this._startUpdateText();
                                }}
                            >
                                Mettre à jour
                                <span className='button-style-5-span'></span>
                            </span>
                        </div>
                    </div>
                </div>}

                { this.state.viewTaskManager &&
                <div className='pop-up task_editor'>
                    <div className='pop-up-content'>
                        <div className='pop-up-content-goBack'>
                            <button className='button-style-5'
                                onClick={(e) => {
                                    this.setState({
                                        viewTaskManager: false,
                                        currentTextContent: {
                                            name: "",
                                        },
                                    });
                                }}
                            >
                                annuler
                            </button>
                        </div>
                        <div className='task_editor_inputContent'>
                            <span>
                                Nom:
                            </span>
                            <input type="text" value={this.state.currentTextContent.name}
                                onChange={(e) => {
                                    let currentTextContent = this.state.currentTextContent;
                                    currentTextContent.name = e.target.value;
                                    this.setState({
                                        currentTextContent: currentTextContent,
                                    });
                                }}
                                autoFocus
                            />
                        </div>

                        <div className='task_editor_inputContent'>
                            <span>
                                Titre
                            </span>
                            <input type="text" value={this.state.currentTextContent.title}
                                onChange={(e) => {
                                    let currentTextContent = this.state.currentTextContent;
                                    currentTextContent.title = e.target.value;
                                    this.setState({
                                        currentTextContent: currentTextContent,
                                    });
                                }}
                            />
                        </div>

                        <div className='task_editor_inputContent'
                            style={{marginRight: "60px"}}
                        >
                            <span>
                                Niveau de titre :
                            </span>
                            <select value={this.state.currentTextContent.title_level}
                                onChange={(e) => {
                                    // console.log(e.target.value);
                                    let currentTextContent = this.state.currentTextContent;
                                    currentTextContent.title_level = Number(e.target.value);
                                    this.setState({
                                        currentTextContent: currentTextContent,
                                    });
                                }}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                            </select>
                        </div>
                        <div className='task_editor_inputContent'>
                            <div className='dashboard_body_input_label'>
                                <span>Text</span>
                            </div>

                            <div className='dashboard_body_input_editor'>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.currentTextContent.text}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        let currentTextContent = this.state.currentTextContent;
                                        currentTextContent.text = data;
                                        this.setState({
                                            currentTextContent: currentTextContent,
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div className='task_editor_button'>
                            <span className='button-style-5'
                                onClick={(e) => {
                                    if(this.state.chronogramUpdateType === "add") {
                                        this._addTask(this.state.currentTextContent, this.state.currentTextContentParentIndex);
                                    } else if(this.state.chronogramUpdateType === "update") {
                                        this._updateTask(this.state.currentTextContent, this.state.currentTextContentParentIndex);
                                    }
                                }}
                            >
                                Mettre à jour
                                <span className='button-style-5-span'></span>
                            </span>
                        </div>
                    </div>
                </div>}
            </>
        )
    }
}


DashboardUpdateText = connect(mapStateToProps, null)(DashboardUpdateText);
export default DashboardUpdateText;