// Store/configureStore.js
import { createStore, combineReducers } from 'redux';
import User from '../store/reducers/user_reducer';
import Panier from '../store/reducers/panier_reducer';
import Admin from './reducers/admin_reducer';

const MY_REDUCER = combineReducers({
    User,
    Panier,
    Admin
});

const Store = createStore(MY_REDUCER);

export default Store;
