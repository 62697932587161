import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */



/**----------------------------------------------------------------------------------------*/
/** Importation des screens */




/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';


/**----------------------------------------------------------------------------------------*/
/**Importation des images*/




/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DashboardCategories extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            categories: [],
            currentCategoriesIdView: [],
            default_attributes: [],
            sysAttr: [],
        }

        this.tools = new tools();
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.setState({
            isLoading: true,
        });

        // this._get_all_categories(( categories )=>{
        //     this.setState({
        //         categories : categories,
        //         isLoading: false,
        //     });
        // });

        let month_values = [];
        let categories_values = [];
        let levels_values = [];

        this._get_all_categories(( categories )=>{
            // console.log(categories);
            this._get_users((accounts) => {
                this._get_all_groups((groups) => {
                    categories.map((category, ind) => {
                        if(category.fields.objects) {
                            category.fields.objects.map((object, idx) => {
                                if(object.objectName) {
                                    if(object.objectName==="Utilisateur") {
                                        // console.log(object.values=accounts);
                                        object.values=accounts;
                                    } else if(object.objectName === "Groupe utilisateurs") {
                                        object.values=groups;
                                    } else if(object.objectName === "Catégorie de documents") {
                                        // console.log(object.values,"------", categories);
                                        let cat = [];
                                        categories.map((elt, index) => {
                                            // console.log(elt);
                                            if(elt.pk !== category.pk){
                                                cat.push(elt);
                                                // console.log(elt, "----", category);
                                            }

                                            if( index === categories.length-1 ) {
                                                // console.log(cat);
                                                object.values=cat;
                                            }
                                        });
                                    }
                                } else {
                                    object.values = [];
                                    object["objectName"] = "Utilisateur";
                                }
                            })
                        }
                    })
                    this.sysAttr.map((attr, index) => {
                        if(attr.name === "Utilisateur") {
                            attr.values=accounts;
                        } else if(attr.name === "Groupe utilisateurs") {
                            attr.values=groups;
                        } else if(attr.name === "Catégorie de documents") {
                            attr.values=categories;
                        }

                        if(index === this.sysAttr.length-1) {
                            this.setState({
                                sysAttr: this.sysAttr,
                            });
                        }
                    })
                })
            });
            // console.log(categories);
 
            this._getAllLevel((levels) => {
                levels.results.map((level) => {
                    levels_values.push(level.name);
                });

                categories.map((category) => {
                    categories_values.push( category.name );
                });
    
                this.month.map((value) => {
                    month_values.push(value.name);
                });
    
                // console.log(categories);
                this.setState({
                    categories : categories,
                    isLoading: false,
    
                    default_attributes: [
                        {
                            name: "Catégorie du document",
                            type: "select",
                            multi_choice: false,
                            required: true,
                            values: categories_values,
                            value: [],
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Niveau d'authentification du document",
                            type: "select",
                            multi_choice: false,
                            required: true,
                            values: levels_values,
                            value: [],
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Titre du document",
                            type: "text-one-line",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Image du document",
                            type: "text-one-line",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Résumé synthétique",
                            type: "text-multi-line",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Année de publication",
                            type: "number",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Mois de publication",
                            type: "select",
                            multi_choice: false,
                            required: true,
                            values: month_values,
                            value: [],
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        },
                        {
                            name: "Date de validation du document",
                            type: "date",
                            multi_choice: false,
                            required: true,
                            values: null,
                            value: "",
                            min: undefined,
                            max: undefined,
                            disabled: true,
                        }
                    ]
                });
            });
        }) ;
    }

    _getAllLevel( callback=()=>{} ) {
        this._getLevel(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getAllLevel( callback );
            });
        });
    }


    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            })
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else if( error.response.status === 500 ) {
                alert("Impossible de supprimer la catégorie. Veuillez supprimer tous les documents de cette catégorie, puis réessayer svp!");
                this.setState({
                    isLoading: false,
                });
            }

            else {
                console.log( error.response.data );
            }
        }
    }

    /**
     * On met a jour la session utilisateur
    */
    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _addAttribute( categoryIndex ) {
        let attributeName = window.prompt("Entrer le nom de l'attribut ici", "");
        if( attributeName !== null && attributeName !== undefined && attributeName !== "" ) {
            let categories = this.state.categories;
            categories[categoryIndex].fields.attributes.push({
                name: attributeName,
                type: "void",
                multi_choice: false,
                required: true,
                values: null,
                value: "",
                min: undefined,
                max: undefined,
            });

            this.setState({
                categories: categories,
            });
        }
    }

    _addObjectAttribute(categoryIndex) {
        let attributeName = window.prompt("Entrer le nom de l'attribut ici", "");
        if (attributeName !== null && attributeName !== undefined && attributeName !== "") {
            let categories = this.state.categories;
            categories[categoryIndex].fields.objects.push({
                name: attributeName,
                objectName: this.state.sysAttr[0].name,
                type: "select",
                multi_choice: false,
                required: true,
                values: this.state.sysAttr[0].values,
                value: [],
                min: undefined,
                max: undefined,
            });

            this.setState({
                categories: categories,
            });
        }
        // console.log(this.state.sysAttr[0].values);
    }


    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }


    _startUpdateCategory(id, category) {
        if( window.confirm("Voulez-vous vraiment modifié?") ) {
            // let category_ = {
            //     name: category.name,
            //     fields: category.fields,
            // };
    
            // this.setState({
            //     isLoading: true,
            // });
    
            // this._update_category(id, category_, () => {
            //     this.setState({
            //         isLoading: false,
            //     });
            //     alert("Catégorie modifiée avec succès");
            // });

            this.setState({
                authAction: (password) => {
                    let category_ = {
                        name: category.name,
                        description: category.description ? category.description : "",
                        fields: category.fields,
                    };
            
                    this.setState({
                        isLoading: true,
                    });
            
                    this._update_category(id, category_, () => {
                        this.setState({
                            isLoading: false,
                        });
                        this._openOrCloseAuth();
                        alert("Catégorie modifiée avec succès");
                    });
                }
            }, () => {
                this._openOrCloseAuth();
            });
        }
    }

    _update_category(id, category, callback=()=>{}) {
        this._updateCategory(id, category, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._update_category(id, category, callback)
            })
        });
    }

    _startDeleteCategory(id) {
        if( window.confirm("Voulez-vous vraiment supprimé?") ) {
            // this.setState({
            //     isLoading: true,
            // });
    
            // this._delete_category(id, () => {
            //     this.setState({
            //         isLoading: false,
            //     });
            //     alert("Catégorie supprimée avec succès");
            //     window.location.reload();
            // });

            this.setState({
                authAction: (password) => {
                    this.setState({
                        isLoading: true,
                    });
            
                    this._delete_category(id, () => {
                        this.setState({
                            isLoading: false,
                        });
                        alert("Catégorie supprimée avec succès");
                        window.location.reload();
                    });
                }
            }, () => {
                this._openOrCloseAuth();
            });
        }
    }

    _delete_category(id, callback=()=>{}) {
        this._deleteCategory(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._delete_category(id, callback)
            })
        });
    }

    _viewCategoryInfo(id) {
        let categoryInfo = document.getElementById(id);
        let currentCategoriesIdView = this.state.currentCategoriesIdView;

        if( !currentCategoriesIdView.includes(id) ) {
            currentCategoriesIdView.push(id);
            categoryInfo.style.height = "auto";
            categoryInfo.style.opacity = 1;
            categoryInfo.style.padding = "20px";
        }

        else {
            currentCategoriesIdView.splice( currentCategoriesIdView.indexOf(id) , 1)
            categoryInfo.style.height = "0px";
            categoryInfo.style.opacity = 0;
            categoryInfo.style.padding = "0px";
        }
        

        this.setState({
            currentCategoriesIdView: currentCategoriesIdView,
        });
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                <div>
                    {
                        this.state.categories.map((category, index) => (
                            <div key={"category" + index}
                                style={{
                                    borderTop: "1px solid #00000055",
                                    paddingTop: "20px",
                                }}
                            >
                                <div style={{
                                    marginLeft: "20px",
                                    fontWeight: "bold",
                                    fontSize: "1.2em",
                                    letterSpacing: "1px",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                                    onClick={() => {
                                        this._viewCategoryInfo( "category-" + category.name + "-" + index );
                                    }}
                                >
                                    <span>
                                        { category.name }
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                color: "#5dc269",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </span>
                                </div>

                                <div className='dashboard-option dashboard-option-listTab' id={ "category-" + category.name + "-" + index }>
                                    <div className='dashboard-option-content'>
                                        <div className='dashboard-option-content-label'>
                                            <span>
                                                Nom de la catégorie
                                            </span>
                                        </div>
                                        <input
                                            className='dashboard-option-content-input'
                                            type='text'
                                            value={category.name}
                                            onChange={(e) => {
                                                let categories = this.state.categories;
                                                categories[index].name = e.target.value;

                                                this.setState({
                                                    categories: categories,
                                                });
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <div className='input-container'>
                                            <span>
                                                Description
                                            </span>
                                            <div className='registration_form_input'>
                                                <textarea name='summary' className='registration_form_input_area'
                                                    // Value={category.description}
                                                    onChange={(e) => {
                                                        let categories = this.state.categories;
                                                        categories[index]['description'] = e.target.value;

                                                        this.setState({
                                                            categories: categories,
                                                        });
                                                    }}
                                                >
                                                    {category.description}
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>

                                    <div className='dashboard-option-content'>

                                    <div className='dashboard-option-content-label'>
                            <span>
                                Liste des attributs par défaut dans le système
                            </span>
                        </div>
                        <br />
                        <table className='tab-style-1'>
                            <tr
                                className="table-head-style1"
                            >
                                <th>
                                    Nom
                                </th>

                                <th>
                                    Requis
                                </th>

                                <th>
                                    Type
                                </th>
                                
                                <th>
                                    Valeur(s)
                                </th>

                                <th
                                    style={{
                                        textAlign: "center",
                                        color: "#00000055"
                                    }}
                                >
                                    Ajouter des valeurs
                                </th>

                                <th
                                    style={{
                                        color: "#bb7474",
                                        textAlign: "center",
                                    }}
                                >
                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </th>
                            </tr>

                            {
                                this.state.default_attributes.map((attribute, attributeIndex) => (
                                    <>
                                        <tr
                                            
                                            style={{
                                                backgroundColor: attributeIndex % 2===0 ? "#0A376411" : "#ffffff",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td>
                                                <input
                                                    className='dashboard-option-content-input2'
                                                    type='text'
                                                    value={attribute.name}
                                                    disabled={attribute.disabled}
                                                />
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.required}
                                                    disabled={attribute.disabled}
                                                >
                                                    <option value={true}>
                                                        oui
                                                    </option>
                                                    <option value={false}>
                                                        non
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.type}
                                                    disabled={attribute.disabled}
                                                >
                                                    {
                                                        this.attr_types.map((type, index) => (
                                                            <option key={"type-" + index} value={type.value}>
                                                                {type.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>

                                                {
                                                    attribute.type === "number" &&
                                                    <div>
                                                        <div className='number-rang'>
                                                            <label>
                                                                min
                                                            </label>
                                                            <input type='number' value={attribute.min}
                                                                disabled={attribute.disabled}
                                                            />
                                                        </div>

                                                        <div className='number-rang'>
                                                            <label>
                                                                max
                                                            </label>
                                                            <input type='number' value={attribute.max}
                                                                disabled={attribute.disabled}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </td>

                                            <td className="userGroups">
                                                {
                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <div className='userGroups_list'>
                                                            <ul className='userGroups_list_ul'>
                                                                {
                                                                    attribute.values.map((value, valueIndex) => (
                                                                        <li className='userGroups_list_ul_li_2' key={value + "-" + valueIndex}>
                                                                            <div className='dashboard-option-content-attr-list-elt-2 flex-row'>
                                                                                <span>
                                                                                    {value}
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                       

                                                        :
                                                            (
                                                                attribute.type === "text-one-line" || attribute.type === "text-multi-line"?
                                                                    <input
                                                                        className='dashboard-option-content-input2'
                                                                        disabled={attribute.disabled}
                                                                        type='text'
                                                                        value={attribute.value}
                                                                    />
                                                                :
                                                                    (
                                                                        attribute.type === "date"?
                                                                            <input
                                                                                className='dashboard-option-content-input2'
                                                                                type='date'
                                                                                disabled={attribute.disabled}
                                                                                value={attribute.value}
                                                                            />
                                                                        :
                                                                            (
                                                                                attribute.type === "number"?
                                                                                    <input
                                                                                        className='dashboard-option-content-input2'
                                                                                        type='number'
                                                                                        disabled={attribute.disabled}
                                                                                        value={attribute.value}
                                                                                        min={attribute.min}
                                                                                        max={attribute.max}
                                                                                    />
                                                                                :
                                                                                    null
                                                                            )
                                                                    )
                                                            )
                                                }

                                                <div className='td_text text-align-center'>
                                                    <span className='text-align-center'
                                                        style={{
                                                            fontSize: "1.7em"
                                                        }}
                                                    >
                                                        {attribute.values && attribute.values.length}
                                                    </span>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                ))
                            }
                        </table>
                        <br/>
                        <br/>
                   
                        <div className='dashboard-option-content-label'>
                            <span>
                                Liste des attributs complémentaires
                            </span>
                        </div>
                        <br />

                                        <table className='tab-style-1'>
                                            <tr
                                                className="table-head-style1"
                                            >
                                               <th>
                                                    Nom
                                                </th>

                                                <th>
                                                    Requis
                                                </th>

                                                <th>
                                                    Type
                                                </th>
                                                
                                                <th>
                                                    Valeur(s)
                                                </th>

                                                <th
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Ajouter des valeurs
                                                </th>

                                                <th
                                                    style={{
                                                        color: "red",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </th>
                                            </tr>

                                            {
                                                category.fields.attributes.map((attribute, attributeIndex) => (
                                                    <>
                                                        <tr
                                                            style={{
                                                                backgroundColor: attributeIndex % 2===0 ? "#0A376411" : "#ffffff",
                                                                zIndex: "10000"
                                                            }}
                                                        >
                                                            <td>
                                                                <input
                                                                    className='dashboard-option-content-input2'
                                                                    type='text'
                                                                    value={attribute.name}
                                                                    onChange={(e) => {
                                                                        let categories = this.state.categories;
                                                                        categories[index].fields.attributes[attributeIndex].name = e.target.value;

                                                                        this.setState({
                                                                            categories: categories,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>

                                                            <td>
                                                                <select
                                                                    className='dashboard-option-content-input2'
                                                                    value={attribute.required}
                                                                    onChange={(e) => {
                                                                        let categories = this.state.categories;
                                                                        categories[index].fields.attributes[attributeIndex].required = e.target.value;

                                                                        this.setState({
                                                                            categories: categories,
                                                                        });
                                                                    }}
                                                                >
                                                                    <option value={true}>
                                                                        oui
                                                                    </option>
                                                                    <option value={false}>
                                                                        non
                                                                    </option>
                                                                </select>
                                                            </td>

                                                            <td>
                                                                <select
                                                                    className='dashboard-option-content-input2'
                                                                    value={attribute.type}
                                                                    onChange={(e) => {
                                                                        let categories = this.state.categories;
                                                                        categories[index].fields.attributes[attributeIndex].type = e.target.value;

                                                                        if(e.target.value === "list" || e.target.value === "select" || e.target.value === "checkbox" || e.target.value === "radio") {
                                                                            categories[index].fields.attributes[attributeIndex].value = [];
                                                                            categories[index].fields.attributes[attributeIndex].values = [];
                                                                        }

                                                                        else {
                                                                            categories[index].fields.attributes[attributeIndex].value = "";
                                                                            categories[index].fields.attributes[attributeIndex].values = null;
                                                                            categories[index].fields.attributes[attributeIndex].min = undefined;
                                                                            categories[index].fields.attributes[attributeIndex].max = undefined;
                                                                            categories[index].fields.attributes[attributeIndex].multi_choice = false;
                                                                        }

                                                                        this.setState({
                                                                            categories: categories,
                                                                        });
                                                                    }}
                                                                >
                                                                    {
                                                                        this.attr_types.map((type, index) => (
                                                                            <option key={"type-" + index} value={type.value}>
                                                                                {type.name}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                    {/* <option value={"void"}>
                                                                        Nature quelconque
                                                                    </option>
                                                                    <option value={"list"}>
                                                                        Liste
                                                                    </option> */}
                                                                </select>

                                                                {
                                                                    attribute.type === "number" &&
                                                                    <div>
                                                                        <div className='number-rang'>
                                                                            <label>
                                                                                min
                                                                            </label>
                                                                            <input type='number' value={attribute.min}
                                                                                onChange={(e) => {

                                                                                    let categories = this.state.categories;

                                                                                    let value = undefined;

                                                                                    if( e.target.value !== '' ) {
                                                                                        value = e.target.value;
                                                                                    }

                                                                                    if( attribute.max !== undefined && value !== undefined ) {
                                                                                        if( Number(value) <= Number(attribute.max) ) {
                                                                                            categories[index].fields.attributes[attributeIndex].min = value;
                                                                                            this.setState({
                                                                                                category: category,
                                                                                            });
                                                                                        }
                                                                                    }

                                                                                    else {
                                                                                        categories[index].fields.attributes[attributeIndex].min = value;
                                                                                        this.setState({
                                                                                            category: category,
                                                                                        });
                                                                                    }
                                                                                    
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className='number-rang'>
                                                                            <label>
                                                                                max
                                                                            </label>
                                                                            <input type='number' value={attribute.max}
                                                                                onChange={(e) => {
                                                                                    let categories = this.state.categories;
                                                                                    let value = undefined;

                                                                                    if( e.target.value !== '' ) {
                                                                                        value = e.target.value;
                                                                                    }

                                                                                    if( attribute.min !== undefined && value !== undefined ) {
                                                                                        if( Number(value) >= Number(attribute.min) ) {
                                                                                            categories[index].fields.attributes[attributeIndex].max = value;
                                                                                            this.setState({
                                                                                                category: category,
                                                                                            });
                                                                                        }
                                                                                    }

                                                                                    else {
                                                                                        categories[index].fields.attributes[attributeIndex].max = value;
                                                                                        this.setState({
                                                                                            category: category,
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>

                                                            {/* <td>
                                                                {
                                                                    attribute.type === "list" ?
                                                                        <select
                                                                            className='dashboard-option-content-input2'
                                                                            value={attribute.multi_choice}
                                                                            onChange={(e) => {
                                                                                let categories = this.state.categories;
                                                                                categories[index].fields.attributes[attributeIndex].multi_choice = e.target.value;

                                                                                this.setState({
                                                                                    categories: categories,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <option value={true}>
                                                                                oui
                                                                            </option>
                                                                            <option value={false}>
                                                                                non
                                                                            </option>
                                                                        </select>
                                                                    :
                                                                        null
                                                                }
                                                            </td> */}


                                                            <td className="userGroups">
                                                                {
                                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                                    <div className='userGroups_list'>
                                                                        <ul className='userGroups_list_ul'>
                                                                            {
                                                                                attribute.values.map((value, valueIndex) => (
                                                                                    <li className='userGroups_list_ul_li_2' key={value + "-" + valueIndex}>
                                                                                    <div className='dashboard-option-content-attr-list-elt-2 flex-row'>
                                                                                        <span>
                                                                                            {value}
                                                                                        </span>
                                                                                        <div className='flex-column dashboard-option-content-attr-list-elt_'>
                                                                                            <button
                                                                                                className='edit-pen-2'
                                                                                                onClick={(e) => {
                                                                                                    let newValue = window.prompt( "modifier la valeur", value );
                                                                                                    if( newValue !== null && newValue !== undefined && newValue !== "" ) {
                                                                                                        let categories = this.state.categories;
                                                                                                        categories[index].fields.attributes[attributeIndex].values[valueIndex] = newValue;

                                                                                                        this.setState({
                                                                                                            categories: categories,
                                                                                                        });
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                                                </svg>
                                                                                            </button>
                                                                                            <button
                                                                                                className='delete-trash'
                                                                                                onClick={(e) => {
                                                                                                    let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                                                                    if( confirm ) {
                                                                                                        let categories = this.state.categories;
                                                                                                        categories[index].fields.attributes[attributeIndex].values.splice(valueIndex, 1);

                                                                                                        this.setState({
                                                                                                            categories: categories,
                                                                                                        });
                                                                                                    }
                                                                                                }}
                                                                                                style={{
                                                                                                    color: "red",
                                                                                                }}
                                                                                            >
                                                                                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                                                </svg>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    :
                                                                    (
                                                                        attribute.type === "text-one-line" || attribute.type === "text-multi-line"?
                                                                            <input
                                                                                className='dashboard-option-content-input2'
                                                                                type='text'
                                                                                value={attribute.value}
                                                                                onChange={(e) => {
                                                                                    let categories = this.state.categories;
                                                                                    categories[index].fields.attributes[attributeIndex].value = e.target.value;
                            
                                                                                    this.setState({
                                                                                        categories: categories,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        :
                                                                            (
                                                                                attribute.type === "date"?
                                                                                    <input
                                                                                        className='dashboard-option-content-input2'
                                                                                        type='date'
                                                                                        value={attribute.value}
                                                                                        onChange={(e) => {
                                                                                            let categories = this.state.categories;
                                                                                            categories[index].fields.attributes[attributeIndex].value = e.target.value;
                                    
                                                                                            this.setState({
                                                                                                categories: categories,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                :
                                                                                    (
                                                                                        attribute.type === "number"?
                                                                                            <input
                                                                                                className='dashboard-option-content-input2'
                                                                                                type='number'
                                                                                                value={attribute.value}
                                                                                                min={attribute.min}
                                                                                                max={attribute.max}

                                                                                                onChange={(e) => {
                                                                                                    let categories = this.state.categories;
                                                                                                    let currentAttribute = categories[index].fields.attributes[attributeIndex];
        
                                                                                                    // let min = currentAttribute.min;
                                                                                                    // let max = currentAttribute.max;
        
                                                                                                    //     // console.log( min, max );
        
                                                                                                    // let isValid = true;
        
                                                                                                    // if( e.target.value !== '' ) {
                                                                                                    //     let value = Number(e.target.value);
        
                                                                                                    //     if( min !== undefined ) {
                                                                                                    //         // if( value >= Number(min) ) {
                                                                                                    //         //     currentAttribute.value = value;
                                                                                                    //         // }
                                                                                                    //         // else {
                                                                                                    //         //     isValid = false;
                                                                                                    //         // }
                                                                                                    //     }else {
                                                                                                    //         currentAttribute.value = e.target.value;
                                                                                                    //     }
        
                                                                                                    //     if( max !== undefined ) {
                                                                                                    //         if( value <= Number(max) ) {
                                                                                                    //             currentAttribute.value = value;
                                                                                                    //         }
                                                                                                    //         else {
                                                                                                    //             isValid = false;
                                                                                                    //         }
                                                                                                    //     }else {
                                                                                                    //         currentAttribute.value = e.target.value;
                                                                                                    //     }
                                                                                                    // }
        
                                                                                                    // else {
                                                                                                    //     currentAttribute.value = e.target.value;
                                                                                                    // }
                                                                                                    
                                                                                                    currentAttribute.value = e.target.value;
                                                                                                    this.setState({
                                                                                                        categories: categories,
                                                                                                    });
        
                                                                                                    // if( isValid ) {
                                                                                                    //     this.setState({
                                                                                                    //         categories: categories,
                                                                                                    //     });
                                                                                                    // }
                                                                                                }}
                                                                                            />
                                                                                        :
                                                                                            null
                                                                                    )
                                                                            )
                                                                    )
                                                                }

                                                                <div className='td_text text-align-center'>
                                                                    <span className='text-align-center'
                                                                        style={{
                                                                            fontSize: "1.7em"
                                                                        }}
                                                                    >
                                                                        {attribute.values && attribute.values.length}
                                                                    </span>
                                                                </div>
                                                            </td>

                                                            <td
                                                                style={{
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                {
                                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio" ?
                                                                        <div className='dashboard-option-content-button'>
                                                                            <button
                                                                                style={{
                                                                                    backgroundColor: "#2f749200",
                                                                                    color: "#5dc269",
                                                                                    border: "1px solid #5dc26900",
                                                                                    marginTop: "-15px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    let value = window.prompt( "nom de la valeur", "" );
                                                                                    if( value !== null && value !== undefined && value !== "" ) {
                                                                                        let categories = this.state.categories;
                                                                                        categories[index].fields.attributes[attributeIndex].values.push(value);

                                                                                        this.setState({
                                                                                            categories: categories,
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                            </td>

                                                            <td
                                                                style={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <button
                                                                    onClick={() => {
                                                                        let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                                        if( confirm ) {
                                                                            let categories = this.state.categories;
                                                                            categories[index].fields.attributes.splice(attributeIndex, 1);
                                                                            this.setState({
                                                                                categories: categories,
                                                                            });
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        background: "transparent",
                                                                        color: "red",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </table>
                                        <div className='dashboard-option-content-button'>
                                            <button
                                                onClick={() => {
                                                    this._addAttribute( index );
                                                }}
                                                className="button-style-5"
                                            >
                                                Ajouter un attribut
                                            </button>
                                        </div>
                                    </div>

                                    <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div className='dashboard-option-content-label'>
                            <span>
                                Liste des attributs d'objets
                            </span>
                        </div>
                        <br />
                        <table className='tab-style-1'>
                            <tr
                                className="table-head-style1"
                            >
                                <th>
                                    Nom
                                </th>

                                <th>
                                    Requis
                                </th>

                                <th>
                                    Objet
                                </th>

                                <th>
                                    Type
                                </th>
                                
                                <th>
                                    Valeur(s)
                                </th>

                                <th
                                    style={{
                                        color: "red",
                                        textAlign: "center",
                                    }}
                                >
                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </th>
                            </tr>
                            {
                                category.fields.objects && category.fields.objects.map((attribute, attributeIndex) => (
                                    <>
                                        <tr
                                            style={{
                                                backgroundColor: attributeIndex % 2===0 ? "#0A376411" : "#ffffff",
                                                zIndex: "10000"
                                            }}
                                        >
                                            <td>
                                                <input
                                                    className='dashboard-option-content-input2'
                                                    type='text'
                                                    value={attribute.name}
                                                    onChange={(e) => {
                                                        let categories = this.state.categories;
                                                        categories[index].fields.objects[attributeIndex].name = e.target.value;

                                                        this.setState({
                                                            categories: categories,
                                                        });
                                                    }}
                                                />
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.required}
                                                    onChange={(e) => {
                                                        let categories = this.state.categories;
                                                        categories[index].fields.objects[attributeIndex].required = e.target.value;

                                                        this.setState({
                                                            categories: categories,
                                                        })
                                                    }}
                                                >
                                                    <option value={true}>
                                                        oui
                                                    </option>
                                                    <option value={false}>
                                                        non
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    // defaultValue={this.state.categories[index].fields.objects[attributeIndex].objectName ? this.state.categories[index].fields.objects[attributeIndex].objectName : ''}
                                                    onChange={(e) => {

                                                        let val = (JSON.parse(e.target.value));

                                                        let categories = this.state.categories;
                                                        categories[index].fields.objects[attributeIndex].values = val.values;
                                                        categories[index].fields.objects[attributeIndex].objectName = val.name;

                                                        this.setState({
                                                            categories: categories,
                                                        })
                                                    }}
                                                >
                                                    {
                                                        attribute.objectName ?
                                                            this.state.sysAttr.map((attr_, index) => (
                                                                <option value={JSON.stringify(attr_)} selected={attribute.objectName===attr_.name ? true : false}>
                                                                    {attr_.name ? attr_.name : ""}
                                                                </option>
                                                            ))
                                                        :
                                                            null
                                                    }
                                                </select>
                                            </td>

                                            <td>
                                                <select
                                                    className='dashboard-option-content-input2'
                                                    value={attribute.type}
                                                    onChange={(e) => {
                                                        let categories = this.state.categories;
                                                        categories[index].fields.objects[attributeIndex].type = e.target.value;

                                                        if (e.target.value === "list" || e.target.value === "select" || e.target.value === "checkbox" || e.target.value === "radio") {
                                                            
                                                        }

                                                        else {
                                                            categories[index].fields.objects[attributeIndex].value = "";
                                                            categories[index].fields.objects[attributeIndex].values = null;
                                                            categories[index].fields.objects[attributeIndex].min = undefined;
                                                            categories[index].fields.objects[attributeIndex].max = undefined;
                                                            categories[index].fields.objects[attributeIndex].multi_choice = false;
                                                        }

                                                        this.setState({
                                                            categories: categories,
                                                        });
                                                    }}
                                                >
                                                    {
                                                        this.attr_types.map((type, index) => (
                                                            (type.type === "list" || type.type === "select" || type.type === "checkbox" || type.type === "radio") ?
                                                                <option key={"type-" + index} value={type.value}>
                                                                    {type.name}
                                                                </option>
                                                            :
                                                                null
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                            <td className="userGroups">
                                                {
                                                    attribute.type === "list" || attribute.type === "select" || attribute.type === "checkbox" || attribute.type === "radio"?
                                                        <div className='userGroups_list'>
                                                            <ul className='userGroups_list_ul'>
                                                                {
                                                                    attribute.values.map((value, valueIndex) => (
                                                                        <li className='userGroups_list_ul_li_2' key={value + "-" + valueIndex}>
                                                                            <div className='dashboard-option-content-attr-list-elt-2 flex-row'>
                                                                                <span>
                                                                                    {value.name ? value.name : ( value.username ? value.username : null)}
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                       

                                                        :
                                                            (
                                                                attribute.type === "text-one-line" || attribute.type === "text-multi-line"?
                                                                    <input
                                                                        className='dashboard-option-content-input2'
                                                                        type='text'
                                                                        value={attribute.value}
                                                                        onChange={(e) => {
                                                                            let category = this.state.category;
                                                                            category.fields.attributes[attributeIndex].value = e.target.value;
                    
                                                                            this.setState({
                                                                                category: category,
                                                                            });
                                                                        }}
                                                                    />
                                                                :
                                                                    (
                                                                        attribute.type === "date"?
                                                                            <input
                                                                                className='dashboard-option-content-input2'
                                                                                type='date'
                                                                                value={attribute.value}
                                                                                onChange={(e) => {
                                                                                    let category = this.state.category;
                                                                                    category.fields.attributes[attributeIndex].value = e.target.value;
                            
                                                                                    this.setState({
                                                                                        category: category,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        :
                                                                            (
                                                                                attribute.type === "number"?
                                                                                    <input
                                                                                        className='dashboard-option-content-input2'
                                                                                        type='number'
                                                                                        value={attribute.value}
                                                                                        min={attribute.min}
                                                                                        max={attribute.max}
                                                                                        onChange={(e) => {
                                                                                            let category = this.state.category;
                                                                                            let currentAttribute = category.fields.attributes[attributeIndex];
                                                                                            currentAttribute.value = e.target.value;

                                                                                            this.setState({
                                                                                                category: category,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                :
                                                                                    null
                                                                            )
                                                                    )
                                                            )
                                                }

                                                <div className='td_text text-align-center'>
                                                    <span className='text-align-center'
                                                        style={{
                                                            fontSize: "1.7em"
                                                        }}
                                                    >
                                                        {attribute.values && attribute.values.length}
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <button
                                                    onClick={() => {
                                                        let confirm = window.confirm("Voulez-vous vraiment supprimé?");
                                                        if (confirm) {
                                                            let categories = this.state.categories;
                                                            categories[index].fields.objects.splice(attributeIndex, 1);
                                                            this.setState({
                                                                categories: categories,
                                                            });
                                                        }
                                                    }}
                                                    style={{
                                                        background: "transparent",
                                                        color: "red",
                                                    }}
                                                >
                                                    <svg width="1.7em" height="1.7em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                ))
                            }
                        </table>
                        <br/>
                        <br/>
                        <div className='dashboard-option-content-button'>
                            <button
                                onClick={() => {
                                    this._addObjectAttribute(index);
                                }}
                                className="button-style-5"
                            >
                                Ajouter un attribut
                            </button>
                        </div>

                                    <div className='flex-row justify-center'>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    this._startUpdateCategory(category.pk, category);
                                                    return true;
                                                }}
                                                className="button-style-6"
                                                style={{
                                                    border: "1px solid #5dc269",
                                                    color: "#5dc269",
                                                }}
                                            >
                                                Modifier cette catégorie
                                            </button>
                                        </div>
                                        <div style={{
                                            marginLeft: "10px",
                                        }}>
                                            <button
                                                onClick={() => {
                                                    this._startDeleteCategory(category.pk);
                                                    return true;
                                                }}
                                                className="button-style-6"
                                                style={{
                                                    border: "1px solid red",
                                                    color: "red",
                                                }}
                                            >
                                                Supprimer cette catégorie
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }
}

DashboardCategories = connect(mapStateToProps, null)(DashboardCategories);
export default DashboardCategories;
