// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class List2Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allIsChecked: false,
            list: [],
            numberIsChecked: 0,
        }
    }

    componentDidMount() {
        let newList = [];
        // console.log( this.props.list );
        if(this.props.object) {
            this.props.list.map((elt, index) => {
                newList[index] = {
                    data: elt.name ? elt.name : ( elt.username ? elt.username : ""),
                    isChecked: false,
                }
            });
        } else {
            this.props.list.map((elt, index) => {
                newList[index] = {
                    data: elt,
                    isChecked: false,
                }
            });
        }
        this.setState({
            list: newList,
        });
    }

    
    render() {
        return (
            <div className='list2Container'>
            <div className='list_name'>
                <span>
                    { this.props.list_name }
                </span>
            </div>
            <div className='list2'>
                <label htmlFor={"id" + this.props.id} className='list2_ input-label'
                    style={{
                        transform: this.state.numberIsChecked === this.state.list.length ? "scale(0.95)" : "scale(1)",
                    }}
                >
                    <input id={"id" + this.props.id} name={this.props.id} checked={this.state.numberIsChecked === this.state.list.length} type='checkbox'
                        onChange={(e) => {
                            let newList = this.state.list;
                            let allIsChecked = (this.state.numberIsChecked === this.state.list.length);
                            let numberIsChecked = 0;
                            newList.map((elt, index) => {
                                elt.isChecked = !allIsChecked;
                                numberIsChecked = elt.isChecked ? numberIsChecked + 1 : 0;
                            });

                            this.setState({
                                numberIsChecked: numberIsChecked,
                                list: newList,
                            }, () => {
                                this.props.OnChange(this.props.list_name, newList);
                            });
                        }}
                    />
                    <label htmlFor={"id" + this.props.id}>Tout</label>
                    <div className='checkbox'
                        style={{
                            border: this.state.numberIsChecked === this.state.list.length ? "0px solid #3a3a3a99" : "1px solid #3a3a3a99",
                            transitionDuration: "0s",
                        }}
                    >
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                            style={{
                                transform: this.state.numberIsChecked === this.state.list.length ? "scale(1.1)" : "scale(0)"
                            }}
                        >
                            <path fillRule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            <path fillRule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                        </svg>
                    </div>
                </label>
                <ul className="list2_ul">
                    {
                        this.state.list.map((elt, index) => (
                            <li key={"list2_ul" + "-" + index + this.props.id} className='list2_ul_li'>
                                <label htmlFor={"id-" + elt.data + "-" + this.props.list_name} className='list2_ul_li_label input-label' key={this.props.id + "-" + index}
                                    style={{
                                        transform: elt.isChecked ? "scale(0.95)" : "scale(1)",
                                        // backgroundColor: elt.isChecked ? "#769ea7" : "#ffffff",
                                        color: elt.isChecked ? "#769ea7" : "#3a3a3a",
                                        fontFamily: elt.isChecked ? "PoetsenOne" : "Montserrat-Regular",
                                        // fontWeight: elt.isChecked ? "bold" : "normal",
                                    }}
                                >
                                    <input id={"id-" + elt.data + "-" + this.props.list_name} name={elt.data} checked={elt.isChecked} type='checkbox'
                                        onChange={(e) => {
                                            let newList = this.state.list;
                                            newList[index].isChecked = !newList[index].isChecked;
                                            if(newList[index].isChecked === false) {
                                                this.setState({
                                                    numberIsChecked: this.state.numberIsChecked - 1
                                                });
                                            }
                                            else {
                                                this.setState({
                                                    numberIsChecked: this.state.numberIsChecked + 1
                                                });
                                            }
                                            this.setState({
                                                list: newList,
                                            }, () => {
                                                this.props.OnChange(this.props.list_name, newList);
                                            });
                                        }}
                                    />
                                    <label className='' htmlFor={"id-" + elt.data + "-" + this.props.list_name}>{elt.data}</label>
                                    <div className='checkbox'
                                        style={{
                                            border: elt.isChecked ? "0px solid #3a3a3a99" : "1px solid #3a3a3a99",
                                            transitionDuration: "0s",
                                        }}
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                transform: elt.isChecked ? "scale(1.1)" : "scale(0)"
                                            }}
                                        >
                                            <path fillRule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                            <path fillRule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                        </svg>
                                    </div>
                                </label>
                            </li>
                        ))
                    }
                </ul>
                <div className='fake-bottom'></div>
            </div>
            </div>
        )
    }
}

export default List2Component;
