import React from 'react';

import { Provider } from 'react-redux';

import ReactDOM from 'react-dom';

import Store from './store/store.config';
import App from './app';
import './font_style/font.css';

ReactDOM.render(
  <Provider store={Store}>
    {/* <React.StrictMode> */}
      <App/>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById('app')
);
