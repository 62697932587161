// import { ReactDOM } from 'react';
import React from 'react';
// import parse from 'html-react-parser';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class WordCloud extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           newList: [],
           elementsList: [],
           positionsList: [],
           numberMaxWOrd: this.props.list.length > 0 ?
                            (
                                this.props.list.length<100 ? this.props.list.length : 100
                            )
                        : 0,
        }

        this.colors = [
            '#ed8105',
            '#ed8105',
            '#052d61',
            '#f60707',
            '#390e62',
            '#7c0ee3',
            '#043a53',
            '#530404',
            '#ea053a',
            '#d14007',
        ]

        this.head = [0,1,2,3,4];
        this._randomGenerator = this._randomGenerator.bind(this);
    }

    componentDidMount() {
        this.setState({
            newList: this._randomGenerator(this.props.list),
        });
        this._wordGenerator();
    }

    _randomGenerator(list) {
        let newList = [];
        while( newList.length < list.length ) {
            let val = Math.floor(Math.random() * list.length);
            if(!newList.includes(val)){
                newList.push(val);
            }
        }
        return newList;
    }

    _wordGenerator() {
        let elementsList = [];
        let positionsList = [];

        let hidden_element = document.getElementById('cloud-hidden-element');
        let parent = document.getElementById('cloud_ul');
        let word = null;
        let word_ = document.createElement("span");

        for(let i=0; i<this.props.list.length; i++) {
            word_.textContent = this.props.list[i].word.word;
            hidden_element.appendChild(word_);
            
            hidden_element.style.fontSize = (Number(this.props.list[i].score)*Number(this.props.max))/this.props.list[0].score > this.props.min ? (Number(this.props.list[i].score)*Number(this.props.max))/this.props.list[0].score + "em" : this.props.min+"em";
            hidden_element.style.fontWeight = (0.02 * this.props.list[i].score) > 1?( (0.02 * this.props.list[i].score) < 4?(0.02 * this.props.list[i].score * 100): "800" ): "100";

            // console.log( parent.clientWidth );

            word =  {
                word: <li
                    style={{
                        fontSize: (Number(this.props.list[i].score)*Number(this.props.max))/this.props.list[0].score > this.props.min ? (Number(this.props.list[i].score)*Number(this.props.max))/this.props.list[0].score + "em" : this.props.min+"em" ,
                        fontWeight: (0.02 * this.props.list[i].score) > 1?( (0.02 * this.props.list[i].score) < 4?(0.02 * this.props.list[i].score * 100): "800" ): "100",
                        zIndex: this.props.list[i].score,
                    }}
                >
                        <a href={"/view-keyword-info:?keyword=" + this.props.list[i].word.word}
                            style={{
                                color: this.colors[ Math.floor(Math.random()*(this.colors.length-1)) + 1 ],
                            }}
                        >
                            {this.props.list[i].word.word}
                        </a>
                    </li>,
                position: null,
                size: null,
            }

            while( word.position === null ) {
                let x = Math.random() * (parent.clientWidth - hidden_element.clientWidth);
                let y =  Math.random() * (parent.clientHeight - hidden_element.clientHeight);
                let validPosition = true;

                if( positionsList.length > 0 ) {
                    positionsList.map((position, index) => {
                        if(
                            (x >= position.x && x <= position.x+position.width ) &&
                            (position.x >= x && position.x <= x + hidden_element.clientWidth ) &&
                            (y >= position.y && x <= position.y+position.height )
                            (position.y >= y && position.y <= y + hidden_element.clientHeight )
                        ) {
                            validPosition = false;
                            return 0;
                        }
                    });
                } else {
                    validPosition = true;
                }

                if( validPosition ) {
                    // console.log( x,y,positionsList);
                    word.position = {
                        x: x,
                        y: y
                    };
                    positionsList.push({
                        x: x,
                        y: y,
                        width: hidden_element.clientWidth,
                        height: hidden_element.clientHeight
                    });
                }
            }

            elementsList.push(word);

            if(i===this.props.list.length-1) {
                this.setState({
                    elementsList: elementsList,
                });
            }
        }

        // console.log( elementsList );
    }

    render() {
        return (
            <div className='cloud' id='cloud'>
                <div className='maxWordChanger'>
                    <input type="number" value={this.state.numberMaxWOrd} min={0}
                        onChange={(e) => {
                            if( e.target.value >= 0 && e.target.value <= this.props.list.length ) {
                                this.setState({
                                    numberMaxWOrd: Number(e.target.value),
                                })
                            }
                        }}
                    />
                    <div className='maxWordChanger_separator'>
                        <span>/</span>
                    </div>
                    <div className='maxWordChanger_total'>
                        <span>
                            {this.props.list.length} chaînes
                        </span>
                    </div>
                </div>
                <ul role={"navigation"} id='cloud_ul'>
                    {/* {
                        this.state.newList.map((elt, index)=>(
                            index<this.state.numberMaxWOrd &&
                                <div style={{
                                    position: "absolute",
                                    display: "inline-block",
                                    top: this.state.elementsList[elt].position.y + "px",
                                    left: this.state.elementsList[elt].position.x + "px",
                                    zIndex: this.props.list[elt].score,
                                }}>
                                    {
                                        this.state.elementsList[elt].word
                                    }
                                </div>
                        ))
                    } */}
                    {
                        this.state.newList.map((elt, index)=>(
                            index<this.state.numberMaxWOrd &&
                            <li key={this.props.list[elt].word.id}
                                style={{
                                    fontSize: (Number(this.props.list[elt].score)*Number(this.props.max))/this.props.list[0].score > this.props.min ? (Number(this.props.list[elt].score)*Number(this.props.max))/this.props.list[0].score + "em" : this.props.min+"em" ,
                                    fontWeight: (0.02 * this.props.list[elt].score) > 1?( (0.02 * this.props.list[elt].score) < 4?(0.02 * this.props.list[elt].score * 100): "800" ): "100",
                                }}
                            >
                                <a data-weight={this.props.list[elt].score ? this.props.list[elt].score : 0} href={"/view-keyword-info:?keyword=" + this.props.list[elt].word.word}
                                    style={{
                                        color: this.colors[ Math.floor(Math.random()*(this.colors.length-1)) + 1 ],
                                    }}
                                >
                                    {
                                        this.props.list[elt].word.word
                                    }

                                    <div className='word-score'
                                        style={{
                                            marginBottom: ((Number(this.props.list[elt].score)*Number(this.props.max))/this.props.list[0].score) > 2 ? ((Number(this.props.list[elt].score)*Number(this.props.max))/this.props.list[0].score)+1+ "em" :"2em" ,
                                        }}
                                    >
                                        <span>
                                            {this.props.list[elt].score}
                                        </span>
                                    </div>
                                </a>
                            </li>
                        ))
                    }
                </ul>
                <div id='cloud-hidden-element'></div>
            </div>
        )
    }
}

export default WordCloud;