import React from 'react';
import { connect } from 'react-redux';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/




/**
 * 
 * Importation des scomposants
 */
import SearchInputComponent from '../components/searchInput.component/searchInput.component';
import AUthScreen from '../components/auth/auth.component';

/**
 * Importation des helpers
*/
import tools from '../helpers/tools.helper';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}


class DashboardUserProfile extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            accountsList: [],
            validAccountsList: [],
            unValidAccountsList: [],
            currentAccountsList: [],
            currentAccountListView: 0,
            
            currentAccountSelected: {},
            currentAccountSelected_groupsToAdd: [],
            currentAccountSelected_groupsToRemove: [],
            
            searchResult: [],

            viewAccountDetails: false,


            groupsList: [],
            currentGroupsList: [],
            groupSearchResult: [],

            addInGroups: false,
            RemoveFromGroups: false,

            authAction: () => {},
            auth: false,

            isReady: false,
        }

        this.tools = new tools();
        this.accessStore = new AccessStore(this.props.dispatch);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            isLoading: true,
        });
        this._get_users((accounts) => {
            // console.log( accounts );
            this.setState({
                accountsList: accounts,
            }, ()=> {
                this._startGetUsersGroups(0, this.state.accountsList, (userList) => {
                    this.setState({
                        accountsList: userList,
                    }, () => {
                        this._startGetUsersPermissions(0, this.state.accountsList, (userList_) => {
                            this.setState({
                                accountsList: userList_,
                            }, () => {
                                let validAccountsList = [];
                                let unValidAccountsList = [];
                                this.state.accountsList.map((account, accountIndex) => {

                                    if( account.username === this.props.user.username ) {
                                        this.setState({
                                            currentAccountSelected: account,
                                        });
                                    }

                                    if( account.permissions.length > 0 ) {
                                        validAccountsList.push( account );
                                    }

                                    else {
                                        unValidAccountsList.push( account );
                                    }
                                });
                                this.setState({
                                    validAccountsList: validAccountsList,
                                    unValidAccountsList: unValidAccountsList,
                                });
                                this._get_all_groups((groups) => {
                                    this.setState({
                                        groupsList: groups,
                                        isLoading: false,
                                    }, () => {
                                        let accountsList = this.state.accountsList;

                                        for(let i=0; i<accountsList.length; i++) {
                                            let not_member_of = [];

                                            for(let j=0; j<this.state.groupsList.length; j++) {

                                                let inGroup = false;

                                                for(let k=0; k<accountsList[i].groups.length; k++) {
                                                    if( this.state.groupsList[j].id === accountsList[i].groups[k].id ) {
                                                        inGroup = true;
                                                        // return true;
                                                    }
                                                }

                                                if( inGroup === false ) {
                                                    let isContent = false;
                                                    
                                                    for(let l=0; l<not_member_of.length; l++) {
                                                        if(not_member_of[l].id === this.state.groupsList[j].id) {
                                                            isContent = true;
                                                        }
                                                    }
                                                    if( isContent === false ) {
                                                        not_member_of = not_member_of.concat( this.state.groupsList[j] );
                                                    }
                                                }
                                            }

                                            // console.log( accountsList[i].username, not_member_of );
                                            accountsList[i]["not_member_of"] = not_member_of;
                                            this.setState({
                                                accountsList: accountsList,
                                            }, () => {
                                                this.setState({
                                                    isReady: true,
                                                });
                                            });
                                        }
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _startGetUsersGroups( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_groups(user.id, (groups) => {
                let users = userList;
                users[index]["groups"] = groups.groups;
                if( index < userList.length - 1 ) {
                    this._startGetUsersGroups( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _startGetUsersPermissions( index=0, userList, callback=()=>{} ) {
        if( userList.length > 0 ) {
            let user = userList[index];
            this._get_user_permissions(user.id, (permissions) => {
                let users = userList;
                users[index]["permissions"] = permissions.permissions;
                if( index < userList.length - 1 ) {
                    this._startGetUsersPermissions( index + 1, users, callback );
                }else {
                    callback(users);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _get_user_groups(id, callback=()=>{}) {
        this._getUserGroups(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_groups(callback);
            })
        });
    }

    _get_user_permissions(id, callback=()=>{}) {
        this._getUserPermissions(this.props.user.access, id, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_user_permissions(callback);
            })
        });
    }

    _set_new_user(response) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user);
    }

    _get_all_groups(callback=()=>{}) {
        this._getAllGroups(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_groups(callback);
            });
        });
    }

    _choiceGroupsForUsers() {
        this.setState({
            addInGroups: !this.state.addInGroups,
        });
    }

    _choiceGroupsForUsersRemove() {
        this.setState({
            RemoveFromGroups: !this.state.RemoveFromGroups,
        });
    }

    _viewAccountDetails() {
        this.setState({
            viewAccountDetails: !this.state.viewAccountDetails,
        });
    }

    _addUsersInGroups() {
        let other_data = {
            users: this.state.currentAccountsList,
            groups: this.state.currentGroupsList,
        }

        this.setState({
            isLoading: true,
        });

        this._add_user_to_group(other_data, () => {
            alert("Les utilisateurs ont été ajouté dans les groupes avec succès");
            window.location.reload();
            this.setState({
                isLoading: false,
                currentGroupsList: [],
            });
            this._choiceGroupsForUsers();
        });
    }

    _removeUsersFromGroups() {
        let other_data = {
            users: this.state.currentAccountsList,
            groups: this.state.currentGroupsList,
        }

        this.setState({
            isLoading: true,
        });

        this._remove_user_from_group(other_data, () => {
            alert("Les utilisateurs ont été retiré des groupes avec succès");
            window.location.reload();
            this.setState({
                isLoading: false,
                currentGroupsList: [],
            });
            this._choiceGroupsForUsersRemove();
        });
    }

    _add_user_to_group( data, callback=()=>{} ) {
        this._addAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_user_to_group(data, callback);
            });
        });
    }

    _remove_user_from_group( data, callback=()=>{} ) {
        this._removeAccess(data, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._add_user_to_group(data, callback);
            });
        });
    }

    _selectAllUsers() {
        let tab = [];
        if( this.state.currentAccountListView === 0 ) {
            this.state.accountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else if( this.state.currentAccountListView === 1 ) {
            this.state.validAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        else {
            this.state.unValidAccountsList.map((account, index) => {
                tab.push(account.id);
            })
        }

        this.setState({
            currentAccountsList: tab,
        });
    }

    _unSelectAllUsers() {
        this.setState({
            currentAccountsList: [],
        });
    }

    _manageError(error, callback = () => { }) {
        if (error.response) {
            if (error.response.status === 401) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if (error.response.data.code === "token_not_valid") {
                    this._refreshUserToken(this.props.user.refresh, (response) => {
                        if (response.status === 200) {
                            this._set_new_user(response);
                            callback();
                        }
                    }, (error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else if (error.response.status === 403) {
                alert("Vous n'êtes pas autorisé à interagir avec cette section");
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    _admin_update_account(id, data, callback=()=>{}) {
        this._AdminUpdateAccount(id, data, this.props.user.access, (response) => {
            // console.log( response );
            if( response.status === 200 ) {
                callback( response.data );
            }
        },(error) => {
            console.log( error.response );
            this._manageError( error, () => {
                this._admin_update_account(id, data, callback);
            });
        });
    }

    _updateUser( user, password) {
        this.setState({
            authAction: (password) => {
                this.setState({
                    isLoading: true,
                });

                let data = {
                    password: password,
                    last_name: this.state.currentAccountSelected.last_name,
                    first_name: this.state.currentAccountSelected.first_name,
                    email: this.state.currentAccountSelected.email,
                    is_staff: this.state.currentAccountSelected.is_staff,
                    username: this.state.currentAccountSelected.username,
                };

                let data1 = {
                    users: [user.id],
                    groups: this.state.currentAccountSelected_groupsToAdd,
                };
        
                let data2 = {
                    users: [user.id],
                    groups: this.state.currentAccountSelected_groupsToRemove,
                }

                this._admin_update_account(user.id, data, (response) => {
                    // console.log( response );
                    this._add_user_to_group(data1, () => {
                        this._remove_user_from_group(data2, () => {
                            alert("Les informations de l'utilisateur on été mis àjour avec succès");
                            this.setState({
                                isLoading: false,
                            });
                            window.location.reload();
                        });
                    });
                });
            }
        }, () => {
            this._openOrCloseAuth();
        })
    }

    _delete_account(id, callback=()=>{}) {
        this.setState({
            isLoading: true,
        });
        this._deleteAccount(id, this.props.user.access, (response) => {
            if( response.status === 204 ) {
                this.setState({
                    isLoading: false,
                });
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._delete_account(id, callback);
            });
        });
    }

    _openOrCloseAuth() {
        this.setState({
            auth: !this.state.auth,
        });
    }

    render() {
        return (
            <>
                {
                    super.render()
                }

                {
                    this.state.isReady ?
                        <div className='userProfile'>
                            <div>
                                <div className='input-container'>
                                    <span>
                                        Nom
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' value={this.state.currentAccountSelected.last_name}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.last_name = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Prénom
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' value={this.state.currentAccountSelected.first_name}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.first_name = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Email
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' value={this.state.currentAccountSelected.email}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.email = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Nom d'utilisateur
                                    </span>
                                    <div>
                                        <input className='input-style-1-1' value={this.state.currentAccountSelected.username}
                                            onChange={(e) => {
                                                let currentAccountSelected = this.state.currentAccountSelected;
                                                currentAccountSelected.username = e.target.value;
                                                this.setState({
                                                    currentAccountSelected: currentAccountSelected
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <div>
                                <label key={this.state.currentAccountSelected.username} htmlFor={this.state.currentAccountSelected.username} className='flex-row align-center'>
                                    <input id={this.state.currentAccountSelected.username} name={this.state.currentAccountSelected.username} type='checkbox' checked={this.state.currentAccountSelected.is_staff}
                                        onChange={(e)=>{
                                            let currentAccountSelected = this.state.currentAccountSelected;
                                            
                                            if( e.target.checked === true ) {
                                                currentAccountSelected.is_staff = true;
                                            }
                                            else {
                                                currentAccountSelected.is_staff = false;
                                            }
                                            this.setState({
                                                currentAccountSelected: currentAccountSelected,
                                            });
                                            return true;
                                        }}
                                    />
                                    <label htmlFor={this.state.currentAccountSelected.username}
                                        style={{
                                            fontSize: "0.8em",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        Définir comme administrateur
                                    </label>
                                </label>
                            </div>
                            <br/>

                            <div>
                                <div className='input-container'>
                                    <span>
                                        Liste des groupes de {this.state.currentAccountSelected.username} :
                                    </span>
                                    <div>
                                        <table>
                                            <tr
                                                style={{
                                                    background: "#0A3764ee",
                                                    color: "#ffffff",
                                                }}
                                                className="table-head"
                                            >
                                                <th>
                                                    NOM DU GROUPE
                                                </th>
                                                <th className='text-align-center flex-wrap'
                                                    onClick={() => {
                                                        if( this.state.currentAccountSelected_groupsToRemove.length !== this.state.currentAccountSelected.groups.length) {
                                                            let currentAccountSelected_groupsToRemove = [];
                                                            this.state.currentAccountSelected.groups.map((group, index) => {
                                                                currentAccountSelected_groupsToRemove.push(group.id);
                                                            });

                                                            this.setState({
                                                                currentAccountSelected_groupsToRemove: currentAccountSelected_groupsToRemove,
                                                            });
                                                        }
                                                        else {
                                                            this.setState({
                                                                currentAccountSelected_groupsToRemove: [],
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <div className='text-align-center flex-row justify-center align-center'>
                                                        RETIRER "{this.state.currentAccountSelected.username}"
                                                        <input type='checkbox' checked={this.state.currentAccountSelected.groups.length === this.state.currentAccountSelected_groupsToRemove.length} style={{
                                                            marginLeft: "4px",
                                                        }}/>
                                                    </div>
                                                </th>
                                            </tr>

                                            {
                                                this.state.currentAccountSelected.groups.map((group, index) => (
                                                    <tr
                                                        onClick={() => {
                                                            if (this.state.currentAccountSelected_groupsToRemove.includes(group.id)) {
                                                                let currentAccountSelected_groupsToRemove = this.state.currentAccountSelected_groupsToRemove;
                                                                for (var i = 0; i < currentAccountSelected_groupsToRemove.length; i++) {
                                                                    if (currentAccountSelected_groupsToRemove[i] === group.id) {
                                                                        currentAccountSelected_groupsToRemove.splice(i, 1);
                                                                        this.setState({
                                                                            currentAccountSelected_groupsToRemove: currentAccountSelected_groupsToRemove,
                                                                        });
                                                                        return true;
                                                                    }
                                                                }
                                                            }
                        
                                                            else {
                                                                let currentAccountSelected_groupsToRemove = this.state.currentAccountSelected_groupsToRemove;
                                                                currentAccountSelected_groupsToRemove.push(group.id);
                                                                this.setState({
                                                                    currentAccountSelected_groupsToRemove: currentAccountSelected_groupsToRemove,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <td>
                                                            {group.name}
                                                        </td>
                                                        <td className='text-center flex-row justify-center'>
                                                            <input type='checkbox' checked={this.state.currentAccountSelected_groupsToRemove.includes(group.id)} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                </div>
                                <br/>


                                <div className='input-container'>
                                    <span>
                                        Liste des groupes auquels "{this.state.currentAccountSelected.username}" n'appartient pas:
                                    </span>
                                    <div>
                                        <table>
                                            <tr
                                                style={{
                                                    background: "#0A3764ee",
                                                    color: "#ffffff",
                                                }}
                                                className="table-head"
                                            >
                                                <th>
                                                    NOM DU GROUPE
                                                </th>
                                                <th className='text-align-center flex-wrap'
                                                    onClick={() => {
                                                        if( this.state.currentAccountSelected_groupsToAdd.length !== this.state.currentAccountSelected.not_member_of.length) {
                                                            let currentAccountSelected_groupsToAdd = [];
                                                            this.state.currentAccountSelected.not_member_of.map((group, index) => {
                                                                currentAccountSelected_groupsToAdd.push(group.id);
                                                            });

                                                            this.setState({
                                                                currentAccountSelected_groupsToAdd: currentAccountSelected_groupsToAdd,
                                                            });
                                                        }
                                                        else {
                                                            this.setState({
                                                                currentAccountSelected_groupsToAdd: [],
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <div className='text-align-center flex-row justify-center align-center'>
                                                        AJOUTER "{this.state.currentAccountSelected.username}"
                                                        <input type='checkbox' checked={this.state.currentAccountSelected.not_member_of.length === this.state.currentAccountSelected_groupsToAdd.length} style={{
                                                            marginLeft: "4px",
                                                        }}/>
                                                    </div>
                                                </th>
                                            </tr>

                                            {
                                                this.state.currentAccountSelected.not_member_of.map((group, index) => (
                                                    <tr
                                                        onClick={() => {
                                                            if (this.state.currentAccountSelected_groupsToAdd.includes(group.id)) {
                                                                let currentAccountSelected_groupsToAdd = this.state.currentAccountSelected_groupsToAdd;
                                                                for (var i = 0; i < currentAccountSelected_groupsToAdd.length; i++) {
                                                                    if (currentAccountSelected_groupsToAdd[i] === group.id) {
                                                                        currentAccountSelected_groupsToAdd.splice(i, 1);
                                                                        this.setState({
                                                                            currentAccountSelected_groupsToAdd: currentAccountSelected_groupsToAdd,
                                                                        });
                                                                        return true;
                                                                    }
                                                                }
                                                            }
                        
                                                            else {
                                                                let currentAccountSelected_groupsToAdd = this.state.currentAccountSelected_groupsToAdd;
                                                                currentAccountSelected_groupsToAdd.push(group.id);
                                                                this.setState({
                                                                    currentAccountSelected_groupsToAdd: currentAccountSelected_groupsToAdd,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <td>
                                                            {group.name}
                                                        </td>
                                                        <td className='text-center flex-row justify-center'>
                                                            <input type='checkbox' checked={this.state.currentAccountSelected_groupsToAdd.includes(group.id)} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            <br/>
                            <div className='flex-row justify-center'>
                                <button className='button-style-5'
                                    onClick={() => {
                                        this._updateUser(this.state.currentAccountSelected);
                                    }}
                                >
                                    Valider
                                </button>
                            </div>
                        </div>
                    :
                        null
                }

                {
                    this.state.auth &&
                        <div className='auth absolute'>
                            <AUthScreen
                                action={ (password) => {
                                    this.state.authAction(password)
                                }}
                                closeAuth={() => {this._openOrCloseAuth()}}
                            />
                        </div>
                }
            </>
        )
    }
}

DashboardUserProfile = connect(mapStateToProps, null)(DashboardUserProfile);

export default DashboardUserProfile;