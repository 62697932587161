import React from 'react';
import { connect } from 'react-redux';
// import { Route } from 'react-router';
// import { Switch } from 'react-router-dom';



/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import Menu from '../components/menu/menu.component';



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.css';
import './responsive.style/home.responsive.style.css';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/
import armoiries from '../assets/logos/logo.png';



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class MenuScreen extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            menuIsOpen: false,
            currentView: 0,

            categories: [],
            currentCategories: [],
            currentCategoriesIndex: [],
        }
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this._get_all_categories(( categories )=>{
            this.setState({
                categories : categories,
                isLoading: false,
            });
        });
        document.addEventListener("scroll", this._onScroll);
    }

    _onScroll(){
        if (window.scrollY > 100 ) {
            let nav = document.getElementById("nav");
            nav.style.background = "#0A3764ee";
        } else {
            let nav = document.getElementById("nav");
            nav.style.background = "#0A376400";
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this._onScroll);
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    _clickMenu() {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen,
        });
    }

    _clickMenuItem( index ) {
        this._clickMenu();
        // document.getElementsByClassName("contain").innerHTML = "";
        if( index >= 0 ){
            this.setState({
                currentView: index,
            });
        }
    }

    render() {
        return (
            <>
                <div className="contain">
                    <div className="nav" id='nav' data-fixed>
                        <div className="nav_">
                            <a href="./" className="nav_icon">
                                <div className="nav_icon_">
                                    <img src={armoiries} alt="DPP"/>
                                </div>
                            </a>
                            <div className='flex-row align-center'>
                                <div className="nav_menuIcon" onClick={() => {
                                    this._clickMenu();
                                }}>
                                    <svg width="54" height="54" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z" fill="#ffffff"/>
                                    </svg>
                                </div>
                                <div className="nav_menu" style={{
                                    marginLeft: this.state.menuIsOpen ? "0px" : "-100%",
                                }}>
                                    <div className="nav_menu_">
                                        <div className="nav_menu_cross" onClick={() => {
                                            this._clickMenu();
                                        }}>
                                            <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                                                <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                                            </svg>
                                        </div>
                                        <Menu
                                            action = {( index) => {
                                                this._clickMenuItem( index );
                                            }}
                                            current={ this.state.currentView }
                                            categories={this.state.categories}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


MenuScreen = connect(mapStateToProps, null)(MenuScreen);
export default MenuScreen;