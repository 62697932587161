import currentEnv from "../envConfig";

class PATH {
    constructor() {
        this.api = currentEnv.REACT_APP_SYGED_BACKEND_URL;
        this.api_ = currentEnv.REACT_APP_SYGED_BACKEND_URL.slice(0, -1);

        this.login = "accounts/token/";
        this.accounts = "accounts/";
        this.registration = "accounts/";
        this.refreshToken = "accounts/refresh/";

        this.documents = "documents/";
        this.documents_create = "documents/create/";
        this.documents_update = "documents/update/";
        this.documents_get = "documents/get/";
        this.documents_delete = "documents/delete/";
        this.documents_download = "documents/download/";
    
        
        this.state = "state/";
        this.documents_public = "public/documents/";
        this.document_public = "public/document/";
        this.documentPassword = "password/";

        this.categories = "categories/";
        this.categories_public = "public/categories/";
        this.category_public = "public/category/";

        this.key_word = "keyword/document/";
        this.key_ = "keyword/";
        this.key_delete = "delete/keyword/document/";
        this.key_public = "public/keyword/";

        this.keydocs = "keydocs/";
        this.adminApi = "admin-api/";
        this.groups = "groups/";
        this.permissions = "permissions";
        this.access = "access/";

        this.chmoder = "api/chmoder/";
        this.group = "group/";
        this.groups_ = "user/groups/";
        this.uperm = "user/permissions/";
        this.admgroups = "admin/groups/";
        this.allperm = "admin/perms/";
        this.isadmin = "isadmin/";
        this.issudo = "issudo/";

        this.level = "api/admin/authlev/";
    }
}

export default PATH;