import React from 'react';
import { connect } from 'react-redux';


import SuperScreen from '../class/SuperScreen';

/**Importation des composants de la page */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import faker from 'faker';
import ProjectCard from '../components/projectCard/projectCard.component';

/**Importation des styles de la page */
import './styles/dashboard.home.style.css';
import './responsive.style/dashboard.home.responsive.style.css';

/**Importation des helpers */
import AccessStore from '../store/accessStore';
import { getDocStats } from '../helpers/utils.helper'

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

// Get stats from helper function and keep them on localStorage
getDocStats()

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

let labels = []

let options = {
    responsive: true,
    tension : 0.3,
    plugins: {
      legend: {
        position: '0',
      },
      title: {
        display: true,
        text: 'Courbe des documents créés',
      },
    },
};

let data = {
    labels,
    datasets: [
        {
            label: '',
            data: [],
            borderColor: '#122f4c',
            backgroundColor: '#122f4c',
        },
    ],
};

const options_2 = {
    responsive: true,
    tension : 0.3,
    plugins: {
        legend: {
            position: '0',
        },
        title: {
            display: true,
            text: 'Courbe des téléchargements',
        },
    },
};
  
let data_2 = {
    labels,
    datasets: [
      {
        label: '',
        data: [],//labels.map(() => faker.datatype.number({ min: 20, max: 23428 })),
        borderColor: '#122f4c',
        backgroundColor: '#122f4c',
      },
    ],
};

class DashboardHome extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            homeDocs: [],
            currentPath: "",

            fromOtherSOurce: false,

            filter: false,
            search: false,
            searchTime: 0,
            noResultFound: false,

            filterResult: false,
            sortResult: false,

            currentSuggestion: [],
            currentResultPage: 1,
            searchResult: [],
            searchResultsFilter: [],
            printMenu: false,
            basket: [],
            printBasket: false,
            copied: false,
            currentDownloadLink: [],
            currentText: [],
            viewSHareOption: false,
            filterContent: {},
            printFilterSelection: false,
            currentFilterSelection: [],
            allResultsGet: true,
            currentSearch: "",

            resultSortedByDate: [],
            typeSort: 1,
            printSortedTab: false,

            categories: [],
            currentCategories: [],
            currentCategoriesIndex: [],

            years: [],
            currentYears: [],
            currentMonths: [],
            searchSuggestion: []
        }

        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        this._get_all_categories(( categories )=>{
            this.setState({
                categories : categories,
            }, () => {
                this._getAllTypeDocOfCategories(0, ()=>{
                    // alert(8);
                    this.setState({
                        isLoading: false,
                    });
                });
            });
        });
        const statsData =  JSON.parse(localStorage.getItem('stats'))
        data.labels= statsData?.x_docs_created
        data.datasets[0].data = statsData?.y_docs_created
      
        this.setState({
            docsCount: statsData?.doc_count,
            viewsCount: statsData?.view_count,
            downloadsCount: statsData?.download_count,
            usersCount: statsData?.user_count,
            currentMonthDocsCount: statsData?.month_docs_count,
            currentMonthViewsCount: statsData?.month_views_count,
            currentMonthDownloadsCount: statsData?.month_downloads_count,
            currentMonthMostViewed: statsData?.most_viewed_docs
        });

        let mostViewedDetails = [], counter = 0
        for(const element of statsData?.most_viewed_docs){
            counter++
            mostViewedDetails.push(<li>{counter}- {element[0]}</li>)
        }
        this.setState({
            mostViewedDetails: mostViewedDetails
        })
        window.scrollTo(0, 0);
    }

    _getAllTypeDocOfCategories( index_=0, callback=()=>{} ) {
        if( this.state.categories.length > 0 ) {
            let category = this.state.categories[index_];
            this._getCategoriesRecentDocs(category.pk, (docList) => {

                this._getAllDocsKeywords(0, docList.results, (newList) => {
                    let newCategory = {
                        name: category.name,
                        pk: category.pk,
                        recentDocs: newList,
                        moreDownload: [],
                    };
                    this._getCategoriesMoreDownloadDocs(category.pk, (list) => {

                        /**Obtenir la liste de mots-clés pour chaque document de la liste obtenue */
                        this._getAllDocsKeywords(0,list.results, (newList) => {
                    
                            newCategory.moreDownload = newList;
                            let homeDocs = this.state.homeDocs;
                            homeDocs.push(newCategory);
                            this.setState({
                                homeDocs: homeDocs,
                            }, ()=> {
                                // console.log( index_ );
                                if( index_ < this.state.categories.length - 1 ) {
                                    this._getAllTypeDocOfCategories( index_ + 1, callback );
                                }else {
                                    // console.log( 9 );
                                    callback();
                                }
                            });
                        })
                    });
                });
            })
        }

        else {
            this.setState({
                isLoading: false,
            });
        }
    }

    _getCategoriesRecentDocs(category, callback=()=>{}) {
        let searchPath = "category=" + category;
        this._startSearchRequest(searchPath, 1, (response)=>{
            callback( response.data );
        });
    }

    _getCategoriesMoreDownloadDocs(category, callback=()=>{}) {
        let searchPath = "category=" + category;
        this._startSearchRequest(searchPath, 1, (response)=>{
            callback( response.data );
        });
    }

    _getAllDocsKeywords( index=0, docList, callback=()=>{} ) {
        if( docList.length > 0 ) {
            let doc = docList[index];
            this._getDocKey(doc.id, (keywords) => {
                let docs = docList;
                docs[index]["keywords"] = keywords;
                // console.log( index );
                if( index < docList.length - 1 ) {
                    this._getAllDocsKeywords( index + 1, docs, callback );
                }else {
                    callback(docs);
                }
            });
        }
        else {
            callback([]);
        }
    }

    _getDocKey(id, callback=()=>{}) {
        this._getKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocKey(id, callback);
            })
        });
    }

    _get_all_categories(callback=()=>{}) {
        this._getAllCategories(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback( response.data );
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    _get_attribute(attribute, callback=()=>{}) {
        this._getAttribute(attribute, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._get_attribute(attribute, callback);
            })
        });
    }

    _getKeyword_for_allDocs(callback=()=>{}) {
        this._getKeywordForAllDocs(this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getKeyword_for_allDocs(callback);
            })
        });
    }

    _startSearchRequest( searchPath, page, callback=()=>{} ) {
        this._search(searchPath, page, this.props.user.access, ( response ) => {
            if( response.status === 200 ) {
                callback( response );
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._startSearchRequest( searchPath, page, callback );
            });
        });
    }

    _search_doc_(e, value, getMore=false, path=null) {
        if( !getMore ) {
            if( e!== null ) {
                e.preventDefault();
                this.setState({
                    filterContent: {},
                    searchResultsFilter: [],
                    currentResultPage: 1,
                });
            }
        }
        
        let startTime = new Date();

        let searchPath =  path===null ? "search="+value : path;

        if( path===null ) {
            this.setState({
                currentSearch: value,
            });
        }
        
        this.setState({
            isLoading: true,
            currentPath: searchPath,
        });

        this._startSearchRequest( searchPath, this.state.currentResultPage, ( response ) => {

            // console.log( response.data );
            if( response.data.count > 0 ) {
                let searchResult = [];

                if( !getMore ) {
                    searchResult = response.data.results;
                }else {
                    searchResult = this.state.searchResult;
                    searchResult = searchResult.concat( response.data.results );
                }
                
                let endTime = new Date();

                this.setState({
                    search: true,
                    isLoading: false,
                    searchResult: searchResult,
                    searchTime: ( endTime - startTime ) / 1000,
                }, () => {
                    this._getAllDocsKeywords(0,this.state.searchResult, (newList) => {
                        this.setState({
                            searchResult: newList,
                        });
                    });
                });
            }

            else {
                let endTime = new Date();
                this.setState({
                    search: true,
                    isLoading: false,
                    noResultFound: true,
                    searchTime: ( endTime - startTime ) / 1000,
                    searchResult: [],
                });
            }
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            if( error.response.status === 401 ) {
                // console.log( error.response );
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response );
                            callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }

            else if(error.response.status === 403){
                this.setState({
                    isLoading: false,
                    no_access: true,
                });
            }
        }
    }

    /**
     * On met a jour la session utilisateur
     */
     _set_new_user( response ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user );
    }

    
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='dashboardHome'>
                    
                    <div className='dashboardHome_title title'>
                        <span>
                            À la date d'aujourd'hui :
                        </span>
                    </div>

                    <div className='dashboardHome_contentCard'>
                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Nombre total de docs
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.docsCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Nombre total de vues
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.viewsCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Nombre total de téléch.
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.downloadsCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Nombre total de users
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.usersCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='dashboardHome_title title'>
                        <span>
                            Ce mois-ci :
                        </span>
                    </div>

                    <div className='dashboardHome_contentCard'>
                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Nombre de docs créés
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.currentMonthDocsCount}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Nombre de vues
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.currentMonthViewsCount}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Nombre de télécharg...
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.currentMonthDownloadsCount}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Les plus téléchargés
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <ul>
                                        {this.state.mostViewedDetails}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='dashboardHome_contentChart'>
                        <Line options={options} data={data} />
                    </div>

                    <br/><br/><br/>
                    <div className='dashboardHome_title title'>
                        <span>
                            Les documents les plus visualisés :
                        </span>
                    </div>

                    <div className='not-search-container'>
                        {
                            this.state.homeDocs.map((category) => (
                                <div>
                                    <div className='card_container'>
                                        <div className='card_container-title'>
                                            <div className='card_container-title-square'></div>
                                            <div className='card_container-title-text'>
                                                <span>{category.name}</span>
                                            </div>
                                        </div>

                                        <div className='flex-row justify-center flex-wrap card_list'>
                                            {
                                                category.recentDocs.map((doc, index) => (
                                                    <div className='card_list_content' key={"doc-list-1-" + index}>
                                                        <ProjectCard
                                                            addToPanier={() => {
                                                                this._updateBasket(doc);
                                                            }}
                                                            project={doc}
                                                            link={"/view-doc-info:?doc=" + encodeURIComponent(doc.id) + "&cover=" + encodeURIComponent(doc.img)}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>  
                            ))
                        }
                    </div>
                </div>
            </>
        )
    }
}

DashboardHome = connect(mapStateToProps, null)(DashboardHome);
export default DashboardHome;
