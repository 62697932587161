import React from 'react';
import parse from 'html-react-parser';
// import { Link } from 'react-router-dom';

import './style/style.scss';
import './style/responsive.style.scss';


class GroupCard extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            projectLink: '',
            percent: null,
        }
    }


    componentDidMount() {
        this.setState({
            link: this.props.link,
        });
    }

    render() {
        return (
            <div className="GroupCard">
                <div className="GroupCard_imageContainer">
                    <a href={this.props.link}
                        onClick={() => {
                            // this.props.action && this.props.action();
                        }}
                    >
                        {/* <img className="GroupCard_image" alt={""} src={this.props.api + this.props.elt.cover} /> */}
                    </a>
                </div>
                <div className="GroupCard_body">
                    <a href={this.props.link} className='GroupCard_body_title_link'>
                        <div className="GroupCard_body_title">
                            <span>{this.props.group.name && this.props.group.name}</span>
                        </div>
                    </a>
                    {/* <div className="GroupCard_body_title GroupCard_body_date">
                        <span>{this.props.elt.month}/{this.props.elt.year}</span>
                    </div> */}
                    <div className="Groupcard_separator_line"></div>
                    <div className="GroupCard_body_text text-form lineHeight1">
                        {/* <p>{this.props.more.description !== null &&parse(this.props.more.description)}</p> */}
                    </div>
                    {/* <div className="GroupCard_competence">
                        {
                            this.props.elt.areas &&
                            this.props.elt.areas.map((competence, index) => (
                                <div key={"competences" + index} className="competence">
                                    <a className="link" href={this.props.competenceLink + competence.id}
                                        onClick={() => {
                                            // this.props.action2 && this.props.action2();
                                        }}
                                    >
                                        <span>
                                            {competence.name}
                                        </span>
                                    </a>
                                </div>
                            ))
                        }
                    </div> */}
                </div>
                <div className='GroupCard_foot'>
                    <a className="link" href={this.props.link}>
                        <span>AFFICHER</span>
                        <span className='GroupCard_foot_fake'></span>
                    </a>
                </div>
            </div>
        )
    }
}

export default GroupCard;