import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';



/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import ProjectCard from '../components/projectCard/projectCard.component';
import {
    EmailShareButton,
    LinkedinShareButton,
} from "react-share";


import {
    EmailIcon,
    LinkedinIcon   
} from "react-share";




/**----------------------------------------------------------------------------------------*/
/** Importation des screens */
// import DocExplorer from './doc_explorer.screen';
import DocViewerScreen from './docViewer.screen';
import HomeView from './home_view.screen';
import MenuScreen from './top_menu.screen';
import OnDocView from './on_doc_view.screen';
import OnKeywordView from './on_keyword_view.screen';
import SearchDocs from './search_docs.screen';
import AboutUsScreen from './about_us.screen';
import AllCategoriesView from './all_categories.screen';
import BibliothequeDocsView from './view_bibliotheque_docs.screen';
import UserHistory from './user_history.screen';
import FooterScreen from './footer.screen';
import AOFScreen from './aof.screen';
import TextsLaw from './textsLaw.screen';
import Struct from './struct.screen';
import LawText from './LawText.screen';

import panier2 from '../assets/icons/panier2.jpg';


/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';




/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.css';
import './responsive.style/home.responsive.style.css';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
        panier: state.Panier.panier,
        panierCount: state.Panier.count
    };
}

class Home extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            printBasket: false,
            viewSHareOption: false,
        }
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.accessStore.callback((session) => {
            if( session && session.access ) {
                this._get_if_IsAdmin(session.access, (stat) => {
                    this.accessStore.setRedux('SET_IS_ADMIN', stat.isadmin);
                });
            }
            // console.log( session );
        });
        this.accessStore.init( 'sigb_kando_user', "INIT_USER");
        // console.log( this.props.panier);
        // this._isObjEmpty( this.props.user );
    }

    _logout() {
        if( window.confirm('Voulez-vous vraiment vous déconnecter?') ) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }

    _get_if_IsAdmin(access, callback=()=>{}) {
        this._getIfCurrentUserIsAdmin(access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._get_if_IsAdmin( this.props.user.access, callback );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response, callback );
                            // callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }

                else if( error.response.data.code==="user_not_found" ) {
                    this.accessStore.setRedux('LOG_OUT', {},() => {
                        window.location.reload();
                    });
                }
            }
        }
    }

    /**
     * On met a jour la session utilisateur
    */
    _set_new_user( response, callback=()=>{} ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user, () => {
            callback();
        });
    }

    _printBasket() {
        let text = [];
        
        this.props.panier.map((elt) => {
            text = text.concat( elt.title + " : " + elt.file + "\n\n" );
        });
        
        this.setState({
            currentText: text,
            printBasket: !this.state.printBasket,
        });
    }

    _downloadAllBasket( index=0 ) {
        if( index === 0 ) {
            this.setState({
                currentDownloadLink: [],
            });
        }
        if( index < this.state.basket.length) {
            let downloadLinks = this.state.currentDownloadLink;
            downloadLinks = downloadLinks.concat(this.state.basket[index].file);
            this.setState({
                currentDownloadLink: downloadLinks,
            }, () => {
                this._downloadAllBasket( index + 1 );
            });
        }
    }

    _copyToClipBoard() {
        var copyText = document.getElementById("element_to_copy2");
        copyText.setAttribute("value", this.state.currentText );
        copyText.select();

        navigator.clipboard.writeText( copyText.value );

        clearTimeout( this.timeout );

        this.setState({
            copied: true,
        }, () => {
            this.timeout = setTimeout( () => {
                this.setState({
                    copied: false,
                });
            }, 5000);
        });
    }

    _printOrHideShareOptions() {
        let shareOptions = document.getElementById( "shareOptionsForAll" );

        if( !this.state.viewSHareOption ) {
            shareOptions.style.opacity = 1;
            shareOptions.style.visibility = "visible";
            shareOptions.style.marginBottom = "40px";

            this.setState({
                viewSHareOption: true,
            });
        }

        else {
            shareOptions.style.opacity = 0;
            shareOptions.style.visibility = "hidden";
            shareOptions.style.marginBottom = "0px";

            this.setState({
                viewSHareOption: false,
            });
        }
    }


   
    render() {
        return (
            <>
                {
                    <div className="home_contain diagonal-background-1">
                        <MenuScreen/>
                        <Switch>
                            <Route exact path="/view-doc:" component={DocViewerScreen}/>
                            <Route exact path="/view-doc-info:" component={OnDocView}/>
                            <Route exact path="/view-keyword-info:" component={OnKeywordView}/>
                            <Route exact path="/search-doc" component={SearchDocs}/>
                            <Route exact path="/qui-sommes-nous" component={AboutUsScreen}/>
                            <Route exact path="/biblotheque" component={AllCategoriesView}/>
                            <Route exact path="/view-docs" component={BibliothequeDocsView}/>
                            <Route exact path="/history" component={UserHistory}/>
                            <Route exact path="/aof" component={AOFScreen}/>
                            <Route exact path="/texte_de_lois" component={TextsLaw}/>
                            <Route exact path="/struct:" component={Struct}/>
                            <Route exact path="/texte:" component={LawText}/>
                            <Route exact path="/" component={HomeView}/>
                        </Switch>

                        {
                            this.props.panierCount > 0 ?
                                <div className='panier'
                                    onClick={() => {
                                        this._printBasket();
                                    }}
                                >
                                    <img src={panier2} alt="Syged-DPFA"/>
                                    <span>
                                        {this.props.panierCount}
                                    </span>
                                </div>
                            :
                                null
                        }

                        {/* Affichage du contenu du pannier */}
                        {
                            this.state.printBasket &&
                            <div className="basketPrinter">
                                <div className='basketPrinter-content'>
                                    <div className='search-result-section-results'>
                                        <div className='search-result-section-results- flex-row flex-wrap justify-center'>
                                            {
                                                this.props.panier.map((result, index)=>(
                                                    <div className='search-result-section-results-one search-result-section-results-one1' key={"basket-" + index}>
                                                        <ProjectCard
                                                            addToPanier={() => {
                                                                this._updateBasket(result);
                                                            }}
                                                            action2={() => {this._start(true)}}
                                                            project={result}
                                                            link={"/view-doc-info:?doc=" + encodeURIComponent(result.id) + "&cover=" + encodeURIComponent(result.img)}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='basketPrinter-content-cross'>
                                        <button
                                            onClick={((e) => {
                                                this._printBasket();
                                            })}
                                        >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                                <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className='basketPrinter-content-left'>
                                        <div className='basketPrinter-shareOptions' id={"shareOptionsForAll"}>
                                            <div className='basketPrinter-shareOptions-content'>
                                                <div className='basketPrinter-shareOptions-content-one'>
                                                    <EmailShareButton url={this.state.currentText} title={this.state.currentText}>
                                                        <EmailIcon size={32} round={true} />
                                                    </EmailShareButton>
                                                </div>

                                                <div className='basketPrinter-shareOptions-content-one'>
                                                    <LinkedinShareButton url={this.state.currentText} title={this.state.currentText}>
                                                        <LinkedinIcon size={32} round={true} />
                                                    </LinkedinShareButton>
                                                </div>
                                            </div>
                                            <div className='basketPrinter-shareOptions-content-rect'></div>
                                        </div>
                                        <div className='basketPrinter-content-left-'>
                                            <div className='basketPrinter-content-left-content'>
                                                <button
                                                    onClick={() => {
                                                        this._copyToClipBoard();
                                                        return true;
                                                    }}

                                                    style={{
                                                        backgroundColor: this.state.copied ? "#769ea7" : "#ffffff",
                                                        color: this.state.copied ? "#ffffff" : "#00000077",
                                                    }}
                                                >
                                                    <input id="element_to_copy2" readOnly={true} type="text" contentEditable={false}/>
                                                    <svg width="17" height="19" viewBox="0 0 17 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M12.1429 16.625V18.1094C12.1429 18.6013 11.7351 19 11.2321 19H0.910714C0.407734 19 0 18.6013 0 18.1094V4.45312C0 3.96124 0.407734 3.5625 0.910714 3.5625H3.64286V14.5469C3.64286 15.6928 4.59611 16.625 5.76786 16.625H12.1429ZM12.1429 3.85938V0H5.76786C5.26488 0 4.85714 0.39874 4.85714 0.890625V14.5469C4.85714 15.0388 5.26488 15.4375 5.76786 15.4375H16.0893C16.5923 15.4375 17 15.0388 17 14.5469V4.75H13.0536C12.5527 4.75 12.1429 4.34922 12.1429 3.85938ZM16.7333 2.70791L14.231 0.260842C14.0602 0.0938283 13.8286 1.23499e-06 13.5871 0L13.3571 0V3.5625H17V3.33765C17 3.10146 16.9041 2.87493 16.7333 2.70791Z"/>
                                                    </svg>
                                                </button>
                                            </div>

                                            <div className='basketPrinter-content-left-content'>
                                                <button
                                                    onClick={() => {
                                                        this._printOrHideShareOptions();
                                                        return true;
                                                    }}
                                                >
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-share-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                                                    </svg>
                                                </button>
                                            </div>

                                            {/* <div className='basketPrinter-content-left-content'>
                                                    <button
                                                        onClick={(e) => {
                                                            this._downloadAllBasket();
                                                        }}
                                                    >
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                            <path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                        </svg>
                                                    </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {/* <FooterScreen/> */}
            </>
        )
    }
}


Home = connect(mapStateToProps, null)(Home);
export default Home;
