// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class PointerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {
        
    }

    
    render() {
        return (
            <div className={this.props.noPointer ? 'pointer_' : 'pointer'}
                style={this.props.style}
            >
                {/* {!this.props.noPointer && <div className='pointer_elt'
                    style={this.props.arrowStyle ? this.props.arrowStyle : null}
                ></div>} */}
                <span
                    style={this.props.textUnderline ? this.props.textUnderlineStyle : null}
                >
                    { this.props.text }
                </span>
            </div>
        )
    }
}

export default PointerComponent;