import axios from "axios";
import PATH from '../GLOBAL-VAR/globals-urls';

const PATH_ = new PATH();

class AuthRequests {
    _login(data) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.login,
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _registration(data) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.registration,
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _refresh_token(refresh) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.refreshToken,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    refresh: refresh
                }
            })
        )
    }

    _getAccounts(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + "admin/users/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _SearchAccounts(access, search) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + "admin/users/?search=" + search,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _deleteAccount(id, access) {
        return (
            axios({
                method: 'delete',
                url: PATH_.api + PATH_.chmoder + "admin/users/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _addAccount(data, access) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.chmoder + "admin/users/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _updateAccount(id, data, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + PATH_.chmoder + "update_user_data/" + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _userUpdateAccount(id, data, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + PATH_.accounts + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _addGroup(data, access) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.chmoder + PATH_.admgroups,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _addAccess(data, access) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.adminApi + PATH_.access,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _RemoveAccess(data, access) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.adminApi + PATH_.access + "?remove=true",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getAllGroups(access) {
        if (access !== null) {
            return (
                axios({
                    method: 'get',
                    url: PATH_.api + PATH_.adminApi + PATH_.groups,
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + access
                    },
                })
            )
        } else {
            return (
                axios({
                    method: 'get',
                    url: PATH_.api + "public/groups/",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            )
        }
    }

    _getAllGroups_(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + "api/chmoder/groups_extended_list/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _initGroupExtended_(data, id, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + "api/chmoder/update_group/" + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _updateGroup(data, id, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + PATH_.adminApi + PATH_.groups + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _updateGroup_(data, id, access) {
        return (
            axios({
                method: 'put',
                url: PATH_.api + "api/chmoder/update_group/" + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _addPermsToGroup(data, id, access) {
        return (
            axios({
                method: 'post',
                url: PATH_.api + PATH_.chmoder + PATH_.group + id + "/admin/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    // _deletePermsToGroup(data, id, access) {
    //     return (
    //         axios({
    //             method: 'DELETE',
    //             url: PATH_.api + PATH_.chmoder + PATH_.group + id + "/admin/",
    //             data: data,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Authorization": "Bearer " + access
    //             },
    //         })
    //     )
    // }

    _deleteGroup(id, access) {
        return (
            axios({
                method: 'delete',
                url: PATH_.api + PATH_.adminApi + PATH_.groups + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getAllPermissions(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + PATH_.allperm,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getCurrentUserPermissions(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + PATH_.uperm,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getUserPermissions(access, id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + PATH_.uperm + "?uid=" + id,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getIfCurrentUserIsAdmin(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + PATH_.isadmin,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }


    _getIfCurrentUserIsSudo(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + PATH_.issudo,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _getUserGroups(access, id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + PATH_.groups_ + "?uid=" + id,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }


    _getGroupUsers(access, id) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + PATH_.chmoder + PATH_.group + id + "/users/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _systemInfoEdit(data, access) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH_.api + "api/chmoder/edit_system_info/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _systemInfoUpdate(id, data, access) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH_.api + "api/chmoder/edit_system_info/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _systemInfoGetAll(access) {
        return (
            axios({
                method: 'get',
                url: PATH_.api + "api/chmoder/edit_system_info/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _systemInfoDelete(id, access) {
        return (
            axios({
                method: 'delete',
                url: PATH_.api + "api/chmoder/edit_system_info/" + id + "/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }
}

export default AuthRequests;