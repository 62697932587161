import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import words from 'an-array-of-french-words';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */
import ProjectCard from '../components/projectCard/projectCard.component';
import PointerComponent from '../components/pointer.component/pointer.component';
import ListManager from '../components/listManager.component/listManager.component';

import {
    EmailShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookShareButton,
} from "react-share";

import {
    EmailIcon,
    LinkedinIcon,
    WhatsappIcon,
    FacebookIcon,
} from "react-share";

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */

/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';

/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/on_doc_view.style.css';
import './responsive.style/on_doc_view.responsive.style.css';

/**
 * Importation des helpers
 */
import tools from '../helpers/tools.helper';
import AccessStore from '../store/accessStore';
import {getUserInfo, _downloadFile, updateDocViewsCounter} from '../helpers/utils.helper'

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

const userInfo = getUserInfo();

class OnDocView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            doc: {},
            category: {},
            id: "",
            cover: "",
            owner: {},
            keywords: [],
            isReady: true,
            keyList1: ["Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité", "Informatique", "Sécurité"],
            keyList2: ["Réseaux informatique", "Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité", "Sécurité informatique", "Informatique", "Sécurité"],
            docList: [],
            canUpdateDoc: userInfo.perms.includes('change_doc'),
            canViewDoc: userInfo.perms.includes('view_doc'),
            canDeleteDoc: userInfo.perms.includes('delete_doc'),

            usersList: {},
        }

        this.accessStore = new AccessStore(this.props.dispatch);
        this.tools = new tools();
        this.query = null;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._start();
    }

    _start( reload=false ) {
        this.query = new URLSearchParams(this.props.location.search);
        
        let owner =  decodeURIComponent(this.query.get("owner"));
        // console.log(owner);

        this._get_users((accounts) => {
            let usersList = {};
            accounts.map((account, index) => {
                usersList[account.id] = account;
                // console.log(usersList);
                if(index === accounts.length - 1) {
                    this.setState({
                        usersList: usersList,
                    });
                }
            });
        });

        if( owner !== null ) {
            this._search_account(owner, (response) => {
                response.map((user) => {
                    if(user.username === owner ) {
                        this.setState({
                            owner: user,
                        });
                        // console.log(user);
                    }
                });
            });
        }
        
        this.setState({
            isLoading: true,
            id: decodeURIComponent(this.query.get("doc")),
            cover: decodeURIComponent(this.query.get("cover")),
        }, () => {
            this._get_one_doc(this.state.id,(response) => {
                this.setState({
                    doc: response.data,
                });
                this._getDocKey(this.state.id, (keywords)=> {
                    this.setState({
                        keywords: keywords.word,
                    }, () => {
                        this._get_on_category(response.data.category, (response) => {
                            this.setState({
                                category: response,
                            });

                            this._getAllKeywordsDocs(0, 1, [], (docList) => {
                                this.setState({
                                    docList: docList,
                                    isLoading: false,
                                }, () => {
                                    this._getAllDocsKeywords(0,this.state.docList, (newList) => {
                                        this.setState({
                                            docList: newList,
                                        });
                                    })
                                });
                            })
                        });
                    });
                });
            });
            if( reload ) {
                window.location.reload();
            }
        });
    }

    _get_users(callback = () => { }) {
        this._getAccounts(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_users(callback);
            })
        });
    }

    _search_account(username, callback=()=>{}) {
        this.setState({
            isLoading: true,
        });
        this._searchAccounts(this.props.user.access, username, (response) => {
            if( response.status === 200 ) {
                this.setState({
                    isLoading: false,
                });
                callback( response.data );
            }
        },(error) => {
            this._manageError( error, () => {
                this._search_account(username, callback);
            });
        });
    }

    _getAllKeywordsDocs(index=0, page=1, list=[], callback=()=>{}) {
        let searchPath = "search=" + this.state.keywords[index];
        this._startSearchRequest(searchPath, page, (response)=>{
            
            response.data.results.map((result) => {
                let inList = false;
                list.map((elt) => {
                    if(result.pk === elt.pk) {
                        inList = true;
                        return true;
                    }
                });

                if( inList === false ) {
                    list = list.concat( result );
                }
            });

            // console.log( list );

            if( index < this.state.keywords.length - 1 ) {
                if( response.data.next !== null ) {
                    this._getAllKeywordsDocs(index, page + 1, list, callback);
                }else {
                    this._getAllKeywordsDocs(index + 1, 1, list, callback);
                }
            }

            else {
                callback( list );
            }
        });
    }

    _getDocKey(id, callback=()=>{}) {
        this._getKeyword(id, this.props.user.access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError( error, () => {
                this._getDocKey(id, callback);
            });
        });
    }

    _getAllDocsKeywords( index=0, docList, callback=()=>{} ) {
        if( docList.length > 0 ) {
            let doc = docList[index], docs = docList;
            // console.log( doc );
            // console.log('docs list', docList)
            try{
                this._getDocKey(doc.id, (keywords) => {
                    // console.log('keywords', keywords)
                    docs[index]["keywords"] = keywords;
                    // console.log( index );
                    if( index < docList.length - 1 ) {
                        this._getAllDocsKeywords( index + 1, docs, callback );
                    }else {
                        callback(docs);
                    }
                });
            }catch(error){
                callback(docs);
                console.error('key error', error)
            }
        }

        else {
            callback([]);
        }
    }

    _get_all_categories(callback = () => { }) {
        this._getAllCategories(this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_all_categories(callback);
            });
        });
    }

    _get_on_category(id, callback = () => { }) {
        this._getOnCategory(id, this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_on_category(id, callback);
            });
        });
    }

    _get_attribute(attribute, callback = () => { }) {
        this._getAttribute(attribute, this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response.data);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_attribute(attribute, callback);
            })
        });
    }

    _startSearchRequest(searchPath, page, callback = () => { }) {
        this._search(searchPath, page, this.props.user.access, (response) => {
            if (response.status === 200) {
                callback(response);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._startSearchRequest(searchPath, page, callback);
            });
        });
    }

    _get_one_doc(id, callback = () => { }) {
        this._getOneDoc(id, this.props.user.access, (response) => {
            if (response.status === 200) {
                // console.log( response );
                callback(response);
            }
        }, (error) => {
            this._manageError(error, () => {
                this._get_one_doc(id, callback);
            });
        });
    }

    _manageError(error, callback = () => { }) {
        if (error.response) {
            if (error.response.status === 401) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if (error.response.data.code === "token_not_valid") {
                    this._refreshUserToken(this.props.user.refresh, (response) => {
                        if (response.status === 200) {
                            this._set_new_user(response);
                            callback();
                        }
                    }, (error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    _downloadFile(e) {
        _downloadFile(e, this.state.doc.id)
    }

    /**
     * On met a jour la session utilisateur
     */
    _set_new_user(response) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user);
    }
    _openDoc(doc) {
        // console.log( doc );
        // Bug here to review
        updateDocViewsCounter(doc.id)
        window.open("/view-doc:?doc=" + encodeURIComponent(doc.file) + "&pk=" + encodeURIComponent(doc.id) + "&output=embed", '_blank', 'location=yes, height=570, width=620, scrollbars=yes,status=yes');
    }

    _logout() {
        if (window.confirm('Voulez-vous vraiment vous déconnecter?')) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }

    render() {
        return (
            <>
                {
                    super.render()
                }
                {
                    this.state.isReady &&
                    <div>
                        <figure className='background-fig absolute'>
                            <img src={this.state.cover} alt={this.state.name} />
                        </figure>
                        <div className='absolute mask'></div>
                        <div className='viewContent' style={{position: "relative", zIndex: 20}}>
                            <div className='viewContent_2'>
                                <div className="OnProjectsView_top_text">
                                    <span className="OnProjectsView_top_text_ title">
                                        #{ this.state.category.name && this.state.category.name !== undefined && this.state.category.name.toUpperCase()}
                                    </span>
                                </div>
                                <div className="OnProjectsView_top_subtext">
                                    <span className="OnProjectsView_top_subtext_ title">
                                        {this.state.doc.title && this.state.doc.title}
                                    </span>
                                </div>

                                <div className='optionsButton flex-row justify-center'>
                                    <button className='scale-to-hover'
                                        onClick={() => {
                                            // if (this.state.canViewDoc){
                                            //     this._openDoc(this.state.doc);
                                            // }
                                            this._openDoc(this.state.doc);
                                        }}
                                    >
                                        <span>
                                            LIRE
                                        </span>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                        </svg>
                                    </button>

                                    <a className='optionsButton_download scale-to-hover' href="#"
                                        onClick={(e) => {
                                            // if (this.state.canViewDoc){
                                            //     this._downloadFile(e)
                                            // }
                                            this._downloadFile(e)
                                        }} 
                                    >
                                        <button className='scale-to-hover'>
                                            <span>
                                                TÉLÉCHARGER
                                            </span>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path fillRule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                            </svg>
                                        </button>
                                    </a>

                                    <button className='scale-to-hover share-button'>
                                        <span>
                                            PARTAGER SUR LES RÉSEAUX SOCIAUX
                                        </span>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-share-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>

                                        <div className='card-shareOptions-2'>
                                            <div className='card-shareOptions-2-content'>
                                                <div className='card-shareOptions-2-content-one'>
                                                    <EmailShareButton url={this.state.doc.file} title={this.state.doc.file}>
                                                        <EmailIcon size={32} round={true} />
                                                    </EmailShareButton>
                                                </div>

                                                <div className='card-shareOptions-2-content-one'>
                                                    <LinkedinShareButton url={this.state.doc.file} title={this.state.doc.file}>
                                                        <LinkedinIcon size={32} round={true} />
                                                    </LinkedinShareButton>
                                                </div>

                                                <div className='card-shareOptions-2-content-one'>
                                                    <WhatsappShareButton url={this.state.doc.file} title={this.state.doc.file}>
                                                        <WhatsappIcon size={32} round={true} />
                                                    </WhatsappShareButton>
                                                </div>

                                                <div className='card-shareOptions-2-content-one'>
                                                    <FacebookShareButton url={this.state.doc.file} title={this.state.doc.file}>
                                                        <FacebookIcon size={32} round={true} />
                                                    </FacebookShareButton>
                                                </div>
                                            </div>
                                            <div className='card-shareOptions-2-content-rect'></div>
                                        </div>
                                    </button>

                                    <button className='scale-to-hover'
                                        onClick={() => {
                                            this._updateBasket(this.state.doc);
                                        }}
                                    >
                                        <span>
                                            AJOUTER AU PANIER
                                        </span>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                        </svg>
                                    </button>
                                </div>

                                <div className="OnProjectsView_top_subtext">
                                    <span className="OnProjectsView_top_subtext_ title"
                                        style={{
                                            textShadow: "none",
                                            fontSize: "1.2em",
                                        }}
                                    >
                                        par : {this.state.owner.username ? this.state.owner.username : ""}
                                    </span>
                                </div>
                            </div>

                            <div className="doc-associated-container">
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2">
                                    {/* <div className="OnProjectsView_body_head_text title">
                                        <span className="">Résumé synthétique</span>
                                    </div> */}
                                    <div className="">
                                        <PointerComponent text={"Informations de l'auteur"} />
                                    </div>
                                </div>
                                <div className="OnProjectsView_body_description OnProjectsView_body_actuality">
                                <ul className='ul-style2'>
                                    <li>
                                        Nom d'utilisateur: <strong className='color-blue'>{this.state.owner.username ? this.state.owner.username : "___"}</strong>
                                    </li>
                                    <li>
                                        Email: <strong className='color-blue'>{this.state.owner.email ? this.state.owner.email : "___"}</strong>
                                    </li>
                                </ul>
                            </div>
                            </div>

                            <div className="doc-associated-container">
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2">
                                    {/* <div className="OnProjectsView_body_head_text title">
                                        <span className="">Résumé synthétique</span>
                                    </div> */}
                                    <div className="">
                                        <PointerComponent text={"Résumé synthétique"} />
                                    </div>
                                </div>
                                <div className="OnProjectsView_body_subDescription text-sizes-1 left-border">
                                    <p className="text-form">
                                        {this.state.doc.summary}
                                    </p>
                                </div>

                                <div className='content'>
                                    
                                </div>
                            </div>

                            <div className='line-separator'></div>

                            <div className='doc-associated-container keyWordList'>
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2">
                                    {/* <div className="OnProjectsView_body_head_text title">
                                        <span className="">Analyse intelligente</span>
                                    </div> */}
                                    <div className="">
                                        <PointerComponent text={"Analyse intelligente"} />
                                    </div>
                                </div>
                                <br/><br/>

                                <div className='ia_option_title color-green'>
                                    <span>
                                        Mots-clés
                                    </span>
                                </div>
                                {
                                    this.state.keywords.length > 0 &&
                                    <ListManager _key_="lists29" fSize="1em" list={this.state.keywords} isSelectable={false} printKey={null}
                                        onChange={(e, category, index) => {
                                            return true;
                                        }}
                                        style={()=>{}}
                                        link_={(key)=>{
                                            return "/view-keyword-info:?keyword=" + key;
                                        }}
                                    />
                                }

                                {/* <div className='keysList-container'>
                                    {
                                        this.state.keywords.map((key, index) => (
                                            <Link key={"key" + index + "-" + key} to={"/view-keyword-info:?keyword=" + key}>
                                                <button className='scale-to-hover'>
                                                    <span className='button_text'>
                                                        {key}
                                                    </span>
                                                    {/* <span className='button_number'>
                                                        {index * 3 + 3}
                                                    </span> * /}
                                                </button>
                                            </Link>
                                        ))
                                    }
                                </div> */}
                            </div>

                            <div className='line-separator'></div>

                            <div className='doc-associated-container'>
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2">
                                    {/* <div className="OnProjectsView_body_head_text title">
                                        <span className="">Documents similaires</span>
                                    </div> */}
                                    <div className="">
                                        <PointerComponent text={"Documents similaires"} />
                                    </div>
                                </div>
                                <div className="OnProjectsView_body_description OnProjectsView_body_actuality">
                                    <div className="OnProjectsView_body_actuality_ justify-center">
                                        {
                                            this.state.docList.map((doc, index) => (
                                                <div className='card_list_content OnProjectsView_body_actuality_card'>
                                                    <ProjectCard addToPanier={() => {
                                                        this._updateBasket(doc);
                                                    }}
                                                    owner={this.state.usersList[doc.owner] ? this.state.usersList[doc.owner] : ""}
                                                    action={() => {this._start(true)}} project={doc} link={"/view-doc-info:?doc=" + encodeURIComponent(doc.id) + "&cover=" + encodeURIComponent(doc.img) + "&owner=" + encodeURIComponent(this.state.usersList[doc.owner] ? this.state.usersList[doc.owner].username : "")}/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}


OnDocView = connect(mapStateToProps, null)(OnDocView);
export default OnDocView;
