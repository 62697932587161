import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';


/**Importation de la vue principal*/
import Home from "../screens/home.screen";
import Dashboard from "../screens/dashboard.screen";
import LoginScreen from '../screens/login.screen';
import RegistrationScreen from '../screens/registration.screen';
import UserManager from '../screens/gestion_user_profil.screen';
// import AOFScreen from '../screens/aof.screen';
// import Annuaire from '../screens/annuaire.screen';
// import AnnuaireLogin from '../screens/annuaireLogin.screen';
// import AnnuaireRegistration from '../screens/annuaireRegistration.screen';
// import AnnuaireRecorverPw from '../screens/annuaireRecorview.screen';


class Routes extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {/* <Route exact path="/annuaire-signin" component={AnnuaireLogin}/>
                    <Route exact path="/annuaire-registration" component={AnnuaireRegistration}/>
                    <Route exact path="/annuaire-recorverpw" component={AnnuaireRecorverPw}/>
                    <Route path="/annuaire" component={Annuaire}/> */}
                    <Route path="/dashboard" component={Dashboard}/>
                    <Route exact path="/registration" component={RegistrationScreen}/>
                    <Route exact path="/login" component={LoginScreen}/>
                    <Route path="/gestion-compte" component={UserManager}/>
                    <Route path="/" component={Home}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default Routes;
