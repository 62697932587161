import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
// import words from 'an-array-of-french-words';



//**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './responsive.style/dashboard.responsive.style.css';



/**Importation des images*/



/**Importation des helpers */
import text from '../GLOBAL-VAR/global-text';

/**
 * 
 * Importation des screens
 */
 import UploadPdf from './upload_pdf.screen';
 import UploadFolder from './upload_folder.screen';
 import LoginScreen from './login.screen';
 import DocListScreen from './doc_list.screen';
 import DashboardHome from './dashboard.home.screen';
 import DashboardCategories from './dashboard.categories.screen';
 import DashboardAddCategories from './dashboard.addCategorie.screen';
 import DashboardAddUsers from './dashboard.addUsers.screen';
 import DashboardUsersList from './dashboard.userList.screen';
 import DashboardAddGroup from './dashboard.addGroup.screen';
 import DashboardGroupsList from './dashboard.groupList.screen';
 import DashboardUserProfile from './dashboard.userProfil.screen';
 import DashboardKeywords from './dashboard.keywords.screen';
 import DashboardAddText from './dashboard.addText.screen';
 import DashboardUpdateText from './dashboard.updateText.screen';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}


class Dashboard extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            currentMenuItem: -1,
            currentSubMenu: {
                parent: -1,
                sub: -1
            },
            currentSubMenuOpen: [],
            printMenu: false,
            printDashboardMenu: true,
            printDashboard: false,
        }

        // this.words = words;

        this.menuList = [
            {
                icon:   <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" className="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                            <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                            <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                        </svg>,
                name: "Mon profil",
                link: "/dashboard/profile",
                action: (name)=>{
                    this._changeCurrentMenuIndex(name);
                    return true;
                },
            },
            {
                icon:   <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" className="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                            <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                            <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                        </svg>,
                name: "Historique",
                link: "/dashboard",
                action: (name)=>{
                    this._changeCurrentMenuIndex(name);
                    return true;
                },
            },
            {
                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>,
                name: "Utilisateurs",
                subMenus: [
                    {
                        icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                             </svg>,
                        name: "Groupes d'utilisateurs",
                        subMenus: [
                            {
                                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                     </svg>,
                                name: "Ajouter un groupe",
                                link: "/dashboard/add-group",
                            },
                            {
                                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                     </svg>,
                                name: "Modifier un groupe",
                                link: "/dashboard/group-list",
                            },
                        ]
                    },
                    {
                        icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                             </svg>,
                        name: "Utilisateurs du système",
                        subMenus: [
                            {
                                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                     </svg>,
                                name: "Ajouter un utilisateur",
                                link: "/dashboard/add-user",
                            },
                            {
                                icon: <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                      </svg>,
                                name: "Modifier un utilisateur",
                                link: "/dashboard/user-list",
                                // action: (index)=>{
                                //     this._changeCurrentMenuIndex(index);
                                //     return true;
                                // },
                            },
                        ]
                    },
                ]
            },
            {
                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>,
                name: "Documents",
                action: (index)=>{
                    this._changeCurrentMenuIndex(index);
                    return true;
                },
                subMenus: [
                    {
                        icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                              </svg>,
                        name: "Catégories des documents",
                        subMenus: [
                            {
                                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>,
                                name: "Ajouter une catégorie",
                                link: "/dashboard/add-categories",
                            },
                            {
                                icon: <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                    </svg>,
                                name: "Modifier une catégorie",
                                link: "/dashboard/categories-list",
                            },
                        ]
                    },
                    {
                        icon: <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                              </svg>,
                        name: "Documents dans le système",
                        subMenus: [
                            {
                                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>,
                                name: "Ajouter un document",
                                link: "/dashboard/add-doc",
                            },
                            {
                                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>,
                                name: "Ajouter un dossier de documents",
                                link: "/dashboard/add-docFolder",
                            },
                            {
                                icon: <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                      </svg>,
                                name: "Modifier un document",
                                link: "/dashboard/doc-list",
                            },
                        ]
                    },
                    {
                        icon: <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                              </svg>,
                        link: "/dashboard/keywords",
                        name: "Mots-clés dans le système",
                        action: (index)=>{
                            this._changeCurrentMenuIndex(index);
                            return true;
                        },
                    },
                ]
            },{
                icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>,
                name: "AOF",
                action: (index)=>{
                    this._changeCurrentMenuIndex(index);
                    return true;
                },
                subMenus: [
                    {
                        icon: <svg width="1.2em" height="1.4em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                              </svg>,
                        name: "Ajouter un Texte",
                        link: "/dashboard/add-text",
                    },
                    {
                        icon: <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                              </svg>,
                        name: "Modifier un Texte",
                        link: "/dashboard/update-text",
                    }
                ]
            },
        ]

        this.accessStore = new AccessStore( this.props.dispatch);
        this.text = new text();
    }

    componentDidMount() {
        this.accessStore.callback((session) => {
            // console.log( session );
            if( session && session.access ) {
                this._get_if_IsAdmin(session.access, (stat) => {
                    this.accessStore.setRedux('SET_IS_ADMIN', stat.isadmin);

                    if( stat.isadmin === true ) {
                        this.setState({
                            printDashboard: true,
                        });
                    }

                    else {
                        window.location.href = '/';
                    }
                });
            }
            // console.log( session );
        });
        this.accessStore.init( 'sigb_kando_user', "INIT_USER");
    }

    _changeCurrentMenuIndex( newIndex ) {
        this.setState({
            currentMenuItem: newIndex,
        });
    }

    _logout() {
        if( window.confirm('Voulez-vous vraiment vous déconnecter?') ) {
            this.accessStore.setRedux('LOG_OUT', {});
        }
    }

    _get_if_IsAdmin(access, callback=()=>{}) {
        this._getIfCurrentUserIsAdmin(access, (response) => {
            if( response.status === 200 ) {
                callback(response.data);
            }
        }, ( error ) => {
            this._manageError( error, () => {
                this._get_if_IsAdmin( this.props.user.access, callback );
            });
        });
    }

    _manageError(error, callback=()=>{}) {
        if( error.response ) {
            // console.log( error.response );
            if( error.response.status === 401 ) {
                /**
                 * Si le token n'est plus valide alors on fais un refresh pour en avoir un autre
                 */
                // console.log( error.response.data.code );
                if( error.response.data.code === "token_not_valid" ) {
                    this._refreshUserToken(this.props.user.refresh, ( response ) => {
                        if( response.status === 200 ) {
                            this._set_new_user( response, callback );
                            // callback();
                        }
                    },( error ) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                this.accessStore.setRedux('LOG_OUT', {});
                            }
                        }
                        if (error.toString() === "Error: Network Error") {
                            alert("Aucun accès à internet :(");
                        }
                    });
                }
            }
        }
    }

    /**
     * On met a jour la session utilisateur
    */
    _set_new_user( response, callback=()=>{} ) {
        let user = {
            username: this.props.user.username,
            access: response.data.access,
            refresh: this.props.user.refresh,
        };
        this.accessStore.setRedux('SET_USER', user, () => {
            callback();
        });
    }

    _printOrHideDashboardMenu() {
        let menu = document.getElementById("aside-menu");

        if( this.state.printDashboardMenu ) {
            menu.style.marginLeft = "0px";
        }

        else {
            menu.style.marginLeft = "-400px";
        }

        this.setState({
            printDashboardMenu: !this.state.printDashboardMenu,
        });
    }

    _generateMenu(listElementsOfMenu, parent=null) {
        return (
            <div>
                {
                    listElementsOfMenu.map((menu, index) => (
                        <div>
                            <Link
                                className="dashboard-menu-content-item"
                                key={this._generateID(36)}
                                to={menu.link ? menu.link : "#"}
                                onClick={() => {
                                    if( !menu.subMenus ) {
                                        if(menu.action) {
                                            menu.action( index );
                                        }
                                    }

                                    let currentSubMenuOpen = this.state.currentSubMenuOpen;

                                    if( currentSubMenuOpen.includes(menu.name)) {
                                        currentSubMenuOpen.splice(currentSubMenuOpen.indexOf(menu.name), 1);
                                    }

                                    else {
                                        currentSubMenuOpen.push(menu.name);
                                    }
                                    this.setState({
                                        currentSubMenuOpen: currentSubMenuOpen,
                                    });
                                }}
                                style={{
                                    borderBottom: menu.subMenus && this.state.currentSubMenuOpen.includes(menu.name) ? "1px solid #dc354555" : "1px solid #ffffff11",
                                    // animation: parent==null ? "" : ( this.state.currentSubMenuOpen.includes(parent) ? "animFromLeft 500ms forwards" : "animToLeft 500ms forwards")
                                }}
                            >
                                <div className='dashboard-menu-content-item_'>
                                    <span className='dashboard-menu-content-item-icon'
                                        style={{
                                            color: this.state.currentMenuItem === index ? "#ffffff" : "#ffffff",
                                        }}
                                    >
                                        { menu.icon }
                                    </span>
                                    <span className='dashboard-menu-content-item-text'>
                                        {menu.name}
                                    </span>
                                </div>

                                {
                                    menu.subMenus ?
                                        <div>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right menuChevron" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    transform: this.state.currentSubMenuOpen.includes(menu.name) ? "rotate(90deg)" : "rotate(0deg)",
                                                }}
                                            >
                                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                    :
                                        null
                                }
                            </Link>
                            {
                                menu.subMenus ?
                                    <div className='subMenu_container'
                                        style={{
                                            opacity: this.state.currentSubMenuOpen.includes(menu.name) ? 1 : 0,
                                            maxHeight: this.state.currentSubMenuOpen.includes(menu.name) ? "50em" : "0em",
                                        }}
                                    >
                                        {/* <div className='menuJoin'></div> */}
                                        <div className='SubMenu'>
                                            {
                                                this._generateMenu(menu.subMenus, menu.name)
                                            }
                                        </div>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    render() {
        return (
            <>
                {
                    this._isObjEmpty( this.props.user ) ?
                        <div>
                            <LoginScreen/>
                        </div>
                    : this.state.printDashboard &&
                    <main className='dashboard'>
                        <aside className='dashboard-menu' id="aside-menu">
                            <div className='dashboard-menu-content bg-color-blue no-view-scroll'>
                                <div className='dashboard-menu-content-head'>
                                    <div className='dashboard-menu-content-head-'>
                                        <div className='dashboard-menu-content-head-circle'></div>
                                        <div className='dashboard-menu-content-head-circle'></div>
                                        <div className='dashboard-menu-content-head-circle'></div>
                                    </div>
                                    <div className='menu-cross-2'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => {
                                                this._printOrHideDashboardMenu();
                                            }}
                                        >
                                            <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                                            <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                                        </svg>
                                    </div>
                                    <Link
                                        className="dashboard-menu-content-head-sub"
                                        to="/"
                                        style={{
                                            color: "#0A3764",
                                        }}
                                    >   
                                        <div className='dashboard-menu-content-head-sub'>
                                            <div className='dashboard-menu-content-head-sub-text family-lato-bold'>
                                                <span className='family-cmuntt'>
                                                    {this.text.appName}
                                                    {/* SIGB */}
                                                    {/* ZeeZaa */}
                                                </span>
                                            </div>
                                            <div>
                                                <img className='dashboard-menu-content-head-sub-logo' src={this.gLogo} alt="NùYoēn" />
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='dashboard-menu-content-body'>
                                    {
                                        this._generateMenu(this.menuList)
                                    }
                                </div>

                                {/* <div className='dashboard-menu-content-fot'>
                                    <div className='dashboard-menu-content-fot-content'>
                                        Réaliser par Kandosia
                                    </div>
                                </div> */}
                            </div>
                        </aside>
                        <div className="dashboard-contain">
                            <div className='dashboard-contain-head'>
                                <div className='dashboard-contain-head-content flex-row justify-between'>
                                    <div>
                                        <div className='dashboard-menu-viewer'
                                            onClick={() => {
                                                this._printOrHideDashboardMenu();
                                            }}
                                        >
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='dashboard-contain-head-content-right flex-row justify-end align-center'>
                                        <div className='flex-row align-center border-left-1-primary color-primary dashboard-contain-head-content-right-profile'>
                                            <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                                                <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                                            </svg>
                                            <span className='font-size-0_8 bold letter-spacing-0'>
                                                { this.props.user.username ? this.props.user.username : "" }
                                            </span>
                                        </div>
                                        <div className='flex-row align-center color-primary dashboard-contain-head-content-right-profile'
                                            onClick={() => {
                                                this.setState({
                                                    printMenu: true,
                                                });
                                            }}
                                        >
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-menu-button-wide-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M14 7H2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zM2 6a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H2z"/>
                                                <path fillRule="evenodd" d="M15 11H1v-1h14v1zM2 12.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM1.5 0A1.5 1.5 0 0 0 0 1.5v2A1.5 1.5 0 0 0 1.5 5h13A1.5 1.5 0 0 0 16 3.5v-2A1.5 1.5 0 0 0 14.5 0h-13zm1 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm9.927.427l.396.396a.25.25 0 0 0 .354 0l.396-.396A.25.25 0 0 0 13.396 2h-.792a.25.25 0 0 0-.177.427z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <main className='menu'
                                    style={{
                                        transform: this.state.printMenu ? "translateX(0%)" : "translateX(100%)",
                                    }}
                                >
                                    <div className='menu-cross flex-row justify-end'>
                                        <button className='flex-row justify-center align-center'
                                            onClick={() => {
                                                this.setState({
                                                    printMenu: false,
                                                });
                                            }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                                <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <ul>
                                        <li>
                                            <Link
                                                className=""
                                                key={this._generateID(36)}
                                                to="/"
                                                style={{
                                                    color: "#dc3545",
                                                }}
                                            >
                                                {/* <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-power" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z"/>
                                                    <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z"/>
                                                </svg> */}
                                                <span>
                                                    Accueil
                                                </span>
                                            </Link>
                                        </li>
                                        <li
                                            onClick={() => {
                                                this._logout();
                                            }}
                                        >
                                            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-power" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z"/>
                                                <path fillRule="evenodd" d="M7.5 8V1h1v7h-1z"/>
                                            </svg>
                                            <span>
                                                Se déconnecter
                                            </span>
                                        </li>
                                    </ul>
                                </main>
                            </div>
                            <Switch>
                                <Route path="/dashboard/categories-list" component={DashboardCategories}/>
                                <Route path="/dashboard/add-categories" component={DashboardAddCategories}/>
                                <Route path="/dashboard/add-doc" component={UploadPdf}/>
                                <Route path="/dashboard/add-docFolder" component={UploadFolder}/>
                                <Route path="/dashboard/add-user" component={DashboardAddUsers}/>
                                <Route path="/dashboard/user-list" component={DashboardUsersList}/>
                                <Route path="/dashboard/profile" component={DashboardUserProfile}/>
                                <Route path="/dashboard/add-group" component={DashboardAddGroup}/>
                                <Route path="/dashboard/doc-list" component={DocListScreen}/>
                                <Route path="/dashboard/group-list" component={DashboardGroupsList}/>
                                <Route path="/dashboard/keywords" component={DashboardKeywords}/>
                                <Route path="/dashboard/add-text" component={DashboardAddText}/>
                                <Route path="/dashboard/update-text" component={DashboardUpdateText}/>
                                <Route path="/dashboard" component={DashboardHome}/>
                            </Switch>
                        </div>
                    </main>
                }
            </>
        )
    }
}

Dashboard = connect(mapStateToProps, null)(Dashboard);

export default Dashboard;
