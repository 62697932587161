import axios from "axios";
import PATH from '../GLOBAL-VAR/globals-urls';
import currentEnv from "../envConfig";
const PATH_ = new PATH();

export const getUserInfo = () => {
    //let token = JSON.parse(localStorage.getItem('currentUserInfo'))
    // let data = Buffer.from(token.split(".")[1], "base64");
    let data = JSON.parse(localStorage.getItem('currentUserInfo'));
    return data;
}

export const getDocStats = async () => {
    await axios({
        method: 'get',
        url: `${PATH_.api}${PATH_.documents}stats/`,
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(response =>{
        localStorage.setItem('stats', JSON.stringify(response.data))
    })
}

export const updateDocViewsCounter = async (docId) => {
    const userId = getUserInfo().user_id
    await axios({
        method: 'get',
        url: `${currentEnv.REACT_APP_SYGED_BACKEND_URL}${PATH_.documents}add_view_doc/${docId}/?userId=${userId}`,
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(response =>{
        localStorage.setItem('docState', JSON.stringify(response.data))
    })
}


// This method is written to allow downloading using navigator pdf reader
// It must be reshaped following the convention used on this projet. It must be cool to make dynamic the baseUrl.
export function _downloadFile(e, docId) {
    e.preventDefault()
    const userId = getUserInfo().user_id
    const urlLink = `${currentEnv.REACT_APP_SYGED_BACKEND_URL}documents/download/${docId}/?userId=${userId}`
    axios.get(urlLink, {responseType: 'blob'})
    .then((response) => {
        let fileObj = new Blob([response.data], {type: 'application/pdf'});
        let fileURL = URL.createObjectURL(fileObj);
        window.open(fileURL, "_blank");
    })
}