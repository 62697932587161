function SET_ADMIN_PROFILE(state, value) {
    let nextState = {
        ...state,
        admin: {
            ...state.admin,
            profile: value,
        },
    }
    return nextState;
}

export default SET_ADMIN_PROFILE;
