/**Importation des actions du store */
import INIT_ADMIN from '../store_actions/adminActions/init_admin.action';
import SET_ADMIN_PROFILE from '../store_actions/adminActions/set_adminProfile.action';
import SET_ADMIN_USERS_LIST from '../store_actions/adminActions/set_adminUsersList.action';

const initialState = {
    admin: {
        profile: {},
        usersList: {},
        usersGroups: {},
    }
}

function setSession(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function RemoveAllSession() {
    localStorage.clear();
}

function Admin(state = initialState, action) {
    switch (action.type) {
        case 'INIT_ADMIN':
            return INIT_ADMIN(state, action.value);

        case 'SET_ADMIN_PROFILE':
            setSession("sigb_kando_admin", action.value);
            return SET_ADMIN_PROFILE(state, action.value);

        case 'SET_ADMIN_USERS_LIST':
            let val = SET_ADMIN_USERS_LIST(state, action.value);
            setSession("sigb_kando_admin", val.admin);
            return val;

        case 'DELETE_ADMIN':
            RemoveAllSession();
            return setSession("sigb_kando_admin", {});

        default:
            return state
    }

}

export default Admin;