import React from 'react';
import { Link } from 'react-router-dom';

import './style/style.css';
import './style/responsive.style.css';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from "axios"

import {
    EmailShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookShareButton
} from "react-share";

import { getUserInfo, _downloadFile } from '../../helpers/utils.helper';


import {
    EmailIcon,
    LinkedinIcon,
    WhatsappIcon,
    FacebookIcon
} from "react-share";

let projectLink = '', userInfo = getUserInfo();
// console.log(userInfo);

class ProjectCard extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            projectLink: '',
            copied: false,
            viewSHareOption: false,
            canViewDoc: userInfo.perms.includes('Can view Document'),
            canUpdateDoc: userInfo.perms.includes('Can change Document'),
            canDeleteDoc: userInfo.perms.includes('Can delete Document')
        }
    }


    componentDidMount() {
        projectLink = this.props.projectLink;
        // console.log( this.props.owner );
    }

    _copyToClipBoard(text) {
        var copyText = document.createElement('input');
        copyText.setAttribute("value", text);
        copyText.select();


        navigator.clipboard.writeText(copyText.value);

        clearTimeout(this.timeout);

        this.setState({
            copied: true,
        }, () => {
            this.timeout = setTimeout(() => {
                this.setState({
                    copied: false,
                });
            }, 5000);
        });
    }


    _printOrHideShareOptions() {
        let shareOptions = document.getElementById("project" + this.props.project.id);

        if (!this.state.viewSHareOption) {
            shareOptions.style.opacity = 1;
            shareOptions.style.visibility = "visible";
            shareOptions.style.marginBottom = "40px";

            this.setState({
                viewSHareOption: true,
            });
        }

        else {
            shareOptions.style.opacity = 0;
            shareOptions.style.visibility = "hidden";
            shareOptions.style.marginBottom = "0px";

            this.setState({
                viewSHareOption: false,
            });
        }
    }

    _downloadFile(e){
        _downloadFile(e, this.props.project.id)
    }

    render() {
        return (
            <div className="projectCard">
                <div className="projectCard_imageContainer">
                    <Link to={this.props.link}
                        onClick={() => {
                            this.props.action && this.props.action();
                        }}
                    >
                        <img className="projectCard_image" alt={""} src={this.props.project.img} />
                    </Link>
                </div>
                <div className="projectCard_body">
                    <Link to={this.props.link}
                        onClick={() => {
                            this.props.action && this.props.action();
                        }}
                    >
                        <div className="projectCard_body_title">
                            <span>{this.props.project.title && this.props.project.title}</span>
                        </div>
                    </Link>
                    <div className="projectCard_body_title projectCard_body_date">
                        <span>{this.props.project.month}/{this.props.project.year}</span>
                    </div>
                    <div className="card_separator_line"></div>
                    <div className="projectCard_body_text text-form lineHeight1">
                        <p>{this.props.project.summary}</p>
                        <p className='projectCard_body_text_hover'>{this.props.project.summary}</p>
                    </div>
                    <div className='projectCard_body_owner'>
                        <div className='projectCard_body_owner_text'>
                            par : {this.props.owner ? this.props.owner.username : "_____"}
                        </div>
                    </div>
                    <div className="projectCard_competence">
                        {
                            this.props.project.keywords && this.props.project.keywords.word &&
                            this.props.project.keywords.word.map((keyword, index) => (
                                <div key={"keywords" + index} className="competence">
                                    <Link className="link" to={"/view-keyword-info:?keyword=" + keyword}
                                        onClick={() => {
                                            this.props.action2 && this.props.action2();
                                        }}
                                    >
                                        <span>
                                            {keyword}
                                        </span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='card-shareOptions' id={"project" + this.props.project.id}>
                    <div className='card-shareOptions-content'>
                        <div className='card-shareOptions-content-one'>
                            <EmailShareButton url={this.props.project.file} title={this.props.project.file}>
                                <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                        </div>

                        <div className='card-shareOptions-content-one'>
                            <LinkedinShareButton url={this.props.project.file} title={this.props.project.file}>
                                <LinkedinIcon size={32} round={true} />
                            </LinkedinShareButton>
                        </div>

                        <div className='card-shareOptions-content-one'>
                            <WhatsappShareButton url={this.props.project.file} title={this.props.project.file}>
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>
                        </div>

                        <div className='card-shareOptions-2-content-one'>
                            <FacebookShareButton url={this.props.project.file} title={this.props.project.file}>
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                        </div>
                    </div>
                    <div className='card-shareOptions-content-rect'></div>
                </div>
                {
                    !this.props.hideBottom &&
                        <div className='card-bottom'>
                        <div className='card-bottom-'>
                            <div className='card-bottom-content'>
                                <button className='card-bottom-content-plus' title='Ajouter au panier'
                                    onClick={(e) => {
                                        this.props.addToPanier && this.props.addToPanier();
                                    }}
                                >
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </button>
                            </div>

                            <div className='card-bottom-content card-bottom-content-copy'>
                                <CopyToClipboard text={this.props.project.file}
                                    onCopy={() => this.setState({ copied: true })}>
                                    <button
                                        className='card-bottom-content-copy'
                                        title='Copier le lien de téléchargement'

                                        style={{
                                            backgroundColor: this.state.copied ? "#769ea7" : "#ffffff",
                                            color: this.state.copied ? "#ffffff" : "#00000077",
                                        }}
                                    >
                                        {/* <input id="element_to_copy" readOnly={true} type="text" contentEditable={false}/> */}
                                        <svg width="17" height="19" viewBox="0 0 17 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.1429 16.625V18.1094C12.1429 18.6013 11.7351 19 11.2321 19H0.910714C0.407734 19 0 18.6013 0 18.1094V4.45312C0 3.96124 0.407734 3.5625 0.910714 3.5625H3.64286V14.5469C3.64286 15.6928 4.59611 16.625 5.76786 16.625H12.1429ZM12.1429 3.85938V0H5.76786C5.26488 0 4.85714 0.39874 4.85714 0.890625V14.5469C4.85714 15.0388 5.26488 15.4375 5.76786 15.4375H16.0893C16.5923 15.4375 17 15.0388 17 14.5469V4.75H13.0536C12.5527 4.75 12.1429 4.34922 12.1429 3.85938ZM16.7333 2.70791L14.231 0.260842C14.0602 0.0938283 13.8286 1.23499e-06 13.5871 0L13.3571 0V3.5625H17V3.33765C17 3.10146 16.9041 2.87493 16.7333 2.70791Z" />
                                        </svg>
                                    </button>
                                </CopyToClipboard>

                            </div>

                            <div className='card-bottom-content'>
                                <button
                                    onClick={() => {
                                        this._printOrHideShareOptions();
                                        return true;
                                    }}
                                    title="Partager sur les réseaux"
                                >
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-share-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                    </svg>
                                </button>
                            </div>

                            {
                                this.state.canViewDoc &&
                                <div className='card-bottom-content'>
                                    {/* <a className='card-bottom-content' href={this.props.project.download_link}> */}
                                    <button
                                        onClick={(e) => this._downloadFile(e)} 
                                        title="Télécharger"
                                        // target="_blank"
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                            <path fillRule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                        </svg>
                                    </button>
                                    {/* </a> */}
                                </div>
                            }

                            {
                                this.state.canViewDoc &&
                                <div className='card-bottom-content'>
                                    {/* <a className='card-bottom-content' download={this.props.data.file} href={this.props.data.file}> */}
                                    <button
                                        onClick={(e) => { window.location.href = this.props.link; this.props.action && this.props.action(); }}
                                        title="Voir plus"
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg>
                                    </button>
                                    {/* </a> */}
                                </div>
                            }

                            {
                                this.props.isModifiable && this.state.canUpdateDoc &&
                                <div className='card-bottom-content'>
                                    <button
                                        onClick={(e) => {
                                            this.props.updateAction && this.props.updateAction();
                                        }}
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </button>
                                </div>
                            }

                            {
                                this.props.isModifiable && this.state.canDeleteDoc && 
                                <div className='card-bottom-content'> 
                                    <button style={{ color: "red" }}
                                        onClick={(e) => {
                                            this.props.deleteACtion && this.props.deleteACtion();
                                        }}
                                    >
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ProjectCard;