import React from 'react';
import DocViewer, {DocViewerRenderers} from 'react-doc-viewer';

import './style/style.scss';
import './style/responsive.style.scss';

import PATH from '../../GLOBAL-VAR/globals-urls';

class LawTextCard extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            projectLink: '',
            percent: null,
        }

        this.path = new PATH();
    }


    componentDidMount() {
        this.setState({
            link: this.props.link,
        });
    }

    render() {
        return (
            <div className="LawTextCard">
                <div className="LawTextCard_imageContainer">
                    <a href={this.props.link}
                        onClick={() => {
                            // this.props.action && this.props.action();
                        }}
                    >
                        <DocViewer
                            documents={[{
                                uri:this.path.api_ + this.props.lawText.content,
                            }]}
                            pluginRenderers={DocViewerRenderers}
                            config={{
                                header: {
                                    disableHeader: true,
                                    disableFileName: true,
                                    retainURLParams: false,
                                },
                            }}
                            style={{
                                marginTop: "-80px"
                            }}
                        />
                    </a>
                </div>
                <div className="LawTextCard_body">
                    <a href={this.props.link} className='LawTextCard_body_title_link'>
                        <div className="LawTextCard_body_title">
                            <h1>{this.props.lawText.details.text_number && this.props.lawText.details.text_number}</h1>
                        </div>
                    </a>
                    {/* <div className="LawTextCard_body_title LawTextCard_body_date">
                        <span>{this.props.elt.month}/{this.props.elt.year}</span>
                    </div> */}
                    <div className="LawTextcard_separator_line"></div>
                    <br/>
                    <div className="LawTextCard_body_text lineHeight1">
                        <p className='LawTextCard_body_text_name'>{this.props.lawText.name} portant : {this.props.lawText.details.subject}</p>
                    </div>
                    {/* <div className="LawTextCard_competence">
                        {
                            this.props.elt.areas &&
                            this.props.elt.areas.map((competence, index) => (
                                <div key={"competences" + index} className="competence">
                                    <a className="link" href={this.props.competenceLink + competence.id}
                                        onClick={() => {
                                            // this.props.action2 && this.props.action2();
                                        }}
                                    >
                                        <span>
                                            {competence.name}
                                        </span>
                                    </a>
                                </div>
                            ))
                        }
                    </div> */}
                </div>
                <div className='LawTextCard_foot'>
                    <a className="link" href={this.props.link}>
                        <span>AFFICHER</span>
                        <span className='LawTextCard_foot_fake'></span>
                    </a>
                </div>
            </div>
        )
    }
}

export default LawTextCard;