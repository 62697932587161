// import { ReactDOM } from 'react';
import React from 'react';
import parse from 'html-react-parser';

/**Importation des styles de la page */
import './style/style.scss';
import './style/responsive.style.scss';

/**Importation des composants */

/**Importation des images*/

class TextViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewPending: true,
            viewInGoodProgress: true,
            viewInBadProgress: true,
            viewLate: true,
            viewGoodFinished: true,
            viewBadFinished: true,
        }
    }

    componentDidMount() {
        
    }

    _generateChronogram(list, state=0, parent=null) {
        return(
            <ul className={ state===0 ? 'textView_list_' : "textView_list"}>
                {
                    list.map((elt, index) => (
                        elt!==-1 && index !==-1 &&
                        ((state===0 && (this.props.positions[index] && ((this.props.positions[index].split(",")).length===1)) ) || (state!==0)) && 
                        <li className={state===0 ? 'textView_list_elt textView_list_' : "textView_list_elt"}>
                            <div className='textView_list_elt_text'>
                                <span className={'textView_list_elt_text_ title_L' + this.props.list[state === 0 ? index : elt].title_level}>
                                    {this.props.list[state === 0 ? index : elt].name + (this.props.list[state === 0 ? index : elt].title !== "" ? " : "+this.props.list[state === 0 ? index : elt].title : "")}
                                </span>
                            </div>
                            {
                                this.props.list[state === 0 ? index : elt].text ? 
                                    <div className='textView_list_elt_content'>
                                        {
                                            parse(this.props.list[state === 0 ? index : elt].text)
                                        }
                                    </div>
                                :
                                    null
                            }
                            { state===0 ? (elt.length > 0 ? this._generateChronogram(elt, state+1, index) : "") : (this.props.tree[elt].length > 0 ?  (this._generateChronogram(this.props.tree[elt], state+1, elt)) : "")}
                        </li>
                    ))
                }
            </ul>
        )
    }

    
    render() {
        return (
            <div>
                <div className='textView'>
                    {this._generateChronogram(this.props.tree)}
                </div>
            </div>
        )
    }
}

export default TextViewer;