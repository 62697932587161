// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/


class Progress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           
        }
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div className='Progress_'>
                <div className="progress_">
                    <svg className="progress_-circle" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg">
                        <circle className="progress_-circle-back" cx="80" cy="80" r="74"></circle>
                        <circle className="progress_-circle-prog" cx="80" cy="80" r="74"
                            style={{
                                strokeDasharray: this.props.progression * ((4.64 * 100) /  100) + ' 999',
                                stroke: this.props.progression > 90 ? "#5dc269" : "#122f4c",
                            }}
                        ></circle>
                    </svg>
                    <div className="progress_-text" data-progress="0">{this.props.progression / 100 * 100}%</div>
                </div>

                <div className='progression_-title'>
                    <span>
                        {this.props.title}
                    </span>
                </div>
            </div>
        )
    }
}

export default Progress;