import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

import './style/style.css';
import './style/responsive.style.css';

import AccessStore from '../../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}
class NoAccess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }

        this.accessStore = new AccessStore( this.props.dispatch);

    }

    componentDidMount() {
        
    }
  
    render() {
        return (
            <div className="NoAccess2_container">
                <div className="NoAccess2_container_">
                    <p className="NoAccess2_container_title">
                        Oops, vous n'êtes pas autorisé à acceder au système.
                    </p>
                    <p>
                        Votre accréditation est en court.
                    </p>
                    <p>
                        Vous pouvez vous déconnecter afin d'utilisé les fonctionnalités normales du système
                    </p>

                    <button className="button-style-2 NoAccess-button"
                        onClick={(e) => {
                            this.props.onClick();
                        }}
                    >
                        Se déconnecter
                    </button>
                </div>
            </div>
        )
    }
}


NoAccess = connect(mapStateToProps, null)(NoAccess);
export default NoAccess;
